<template>
  <div v-if="Propositions?.length > 0">
    <!-- loading -->
    <v-row>
      <v-col md="12" lg="12" xl="12" cols="12" v-if="dialog == true">
        <loadingComponent @closeDialog="dialog = $event" :messageDialogeProps="messageDialogeError" :dialogProps="dialog"
          :loadingProps="loading" :errorProps="error" />
      </v-col>
    </v-row>

    <!-- presentation pdf -->
    <presentation :donnerCompteur="donnerCompteur" :parametreCompteur="parametreCompteur"
      :donnerDesFournisseur="Propositions" :dataEnedis="enedisData" :numCompteur="numCompteur" :turpeData="turpeData"
      :indexes="selectedData" :typeCompteur="typeCompteur" :fournisseurActuelle="fournisseurActuelle"
      :societe="SocieteData" />

    <!-- liste proposition par compteur -->
    <v-expansion-panels multiple class="my-8">
      <v-expansion-panel 
      class="px-6 py-4 mt-1" 
      style="
      border:1px solid rgb(199, 204, 216);
      box-shadow: 0px 4px 16px 0px rgb(199, 204, 216);
      " 
      v-for="(PDL, indexPDL) in Propositions" :key="indexPDL">
        <!-- header -->
        <v-expansion-panel-header style="padding: 0px">
          <div class="d-flex">
            <span>N° Compteur:&nbsp;&nbsp;</span>
            <span style="font-size: 18px;font-weight:bold;color: #02325c;">{{ PDL.NumCompteur }}</span>
          </div>
        </v-expansion-panel-header>

        <div v-if="filteredData(PDL.resultat?.result).length>0">
          <v-divider class="mt-0"></v-divider>
          <!-- logo list -->
          <ul class="list-inline" style="padding: 0px">
            <li 
            class="list-inline-item" 
            v-for="(icon, index_v) in filteredData(PDL.resultat?.result)" 
            :key="icon.name" >
              <v-tooltip bottom style="z-index:1000;">
                <template v-slot:activator="{ on, attrs }">
                  <div 
                  style="
                  position: relative;
                  border: 2px solid #f04f4f; 
                  border-radius: 10px; 
                  padding:10px; background: #fff;"
                  class="td-table shadow" 
                  v-bind="attrs" 
                  v-on="on">
                    <v-img 
                    width="120px"
                    height="40px"
                    :src="getImageLogo(icon.logo)"
                    >
                    </v-img>
                    <v-icon style="position:absolute; top: 2px; right: 2px;" color="#EED202">mdi-alert</v-icon>
                  </div>
                </template>
                <p style="color: white">{{ icon.message }}</p>
              </v-tooltip>
            </li>
          </ul>
        </div>

        <!-- propositions list -->
        <v-expansion-panel-content>
          <v-expansion-panel 
          style="
          z-index: 1;
          border-radius: 5px;
          padding: 24px 15px;
          margin-bottom: 20px;
          border: 1px solid rgb(199, 204, 216);
          box-shadow: 0px 4px 10px 0px rgb(174, 209, 204);
          " 
          v-for="(company, index) in PDL.resultat.result" 
          :key="company.name"  
          v-if="company.status != 'enattend' && (('message' in company == false) || ('offreIdResult' in company == true))"
          >
            <v-expansion-panel-header
            hide-actions 
            class="d-flex flex-column align-items-start pa-0 elevation-0" 
            style="z-index: 1; background: #ffffff">
              <!-- warning message -->
              <div class="ml-8 mt-2" v-if="company.fournisseur == 'Gazel Energie'">
                <p style="color: red">
                  Attention, avant l'edition du contrat une verification du BILAN
                  2021/2022 est necessaire
                </p>
              </div>
              <div outlined class="d-flex flex-row" style="justify-content: space-between; background: #ffffff">
                <!-- checkbox + logo icon -->
                <div class="mb-2 mt-2">
                  <v-checkbox 
                  v-if="PDL.resultat.actuelle == true &&  index == 0 &&  company.typeFournisseur == 'Actuelle'" 
                  style="position: absolute; top: 0; left: 0; z-index: 3" 
                  class="ma-0 pa-0" 
                  input-value="true" 
                  :disabled="true">
                  </v-checkbox>
                  <v-checkbox 
                  v-else 
                  style="position: absolute; top: 0; left: 0; z-index: 3" 
                  class="ma-0 pa-0"
                  v-model="selectedData[indexPDL]" 
                  @click.capture.stop 
                  :value="index" 
                  :disabled="(selectedData[indexPDL].indexOf(index) === -1 &&
                    selectedData[indexPDL].length > 5) ||
                    (selectedData[indexPDL].indexOf(index) === -1 &&
                    selectedData[indexPDL].length > 4 &&
                    PDL.resultat.actuelle == true)
                  "
                  ></v-checkbox>
                  <v-img v-if="company.fournisseur == 'OHM'" style="border-radius: 5px" overflow="hiden"
                    max-height="80px" width="200px" class="ml-16 mr-10" contain :src="getImageLogo(company.logo)">
                  </v-img>
                  <v-img v-else class="ml-16" overflow="hiden" max-height="80px" width="200px" contain 
                  :src="getImageLogo(company.logo)">
                  </v-img>
                </div>

                <!-- edit icons -->
                <div 
                v-if="(company.fournisseur != 'Enovos' &&
                    company.fournisseur != 'Synelva' &&
                    company.fournisseur != 'WEKIWI' &&
                    company.fournisseur != 'Gazel Energie') ||
                  (company.fournisseur == 'OHM' && company.type == 'Prix Fixe')
                  " 
                style="height: fit-content"
                @click="readonlyExpansionPanels = true" 
                >
                  <div 
                  v-if="company.fournisseur == 'engie' || company.fournisseur == 'Engie'"
                  style="position: absolute; top: 0px; right: 0px;"
                  >
                    <engiePro @readonlyExpand="readonlyExpansionPanels = $event"
                      :donner="company" :optionTarifaire="PDL.resultat.donnerCompteur['optionTarifaire']" :numCompteur="PDL.resultat.numCompteur"
                      :societe="PDL.resultat.dataSociete" :donnerEnedis="PDL.resultat.dataEnedis" />
                  </div>
                  <div 
                  v-if="company.fournisseur == 'Ekwateur'" 
                  style="position: absolute; top: 0px; right: 0px;"
                  >
                    <EkwateurContrat @readonlyExpand="readonlyExpansionPanels = $event" :donner="company"
                      :listCompteur="listCompteur" :optionTarifaire="PDL.resultat.donnerCompteur['optionTarifaire']" :numCompteur="PDL.resultat.numCompteur"
                      :societe="PDL.resultat.dataSociete" :donnerEnedis="PDL.resultat.dataEnedis" />
                  </div>

                  <div 
                  v-if="(company.fournisseur == 'totale energie' || company.fournisseur == 'TDE') && company.type != 'HORIZON' && company.type != 'Horizon'"
                  style="position: absolute; top: 0px; right: 0px;"
                  >
                    <Totale :donner="company" :numCompteur="PDL.resultat.numCompteur" :societe="PDL.resultat.dataSociete"
                      :donnerEnedis="PDL.resultat.dataEnedis" />
                  </div>

                  <div 
                  v-if="(company.fournisseur == 'totale energie' || company.fournisseur == 'TDE') && (company.type == 'HORIZON' || company.type == 'Horizon')"
                  style="position: absolute; top: 0px; right: 0px;"
                  >
                      <TotalHorizonOffre :donner="company" :numCompteur="PDL.resultat.numCompteur" :societe="PDL.resultat.dataSociete"
                      :donnerEnedis="PDL.resultat.dataEnedis" />
                  </div>
                  <div 
                  v-if="company.fournisseur == 'EDS'"
                  style="position: absolute; top: 0px; right: 0px;"
                  >
                    <edsContrat :optionTarifaire="PDL.resultat.donnerCompteur['optionTarifaire']" :donner="company" :numCompteur="PDL.resultat.numCompteur"
                      :societe="PDL.resultat.dataSociete" :donnerEnedis="PDL.resultat.dataEnedis" />
                  </div>

                  <div 
                  v-if="company.fournisseur == 'Primeo'"
                  style="position: absolute; top: 0px; right: 0px;"
                  >
                    <!--<premioContrat  :codePremio="codePremio" :optionTarifaire="PDL.resultat.donnerCompteur['optionTarifaire']" :donner="company" :numCompteur="PDL.resultat.numCompteur" :societe="PDL.resultat.dataSociete" :donnerEnedis="PDL.resultat.dataEnedis" />-->
                    <primeoMulti @readonlyExpand="readonlyExpansionPanels = $event" :codePremio="listecodePremio"
                      :optionTarifaire="PDL.resultat.donnerCompteur['optionTarifaire']" :donner="company" :numCompteur="PDL.resultat.numCompteur"
                      :societe="PDL.resultat.dataSociete" :donnerEnedis="PDL.resultat.dataEnedis" />
                  </div>
                  
                  <div 
                  v-if="'fournisseur' in company && company.fournisseur.toLowerCase() == 'vattenfall' && company.type == 'prix fixe'"
                  style="position: absolute; top: 0px; right: 0px;" 
                  >
                    <vattenfallContrat :donner="company" :numCompteur="PDL.resultat.numCompteur" :societe="PDL.resultat.dataSociete"
                      :donnerEnedis="PDL.resultat.dataEnedis" />
                  </div>
                  <div v-if="company.fournisseur == 'LLUMC5'"
                  style="position: absolute; top: 0px; right: 0px;" 
                  >
                    <LLUMContratElecC5 @readonlyExpand="readonlyExpansionPanels = $event" :donner="company"
                      :optionTarifaire="PDL.resultat.donnerCompteur['optionTarifaire']" :numCompteur="PDL.resultat.numCompteur" :societe="PDL.resultat.dataSociete"
                      :donnerEnedis="PDL.resultat.dataEnedis" />
                  </div>
                  <div 
                  v-if="company.fournisseur == 'LLUM'" 
                  style="position: absolute; top: 0px; right: 0px;"
                  >
                    <LLUMContratElec @readonlyExpand="readonlyExpansionPanels = $event" :donner="company"
                      :optionTarifaire="PDL.resultat.donnerCompteur['optionTarifaire']" :numCompteur="PDL.resultat.numCompteur" :societe="PDL.resultat.dataSociete"
                      :donnerEnedis="PDL.resultat.dataEnedis" />
                  </div>
                  <div 
                  v-if="company.fournisseur == 'Elecocite' && company.typeFournisseur == 'normal'" 
                  style="position: absolute; top: 0px; right: 0px;"
                  >
                    <ElecociteContrat @readonlyExpand="readonlyExpansionPanels = $event" :donner="company"
                      :optionTarifaire="PDL.resultat.donnerCompteur['optionTarifaire']" :numCompteur="PDL.resultat.numCompteur" :societe="PDL.resultat.dataSociete"
                      :donnerEnedis="PDL.resultat.dataEnedis" />
                  </div>
                  <div 
                  v-if="company.fournisseur == 'EngieEc'" 
                  style="position: absolute; top: 0px; right: 0px;"
                  >
                    <engieECcontrat @readonlyExpand="readonlyExpansionPanels = $event" :donner="company"
                      :optionTarifaire="PDL.resultat.donnerCompteur['optionTarifaire']" :numCompteur="PDL.resultat.numCompteur" :societe="PDL.resultat.dataSociete"
                      :donnerEnedis="PDL.resultat.dataEnedis" />
                  </div>
                </div>
              </div>

              <!-- header informations -->
              <div class="d-flex flex-column ma-4">
                <div class="d-flex flex-row align-center" v-if="company.typeFournisseur != 'AuFrn' &&
                  company.typeFournisseur != 'Actuelle'
                  ">
                  <p v-if="company.type" class="grey--text font-weight-light mx-2 mb-0">
                    Type d'offre :
                    <span style="color: #103a5e" class="text-body-1 font-weight-bold">{{
                      company.type
                    }}</span>
                  </p>
                  <p class="grey--text font-weight-light mx-2 mb-0">
                    Début de contrat :
                    <span style="color: #103a5e" class="text-body-1 font-weight-bold">{{
                      company.dateDebut
                    }}</span>
                  </p>
                  <p class="grey--text font-weight-light mx-2 mb-0">
                    Fin de contrat :<span style="color: #103a5e" class="text-body-1 font-weight-bold ml-2">{{ company.dateFin
                    }}</span>
                  </p>
                  <p class="grey--text font-weight-light mx-2 mb-0">
                    Budget annuel :<span style="color: #103a5e" class="text-body-1 font-weight-bold ml-2">{{ "" +
                      company.budget.toLocaleString() }} €</span>
                  </p>
                </div>
                <div class="d-flex flex-row align-center" v-else>
                  <p class="grey--text font-weight-light mb-0 mx-2">
                    Type d'offre :
                    <span style="color: #103a5e" class="text-body-1 font-weight-bold">{{
                      company.type
                    }}</span>
                  </p>
                  <p class="grey--text font-weight-light mb-0 mx-2">
                    Durée de contrat :
                    <span style="color: #103a5e" class="text-body-1 font-weight-bold">{{ company.DUREE }} mois</span>
                  </p>
                  <p class="grey--text font-weight-light mx-2 mb-0">
                    Budget annuel :<span style="color: #103a5e" class="text-body-1 font-weight-bold ml-2">{{ "" +
                      company.budget.toLocaleString() }} €</span>
                  </p>
                </div>
              </div>
              
              <!-- header price panelData informations -->
              <v-col 
              cols="12" sm="12" md="12" lg="12" xl="12"
              style="background: #024f72" 
              class="header d-flex flex-column px-2" 
              :id="index" 
              v-if="index != panelData">
                <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="d-flex flex-column pt-0 px-0 pb-0">
                  <v-card class="d-flex flex-row justify-space-between rounded-0"
                    style="background: transparent; box-shadow: 0px 0px 0px 0px">
                    <v-col class="pa-0" cols="10" sm="4" md="3" lg="2" xl="2" align="center" justify="center">
                      <p class="d-flex flex-column text-body-1 ma-0">
                        <span class="text-body-1 font-weight-light" style="color: #fff">Budget KWh</span>
                        <span class="text-body-1 font-weight-bold" style="color: #fff">
                          {{ "" + company.budgetKWH.toLocaleString() }} €
                        </span>
                      </p>
                    </v-col>
                    <v-col class="pa-0" cols="10" sm="4" md="3" lg="2" xl="2" align="center" justify="center">
                      <p class="d-flex flex-column text-body-1 ma-0">
                        <span class="text-body-1 font-weight-light" style="color: #fff">CAPA</span>
                        <span class="text-body-1 font-weight-bold" style="color: #fff">
                        {{ company.budgetCapa == 0
                          ? "Inclus"
                          : "" + company.budgetCapa.toLocaleString() + "€" }}
                        </span>
                      </p>
                    </v-col>
                    <v-col class="pa-0" cols="10" sm="4" md="3" lg="2" xl="2" align="center" justify="center">
                      <p class="d-flex flex-column text-body-1 ma-0">
                        <span class="text-body-1 font-weight-light"style="color: #fff">TURPE</span>
                        <span v-if="company.TURPE == 'inclus'" class="text-body-1 font-weight-bold" style="color: #fff">Inclus</span>
                        <span v-else class="text-body-1 font-weight-bold" style="color: #fff">
                          {{ "" + company.TURPE.toLocaleString() }} €
                        </span>
                      </p>
                    </v-col>
                    <v-col class="pa-0" cols="10" sm="4" md="3" lg="2" xl="2" align="center" justify="center">
                      <p class="d-flex flex-column text-body-1 ma-0">
                        <span class="text-body-1 font-weight-light" style="color: #fff">TAXE</span>
                        <span class="text-body-1 font-weight-bold" style="color: #fff">
                         {{ "" + company.budgetTaxe.toLocaleString() }} €
                        </span>
                      </p>
                    </v-col>
                  </v-card>
                </v-col>
              </v-col>
            </v-expansion-panel-header>

            


            <!-- price info -->
            <v-expansion-panel-content class="pa-0" v-if="!company.hasOwnProperty('message')">
              <v-row justify="space-between" outlined no-gutters class="pa-4 header">
                <!-- Prix du KWh -->

                <v-col align="center" cols="10" sm="4" md="3" lg="2" xl="2" class="pl-4">
                  <div class="d-flex flex-column mb-4" v-if="PDL.resultat.typeCompteur == 'C3'">
                    <span class="text-body-1 font-weight-bold mb-1" style="color: #365360;font-weight: 500;">POINTE</span>
                    <span style="color: #d27d00" class="text-body-1 font-weight-bold">
                      {{ "" + company.unitairepointe.toLocaleString() }} €/MWh
                    </span>
                  </div>
                  <div class="d-flex flex-column mb-4" v-if="PDL.resultat.typeCompteur == 'C5HP'">
                    <span class="text-body-1 font-weight-bold mb-1" style="color: #365360;font-weight: 500;">HP</span>
                    <span style="color: #d27d00" class="text-body-1 font-weight-bold">
                      {{ "" + company.unitairehp.toLocaleString() }} €/MWh
                    </span>
                  </div>
                  <div class="d-flex flex-column mb-4" v-if="PDL.resultat.typeCompteur == 'C5HP'">
                    <span class="text-body-1 font-weight-bold mb-1" style="color: #365360;font-weight: 500;">HC</span>
                    <span style="color: #d27d00" class="text-body-1 font-weight-bold">
                      {{ "" + company.unitairehc.toLocaleString() }} €/MWh
                    </span>
                  </div>
                  <div class="d-flex flex-column mb-4" v-if="PDL.resultat.typeCompteur == 'C5BASE'">
                    <span class="text-body-1 font-weight-bold mb-1" style="color: #365360;font-weight: 500;">BASE
                    </span>
                    <span style="color: #d27d00" class="text-body-1 font-weight-bold">{{ "" + company.unitaire.toLocaleString() }}
                      €/MWh</span>
                  </div>
                  <div class="d-flex flex-column mb-4" v-if="PDL.resultat.typeCompteur == 'C3' || PDL.resultat.typeCompteur == 'C4'">
                    <span class="text-body-1 font-weight-bold mb-1" style="color: #365360;font-weight: 500;">HPH
                    </span>
                    <span style="color: #d27d00" class="text-body-1 font-weight-bold">{{ "" + company.unitairehph.toLocaleString()
                    }} €/MWh</span>
                  </div>
                  <div class="d-flex flex-column mb-4" v-if="PDL.resultat.typeCompteur == 'C3' || PDL.resultat.typeCompteur == 'C4'">
                    <span class="text-body-1 font-weight-bold mb-1" style="color: #365360;font-weight: 500;">HCH
                    </span>
                    <span style="color: #d27d00" class="text-body-1 font-weight-bold">{{ "" + company.unitairehch.toLocaleString()
                    }} €/MWh</span>
                  </div>
                  <div class="d-flex flex-column mb-4" v-if="PDL.resultat.typeCompteur == 'C3' || PDL.resultat.typeCompteur == 'C4'">
                    <span class="text-body-1 font-weight-bold mb-1" style="color: #365360;font-weight: 500;">HPE
                    </span>
                    <span style="color: #d27d00" class="text-body-1 font-weight-bold">{{ "" + company.unitairehpe.toLocaleString()
                    }} €/MWh</span>
                  </div>
                  <div class="d-flex flex-column mb-4" v-if="PDL.resultat.typeCompteur == 'C3' || PDL.resultat.typeCompteur == 'C4'">
                    <span class="text-body-1 font-weight-bold mb-1" style="color: #365360;font-weight: 500;">HCE
                    </span>
                    <span style="color: #d27d00" class="text-body-1 font-weight-bold">{{ "" + company.unitairehce.toLocaleString()
                    }} €/MWh</span>
                  </div>
                  <div class="d-flex flex-column mb-4">
                    <span class="text-body-1 font-weight-bold mb-1" style="color: #365360;font-weight: 500;">CEE
                    </span>
                    <span style="color: #d27d00" class="text-body-1 font-weight-bold" v-if="company.cee == 'NON SOUMIS'">NON
                      SOUMIS</span>
                    <span style="color: #d27d00" class="text-body-1 font-weight-bold" v-else>{{ "" + company.cee.toLocaleString()
                    }}€/MWh</span>
                  </div>
                  <div class="d-flex flex-column mb-4">
                    <span class="text-body-1 font-weight-bold mb-1" style="color: #365360;font-weight: 500;">Abonnement mensuel
                    </span>
                    <span style="color: #d27d00" class="text-body-1 font-weight-bold">{{ "" + company.mensuel.toLocaleString() }}
                      €</span>
                  </div>
                </v-col>

                <!-- CAPA -->
                <v-col align="center" class="pt-0 d-flex flex-column" cols="10" sm="4" md="3" lg="2" xl="2">
                  <div class="d-flex flex-column mb-4" v-if="PDL.resultat.typeCompteur == 'C3'">
                    <span class="text-body-1 font-weight-bold mb-1" style="color: #365360;font-weight: 500;">POINTE
                    </span>
                    <span style="color: #d27d00" class="text-body-1 font-weight-bold"
                      v-if="company.capa_pointe == 'inclus'">Inclus</span>
                    <span style="color: #d27d00" class="text-body-1 font-weight-bold" v-else>{{
                      company.budgetCapa == 0
                      ? "Inclus"
                      : "" + company.capa_pointe.toLocaleString() + "€/MWh"
                    }}
                    </span>
                  </div>
                  <div class="d-flex flex-column mb-4" v-if="PDL.resultat.typeCompteur == 'C5HP'">
                    <span class="text-body-1 font-weight-bold mb-1" style="color: #365360;font-weight: 500;">HP</span>
                    <span style="color: #d27d00" class="text-body-1 font-weight-bold"
                      v-if="company.capa_hp == 'inclus'">Inclus</span>
                    <span style="color: #d27d00" class="text-body-1 font-weight-bold" v-else>{{
                      company.budgetCapa == 0
                      ? "Inclus"
                      : "" + company.capa_hp.toLocaleString() + "€/MWh"
                    }}</span>
                  </div>
                  <div class="d-flex flex-column mb-4" v-if="PDL.resultat.typeCompteur == 'C5HP'">
                    <span class="text-body-1 font-weight-bold mb-1" style="color: #365360;font-weight: 500;">HC
                    </span>
                    <span style="color: #d27d00" class="text-body-1 font-weight-bold"
                      v-if="company.capa_hc == 'inclus'">Inclus</span>
                    <span style="color: #d27d00" class="text-body-1 font-weight-bold" v-else>{{
                      company.budgetCapa == 0
                      ? "Inclus"
                      : "" + company.capa_hc.toLocaleString() + "€/MWh"
                    }}</span>
                  </div>
                  <div class="d-flex flex-column mb-4" v-if="PDL.resultat.typeCompteur == 'C5BASE'">
                    <span class="text-body-1 font-weight-bold mb-1" style="color: #365360;font-weight: 500;">BASE
                    </span>
                    <span style="color: #d27d00" class="text-body-1 font-weight-bold"
                      v-if="company.capa_base == 'inclus'">Inclus</span>
                    <span style="color: #d27d00" class="text-body-1 font-weight-bold" v-else>{{
                      company.budgetCapa == 0
                      ? "Inclus"
                      : "" + company.capa_base.toLocaleString() + "€/MWh"
                    }}</span>
                  </div>
                  <div class="d-flex flex-column mb-4" v-if="PDL.resultat.typeCompteur == 'C3' || PDL.resultat.typeCompteur == 'C4'">
                    <span class="text-body-1 font-weight-bold mb-1" style="color: #365360;font-weight: 500;">HPH
                    </span>
                    <span style="color: #d27d00" class="text-body-1 font-weight-bold"
                      v-if="company.capa_hph == 'inclus'">Inclus</span>
                    <span style="color: #d27d00" class="text-body-1 font-weight-bold" v-else>{{
                      company.budgetCapa == 0
                      ? "Inclus"
                      : "" + company.capa_hph.toLocaleString() + "€/MWh"
                    }}</span>
                  </div>
                  <div class="d-flex flex-column mb-4" v-if="PDL.resultat.typeCompteur == 'C3' || PDL.resultat.typeCompteur == 'C4'">
                    <span class="text-body-1 font-weight-bold mb-1" style="color: #365360;font-weight: 500;">HCH
                    </span>
                    <span style="color: #d27d00" class="text-body-1 font-weight-bold"
                      v-if="company.capa_hch == 'inclus'">Inclus</span>
                    <span style="color: #d27d00" class="text-body-1 font-weight-bold" v-else>{{
                      company.budgetCapa == 0
                      ? "Inclus"
                      : "" + company.capa_hch.toLocaleString() + "€/MWh"
                    }}</span>
                  </div>
                  <div class="d-flex flex-column mb-4" v-if="PDL.resultat.typeCompteur == 'C3' || PDL.resultat.typeCompteur == 'C4'">
                    <span class="text-body-1 font-weight-bold mb-1" style="color: #365360;font-weight: 500;">HPE
                    </span>
                    <span style="color: #d27d00" class="text-body-1 font-weight-bold"
                      v-if="company.capa_hpe == 'inclus'">Inclus</span>
                    <span style="color: #d27d00" class="text-body-1 font-weight-bold" v-else>{{
                      company.budgetCapa == 0
                      ? "Inclus"
                      : "" + company.capa_hpe.toLocaleString() + "€/MWh"
                    }}</span>
                  </div>
                  <div class="d-flex flex-column mb-4" v-if="PDL.resultat.typeCompteur == 'C3' || PDL.resultat.typeCompteur == 'C4'">
                    <span class="text-body-1 font-weight-bold mb-1" style="color: #365360;font-weight: 500;">HCE
                    </span>
                    <span style="color: #d27d00" class="text-body-1 font-weight-bold"
                      v-if="company.capa_hce == 'inclus'">Inclus</span>
                    <span style="color: #d27d00" class="text-body-1 font-weight-bold" v-else>{{
                      company.budgetCapa == 0
                      ? "Inclus"
                      : "" + company.capa_hce.toLocaleString() + "€/MWh"
                    }}</span>
                  </div>
                </v-col>

                <!-- TURP -->
                <v-col cols="10" sm="4" md="3" lg="2" xl="2" align="center" class="pt-0 d-flex flex-column">
                  <div>
                    <p class="mb-4 d-flex flex-column">
                      <span class="text-body-1 font-weight-bold mb-1" style="color: #365360;font-weight: 500;">Énergie
                      </span>
                      <span style="color: #d27d00" class="text-body-1 font-weight-bold"
                        v-if="company.TURPE == 'inclus'">Inclus</span>
                      <span style="color: #d27d00" class="text-body-1 font-weight-bold" v-else>{{ "" +
                        PDL.resultat.dataTurpe.energieTurp.toLocaleString() }} €</span>
                    </p>
                  </div>
                  <div>
                    <p class="mb-4 d-flex flex-column">
                      <span class="text-body-1 font-weight-bold mb-1" style="color: #365360;font-weight: 500;">C.A de gestion
                      </span>
                      <span style="color: #d27d00" class="text-body-1 font-weight-bold" v-if="company.CAG == 'inclus'">Inclus</span>
                      <span style="color: #d27d00" class="text-body-1 font-weight-bold" v-else>{{ "" + company.CAG.toLocaleString()
                      }} €</span>
                    </p>
                  </div>
                  <div>
                    <p class="mb-4 d-flex flex-column">
                      <span class="text-body-1 font-weight-bold mb-1" style="color: #365360;font-weight: 500;">C.A de comptage
                      </span>
                      <span style="color: #d27d00" class="text-body-1 font-weight-bold"
                        v-if="company.CACC == 'inclus'">Inclus</span>
                      <span style="color: #d27d00" class="text-body-1 font-weight-bold" v-else>{{ "" + company.CACC.toLocaleString()
                      }} €</span>
                    </p>
                  </div>
                  <div>
                    <p class="mb-4 d-flex flex-column">
                      <span class="text-body-1 font-weight-bold mb-1" style="color: #365360;font-weight: 500;">Puissance souscrite
                      </span>
                      <span style="color: #d27d00" class="text-body-1 font-weight-bold"
                        v-if="company.TURPE == 'inclus'">Inclus</span>
                      <span style="color: #d27d00" class="text-body-1 font-weight-bold" v-else>{{ "" +
                        PDL.resultat.dataTurpe.PSTurpe.toLocaleString() }} €</span>
                    </p>
                  </div>
                </v-col>

                <!-- TAXE -->
                <v-col cols="10" sm="4" md="3" lg="2" xl="2" align="center" class="pt-0 d-flex flex-column">
                  <!-- <div>
                    <p class="mb-4 d-flex flex-column">
                      <span class="text-body-1 font-weight-bold mb-1" style="color: #365360;font-weight: 500;">TCFE
                      </span>
                      <span style="color: #d27d00" class="text-body-1 font-weight-bold">{{ "" + company.TAXE.toLocaleString() }}
                        €</span>
                    </p>
                  </div> -->
                  <div>
                    <p class="mb-4 d-flex flex-column">
                      <span class="text-body-1 font-weight-bold mb-1" style="color: #365360;font-weight: 500;">CTA
                      </span>
                      <span style="color: #d27d00" class="text-body-1 font-weight-bold">{{ "" + company.CTA.toLocaleString() }}
                        €/an</span>
                    </p>
                    <p class="mb-4 d-flex flex-column">
                      <span class="text-body-1 font-weight-bold mb-1" style="color: #365360;font-weight: 500;">CSPE
                      </span>
                      <span style="color: #d27d00" class="text-body-1 font-weight-bold">{{ "" + company.CSPE.toLocaleString() }}
                        €/MWh</span>
                    </p>
                  </div>
                  <!-- <div>
                    <p class="mb-4 d-flex flex-column">
                      <span class="text-body-1 font-weight-bold mb-1" style="color: #365360;font-weight: 500;">Locales communales
                      </span>
                      <span style="color: #d27d00" class="text-body-1 font-weight-bold">{{ "" +
                        company.taxe_communal.toLocaleString() }} €
                      </span>
                    </p>
                  </div> -->
                  <!-- <div>
                    <p class="mb-4 d-flex flex-column">
                      <span class="text-body-1 font-weight-bold mb-1" style="color: #365360;font-weight: 500;">Locales départementales
                      </span>
                      <span style="color: #d27d00" class="text-body-1 font-weight-bold">{{ "" +
                        company.taxe_departemantale.toLocaleString() }} €</span>
                    </p>
                  </div> -->
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import ElecociteContrat from "../../../views/edition-contrat/Elecocite.vue";
import LLUMContratElecC5 from "../../../views/edition-contrat/LLUMContratElecC5.vue";
import presentation from "../../../views/presentation/NewElecCalcul/newPresentation.vue";
import TestView from "../../../views/TestView";
import premioContrat from "../../../views/edition-contrat/primeoEnergie.vue";
import engiePro from "../../../views/edition-contrat/engiePro.vue";
import engieECcontrat from "../../../views/edition-contrat/engieECcontrat.vue";
import gazelContrat from "../../../views/edition-contrat/gazelContrat.vue";
import Totale from "../../../views/edition-contrat/tataleContrat.vue";
import edsContrat from "../../../views/edition-contrat/EDS.vue";
// import TotalHorizon from "../../../views/edition-contrat/totalHorizon.vue";
import TotalHorizonOffre from "../../../views/edition-contrat/totalHorizonOffre.vue";
import vattenfallContrat from "../../../views/edition-contrat/Vattenfall.vue";
import EkwateurContrat from "../../../views/edition-contrat/Ekwateur.vue";
import LLUMContratElec from "../../../views/edition-contrat/LLUM_ELEC.vue";
import loadingComponent from "../../ui/loading-component";
import primeoMulti from "../../../views/edition-contrat/primeoMulti.vue";
import ohmAvecArenh from "../../../views/edition-contrat/ohmAvecArenh.vue";

import axios from "axios";
export default {
  name: "ListDesPropostions",
  props: {
    donnesDesFournisseurs: [],
    optionTarifaire: "",
    parametreCompteur: null,
    Propositions: [],
    SocieteData: [],
    enedisData: [],
    numCompteur: null,
    turpeData: [],
    taxeData: [],
    typeCompteur: null,
    panel: [],
    fournisseurActuelle: null,
    selected: [],
    listCompteur: [],
    donneDeCotation: [],
    donnerCompteur: [],
  },
  components: {
    TestView,
    premioContrat,
    engiePro,
    gazelContrat,
    Totale,
    edsContrat,
    // TotalHorizon,
    TotalHorizonOffre,
    vattenfallContrat,
    EkwateurContrat,
    LLUMContratElec,
    primeoMulti,
    ohmAvecArenh,
    presentation,
    LLUMContratElecC5,
    loadingComponent,
    engieECcontrat,
    ElecociteContrat,
  },
  data() {
    return {
      selectedData: [],
      panelData: this.panel,
      readonlyExpansionPanels: false,
      dialog: false,
      loading: false,
      error: false,
      codePremio: "",
      listecodePremio: [],
      messageDialogeError: "",
      messageDialogeSucces: "",
    };
  },
  mounted() {
    for (let i = 0; i < this.Propositions?.length; i++) {
        this.selectedData.splice(i, 0, []);
        if (this.Propositions[i].resultat.result.length > 0) {
          // verifie si une offre actuelle existe
          for (let j = 0; j < this.Propositions[i].resultat.result.length; j++)
            if (this.Propositions[i].resultat.result[j].typeFournisseur == "Actuelle"
            ) {
              this.selectedData[i].push(0);
            }
        }
      }
  },
  watch: {
    Propositions: function (newValue) {
      this.selectedData = []
      for (let i = 0; i < this.Propositions?.length; i++) {
        this.selectedData.splice(i, 0, []);
        
        if (this.Propositions[i].resultat.result.length > 0) {
          // verifie si une offre actuelle existe
          for (let j = 0; j < this.Propositions[i].resultat.result.length; j++){
            for (var key in this.Propositions[i].resultat.donnerCompteur) {
              if (this.Propositions[i].resultat.donnerCompteur.hasOwnProperty(key)) {
                this.Propositions[i].resultat.result[j][key] = isNaN(this.Propositions[i].resultat.donnerCompteur[key]) ?this.Propositions[i].resultat.donnerCompteur[key]: parseFloat(this.Propositions[i].resultat.donnerCompteur[key]).toFixed(2)
              }
            }
            this.Propositions[i].resultat.result[j]['parametreCompteur'] = this.Propositions[i].resultat.dataEnedis.parametreDeCompteur
            this.Propositions[i].resultat.result[j]['duree'] = this.Propositions[i].resultat.result[j]['nbrMonth']
            this.Propositions[i].resultat.result[j]['scoreCreditSafe'] = this.Propositions[i].resultat.dataSociete.scoreCreditSafe
            
            if (this.Propositions[i].resultat.result[j].typeFournisseur == "Actuelle") {
              this.selectedData[i].push(0);
            }
          }
            
        }
      }
    }
  },
  methods: {
    async validerOffreMultiPrimeo(offre, demandeur, firstName, lastName, phone, email) {
      var errorValide = 0;
      var msg = "";
      this.dialog = true;
      this.loading = true;
      this.error = false;
      this.messageDialogeError = "";
      this.messageDialogeSucces = "";
      var years = ["2023", "2024", "2025", "2026"];
      for (var i = 0; i < years.length; i++) {
        if (years[i] in offre) {
          var formData = new FormData();
          formData.append("offerId", offre[years[i]].offerId);
          formData.append("transactionId", offre[years[i]].transactionId);
          formData.append("demandeur", demandeur);
          formData.append("firstName", firstName);
          formData.append("lastName", lastName);
          formData.append("phone", phone);
          formData.append("email", email);
          formData.append("numCompteur", this.numCompteur);
          formData.append("raison", this.SocieteData.Raison);
          // cout capa
          //upload pdf file to php server
          await axios({
            url: process.env.VUE_APP_URL_API_CLIENT + "ValiderOffrePrimeoMulti/",
            method: "POST",
            data: formData,
          })
            .then((res) => {
              if (res.data.valider == true) {
                this.listecodePremio = res.data.oppid;
                errorValide = 0;
              } else {
                errorValide = 1;
                msg = res.data.message;
              }
            })
            .catch((err) => {
              errorValide = 2;
            });
          this.loading = false;
          this.error = false;
          this.readonlyExpansionPanels = false;
          if (errorValide == 0) {
            this.messageDialogeSucces = "l'offre a été validée avec succès";
            alert(this.messageDialogeSucces);
          } else if (errorValide == 1) {
            this.messageDialogeError = msg;
            alert(this.messageDialogeError);
          } else if (errorValide == 2) {
            this.messageDialogeError =
              "une erreur s'est produite lors du validation d'offre";
            alert(this.messageDialogeError);
          }
        }
      }
    },
    validOffre(offerId, transactionId, demandeur, firstName, lastName, phone, email) {
      this.dialog = true;
      this.loading = true;
      this.error = false;
      this.messageDialogeError = "";
      this.messageDialogeSucces = "";
      var formData = new FormData();
      formData.append("offerId", offerId);
      formData.append("transactionId", transactionId);
      formData.append("demandeur", demandeur);
      formData.append("firstName", firstName);
      formData.append("lastName", lastName);
      formData.append("phone", phone);
      formData.append("email", email);
      formData.append("numCompteur", this.numCompteur);
      formData.append("raison", this.SocieteData.Raison);
      // cout capa
      //upload pdf file to php server
      axios({
        url: process.env.VUE_APP_URL_API_CLIENT + "ValiderOffrePrimeo/",
        method: "POST",
        data: formData,
      })
        .then((res) => {
          if (res.data.valider == true) {
            this.codePremio = res.data.oppid;
            this.loading = false;
            this.error = false;
            this.messageDialogeSucces = "l'offre a été validée avec succès";
          } else {
            this.loading = false;
            this.error = true;
            this.messageDialogeError = res.data.message;
          }
        })
        .catch((err) => {
          this.loading = false;
          this.error = true;
          this.messageDialogeError =
            "une erreur s'est produite lors du validation d'offre";
        });
    },
    filteredData(dataArray) {
      const filtered = [];
      const seen = new Set();
      dataArray.forEach(item => {
        // Check if logo is the same and status is 'enattend'
        if (item.hasOwnProperty('message') && item.hasOwnProperty('status') && (item.status == 'error' || item.status == 'enattend') && !seen.has(item.logo)) {
          filtered.push(item);
          seen.add(item.logo);
        }
      });
      return filtered;
    },
    getImageLogo(logo) {
      return require("@/assets/logoParticulier/" + logo);
    },
  },
};
</script>
<style scoped>
.v-expansion-panel::before {
    box-shadow: none;
}
.v-expansion-panel::after {
    border: none;
}
::v-deep .v-expansion-panel-content__wrap {
  padding: 0px !important;
}


@media only screen and (max-width: 600px) {
  .marginBtnPresentation {
    padding-right: 0px !important;
    padding-left: 0px !important;
  }
}

@media only screen and (max-width: 350px) {
  ::v-deep .v-expansion-panel-header {
    padding: 15px !important;
  }

  ::v-deep .responsiveSpan {
    font-size: 0.8rem !important;
  }
}
</style>
