<template>
  <div class="mr-2 ml-3">
    <!-- chart 1 -->
    <v-card class="mt-6 pa-4 px-16 page" elevation="10">
      <h1 class="my-4 mb-6">Chart 1</h1>

      <v-subheader class="px-0"
        >Le newsletter du monde de l'energie :</v-subheader
      >
      <v-divider class="mb-12"></v-divider>

      <v-card-text class="pa-5">
        <div id="chart">
          <apexchart
            type="area"
            height="350"
            :options="chartOptions1"
            :series="series1"
          ></apexchart>
        </div>
      </v-card-text>
    </v-card>
    <!-- chart 1 with text -->
    <v-card class="mt-6 pa-4 px-16 page" elevation="10">
      <h1 class="my-4 mb-6">Chart 1</h1>

      <v-subheader class="px-0"
        >Le newsletter du monde de l'energie :</v-subheader
      >
      <v-divider class="mb-12"></v-divider>
      <v-row>
        <v-col cols="12" sm="12" md="12" lg="6" xl="6">
          <v-row
            class="mb-4"
            v-if="isAdmin === true"
            align="center"
            justify="end"
          >
            <v-btn
              :disabled="actionButtonsToggler == 'save'"
              @click="modifyClicked()"
              tile
              class="mr-4"
            >
              <v-icon left> mdi-pencil </v-icon>
              Modifier
            </v-btn>
            <v-btn
              @click="saveClicked()"
              :disabled="actionButtonsToggler == 'modify'"
              tile
              class="mr-4"
            >
              <v-icon left> mdi-content-save </v-icon>
              Enregistrer
            </v-btn>
          </v-row>

          <v-container fluid>
            <v-textarea
              v-if="showTextArea"
              flat
              loader-height="0"
              clear-icon="mdi-close-circle"
              v-model="textChart1"
            ></v-textarea>

            <p v-else class="text-justify">
              {{ textChart1 }}
            </p>
          </v-container>
        </v-col>

        <v-col cols="12" sm="12" md="12" lg="6" xl="6">
          <v-card-text class="pa-5">
            <div id="chart">
              <apexchart
                type="area"
                height="350"
                :options="chartOptions1"
                :series="series1"
              ></apexchart>
            </div>
          </v-card-text>
        </v-col>
      </v-row>
    </v-card>
    <!-- chart 2 -->
    <v-card class="mt-6 pa-4 px-16 page" elevation="10">
      <h1 class="my-4 mb-6">Chart 2</h1>

      <v-subheader class="px-0"
        >Le newsletter du monde de l'energie :</v-subheader
      >
      <v-divider class="mb-12"></v-divider>

      <v-card-text class="pa-5">
        <div id="chart">
          <apexchart
            type="area"
            height="400"
            :options="chartOptions2"
            :series="series2"
          ></apexchart>
        </div>
      </v-card-text>
    </v-card>
    <!-- chart 3 -->
    <v-card class="mt-6 pa-4 px-16 page" elevation="10">
      <h1 class="my-4 mb-6">Chart 3</h1>

      <v-subheader class="px-0"
        >Le newsletter du monde de l'energie :</v-subheader
      >
      <v-divider class="mb-12"></v-divider>

      <v-card-text class="pa-5">
        <div id="chart">
          <apexchart
            type="bar"
            height="400"
            :options="chartOptions3"
            :series="series3"
          ></apexchart>
        </div>
      </v-card-text>
    </v-card>
    <!-- tableau 1 -->
    <v-card class="mt-6 pa-4 px-16 page" elevation="10">
      <h1 class="my-4 mb-6">Tableau 1</h1>

      <v-subheader class="px-0">Gas Price Update:</v-subheader>
      <v-divider class="mb-12"></v-divider>

      <v-card-text class="pa-5">
        <v-row>
          <v-col cols="12" sm="6" class="py-2">
            <v-btn-toggle class="mb-5" mandatory v-model="table_toggle">
              <v-btn class="text-lowercase"> p/therm </v-btn>
              <v-btn class="text-lowercase"> p/kWh </v-btn>
            </v-btn-toggle>
          </v-col>
        </v-row>

        <v-data-table
          v-if="this.table_toggle == 0"
          :headers="this.tableHeadersKwh"
          :items="this.tableData.gazDataKwh"
          item-key="id"
          :options="{ itemsPerPage: 20 }"
          hide-default-footer
        >
          <template v-slot:item.day_on_day="{ item }">
            <span class="negative" v-if="item.day_on_day < 0"
              >{{ item.day_on_day }}
              <v-icon color="red"> mdi-arrow-bottom-right</v-icon></span
            >
            <span class="positive" v-else
              >{{ item.day_on_day }}
              <v-icon color="green"> mdi-arrow-top-right</v-icon></span
            >
          </template>
          <template v-slot:item.day_on_day_perc="{ item }">
            <span class="negative" v-if="item.day_on_day_perc < 0"
              >{{ item.day_on_day_perc }}
              <v-icon color="red"> mdi-arrow-bottom-right</v-icon></span
            >
            <span class="positive" v-else
              >{{ item.day_on_day_perc }}
              <v-icon color="green"> mdi-arrow-top-right</v-icon></span
            >
          </template>
          <template v-slot:item.week_on_day="{ item }">
            <span class="negative" v-if="item.week_on_day < 0"
              >{{ item.week_on_day }}
              <v-icon color="red"> mdi-arrow-bottom-right</v-icon></span
            >
            <span class="positive" v-else
              >{{ item.week_on_day }}
              <v-icon color="green"> mdi-arrow-top-right</v-icon></span
            >
          </template>
        </v-data-table>

        <v-data-table
          v-if="this.table_toggle == 1"
          :headers="this.tableHeadersTherm"
          :items="this.tableData.gazDataTherm"
          item-key="id"
          :options="{ itemsPerPage: 20 }"
          hide-default-footer
        >
          <template v-slot:item.day_on_day="{ item }">
            <span class="negative" v-if="item.day_on_day < 0"
              >{{ item.day_on_day }}
              <v-icon color="red"> mdi-arrow-bottom-right</v-icon></span
            >
            <span class="positive" v-else
              >{{ item.day_on_day }}
              <v-icon color="green"> mdi-arrow-top-right</v-icon></span
            >
          </template>
          <template v-slot:item.day_on_day_perc="{ item }">
            <span class="negative" v-if="item.day_on_day_perc < 0"
              >{{ item.day_on_day_perc }}
              <v-icon color="red"> mdi-arrow-bottom-right</v-icon></span
            >
            <span class="positive" v-else
              >{{ item.day_on_day_perc }}
              <v-icon color="green"> mdi-arrow-top-right</v-icon></span
            >
          </template>
          <template v-slot:item.week_on_day="{ item }">
            <span class="negative" v-if="item.week_on_day < 0"
              >{{ item.week_on_day }}
              <v-icon color="red"> mdi-arrow-bottom-right</v-icon></span
            >
            <span class="positive" v-else
              >{{ item.week_on_day }}
              <v-icon color="green"> mdi-arrow-top-right</v-icon></span
            >
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import { series } from '../assets/data/series.js'
import { series2 } from '../assets/data/series2.js'
import { gazDataKwh } from '../assets/data/gaz_data_kwh.js'
import { gazDataTherm } from '../assets/data/gaz_data_therm.js'

export default {
  components: {
    apexchart: VueApexCharts,
  },

  data() {
    return {
      actionButtonsToggler: 'modify',
      isAdmin: false,
      showTextArea: false,
      textChart1:
        'UK gas prices traded lower yesterday, in line with the market pricing in a lower possibility of Russian gas supply disruptions. UK gas prices rose earlier in the week due to Russia halting gas supplies to Poland and Bulgaria, with both countries refusing to pay for gas in roubles.',
      tableData: { gazDataKwh, gazDataTherm },

      table_toggle: 0,

      tableHeadersTherm: [
        {
          text: 'Gas',
          align: 'left',
          sortable: false,
          //nom de variable dans data
          value: 'gas',
          class: 'font-weight-medium subtitle-1 ',
          cellClass: 'font-weight-light text-no-wrap subtitle-2 body-1 ',
        },
        {
          text: '26th Apr (p/th)',
          align: 'left',
          sortable: false,
          //nom de variable dans data
          value: '26_apr',
          class: 'font-weight-medium subtitle-1 ',
          cellClass: 'font-weight-light text-no-wrap subtitle-2 body-1 ',
        },
        {
          text: '27th Apr (p/th)',
          align: 'left',
          sortable: false,
          //nom de variable dans data
          value: '27_apr',
          class: 'font-weight-medium subtitle-1 ',
          cellClass: 'font-weight-light text-no-wrap subtitle-2 body-1 ',
        },
        {
          text: 'Day-On-Day',
          align: 'left',
          sortable: false,
          //nom de variable dans data
          value: 'day_on_day',
          class: 'font-weight-medium subtitle-1 ',
          cellClass: 'font-weight-light text-no-wrap subtitle-2 body-1 ',
        },
        {
          text: 'Day-On-Day %',
          align: 'left',
          sortable: false,
          //nom de variable dans data
          value: 'day_on_day_perc',
          class: 'font-weight-medium subtitle-1 ',
          cellClass: 'font-weight-light text-no-wrap subtitle-2 body-1 ',
        },
        {
          text: 'Week-On-Day %',
          align: 'left',
          sortable: false,
          //nom de variable dans data
          value: 'week_on_day',
          class: 'font-weight-medium subtitle-1 ',
          cellClass: 'font-weight-light text-no-wrap subtitle-2 body-1 ',
        },
      ],
      tableHeadersKwh: [
        {
          text: 'Gas',
          align: 'left',
          sortable: false,
          //nom de variable dans data
          value: 'gas',
          class: 'font-weight-medium subtitle-1 ',
          cellClass: 'font-weight-light text-no-wrap subtitle-2 body-1 ',
        },
        {
          text: '26th Apr (p/KWh)',
          align: 'left',
          sortable: false,
          //nom de variable dans data
          value: '26_apr',
          class: 'font-weight-medium subtitle-1 ',
          cellClass: 'font-weight-light text-no-wrap subtitle-2 body-1 ',
        },
        {
          text: '27th Apr (p/KWh)',
          align: 'left',
          sortable: false,
          //nom de variable dans data
          value: '27_apr',
          class: 'font-weight-medium subtitle-1 ',
          cellClass: 'font-weight-light text-no-wrap subtitle-2 body-1 ',
        },
        {
          text: 'Day-On-Day',
          align: 'left',
          sortable: false,
          //nom de variable dans data
          value: 'day_on_day',
          class: 'font-weight-medium subtitle-1 ',
          cellClass: 'font-weight-light text-no-wrap subtitle-2 body-1 ',
        },
        {
          text: 'Day-On-Day %',
          align: 'left',
          sortable: false,
          //nom de variable dans data
          value: 'day_on_day_perc',
          class: 'font-weight-medium subtitle-1 ',
          cellClass: 'font-weight-light text-no-wrap subtitle-2 body-1 ',
        },

        {
          text: 'Week-On-Day %',
          align: 'left',
          sortable: false,
          //nom de variable dans data
          value: 'week_on_day',
          class: 'font-weight-medium subtitle-1 ',
          cellClass: 'font-weight-light text-no-wrap subtitle-2 body-1 ',
        },
      ],

      series1: [
        {
          name: 'north',
          data: [
            {
              x: 1996,
              y: 322,
            },
            {
              x: 1997,
              y: 324,
            },
            {
              x: 1998,
              y: 329,
            },
            {
              x: 1999,
              y: 342,
            },
            {
              x: 2000,
              y: 348,
            },
            {
              x: 2001,
              y: 334,
            },
            {
              x: 2002,
              y: 325,
            },
            {
              x: 2003,
              y: 316,
            },
            {
              x: 2004,
              y: 318,
            },
            {
              x: 2005,
              y: 330,
            },
            {
              x: 2006,
              y: 355,
            },
            {
              x: 2007,
              y: 366,
            },
            {
              x: 2008,
              y: 337,
            },
            {
              x: 2009,
              y: 352,
            },
            {
              x: 2010,
              y: 377,
            },
            {
              x: 2011,
              y: 383,
            },
            {
              x: 2012,
              y: 344,
            },
            {
              x: 2013,
              y: 366,
            },
            {
              x: 2014,
              y: 389,
            },
            {
              x: 2015,
              y: 334,
            },
          ],
        },
        {
          name: 'south',
          data: [
            {
              x: 1996,
              y: 162,
            },
            {
              x: 1997,
              y: 90,
            },
            {
              x: 1998,
              y: 50,
            },
            {
              x: 1999,
              y: 77,
            },
            {
              x: 2000,
              y: 35,
            },
            {
              x: 2001,
              y: -45,
            },
            {
              x: 2002,
              y: -88,
            },
            {
              x: 2003,
              y: -120,
            },
            {
              x: 2004,
              y: -156,
            },
            {
              x: 2005,
              y: -123,
            },
            {
              x: 2006,
              y: -88,
            },
            {
              x: 2007,
              y: -66,
            },
            {
              x: 2008,
              y: -45,
            },
            {
              x: 2009,
              y: -29,
            },
            {
              x: 2010,
              y: -45,
            },
            {
              x: 2011,
              y: -88,
            },
            {
              x: 2012,
              y: -132,
            },
            {
              x: 2013,
              y: -146,
            },
            {
              x: 2014,
              y: -169,
            },
            {
              x: 2015,
              y: -184,
            },
          ],
        },
      ],

      chartOptions1: {
        chart: {
          type: 'area',
          height: 350,
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: 'straight',
        },
        title: {
          text: 'Area with Negative Values',
          align: 'left',
          style: {
            fontSize: '14px',
          },
        },
        xaxis: {
          type: 'datetime',
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
        },
        yaxis: {
          tickAmount: 4,
          floating: false,

          labels: {
            style: {
              colors: '#8e8da4',
            },
            offsetY: -7,
            offsetX: 0,
          },
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
        },
        fill: {
          opacity: 0.5,
        },
        tooltip: {
          x: {
            format: 'yyyy',
          },
          fixed: {
            enabled: false,
            position: 'topRight',
          },
        },
        grid: {
          yaxis: {
            lines: {
              offsetX: -30,
            },
          },
          padding: {
            left: 20,
          },
        },
      },

      series2: [
        {
          name: 'gaz',
          data: series,
        },
        {
          name: 'electricité',
          data: series2,
        },
      ],
      chartOptions2: {
        chart: {
          type: 'area',
          height: 350,
          zoomType: 'x',
          toolbar: {
            tools: {
              download: true,
              selection: false,
              zoom: false,
              pan: false,
              reset: false,
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
        title: {
          text: "Évolution du prix de l'énergie",
        },
        xaxis: {
          type: 'datetime',
          labels: {
            style: {
              colors: '#90a4ae',
              fontSize: '0.7rem',
              fontFamily: 'Roboto, sans-serif',
              fontWeight: 400,
            },
          },
          axisBorder: {
            show: false,
          },
          // axisBorder: {
          //   show: true,
          //   color: '#90a4ae',
          //   height: 1,
          //   width: '100%',
          //   offsetX: 0,
          //   offsetY: 0,
          // },
          axisTicks: {
            show: true,
            borderType: 'solid',
            color: '#90a4ae',
            height: 6,
            offsetX: 0,
            offsetY: 0,
          },
        },
        yaxis: {
          title: 'Exchange rate',
          tickAmount: 4,
          floating: false,
          forceNiceScale: true,
          decimalsInFloat: 1,
          labels: {
            style: {
              colors: '#90a4ae',
              fontSize: '0.7rem',
              fontFamily: 'Roboto, sans-serif',
              fontWeight: 400,
            },
            offsetY: -7,
            offsetX: 0,
          },
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
        },
        legend: {
          offsetX: 0,
          offsetY: 0,
          itemMargin: {
            horizontal: 15,
            vertical: 0,
          },
        },
        stroke: {
          show: true,
          curve: 'smooth',

          colors: ['#1DC2A3', '#1976D2'],
          width: 0.5,
          dashArray: 0,
        },

        fill: {
          colors: undefined,
          opacity: 0.9,
          // type: 'solid',
          gradient: {
            shade: 'light',
            type: 'vertical',
            shadeIntensity: 0.5,
            gradientToColors: undefined,
            inverseColors: true,
            opacityFrom: 0,
            opacityTo: 1,
            stops: [0, 50, 100],
            colorStops: [
              [
                {
                  offset: 0,
                  color: '#1CB194',
                  opacity: 0.9,
                },
                {
                  offset: 100,
                  color: '#10E1B9',
                  opacity: 0,
                },
              ],
              [
                {
                  offset: 0,
                  color: '#2196F3',
                  opacity: 0.9,
                },
                {
                  offset: 100,
                  color: '#BBDEFB',
                  opacity: 0,
                },
              ],
            ],
          },
        },

        plotOptions: {
          area: {
            states: {
              hover: {
                lineWidth: 1,
              },
            },
          },
        },
      },
      series3: [
        {
          name: 'Actual',
          data: [
            {
              x: '2011',
              y: 1292,
            },
            {
              x: '2012',
              y: 4432,
            },
            {
              x: '2013',
              y: 5423,
            },
            {
              x: '2014',
              y: 6653,
            },
            {
              x: '2015',
              y: 8133,
            },

            {
              x: '2016',
              y: 7132,
            },
            {
              x: '2017',
              y: 7332,
            },
            {
              x: '2018',
              y: 6553,
            },
          ],
        },
      ],
      chartOptions3: {
        chart: {
          height: 350,
          type: 'bar',
        },
        plotOptions: {
          bar: {
            columnWidth: '60%',
          },
        },
        colors: ['#1DC2A3'],
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: true,
          showForSingleSeries: true,
          customLegendItems: ['Prix actuel'],
          markers: {
            fillColors: ['#1DC2A3'],
          },
        },
      },
    }
  },
  methods: {
    modifyClicked: function () {
      console.log('modify clicked')
      this.actionButtonsToggler = 'save'
      this.showTextArea = true
    },
    saveClicked: function () {
      console.log('save clicked')
      this.actionButtonsToggler = 'modify'
      this.showTextArea = false
    },
  },
  async created() {
    var whoIam = ""
    await this.whoIAm(this.getToken('token')).then((data)=>{whoIam=data});
    if(whoIam == "noToken"){
        localStorage.removeItem('vendeurName')
        localStorage.removeItem('typeUser') 
        localStorage.removeItem('token')
        this.$router.push('/login')
    }else{
      localStorage.setItem('typeUser', whoIam)
    }
  }
}
</script>

<style>
line {
  stroke: #90a4aeb0;
  stroke-width: 0.35;
}

div.apexcharts-legend {
  padding-top: 30px;
}

.positive {
  color: green;
}
.negative {
  color: red;
}
.v-data-table-header {
  background-color: #f5f5f5;
}
tbody tr:nth-of-type(2n) {
  background-color: rgba(0, 0, 0, 0.02);
}
.v-data-table {
  border: solid 2px rgba(0, 0, 0, 0.05);
}

.v-data-table__wrapper > table > tbody > tr {
  pointer-events: none;
}
</style>
