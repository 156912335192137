<template>
    <div>
            <datatable class="ma-8" :columns="columns" :sortKey="sortKey" :sortOrders="sortOrders" @sort="sortBy"  @search="rechercheData">
                
                <template v-slot:body>
                    <tbody v-if="projects.length > 0 && loadingTable == false">
                        <tr  v-for="(project, index) in projects" :key="project.id">
                            <td>
                                <div class="td-table">
                                    {{project.date}}
                                </div>
                            </td>
                            <td>
                                <div class="td-table">
                                    {{project.Commercial}}
                                </div>

                            </td>
                            <td>
                            <small class="td-table">
                                    <router-link :to="{path: '/mes-profils-societes', query: { id: project.Societe }}">{{project.Raison}}</router-link> 
                            </small>
                            </td>
                            <td>
                                <div class="td-table">
                                    {{project.type}}
                                </div>
                            </td>
                            <td>
                                <div class="td-table">
                                    {{project.numCompteur}}
                                </div>
                            </td>
                            <td>
                                <div class="td-table">
                                    {{project.fournisseur}}
                                </div>
                            </td>
                            <td>
                                <div style="max-width:200px">
                                    <div v-if="project.Contrat!='' && project.Contrat!='undefined' && project.Contrat!=null">
                                    <small>
                                            <a :href="project.Contrat" target="_blank"><span class="text-body-4 blue--text">Contrat</span></a>
                                        </small>
                                        <v-icon
                                            v-if="(project.RIB!=''&& project.RIB!='undefined' && project.RIB!=null)||
                                            (project.Factures!=''&& project.Factures!='undefined' && project.Factures!=null)||
                                            (project.SEPA!=''&& project.SEPA!='undefined' && project.SEPA!=null)||
                                            (project.ACD!=''&& project.ACD!='undefined' && project.ACD!=null)||
                                            (project.CGV!=''&& project.CGV!='undefined' && project.CGV!=null)||
                                            (project.IBAN!=''&& project.IBAN!='undefined' && project.IBAN!=null)"
                                            large
                                            data-toggle="collapse" :data-target="'#multiCollapseCompteur'+index" aria-expanded="false" :aria-controls="'multiCollapseCompteur'+index"
                                            right
                                            >
                                            mdi-menu-down
                                        </v-icon>
                                    </div>
                                    <div v-else-if="project.RIB!='' && project.RIB!='undefined' && project.RIB!=null">
                                        <small>
                                            <a :href="project.RIB" target="_blank"><span class="text-body-4 blue--text">RIB</span></a>
                                        </small>
                                        <v-icon
                                            v-if="(project.Contrat!=''&& project.Contrat!='undefined' && project.Contrat!=null)||
                                            (project.Factures!=''&& project.Factures!='undefined' && project.Factures!=null)||
                                            (project.SEPA!=''&& project.SEPA!='undefined' && project.SEPA!=null)||
                                            (project.ACD!=''&& project.ACD!='undefined' && project.ACD!=null)||
                                            (project.CGV!=''&& project.CGV!='undefined' && project.CGV!=null)||
                                            (project.IBAN!=''&& project.IBAN!='undefined' && project.IBAN!=null)"
                                            large
                                            data-toggle="collapse" :data-target="'#multiCollapseCompteur'+index" aria-expanded="false" :aria-controls="'multiCollapseCompteur'+index"
                                            right
                                            >
                                            mdi-menu-down
                                        </v-icon>
                                    </div>
                                    <div v-else-if="project.SEPA!='' && project.SEPA!='undefined' && project.SEPA!=null">
                                        <small>
                                            <a :href="project.SEPA" target="_blank"><span class="text-body-4 blue--text">SEPA</span></a>
                                        </small>
                                        <v-icon
                                            v-if="(project.Contrat!=''&& project.Contrat!='undefined' && project.Contrat!=null)||
                                            (project.Factures!=''&& project.Factures!='undefined' && project.Factures!=null)||
                                            (project.RIB!=''&& project.RIB!='undefined' && project.RIB!=null)||
                                            (project.ACD!=''&& project.ACD!='undefined' && project.ACD!=null)||
                                            (project.CGV!=''&& project.CGV!='undefined' && project.CGV!=null)||
                                            (project.IBAN!=''&& project.IBAN!='undefined' && project.IBAN!=null)"
                                            large
                                            data-toggle="collapse" :data-target="'#multiCollapseCompteur'+index" aria-expanded="false" :aria-controls="'multiCollapseCompteur'+index"
                                            right
                                            >
                                            mdi-menu-down
                                        </v-icon>
                                    </div>
                                    <div class="collapse multi-collapse" :id="'multiCollapseCompteur'+index">
                                        <div v-if="project.Contrat!=''&& project.Contrat!='undefined' && project.Contrat!=null">
                                            <small>
                                                <a :href="project.Contrat" target="_blank"><span class="text-body-4 blue--text">Contrat</span></a>
                                            </small>
                                        </div>
                                        <div v-if="project.RIB!=''&& project.RIB!='undefined' && project.RIB!=null">
                                            <small>
                                                <a :href="project.RIB" target="_blank"><span class="text-body-4 blue--text">RIB</span></a>
                                            </small>
                                        </div>
                                        <div v-if="project.Factures!=''&& project.Factures!='undefined' && project.Factures!=null">
                                            <small>
                                                <a :href="project.Factures" target="_blank"><span class="text-body-4 blue--text">Facture</span></a>
                                            </small>
                                        </div>
                                        <div v-if="project.SEPA!=''&& project.SEPA!='undefined' && project.SEPA!=null">
                                            <small>
                                                <a :href="project.SEPA" target="_blank"><span class="text-body-4 blue--text">SEPA</span></a>
                                            </small>
                                        </div>
                                        <div v-if="project.ACD!=''&& project.ACD!='undefined' && project.ACD!=null">
                                            <small>
                                                <a :href="project.ACD" target="_blank"><span class="text-body-4 blue--text">ACD</span></a>
                                            </small>
                                        </div>
                                        <div v-if="project.CGV!=''&& project.CGV!='undefined' && project.CGV!=null">
                                            <small>
                                                <a :href="project.CGV" target="_blank"><span class="text-body-4 blue--text">CGV</span></a>
                                            </small>
                                        </div>
                                        <div v-if="project.IBAN!=''&& project.IBAN!='undefined' && project.IBAN!=null">
                                            <small>
                                                <a :href="project.IBAN" target="_blank"><span class="text-body-4 blue--text">IBAN</span></a>
                                            </small>
                                        </div>
                                        <div v-if="project.Annexe5Ekwateur!=''&& project.Annexe5Ekwateur!='undefined' && project.Annexe5Ekwateur!=null">
                                            <small>
                                                <a :href="project.Annexe5Ekwateur" target="_blank"><span class="text-body-4 blue--text">Annexe 5</span></a>
                                            </small>
                                        </div>
                                        <div v-if="project.EXTRAITPAPPERS!=''&& project.EXTRAITPAPPERS!='undefined' && project.EXTRAITPAPPERS!=null">
                                            <small>
                                                <a :href="project.EXTRAITPAPPERS" target="_blank"><span class="text-body-4 blue--text">EXTRAIT PAPPERS</span></a>
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div class='td-valeur'>
                                    <span>
                                        {{project.valeur}} €  
                                    </span>
                                </div>
                            </td>
                            <td>
                                <div  class="btn-group td-statut">
                                    <small v-if="project.status == 'Refuser'" style="color: red;"><b>refusée</b></small>
                                    <small v-if="project.status == 'Accepter'" style="color: green;"><b>acceptée</b></small>
                                    <small v-if="project.status == 'en Attend'" style="color: gray;"><b>en attente</b></small>
                                    <small v-if="project.status == 'en attente de validation'" style="color: blue;"><b>Phase de validation</b></small>
                                    
                                </div>
                            </td>
                        </tr>
                    </tbody>
                    <noDataTable class="ma-4" v-else-if="projects?.length == 0 && loadingTable == false"/>
                    <progressCircular class="ma-8" v-else-if='loadingTable == true'/>
                </template>
            </datatable>
            <v-row >
                <v-col >
                    <pagination v-if="projects.length > 0" :pagination="pagination"
                        @selectNumberOfRecords="rechercheData"
                        @first="getData(pagination.firstPage)"
                        @last="getData(pagination.lastPage)"
                        @prev="getData(pagination.prevPageUrl)"
                        @current="getData(pagination.currentPage)"
                        @next="getData(pagination.nextPageUrl)">
                    </pagination>
                </v-col>
            </v-row>
    </div>
</template>

<script>
    import "jquery/dist/jquery.min.js";
    import "bootstrap/dist/css/bootstrap.min.css";
    import "bootstrap/dist/css/bootstrap.css";
    import "bootstrap/dist/js/bootstrap.bundle";
    import "datatables.net-dt/js/dataTables.dataTables";
    import "datatables.net-dt/css/jquery.dataTables.min.css";

    import $ from 'jquery'
    import jQuery from 'jquery'

    import Datatable from '../../components/dataTable/Datatable.vue'
    import Pagination from '../../components/dataTable/Pagination.vue'
    import noDataTable from "../../components/no-data-table.vue"
    import progressCircular from "../../components/progress-circular.vue"
    import axios from 'axios'
    export default{
        name: 'listeMesVentePro',
        components: {datatable:Datatable, pagination:Pagination,noDataTable,progressCircular},
        data(){
            let sortOrders={};
            let columns = [
                {width:'11vw',label:"Date d'ajout", name:'date', sortable:true},
                {width:'11vw', label:'Commercial', name:'nom', sortable:true},
                {width:'11vw', label:'Raison Sociale', name:'raison', sortable:true},
                {width:'11vw',label:'Energie', name:'type', sortable:true},
                {width:'11vw', label:'PDL', name:'numCompteur', sortable:true},
                {width:'11vw',label:'Fournisseur', name:'fournisseur', sortable:true},
                {width:'11vw',label:'Fichier(s)', name:'Contrat', sortable:false},
                {width:'11vw', label:'Valeur', name:'valeur', sortable:true},
                {width:'11vw', label:'Statut', name:'status', sortable:true},
            ];
            columns.forEach((column)=>{
                sortOrders[column.name] = -1;
            });
            return{
                loadingTable:false,
                projects: [],
                columns: columns,
                sortKey: 'date',
                sortOrders: sortOrders,
                perPage: ['15', '15', '30'],
                tableData: {
                    draw: 0,
                    length: 15,
                    search: '',
                    column: 0,
                    dir: 'desc',
                },
                pagination: {
                    perPage: ['10', '15', '30'],
                    firstPage: 0,
                    lastPage: '',
                    currentPage: '',
                    total: '',
                    lastPageUrl: '',
                    nextPageUrl: '',
                    prevPageUrl: '',
                    from: '',
                    to: ''
                },
            }
        },
        async created() {
            this.loadingTable = true;
            var whoIam = ""
            await this.whoIAm(this.getToken('token')).then((data)=>{whoIam=data});
            if(whoIam == "noToken"){
                localStorage.removeItem('vendeurName')
                localStorage.removeItem('typeUser') 
                localStorage.removeItem('token')
                this.$router.push('/login')
            }else{
                localStorage.setItem('typeUser', whoIam)
            }
            this.typeUser = localStorage.getItem('typeUser')
            await this.getData()
            this.loadingTable = false;
        },
        methods: {
            rechercheData({type, key}) {
                console.log(type)
                if(type=='search'){
                    var pageNumber = 0
                    this.tableData.search = key
                }else if(type=="nbrRecords")
                {
                    this.tableData.length = keyLength
                    console.log(key)
                }
                var pageNumber = 0
                this.tableData.draw++;
                axios({url:process.env.VUE_APP_URL_API_CLIENT + 'getMesVenteProServerSide/',
                    method: 'POST',
                    data: {'dataTable': this.tableData,
                        "pageNumber": pageNumber,
                        'token': this.getToken('token')},
                })
                    .then(response => {
                       if(response.data.result == true){
                        let data = response.data.data;
                        if (this.tableData.draw == data.draw) {
                            this.projects = data.data.data;
                            this.configPagination(data.data);
                        }
                    }else{
                        alert('permission refusée')
                        localStorage.removeItem('vendeurName')
                        localStorage.removeItem('typeUser')
                        localStorage.removeItem('token')
                        this.$router.push('/login')
                    }
                })
                .catch(errors => {
                    console.log(errors);
                });
            },

            async getData(pageNumber = 0) {
                this.tableData.draw++;
                await axios({url:process.env.VUE_APP_URL_API_CLIENT + 'getMesVenteProServerSide/',
                    method: 'POST',
                    data: {'dataTable': this.tableData,
                        "pageNumber": pageNumber,
                        'token': this.getToken('token')},
                })
                    .then(response => {
                        if(response.data.result == true){
                        let data = response.data.data;
                        if (this.tableData.draw == data.draw) {
                            this.projects = data.data.data;
                            this.configPagination(data.data);
                        }
                    }else{
                        alert('permission refusée')
                        localStorage.removeItem('vendeurName')
                        localStorage.removeItem('typeUser')
                        localStorage.removeItem('token')
                        this.$router.push('/login')
                    }
                })
                .catch(errors => {
                    console.log(errors);
                });
            },
            configPagination(data) {
                this.pagination.lastPage = data.last_page;
                this.pagination.currentPage = data.current_page;
                this.pagination.total = data.total;
                this.pagination.lastPageUrl = data.last_page_url;
                this.pagination.nextPageUrl = data.next_page_url;
                this.pagination.prevPageUrl = data.prev_page_url;
                this.pagination.from = data.from;
                this.pagination.to = data.to;
            },
            sortBy(key) {
                this.sortKey = key;
                this.sortOrders[key] = this.sortOrders[key] * -1;
                this.tableData.column = this.getIndex(this.columns, 'name', key);
                this.tableData.dir = this.sortOrders[key] === 1 ? 'asc' : 'desc';
                this.getData();
            },
            getIndex(array, key, value) {
                return array.findIndex(i => i[key] == value)
            },

            
        }
    }
</script>
<style>
    .td-valeur{
        align-items:center;
        flex-direction:column
    }

    .td-statut{
        align-items:center;
        flex-direction:row;
    }
    .td-table, .td-valeur, .td-statut{
        display:flex;
        justify-content:center;
    }
    .d1{
        display: flex;
        align-items:center;
        width:100%;
        justify-content:flex-end
    }
</style>
