export const gazDataKwh = [
  {
    id: 1,
    gas: 'Day-Ahead',
    '26_apr': 5.05,
    '27_apr': 4.13,
    day_on_day: -0.92,
    day_on_day_perc: -18.24,
    week_on_day: -27.54,
  },
  {
    id: 2,
    gas: 'Day-Ahead',
    '26_apr': 5.05,
    '27_apr': 4.13,
    day_on_day: -0.92,
    day_on_day_perc: -18.24,
    week_on_day: -27.54,
  },
  {
    id: 3,
    gas: 'Day-Ahead',
    '26_apr': 5.05,
    '27_apr': 4.13,
    day_on_day: 0.62,
    day_on_day_perc: -18.24,
    week_on_day: -27.54,
  },
  {
    id: 4,
    gas: 'Day-Ahead',
    '26_apr': 5.05,
    '27_apr': 4.13,
    day_on_day: -0.92,
    day_on_day_perc: 18.24,
    week_on_day: -27.54,
  },
  {
    id: 5,
    gas: 'Day-Ahead',
    '26_apr': 5.05,
    '27_apr': 4.13,
    day_on_day: -0.92,
    day_on_day_perc: -18.24,
    week_on_day: 27.54,
  },
  {
    id: 6,
    gas: 'Day-Ahead',
    '26_apr': 5.05,
    '27_apr': 4.13,
    day_on_day: -0.92,
    day_on_day_perc: -18.24,
    week_on_day: -27.54,
  },
  {
    id: 7,
    gas: 'Day-Ahead',
    '26_apr': 5.05,
    '27_apr': 4.13,
    day_on_day: 0.72,
    day_on_day_perc: 18.24,
    week_on_day: 27.54,
  },
]
