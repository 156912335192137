<template>
  <div style="background-color: #eee">
    <v-dialog v-model="openModalAcdEngie" persistent max-width="70%">
      <v-card style="overflow: hidden">
        <v-toolbar :color="$colors[0]" dark>
          <span class="text-h6 text-white">Création lettre de résiliation</span>
        </v-toolbar>
        <v-card-text class="my-6">
          <v-form ref="formResiliation" lazy-validation>
            <v-row>
              <v-col md="6">
                <v-text-field
                  v-model="societe.Raison"
                  label="Raison Sociale"
                  :rules="[rules.required]"
                  outlined
                />
              </v-col>
              <v-col md="6">
                <v-text-field
                  v-model="societe.AdressePostal"
                  label="Adresse Societe"
                  :rules="[rules.required]"
                  outlined
                />
              </v-col>
              <v-col md="6">
                <v-text-field
                  v-model="societe.nom"
                  label="Nom client"
                  :rules="[rules.required]"
                  outlined
                />
              </v-col>
              <v-col md="6">
                <v-text-field
                  v-model="societe.prenom"
                  label="Prénom client"
                  :rules="[rules.required]"
                  outlined
                />
              </v-col>
              <v-col md="6">
                <v-text-field
                  type="date"
                  v-model="dataFournisseur.dateResiliation"
                  label="Date Résiliation"
                  :rules="[rules.required]"
                  outlined
                />
              </v-col>
              <v-col md="6">
                <v-select
                  :items="optionsFournisseurs"
                  v-model="dataFournisseur.nomFournisseur"
                  label="Nom Fournisseur"
                  :rules="[rules.required]"
                  outlined
                />
              </v-col>
              <v-col md="6">
                <v-text-field
                  v-model="dataFournisseur.adresseFournisseur"
                  label="Adresse Fournisseur"
                  :rules="[rules.required]"
                  outlined
                />
              </v-col>
              <v-col md="6">
                <v-text-field
                  v-model="dataFournisseur.compteFacturation"
                  label="Compte de facturation"
                  :rules="[rules.required]"
                  outlined
                />
              </v-col>
              <v-col md="6">
                <v-autocomplete
                  @change="getTypeEnergieCompteur(selectedCompteur)"
                  v-model="selectedCompteur"
                  :items="listCompteur"
                  :loading="isLoading"
                  hide-no-data
                  :rules="[rules.required]"
                  label="PDL"
                  placeholder="Ajouter un compteur"
                  prepend-inner-icon="mdi-account-search-outline"
                  chips
                  outlined
                  item-text="numCompteur"
                  item-value="numCompteur"
                  deletable-chips
                  multiple
                  return-object
                >
                  <template v-slot:selection="{ item, index }">
                    <v-chip v-if="index === 0">
                      <span>{{ item.numCompteur }}</span>
                    </v-chip>
                    <span v-if="index === 1" class="grey--text text-caption">
                      (+{{ selectedCompteur.length - 1 }}
                      {{ selectedCompteur.length - 1 == 1 ? "élément" : "éléments" }})
                    </span>
                  </template>
                  <template v-slot:item="data">
                    <template>
                      <v-list-item-content>
                        <v-list-item-title
                          v-html="data.item.numCompteur"
                        ></v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </template>
                  <template v-slot:prepend-item>
                    <v-checkbox
                      @click="selectAll(checboxModelCompteur, 'compteur')"
                      v-model="checboxModelCompteur"
                      :class="
                        checboxModelCompteur == false
                          ? 'checkboxAll'
                          : 'checkboxAllClicked'
                      "
                      class="px-4"
                    >
                      <template v-slot:label>
                        <span
                          :class="
                            checboxModelCompteur == false ? '' : 'checkboxAllClickedText'
                          "
                        >
                          {{
                            selectedCompteur.length == listCompteur.length
                              ? "Désélectionnez tous"
                              : "Sélectionnez tous"
                          }}</span
                        >
                      </template>
                    </v-checkbox>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
            <div class="d-flex justify-content-end">
              <v-btn text color="error" class="mt-8 mr-4" @click="closeModal">
                Fermer
              </v-btn>
              <v-btn
                :color="$colors[0]"
                class="mt-8 mr-4 text-white"
                @click="createLettreResiliation"
              >
                Valider
              </v-btn>
            </div>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import {
  degrees,
  PDFDocument,
  rgb,
  StandardFonts,
  TextAlignment,
  setFillingRgbColor,
  setFontAndSize,
  layoutMultilineText,
} from "pdf-lib";
import download from "downloadjs";
import jsPDF from "jspdf";
import axios from "axios";
import "jspdf-autotable";
export default {
  name: "lettreDeRisiliation",
  props: { societe: "", listCompteur: [] },
  data() {
    return {
      listTypeEnergieCompteurSelecteur: [],
      checboxModelCompteur: false,
      optionsFournisseurs: [
        "Alpiq",
        "Alterna",
        "Antargaz",
        "Axpo",
        "BARRY",
        "BULB",
        "BUTAGAZ",
        "CDISCOUNT",
        "Dyneff",
        "Edenkia",
        "EDF",
        "EDS",
        "EDSB",
        "Ekwateur",
        "Elecocite",
        "Electricite_de_provence",
        "Electricite_de_savoie",
        "Electricite_de_Strasbourg",
        "ELMY",
        "Enalp",
        "Endesa",
        "Enercoop",
        "Energem",
        "ENERGIE_DICI",
        "Engie",
        "ENI",
        "Enovos",
        "Gaz_de_Bordeaux",
        "GazelEnergie",
        "Gazprom",
        "GEDIA",
        "GEG",
        "GREEN_YELLOW",
        "HAPPE",
        "IBERDROLA",
        "ILEK",
        'JPME',
        "LECLERCQ",
        "LLUM",
        "LUCIA",
        "MEGA",
        "MET-France",
        "mint-energie",
        "NatGas",
        "OHM",
        "OVO",
        "Picoty",
        "PLUM",
        "Primeo",
        "Proxelia",
        "Redeéo",
        "SaveEnergies",
        "Selia",
        "SEFE",
        "SOWEE",
        "Synelva",
        "TDE",
        "Urban_Solar",
        "Vattenfall",
        "Volterres",
        "Wekiwi",
        "yeli-xs",
      ],
      openModalAcdEngie: true,
      isLoading: false,
      dataFournisseur: {
        nomFournisseur: "",
        adresseFournisseur: "",
        dateResiliation: "",
        compteFacturation: "",
      },
      selectedCompteur: [],
      rules: {
        required: (value) =>
          (value != null && ("" + value).length > 0) || "Ce champ est obligatoire.",
        requiredString: (value) => ("" + value).length > 8 || "Ce champ est obligatoire.",
        siretLength: (v) => v.length == 14 || "Le siret doit comporter 14 chiffres",
        isNumber: (v) =>
          v.match(/^[0-9]+$/) != null || "Ce champ n'accepte que les chiffres",
        compteurElecLength: (v) =>
          v.length == 14 || "Le RAE / PDL est une suite de 14 chiffres",
        isGazCompteur: (v) =>
          v.match(/^(GI)[0-9]{6}$/) ||
          (v.length == 14 && v.match(/^[0-9]+$/) != null) ||
          "Le numéro PCE/ PDL doit commencer par (GI) ou comporter 14 chiffres",
        phoneLength: (v) =>
          v.length == 10 || "Le numéro de téléphone est une suite de 10 chiffres",
        phoneisNumber: (v) =>
          v.match(/^[0-9]+$/) != null || "Ce champ n'accepte que les chiffres",
        margeMaxMin: (v) =>
          (v <= 25 && v >= 2) || "La marge doit être comprise entre 2 et 25",

        emailRules: [
          (v) => !!v || "E-mail obligatoire",
          (v) => /.+@.+\.+.+/.test(v) || "l'adresse mail est invalide",
        ],
      },
      required(fieldName) {
        return (value) =>
          !!value ||
          value >= 0 ||
          (!!value && value != "") ||
          `Veuillez saisir ${fieldName}`;
      },
    };
  },
  methods: {
    getTypeEnergieCompteur(listSelectedCompteur) {
      this.listTypeEnergieCompteurSelecteur = [];
      listSelectedCompteur.forEach((element) => {
        let object = {};
        if (element.typeDemande == "ELEC") {
          object = { label: "d'éléctricité", value: element.typeDemande };
        } else {
          object = { label: "de gaz", value: element.typeDemande };
        }
        console.log(object, this.listTypeEnergieCompteurSelecteur);
        if (
          !this.listTypeEnergieCompteurSelecteur.some(
            (element) => element.value === object.value
          )
        ) {
          this.listTypeEnergieCompteurSelecteur.push(object);
        }
      });
    },
    selectAll(checkboxValue, type) {
      if (type == "compteur") {
        if (checkboxValue) {
          this.selectedCompteur = [];
          this.listCompteur.forEach((element) => {
            if (!this.selectedCompteur.includes(element)) {
              this.selectedCompteur.push(element);
            }
          });
          this.getTypeEnergieCompteur(this.selectedCompteur);
        } else {
          this.selectedCompteur = [];
        }
      }
    },
    closeModal() {
      this.$emit("closeResiliationModal");
    },
    toggle() {
      this.selectedCompteur = [...this.listCompteur];
    },
    async getImage(link, doc) {
      const ImageLogo = await fetch(link).then((res) => res.arrayBuffer());

      var pngImage = await doc.embedPng(ImageLogo);
      return pngImage;
    },
    async createLettreResiliation() {
      if (this.$refs.formResiliation.validate()) {
        /////////////// page 1 ///////////////////////////////////////
        var url0 = "/pdf/resiliation/Model_lettre_resiliation.pdf";
        var pageZero = await fetch(url0).then((res) => res.arrayBuffer());
        // Load a PDFDocument from each of the existing PDFs
        var pageZeroDoc = await PDFDocument.load(pageZero);
        const HelveticaBoldFont = await pageZeroDoc.embedFont(
          StandardFonts.HelveticaBold
        );
        var getPages = pageZeroDoc.getPages();
        var firstPage = getPages[0];
        var form = pageZeroDoc.getForm();
        var field = form.getTextField("infoSociete");
        var image = await this.getImage(
          `/logoParticulier/${this.dataFournisseur.nomFournisseur}_120X35.png`,
          pageZeroDoc
        );
        firstPage.drawImage(image, {
          x: 335,
          y: 710,
          width: 119,
          height: 35,
        });
        const regex = /(\d{5})\s/;
        const match = this.societe.AdressePostal.match(regex);

        if (match) {
          const codePostalIndex = match.index;
          const premierePartie = this.societe.AdressePostal.substring(0, codePostalIndex);
          const deuxiemePartie = this.societe.AdressePostal.substring(
            codePostalIndex
          ).trim();
          console.log("Première partie:", premierePartie);
          console.log("Deuxième partie:", deuxiemePartie);
          var txt =
            this.societe.Raison.toUpperCase() +
            " \n" +
            premierePartie +
            "\n" +
            deuxiemePartie;
        } else {
          console.log("Aucun code postal trouvé dans l'adresse.");
        }

        field.setText(txt);
        field.setFontSize(10.5);
        var field = form.getTextField("dateAjd");
        var DateAujourdhui = new Date();
        DateAujourdhui =
          DateAujourdhui.getDate() +
          "/" +
          (DateAujourdhui.getMonth() + 1 < 10
            ? `0${DateAujourdhui.getMonth() + 1}`
            : DateAujourdhui.getMonth() + 1) +
          "/" +
          DateAujourdhui.getFullYear();
        field.setText("Fait à Montpellier, le " + DateAujourdhui);
        field.setFontSize(10.5);
        var field = form.getTextField("infofour");
        const matchAdresseEntreprise = this.dataFournisseur.adresseFournisseur.match(
          regex
        );
        let adresseFournisseur = "";
        if (matchAdresseEntreprise) {
          const codePostalIndex = matchAdresseEntreprise.index;
          const premierePartie = this.dataFournisseur.adresseFournisseur.substring(
            0,
            codePostalIndex
          );
          const deuxiemePartie = this.dataFournisseur.adresseFournisseur
            .substring(codePostalIndex)
            .trim();
          console.log("Première partie:", premierePartie);
          console.log("Deuxième partie:", deuxiemePartie);
          adresseFournisseur = premierePartie + "\n" + deuxiemePartie;
        } else {
          console.log("Aucun code postal trouvé dans l'adresse.");
        }
        var txt =
          this.dataFournisseur.nomFournisseur.toUpperCase() + " \n" + adresseFournisseur;
        field.setText(txt);
        field.setFontSize(10.5);
        let textTypeEnergie = "";
        if (this.listTypeEnergieCompteurSelecteur.length > 1) {
          textTypeEnergie = `${this.listTypeEnergieCompteurSelecteur[0].label} et ${this.listTypeEnergieCompteurSelecteur[1].label}`;
        } else {
          textTypeEnergie = `${this.listTypeEnergieCompteurSelecteur[0].label}`;
        }
        txt =
          `Objet : Résiliation de nos contrats de fourniture ${textTypeEnergie} à date anniversaire le ` +
          this.dateFormatSlach(this.dataFournisseur.dateResiliation);
        let textFieldObject = form.getTextField("objet");
        textFieldObject.setFontSize(10);
        textFieldObject.setText(txt);
        textFieldObject.updateAppearances(HelveticaBoldFont);

        var nPointLivraison = "";
        let categorieText = "";
        for (var i = 0; i < this.selectedCompteur.length; i++) {
          if (
            categorieText == "" ||
            categorieText !== this.selectedCompteur[i].typeDemande
          ) {
            categorieText = this.selectedCompteur[i].typeDemande;
            nPointLivraison += `\n\nCOMPTEUR ${categorieText}\n`;
          }

          nPointLivraison += `\n    - ${this.selectedCompteur[i]["numCompteur"]}`;
        }

        var txt =
          "Madame, Monsieur,\n" +
          "Par la présente, je vous informe de mon souhait de résilier mon contrat de fourniture" +
          "\n" +
          `${textTypeEnergie} souscrit auprès de votre service et portant le numéro ${this.dataFournisseur.compteFacturation}.` +
          "\n" +
          "Les numéros des compteurs rattachés au contrat sont :" +
          nPointLivraison +
          "\n\n" +
          "Cette demande de résiliation respecte le préavis de résiliation contractuel de 45 jours. \n" +
          "En conséquence, conformément aux dispositions dudit contrat, celui-ci sera résilié de plein droit le : " +
          this.dateFormatSlach(this.dataFournisseur.dateResiliation) +
          " à 00H00.\n\n" +
          "Nous vous serions reconnaissants de nous faire parvenir, par retour, un accusé de réception de cette " +
          "demande de résiliation et de nous confirmer que vous respecterez vos obligations contractuelles de " +
          "fourniture jusqu’à cette date.\n\n" +
          "Nous vous prions d’agréer, Madame, Monsieur, l’expression de nos salutations distinguées.\n\n" +
          this.societe.nom +
          " " +
          this.societe.prenom;
        const textLines = txt.split("\n");
        //await this.getTableau(pageZeroDoc);

        const lineHeight = 20; // Adjust this value to set the line spacing
        const pageWidth = firstPage.getWidth();
        const pageHeight = firstPage.getHeight();
        let startingPositon = pageHeight - 300; // Adjust the starting Y coordinate

        var multiText = layoutMultilineText(txt, {
          alignment: "left",
          fontSize: 11.2,
          font: HelveticaBoldFont,
          lineHeight: lineHeight,
          bounds: { width: pageWidth - 80, height: pageHeight },
        });
        for (let i = 0; i < multiText.lines.length; i++) {
          if (startingPositon - lineHeight < 30) {
            var firstPage = pageZeroDoc.addPage([pageWidth, pageHeight]);
            var newPageFirstTextY = pageHeight - 50;
            startingPositon = newPageFirstTextY - lineHeight; // Reset the Y coordinate for the new page
            firstPage.drawText(`${multiText.lines[i].text}`, {
              x: 50,
              y: startingPositon,
              size: 11.2,
              maxWidth: pageWidth - 80,
              color: rgb(0, 0, 0),
            });
          } else {
            firstPage.drawText(`${multiText.lines[i].text}`, {
              x: 50,
              y: startingPositon,
              size: 11.2,
              maxWidth: pageWidth - 80,
              color: rgb(0, 0, 0),
            });
          }
          // move position down
          startingPositon = startingPositon - lineHeight;
        }

        var fields = form.getFields();
        fields.forEach((field) => {
          field.enableReadOnly();
        });
        form.updateFieldAppearances();
        let mergedPdf = await PDFDocument.create();
        mergedPdf = await this.addPagesToPdf(mergedPdf, pageZeroDoc);

        const pdfBytes = await mergedPdf.save();
        this.generatedPdf = pdfBytes;
        download(pdfBytes, "Lettre_de_résiliation.pdf", "application/pdf");
      }
    },
    dateFormatSlach(date) {
      try {
        console.log(date, "DATE");
        const date2 = new Date(date);
        const year = date2.getFullYear();
        const month = String(date2.getMonth() + 2).padStart(2, "0");
        const day = String(date2.getDate()).padStart(2, "0");
        const dateString = `${day}/${month}/${year}`;
        return dateString;
      } catch (err) {
        return date;
      }
    },
    async addPagesToPdf(mergedPdf, donorPdf) {
      const copiedPages = await mergedPdf.copyPages(donorPdf, donorPdf.getPageIndices());
      copiedPages.forEach((page) => {
        mergedPdf.addPage(page);
      });
      return mergedPdf;
    },
  },
};
</script>
<style scoped>
.checkboxAllClicked {
  background-color: rgba(25, 118, 210, 0.12);
  opacity: 1;
}
.checkboxAllClickedText {
  color: #1976d2 !important;
}
.checkboxAll:hover {
  background-color: rgba(0, 0, 0, 0.04);
}
</style>
