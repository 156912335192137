<template>
    <div class="mr-2 ml-3 mt-2">
        <!-- modal supprimer -->
        <v-dialog v-model="ouvrireDialogeSupprimer" max-width="700px">
            <v-card style="overflow: hidden;">
                <v-card-title class="text-h5">Êtes-vous sûr de bien vouloir supprimer cette vente ?</v-card-title>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="fermerDialogeSupprimerVente"
                    >Annuler</v-btn
                >
                <v-btn color="blue darken-1" text @click="supprimerVente"
                    >Confirmer</v-btn
                >
                <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
        
        <!-- modal refuser -->
        <v-dialog v-model="ouvrireDialogeRefuser" max-width="700px">
            <v-card style="overflow: hidden;">
                <v-card-title class="text-h5">Êtes-vous sûr de bien vouloir refuser cette vente ?</v-card-title>
                <v-card-actions>
                <v-spacer></v-spacer>
                
                <v-form ref="form" lazy-validation>
                    <v-row>
                        <v-col cols="12" sm="12" >
                            <v-textarea
                                filled
                                :rules="[rules.required('une commentaire.')]"
                                auto-grow
                                label="Commentaire"
                                rows="2"
                                row-height="20"
                                v-model="commentRefuser"
                                >
                            </v-textarea>
                        </v-col>
                        <v-col cols="12" sm="12" >
                            <v-btn color="blue darken-1" text @click="fermerDialogeRefuserVente"
                                >Annuler</v-btn
                            >
                            <v-btn color="blue darken-1" text @click="refuserVente"
                                >Confirmer</v-btn
                            >
                        </v-col>
                    </v-row>
                </v-form>
                <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- modale loading-->
        <v-dialog
            v-model="dialog"
            persistent
            max-width="500"
            >
            <v-card>
                <v-card-title class="text-h5">
                Controle des ventes pro
                </v-card-title>
                <v-card-text>
                    <v-alert v-if="error==true && loading==false" type="error">
                        {{messageDialoge}}
                    </v-alert>
                    <v-alert v-if="error==false && loading==false" type="success">
                        {{messageDialoge}}
                    </v-alert>
                    <v-flex  class="text-center">
                        <v-progress-circular v-if="loading==true"
                            :size="150"
                            :width="7"
                            color="primary"
                            indeterminate
                            >
                            Patientez  ...
                        </v-progress-circular>
                    </v-flex>
                </v-card-text>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn v-if="loading==false"
                    color="green darken-1"
                    text
                    @click="dialog = false"
                >
                    FERMER
                </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-card>
            <p class="mb-4"><b><span class="text-primary font-italic me-1">Mes Ventes  </span>Pro</b></p>
            <table id="example" class="table table-striped table-bordered" style="width:100%;">
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>Vendeur</th>
                        <th>Raison Social</th>
                        <th>Compteur</th>
                        <th>Energie</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, index) in mesVentePro.ventes" :key="item.id">
                        <td>
                           <small>{{dateFormatSlach(item.date)}}</small>
                        </td>
                        <td >
                            <small>{{mesVentePro.comptes[item.Compte].nom+' '+mesVentePro.comptes[item.Compte].prenom }}</small>
                            <!--<small>sdsd</small>-->
                        </td>
                        <td >
                            <small>
                                <router-link :to="{path: '/les-profils-societes', query: { id: item.Societe }}">{{mesVentePro.societes[item.Societe].Raison}}</router-link> 
                            </small>
                        </td>
                        <td >
                            <small>
                                    {{item.numCompteur}}
                            </small>
                            <!--<small>sdsd</small>-->
                        </td>
                        <td>
                            <small>
                                {{item.type}}
                            </small>
                        </td>
                        <td>
                            <div class="btn-group">
                                <small v-if="item.status == 'Refuser'" style="color: red;"><b>refusée</b></small>
                                <small v-if="item.status == 'Accepter'" style="color: green;"><b>acceptée</b></small>
                                <small v-if="item.status == 'en Attend'" style="color: gray;"><b>en attente</b></small>
                                <small v-if="item.status == 'en attente de validation'" style="color: blue;"><b>Phase de validation</b></small>
                            </div>
                        </td>
                    </tr>
                </tbody>
                <!--<tfoot>
                    <tr>
                        <th>Name</th>
                        <th>Position</th>
                        <th>Office</th>
                        <th>Start date</th>
                    </tr>
                </tfoot>-->
            </table>
        </v-card>
    </div>
</template>
<script>
    import "jquery/dist/jquery.min.js";
    import "bootstrap/dist/css/bootstrap.min.css";
    import "bootstrap/dist/css/bootstrap.css";
    import "bootstrap/dist/js/bootstrap.bundle";
    import "datatables.net-dt/js/dataTables.dataTables";
    import "datatables.net-dt/css/jquery.dataTables.min.css";
    import $ from 'jquery'
    import jQuery from 'jquery'
    import axios from 'axios'
    export default {
        name: 'listeDeMesVentePro',
        data() {
            return {
                singleExpand: false,
                dialog: false,
                loading: false,
                error: false,
                messageDialoge: '',
                ouvrireDialogeRefuser: false,
                ouvrireDialogeSupprimer: false,
                VenteSupprimer: '',
                VenteRefuser: '',
                commentRefuser:'',
                status : "",
                search: '',
                
                mesVentePro: [],
                toutLesDonner: [],

                //règles de validation forme propositions
                rules: {
                    required(finPhrase) {
                    return (value) => !!value || `Veuillez saisir ${finPhrase}`
                    },
                },
            }
        },
        async created() {
            var whoIam = ""
            await this.whoIAm(this.getToken('token')).then((data)=>{whoIam=data});
            if(whoIam == "noToken"){
                localStorage.removeItem('vendeurName')
                localStorage.removeItem('typeUser') 
                localStorage.removeItem('token')
                this.$router.push('/login')
            }else{
                localStorage.setItem('typeUser', whoIam)
            }
            this.getAllVentePro();
        },
        watch:{
            mesVentePro(val){
                
                var dateType = jQuery.extend( jQuery.fn.dataTableExt.oSort, {
                    "date-eu-pre": function ( date ) {
                        date = date.replace(" ", "");
                        
                        if ( ! date ) {
                            return 0;
                        }

                        var year;
                        var eu_date = date.split(/[\.\-\/]/);

                        /*year (optional)*/
                        if ( eu_date[2] ) {
                            year = eu_date[2];
                        }
                        else {
                            year = 0;
                        }

                        /*month*/
                        var month = eu_date[1];
                        if ( month.length == 1 ) {
                            month = 0+month;
                        }

                        /*day*/
                        var day = eu_date[0];
                        if ( day.length == 1 ) {
                            day = 0+day;
                        }

                        return (year + month + day) * 1;
                    },

                    "date-eu-asc": function ( a, b ) {
                        return ((a < b) ? -1 : ((a > b) ? 1 : 0));
                    },

                    "date-eu-desc": function ( a, b ) {
                        return ((a < b) ? 1 : ((a > b) ? -1 : 0));
                    }
                } );
                
                $('#example').DataTable().destroy();
                setTimeout(() => $('#example').DataTable({
                    responsive: true,
                    "info": false,
                    
                    "pagingType": "full_numbers",
                    "language": {
                        "sProcessing":    "voulez vous patienter...",
                        "sLengthMenu":    "_MENU_ Lignes par page",
                        "sZeroRecords":   "Aucun résultat trouvé",
                        "sEmptyTable":    "Aucune donnée disponible dans ce tableau",
                        "sInfo":          "Affichage des enregistrements de _START_ à _END_ sur un total de _TOTAL_ enregistrements",
                        "sInfoEmpty":     "Affichage des enregistrements de 0 à 0 sur un total de 0 enregistrements",
                        "sInfoFiltered":  "(filtrer un total de _MAX_ enregistrements)",
                        "sInfoPostFix":   "",
                        "sSearch":        "recherché:",
                        "sUrl":           "",
                        "sInfoThousands":  ",",
                        "sLoadingRecords": "voulez vous patienter...",
                        "oPaginate": {
                            "sFirst":    "Premier",
                            "sLast":    "Dernier",
                            "sNext":    ">>",
                            "sPrevious": "<<"
                        },
                        "oAria": {
                            "sSortAscending":  ": Cochez pour trier la colonne par ordre croissant",
                            "sSortDescending": ": Cochez pour trier la colonne par ordre décroissant"
                        }
                    },
                    "pageLength": 20,
                    lengthChange: false,
                    "aoColumns": [
                        { "sType": dateType },
                        null,
                        null,
                        null,
                        null,
                        null,
                    ]
                }), 100);
            }
        },
        methods: {
            dateFormatSlach(date){
                return date.split('-').reverse().join('/')
            },
            dateFormatMinus(date){
                return date.split('/').reverse().join('-')
            },
            getFileName(fileUrl){
                return fileUrl.split('/')[fileUrl.split('/').length-1].split('_')[0]
            },
            splitedList(str){
                
                if(str !== '' && str != null){
                    return str.split(',')
                }else{
                    return null
                }
            },
            getAllVentePro() {
                let self = this     
                var formData = new FormData()
                formData.append('token', this.getToken('token'))
                axios({
                    url:
                    process.env.VUE_APP_URL_API_CLIENT + 'getMesVentePro/',
                    method: 'POST',
                    data: formData,
                }).then((res) => {
                    if(res.data.result == true){
                        self.mesVentePro = res.data
                    }else if(res.data.result == "permission"){
                        alert('permission refusée')
                        localStorage.removeItem('vendeurName')
                        localStorage.removeItem('typeUser')
                        localStorage.removeItem('token')
                        this.$router.push('/login')
                    }
                })
            },
            async envoyerNotification(message){
                var formData = new FormData()
                formData.append('msg', message)

                axios({
                    url:'https://app.volto.fr/slackBotSendMessage/',
                    method: 'POST',
                    data: formData,
                }).then((res) => {
                })
            }
        }
    }
</script>
<style>
.pull-left{
    float: left !important;
}
.dataTables_filter {
   float: right !important;
}
.dataTables {
   float: left !important;
}
.dropbtn {
  background-color: #04AA6D;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: gray;
  min-width: 100px;
  max-width: 100px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}
.dropdown-menu {
    background-color: rgba(0,0,0,0);
    min-width: 55px;
    width: 60px !important;
    height: 1px !important;
}
.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {background-color: #ddd;}

.dropdown:hover .dropdown-content {display: block;}

.dropdown:hover .dropbtn {background-color: #3e8e41;}
</style>