<template>
  <v-card class="mt-6 pa-4" elevation="10">
    <h1 class="ma-4 mb-6">Gaz Professionnels</h1>
    <FormProgaz />

    <div v-if="showModal" id="myModall" class="modall">
      <!-- Modal content -->
      <div class="modall-content">
        <p>Patientez  ...</p>
        <div class="loader"></div>
      </div>
    </div>
  </v-card>
</template>

<script>
import axios from 'axios'
import FormProgaz from './FormProgaz'

export default {
  name: 'gazProfessionnels',
  components: {
    FormProgaz,
  },

  data() {
    return {
      //data for type of vente
      typeUser: null,
      MakeVentePart: false,
      isUserLogged: false,

      // le boolean d'affichage de loader
      showModal: false,

      //form data for upload file
      formData: null,
    }
  },
  methods: {
    verifyApplicationDataSecurity() {
      var formData = new FormData()
      formData.append('type', localStorage.getItem('typeUser'))
      formData.append('vueAfficher', localStorage.getItem('vueAfficher'))
      axios({
        url:
          process.env.VUE_APP_URL_API_CLIENT +
          'verifyApplicationDataSecurity.php',
        method: 'POST',
        data: formData,
        
      })
        .then((res) => {
          var data = JSON.parse(JSON.stringify(res.data))
          if (data[0].result === 'notLogin') {
            localStorage.setItem('vueAfficher', 'particulier')
            localStorage.removeItem('vendeurName')
            localStorage.removeItem('typeUser')
            localStorage.removeItem('token')
            this.$router.push('/login')
          } else if (data[0].result === 'accessDenied') {
            localStorage.setItem('vueAfficher', 'particulier')
            localStorage.removeItem('vendeurName')
            localStorage.removeItem('typeUser')
            localStorage.removeItem('token')
            this.$router.push('/login')
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h2,
h3,
p {
  margin: 0px;
  text-align: top;
}
.pt-2 {
  padding-top: 7px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: button;
}

.h2 {
  color: rgb(226, 224, 79);
}
.makeVente {
  margin-right: 50px;
  float: right;
}
.company {
  max-width: 80%;
  min-width: 80%;
  margin: auto;
  .my-form2 {
    display: grid;
    grid-template-columns: 33% 33% 33%;
    justify-items: center;
    align-items: center;
    table {
      display: flex;
    }
    label {
      max-width: 120px;
      min-width: 120px;
    }
    input[type='number'],
    input[type='text'],
    input[type='date'],
    select {
      display: inline-block;
      width: 100%;
      max-width: 200px;
      min-width: 200px;
      border: 2px solid #aaa;
      border-radius: 55px;
      outline: none;
      padding: 10px;
      color: #333;
      box-sizing: border-box;
      transition: 0.3s;
      // &focus {
      //   border-color: none;
      //   box-shadow: 0 0 8px 0 dodgerBlue;
      // }
    }

    @media screen and (max-width: 1150px) {
      label {
        max-width: 100px;
        min-width: 100px;
      }
      input[type='number'],
      input[type='text'],
      input[type='date'],
      select {
        display: inline-block;
        width: 100%;
        max-width: 150px;
        min-width: 150px;
        border: 2px solid #aaa;
        border-radius: 55px;
        outline: none;
        padding: 10px;
        color: #333;
        box-sizing: border-box;
        transition: 0.3s;
        // &focus {
        //   border-color: none;
        //   box-shadow: 0 0 8px 0 dodgerBlue;
        // }
      }
    }
    @media screen and (max-width: 1000px) {
      grid-template-columns: 50% 50%;
      input[type='number'],
      input[type='text'],
      input[type='date'],
      select {
        display: inline-block;
        width: 100%;
        max-width: 150px;
        min-width: 150px;
        border: 2px solid #aaa;
        border-radius: 55px;
        outline: none;
        padding: 10px;
        color: #333;
        box-sizing: border-box;
        transition: 0.3s;
        // &focus {
        //   border-color: none;
        //   box-shadow: 0 0 8px 0 dodgerBlue;
        // }
      }
      label {
        max-width: 100px;
        min-width: 100px;
      }
    }
    @media screen and (max-width: 600px) {
      grid-template-columns: 100%;
      input[type='number'],
      input[type='text'],
      input[type='date'],
      select {
        display: inline-block;
        width: 100%;
        min-width: 220px;
        border: 2px solid #aaa;
        border-radius: 55px;
        outline: none;
        padding: 10px;
        color: #333;
        box-sizing: border-box;
        transition: 0.3s;
        // &focus {
        //   border-color: none;
        //   box-shadow: 0 0 8px 0 dodgerBlue;
        // }
      }
      label {
        max-width: 100px;
        min-width: 100px;
      }
    }
  }
  .my-form {
    align-items: center;
    align-items: center;
    button {
      width: 200px;
      border-radius: 55px;
      margin: 10px;
    }
    table,
    button {
      display: inline-block;
    }
    table {
      width: 33%;
    }
    table td {
      width: 50%;
    }

    input[type='radio'] {
      -ms-transform: scale(2.5); /* IE */
      -moz-transform: scale(2.5); /* FF */
      -webkit-transform: scale(2.5); /* Safari and Chrome */
      -o-transform: scale(2.5); /* Opera */
      transform: scale(2.5);
      padding: 30px;
    }
    .check1 {
      display: inline-block;
      margin: 10px 10% 10px 20px;
    }
    .checkboxtext {
      font-size: 110%;
    }
    .compteur_form {
      display: inline-block;
    }
    label {
      min-width: 120px;
      max-width: 120px;
    }
    input[type='number'],
    input[type='text'],
    input[type='date'],
    select {
      display: inline-block;
      width: 100%;
      max-width: 200px;
      min-width: 200px;
      border: 2px solid #aaa;
      border-radius: 55px;
      outline: none;
      padding: 10px;
      color: #333;
      box-sizing: border-box;
      transition: 0.3s;
      // &focus {
      //   border-color: none;
      //   box-shadow: 0 0 8px 0 dodgerBlue;
      // }
    }
    #nom,
    #prenom {
      width: 100%;
      width: 200px;
      border: 2px solid #aaa;
      border-radius: 55px;
      margin: 10px;
      outline: none;
      padding: 10px;
      color: #333;
      box-sizing: border-box;
      transition: 0.3s;
      // &focus {
      //   border-color: none;
      //   box-shadow: 0 0 8px 0 dodgerBlue;
      // }
    }
    #raison {
      width: 100%;
      width: 500px;
      border: 2px solid #aaa;
      border-radius: 55px;
      margin: 10px;
      outline: none;
      padding: 10px;
      color: #333;
      box-sizing: border-box;
      transition: 0.3s;
      // &focus {
      //   border-color: none;
      //   box-shadow: 0 0 8px 0 dodgerBlue;
      // }
    }
    @media screen and (max-width: 600px) {
      table {
        width: 100%;
      }
      label {
        min-width: 120px;
        max-width: 120px;
      }
      input[type='number'],
      input[type='text'],
      input[type='date'],
      select {
        display: inline-block;
        width: 100%;
        max-width: 200px;
        min-width: 200px;
        border: 2px solid #aaa;
        border-radius: 55px;
        margin: 10px;
        outline: none;
        padding: 10px;
        color: #333;
        box-sizing: border-box;
        transition: 0.3s;
        // &focus {
        //   border-color: none;
        //   box-shadow: 0 0 8px 0 dodgerBlue;
        // }
      }
    }
  }
  .inputs {
    display: inline-block;
    width: 100%;
    max-width: 200px;
    min-width: 200px;
    border: 2px solid #aaa;
    border-radius: 55px;
    outline: none;
    padding: 10px;
    color: #333;
    box-sizing: border-box;
    transition: 0.3s;
    // &focus {
    //   border-color: none;
    //   box-shadow: 0 0 8px 0 dodgerBlue;
    // }
  }
  .company-list {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 30px 30px 0px 0px;
    margin-top: 20px;
    .list-header {
      border-radius: 55px 55px 0px 0px;
      text-align: left;
      background-color: #3394e7;
      font-weight: bold;
      color: #fff;
      font-size: 20px;
      padding: 15px;
      margin: 0px;
    }
    .list-data {
      display: grid;
      grid-template-columns: 20% 16% 16% 16% 16% 16%;
      justify-content: space-between;
      padding: 15px;
      align-items: center;
      .company-info {
        padding: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: 100%;
          height: auto;
        }
      }
      .details-btn {
        padding: 10px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
      }
      .yellow-bg {
        background-color: #fdedcd;
        border-radius: 10px;
      }
      .calculation {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        padding: 10px;
      }
      @media screen and (max-width: 650px) {
        grid-template-columns: 100%;
      }
    }
  }
}
.yellow-btn {
  background-color: #fab627;
  color: #fff;
  padding: 10px 0px;
  width: 120px;
  font-size: 16px;
  border-radius: 55px;
  border: none;
  cursor: pointer;
  &:focus {
    outline: none;
  }
  &:hover {
    background-color: #dea939;
  }
}
.yellow-btn1 {
  background-color: #fab627;
  color: #fff;
  width: 120px;
  font-size: 14px;
  border-radius: 55px;
  border: none;
  cursor: pointer;
  &:focus {
    outline: none;
  }
  &:hover {
    background-color: #dea939;
  }
}
.red-del {
  background-color: #ee4951;
  color: rgb(27, 25, 25);
  padding: 8px 8px;

  font-size: 14px;
  border-radius: 55px;
  border: none;
  cursor: pointer;
  &:focus {
    outline: none;
  }
  &:hover {
    background-color: #d44242;
  }
}
.green-add {
  background-color: #addda1;
  color: rgb(27, 25, 25);
  padding: 8px 8px;

  font-size: 14px;
  border-radius: 55px;
  border: none;
  cursor: pointer;
  &:focus {
    outline: none;
  }
  &:hover {
    background-color: #7ad351;
  }
}
.head1 {
  font-size: 36px;
}
.head2 {
  font-size: 1.15em;
}
.head3 {
  font-size: 18px;
}

.cls_002 {
  font-family: Arial, serif;
  font-size: 12.1px;
  color: rgb(0, 0, 0);
  font-weight: normal;
  font-style: normal;
  text-decoration: none;
}
.cls_003 {
  font-family: Arial, serif;
  font-size: 9.1px;
  color: rgb(0, 0, 0);
  font-weight: normal;
  font-style: normal;
  text-decoration: none;
}
.cls_004 {
  font-family: Arial, serif;
  font-size: 9.1px;
  color: rgb(0, 0, 0);
  font-weight: bold;
  font-style: normal;
  text-decoration: none;
}
.cls_005 {
  font-family: Arial, serif;
  font-size: 8.1px;
  color: rgb(0, 0, 0);
  font-weight: normal;
  font-style: normal;
  text-decoration: none;
}
.cls_006 {
  font-family: Arial, serif;
  font-size: 8.1px;
  color: rgb(17, 84, 204);
  font-weight: normal;
  font-style: normal;
  text-decoration: none;
}
.cls_007 {
  font-family: Arial, serif;
  font-size: 9.1px;
  color: rgb(0, 0, 0);
  font-weight: bold;
  font-style: normal;
}

/* The Modal (background) */
.modall {
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

/* Modal Content */
.modall-content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 50%;
}
.loader {
  margin: auto;
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
