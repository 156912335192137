<template>
    <div class="mr-2 ml-3 mt-2">
        <!-- modal supprimer -->
        <v-dialog v-model="ouvrireDialogeSupprimer" max-width="700px">
            <v-card style="overflow: hidden;">
                <v-card-title class="text-h5">Êtes-vous sûr de bien vouloir supprimer cette vente ?</v-card-title>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="fermerDialogeSupprimerVente"
                    >Annuler</v-btn
                >
                <v-btn color="blue darken-1" text @click="supprimerVente"
                    >Confirmer</v-btn
                >
                <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
        
        <!-- modal refuser -->
        <v-dialog v-model="ouvrireDialogeRefuser" max-width="700px">
            <v-card style="overflow: hidden;">
                <v-card-title class="text-h5">Êtes-vous sûr de bien vouloir refuser cette vente ?</v-card-title>
                <v-card-actions>
                <v-spacer></v-spacer>
                
                <v-form ref="form" lazy-validation>
                    <v-row>
                        <v-col cols="12" sm="12" >
                            <v-textarea
                                filled
                                :rules="[rules.required('un commentaire.')]"
                                auto-grow
                                label="Commentaire"
                                rows="2"
                                row-height="20"
                                v-model="commentRefuser"
                                >
                            </v-textarea>
                        </v-col>
                        <v-col cols="12" sm="12" >
                            <v-btn color="blue darken-1" text @click="fermerDialogeRefuserVente"
                                >Annuler</v-btn
                            >
                            <v-btn color="blue darken-1" text @click="refuserVente"
                                >Confirmer</v-btn
                            >
                        </v-col>
                    </v-row>
                </v-form>
                <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- modale loading-->
        <v-dialog
            v-model="dialog"
            persistent
            max-width="500"
            >
            <v-card>
                <v-card-title class="text-h5">
                Controle des ventes pro
                </v-card-title>
                <v-card-text>
                    <v-alert v-if="error==true && loading==false" type="error">
                        {{messageDialoge}}
                    </v-alert>
                    <v-alert v-if="error==false && loading==false" type="success">
                        {{messageDialoge}}
                    </v-alert>
                    <v-flex  class="text-center">
                        <v-progress-circular v-if="loading==true"
                            :size="150"
                            :width="7"
                            color="primary"
                            indeterminate
                            >
                            Patientez  ...
                        </v-progress-circular>
                    </v-flex>
                </v-card-text>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn v-if="loading==false"
                    color="green darken-1"
                    text
                    @click="dialog = false"
                >
                    FERMER
                </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-card>
            <table id="example" class="table table-striped table-bordered" style="width:100%;">
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>Vendeur</th>
                        <th>Raison Sociale</th>
                        <th>Compteur</th>
                        <th>Énergie</th>
                        <th>Valeur</th>
                        <th>Statut</th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="text-center" v-for="(item, index) in mesVentePro.ventes" :key="item.id">
                        <td>
                           <small>{{dateFormatSlach(item.date)}}</small>
                        </td>
                        <td >
                            <small>{{mesVentePro.comptes[item.Compte].nom+' '+mesVentePro.comptes[item.Compte].prenom }}</small>
                            <!--<small>sdsd</small>-->
                        </td>
                        <td >
                            <small>
                                <router-link :to="{path: '/les-profils-societes', query: { id: item.Societe }}">{{mesVentePro.societes[item.Societe].Raison}}</router-link> 
                            </small>
                        </td>
                        <td >
                            <small>
                                    {{item.numCompteur}}
                            </small>
                            <!--<small>sdsd</small>-->
                        </td>
                        <td>
                            <small>
                                {{item.type}}
                            </small>
                        </td>
                        <td>
                            <small  :id="'Value'+item.id" style="display: inline-block;">
                                {{item.valeur}} €
                            </small>
                            <input type="text " :id="'inputValue'+item.id" :value="item.valeur" style="display: none;"
                                class="form-control form-control-sm"
                            />
                            <v-icon @click="tryUpdateValue(item.id)" :id="'buttonTryEdit'+item.id" class="left-icon-pos pull-left" style="display: inline-block;"
                                color='grey darken-4'>
                                mdi-playlist-edit
                            </v-icon>
                            <v-icon @click="annulerUpdateValue(item.id)" :id="'buttonAnnulerEdit'+item.id" class="left-icon-pos pull-left" style="display: none;"
                                color='red darken-4'>
                                mdi-marker-cancel
                            </v-icon>
                            <v-icon @click="modifierValue(item.id)" :id="'buttonValideEdit'+item.id" class="left-icon-pos pull-left ml-8" style="display: none;"
                                color='green darken-4'>
                                mdi-cloud-check
                            </v-icon>
                        </td>
                        <td>
                            <div class="btn-group">
                                <small v-if="item.status == 'Refuser'" style="color: red;"><b>refusée</b></small>
                                <small v-if="item.status == 'Accepter'" style="color: green;"><b>acceptée</b></small>
                                <small v-if="item.status == 'en Attend'" style="color: gray;"><b>en attente</b></small>
                                <small v-if="item.status == 'en attente de validation'" style="color: blue;"><b>Phase de validation</b></small>
                                <v-icon
                                    large
                                    class="dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" 
                                    left
                                    >
                                    mdi-menu-down
                                </v-icon>
                                <div class="dropdown-menu text-center">
                                    <v-btn
                                        @click="envoyerPourValider(item)"
                                        v-if="item.status != 'en attente de validation' && item.status!='Accepter' && item.status!='Refuser'"
                                        class="dropdown-item mt-1"
                                        color='grey lighten-1'
                                        dark
                                        small>
                                        <v-icon
                                        color='grey darken-4'>
                                            mdi-send-check-outline
                                        </v-icon>
                                    </v-btn>
                                    <v-btn
                                        <v-btn
                                        @click="accepterVente(item)"
                                        v-if="item.status!='Accepter'"
                                        class="dropdown-item mt-1"
                                        color='grey lighten-1'
                                        small
                                        dark>
                                        <v-icon
                                        color='grey darken-4'>
                                            mdi-check-outline
                                        </v-icon>
                                    </v-btn>
                                    <v-btn
                                        @click="ouvrireDialogeRefuserVente(item)"
                                        v-if="item.status!='Refuser'"
                                        class="dropdown-item mt-1"
                                        color='grey lighten-1'
                                        small
                                        dark>
                                        <v-icon
                                            color='grey darken-4'>
                                            mdi-cancel
                                        </v-icon>
                                    </v-btn>
                                    <v-btn
                                        @click="ouvrireDialogeSupprimerVente(item)"
                                        class="dropdown-item mt-2"
                                        color='grey lighten-1'
                                        small
                                        dark>
                                        <v-icon
                                        color='grey darken-4'>
                                            mdi-delete
                                        </v-icon>
                                    </v-btn>
                                    <!--<v-btn class="dropdown-item" href="#">Action</v-btn>
                                    <v-btn class="dropdown-item" href="#">Another action</v-btn>
                                    <v-btn class="dropdown-item" href="#">Something else here</v-btn>
                                    <div class="dropdown-divider"></div>
                                    <v-btn class="dropdown-item" href="#">Separated link</v-btn>-->
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
                <!--<tfoot>
                    <tr>
                        <th>Name</th>
                        <th>Position</th>
                        <th>Office</th>
                        <th>Start date</th>
                    </tr>
                </tfoot>-->
            </table>
        </v-card>
    </div>
</template>
<script>
    import "jquery/dist/jquery.min.js";
    import "bootstrap/dist/css/bootstrap.min.css";
    import "bootstrap/dist/css/bootstrap.css";
    import "bootstrap/dist/js/bootstrap.bundle";
    import "datatables.net-dt/js/dataTables.dataTables";
    import "datatables.net-dt/css/jquery.dataTables.min.css";
    import $ from 'jquery'
    import jQuery from 'jquery'
    import axios from 'axios'
    export default {
        name: 'listeDesVentePro',
        data() {
            return {
                nouveauValeur: 0,
                updateValue: false,
                singleExpand: false,
                dialog: false,
                loading: false,
                error: false,
                messageDialoge: '',
                ouvrireDialogeRefuser: false,
                ouvrireDialogeSupprimer: false,
                VenteSupprimer: '',
                VenteRefuser: '',
                commentRefuser:'',
                status : "",
                search: '',
                
                mesVentePro: [],
                toutLesDonner: [],

                //règles de validation forme propositions
                rules: {
                    required(finPhrase) {
                    return (value) => !!value || `Veuillez saisir ${finPhrase}`
                    },
                },
            }
        },
        async created() {
            var whoIam = ""
            await this.whoIAm(this.getToken('token')).then((data)=>{whoIam=data});
            if(whoIam == "noToken"){
                localStorage.removeItem('vendeurName')
                localStorage.removeItem('typeUser') 
                localStorage.removeItem('token')
                this.$router.push('/login')
            }else{
                localStorage.setItem('typeUser', whoIam)
            }
            this.typeUser = localStorage.getItem('typeUser')
            this.getAllVentePro()
        },
        watch:{
            mesVentePro(val){
                
                var dateType = jQuery.extend( jQuery.fn.dataTableExt.oSort, {
                    "date-eu-pre": function ( date ) {
                        date = date.replace(" ", "");
                        
                        if ( ! date ) {
                            return 0;
                        }

                        var year;
                        var eu_date = date.split(/[\.\-\/]/);

                        /*year (optional)*/
                        if ( eu_date[2] ) {
                            year = eu_date[2];
                        }
                        else {
                            year = 0;
                        }

                        /*month*/
                        var month = eu_date[1];
                        if ( month.length == 1 ) {
                            month = 0+month;
                        }

                        /*day*/
                        var day = eu_date[0];
                        if ( day.length == 1 ) {
                            day = 0+day;
                        }

                        return (year + month + day) * 1;
                    },

                    "date-eu-asc": function ( a, b ) {
                        return ((a < b) ? -1 : ((a > b) ? 1 : 0));
                    },

                    "date-eu-desc": function ( a, b ) {
                        return ((a < b) ? 1 : ((a > b) ? -1 : 0));
                    }
                } );
                
                $('#example').DataTable().destroy();
                setTimeout(() => $('#example').DataTable({
                    responsive: true,
                    "info": false,
                    
                    "pagingType": "full_numbers",
                    "language": {
                        "sProcessing":    "voulez vous patienter...",
                        "sLengthMenu":    "_MENU_ Lignes par page",
                        "sZeroRecords":   "Aucun résultat trouvé",
                        "sEmptyTable":    "Aucune donnée disponible dans ce tableau",
                        "sInfo":          "Affichage des enregistrements de _START_ à _END_ sur un total de _TOTAL_ enregistrements",
                        "sInfoEmpty":     "Affichage des enregistrements de 0 à 0 sur un total de 0 enregistrements",
                        "sInfoFiltered":  "(filtrer un total de _MAX_ enregistrements)",
                        "sInfoPostFix":   "",
                        "sSearch":        "rechercher : ",
                        "sUrl":           "",
                        "sInfoThousands":  ",",
                        "sLoadingRecords": "voulez vous patienter...",
                        "oPaginate": {
                            "sFirst":    "Premier",
                            "sLast":    "Dernier",
                            "sNext":    ">>",
                            "sPrevious": "<<"
                        },
                        "oAria": {
                            "sSortAscending":  ": Cochez pour trier la colonne par ordre croissant",
                            "sSortDescending": ": Cochez pour trier la colonne par ordre décroissant"
                        }
                    },
                    "pageLength": 20,
                    lengthChange: false,
                    "aoColumns": [
                        { "sType": dateType },
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                    ]
                }), 1000);
            }
        },
        methods: {
            tryUpdateValue(id){
                $("#buttonTryEdit"+id).css("display", "none");
                $("#buttonAnnulerEdit"+id).css("display", "inline-block");
                $("#buttonValideEdit"+id).css("display", "inline-block");
                
                $("#Value"+id).css("display", "none");
                $("#inputValue"+id).css("display", "inline-block");
            },
            annulerUpdateValue(id){
                $("#buttonTryEdit"+id).css("display", "inline-block");
                $("#buttonAnnulerEdit"+id).css("display", "none");
                $("#buttonValideEdit"+id).css("display", "none");

                $("#Value"+id).css("display", "inline-block");
                $("#inputValue"+id).css("display", "none");
            },
            modifierValue(id){
                var val = $('#inputValue'+id).val();
                
                var formData = new FormData()
                formData.append('token', this.getToken('token'))
                formData.append('val', val)
                formData.append('idVente', id)
                axios({
                    url:
                    process.env.VUE_APP_URL_API_CLIENT + 'updateValeurVentePro/',
                    method: 'POST',
                    data: formData,
                }).then((res) => {
                    if(res.data.result == true){
                        alert('la valeur de cette vente a été mise à jour')
                        this.getAllVentePro()
                        this.annulerUpdateValue(id)
                    }else if(res.data.result == false){
                        alert('vérifiez que votre valeur soit valide')
                        this.getAllVentePro()
                    }else if(res.data.result == "permission"){
                        alert('permission refusée')
                        localStorage.removeItem('vendeurName')
                        localStorage.removeItem('typeUser')
                        localStorage.removeItem('token')
                        this.$router.push('/login')
                    }
                }).catch((err)=>{
                        alert('permission refusée')
                        /*localStorage.removeItem('vendeurName')
                        localStorage.removeItem('typeUser')
                        localStorage.removeItem('token')
                        this.$router.push('/login')*/
                })
            },
            dateFormatSlach(date){
                return date.split('-').reverse().join('/')
            },
            dateFormatMinus(date){
                return date.split('/').reverse().join('-')
            },
            getFileName(fileUrl){
                return fileUrl.split('/')[fileUrl.split('/').length-1].split('_')[0]
            },
            splitedList(str){
                
                if(str !== '' && str != null){
                    return str.split(',')
                }else{
                    return null
                }
            },
            getAllVentePro() {
                let self = this     
                var formData = new FormData()
                formData.append('token', this.getToken('token'))
                axios({
                    url:
                    process.env.VUE_APP_URL_API_CLIENT + 'getAllVentePro/',
                    method: 'POST',
                    data: formData,
                }).then((res) => {
                    if(res.data.result == true){
                        self.mesVentePro = res.data
                    }else if(res.data.result == "permission"){
                        alert('permission refusée')
                        localStorage.removeItem('vendeurName')
                        localStorage.removeItem('typeUser')
                        localStorage.removeItem('token')
                        this.$router.push('/login')
                    }
                })
            },
            envoyerPourValider(item){
                //open modal loading
                this.dialog = true
                this.loading = true
                this.error = false
                this.messageDialoge = ''
                let self = this
                var formData = new FormData()
                formData.append('token', this.getToken('token'))
                formData.append('id', item.id)
                axios({
                    url:
                    process.env.VUE_APP_URL_API_CLIENT +
                    'envoyerPourValiderUneVentePro/',
                    method: 'POST',
                    data: formData,
                    
                }).then((res) => {
                    if(res.data.result==true){
                        self.getAllVentePro()
                        var message="----------------------------------------\nL'administrateur a changer le status de vente pro en <<Demande en cours de validation>>\n Fournisseur: "+
                        item.fournisseur+"\n N°Compteur :"+
                        item.num_Compteur+
                        "\n siret: "+item.siret+
                        "\n vendeur: "+this.mesVentePro.comptes[item.Compte].nom+' '+this.mesVentePro.comptes[item.Compte].prenom +
                        "\n----------------------------------------"
                        this.envoyerNotification(message)
                        self.loading = false
                        self.error = false
                        self.messageDialoge = 'Un email déjà envoyer au vendeur pour notifier'
                    }else if(res.data.result==false){
                        self.loading = false
                        self.error = true
                        self.messageDialoge = 'vente n\'existe pas'
                    }else if(res.data.result=='permission'){
                        self.loading = false
                        self.error = true
                        alert('permission refusée')
                        localStorage.removeItem('vendeurName')
                        localStorage.removeItem('typeUser')
                        localStorage.removeItem('token')
                        this.$router.push('/login')
                    }
                })
            },
            accepterVente(item){
                //open modal loading
                this.dialog = true
                this.loading = true
                this.error = false
                this.messageDialoge = ''
                let self = this
                var formData = new FormData()
                formData.append('id', item.id)
                formData.append('token', this.getToken('token'))
                axios({
                    url:
                    process.env.VUE_APP_URL_API_CLIENT +'accepterUneVentePro/',
                    method: 'POST',
                    data: formData,
                    
                }).then((res) => {
                    if(res.data.result==true){
                        self.getAllVentePro()
                        
                        var message="----------------------------------------\nL'administrateur des ventes à valider une vente pro \n au compteur numéro :"+
                        item.num_Compteur+
                        "\n siret: "+item.siret+
                        "\n vendeur: "+this.mesVentePro.comptes[item.Compte].nom+' '+this.mesVentePro.comptes[item.Compte].prenom +
                        "\n----------------------------------------"
                        this.envoyerNotification(message)
                        self.loading = false
                        self.error = false
                        self.messageDialoge = 'Vente accepter, et un email déjà envoyer au vendeur pour notifier'
                    }else if(res.data.result==false){
                        self.loading = false
                        self.error = true
                        self.messageDialoge = 'vente n\'existe plus'
                    }else if(res.data.result=='permission'){
                        self.loading = false
                        self.error = true
                        alert('permission refusée')
                        localStorage.removeItem('vendeurName')
                        localStorage.removeItem('typeUser')
                        localStorage.removeItem('token')
                        this.$router.push('/login')

                    }
                })
            },
            ouvrireDialogeRefuserVente(item){
                this.VenteRefuser = item
                this.ouvrireDialogeRefuser = true
            },
            fermerDialogeRefuserVente(){
                this.VenteRefuser = ''
                this.ouvrireDialogeRefuser = false
            },
            ouvrireDialogeSupprimerVente(item){
                this.VenteSupprimer = item
                this.ouvrireDialogeSupprimer = true
            },
            fermerDialogeSupprimerVente(){
                this.VenteSupprimer = ''
                this.ouvrireDialogeSupprimer = false
            },
            refuserVente(){
                if (!this.$refs.form.validate()) return
                //fermer modal comment
                this.ouvrireDialogeRefuser = false
                //open modal loading
                this.dialog = true
                this.loading = true
                this.error = false
                this.messageDialoge = ''

                let self = this
                var formData = new FormData()
                formData.append('token', this.getToken('token'))
                formData.append('comment', this.commentRefuser)
                formData.append('id', this.VenteRefuser.id)
                axios({
                    url:
                    process.env.VUE_APP_URL_API_CLIENT + 'refuserUneVentePro/',
                    method: 'POST',
                    data: formData,
                    
                }).then((res) => {
                    if(res.data.result==true){
                        var message="----------------------------------------\nL'administrateur des ventes à refusée une vente pro \n au compteur numéro :"+
                        this.VenteRefuser.num_Compteur+
                        "\n siret: "+this.VenteRefuser.siret+
                        "\n vendeur: "+this.mesVentePro.comptes[this.VenteRefuser.Compte].nom+' '+this.mesVentePro.comptes[this.VenteRefuser.Compte].prenom +
                        "\n Commentaire: "+self.commentRefuser+
                        "\n----------------------------------------"
                        this.envoyerNotification(message)
                        self.getAllVentePro()
                        this.loading = false
                        this.error = false
                        self.commentRefuser=''
                        this.messageDialoge = 'Vente refuser, et un email déjà envoyer au vendeur'
                    }else if(res.data.result==false){
                        self.loading = false
                        self.error = true
                        self.messageDialoge = 'vente n\'existe pas'
                    }else if(res.data.result=='permission'){
                        self.loading = false
                        self.error = true
                        alert('permission refusée')
                        localStorage.removeItem('vendeurName')
                        localStorage.removeItem('typeUser')
                        localStorage.removeItem('token')
                        this.$router.push('/login')
                    }
                })
            },
            supprimerVente(){
                this.ouvrireDialogeSupprimer = false
                //open modal loading
                this.dialog = true
                this.loading = true
                this.error = false
                this.messageDialoge = ''
                let self = this
                var formData = new FormData()
                
                formData.append('token', this.getToken('token'))
                formData.append('id', this.VenteSupprimer.id)
                axios({
                    url:
                    process.env.VUE_APP_URL_API_CLIENT +
                    'supprimerUneVentePro/',
                    method: 'POST',
                    data: formData,
                    
                }).then((res) => {
                    if(res.data.result==true){
                        var message="----------------------------------------\nL'administrateur des ventes à supprimer la vente pro \n au compteur numéro :"+
                        this.VenteSupprimer.num_Compteur+
                        "\n siret: "+this.VenteSupprimer.siret+
                        "\n vendeur: "+this.mesVentePro.comptes[this.VenteSupprimer.Compte].nom+' '+this.mesVentePro.comptes[this.VenteSupprimer.Compte].prenom +
                        "\n----------------------------------------"
                        this.envoyerNotification(message)
                        self.getAllVentePro()
                        self.loading = false
                        self.error = false
                        self.VenteSupprimer=''
                        self.messageDialoge = 'Vente supprimer, et un email déjà envoyer au vendeur'
                    }else if(res.data.result==false){
                        self.loading = false
                        self.error = true
                        self.messageDialoge = 'vente n\'existe pas'
                    }else if(res.data.result=='permission'){
                        self.loading = false
                        self.error = true
                        self.messageDialoge = 'permission refusée'
                        localStorage.removeItem('vendeurName')
                        localStorage.removeItem('typeUser')
                        localStorage.removeItem('token')
                        this.$router.push('/login')
                    }
                })
            },
            async envoyerNotification(message){
                var formData = new FormData()
                formData.append('msg', message)

                axios({
                    url:'https://app.volto.fr/slackBotSendMessage/',
                    method: 'POST',
                    data: formData,
                }).then((res) => {
                })
            }
        }
    }
</script>
<style>
.pull-left{
    float: left !important;
}
.dataTables_filter {
   float: left !important;
}
.dataTables {
   float: left !important;
}
.dropbtn {
  background-color: #04AA6D;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: gray;
  min-width: 100px;
  max-width: 100px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}
.dropdown-menu {
    background-color: rgba(0,0,0,0);
    min-width: 55px;
    width: 60px !important;
    height: 1px !important;
}
.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {background-color: #ddd;}

.dropdown:hover .dropdown-content {display: block;}

.dropdown:hover .dropbtn {background-color: #3e8e41;}
</style>