<template>
  <div  class="company">
    <h1>GAZ</h1>
    <p style="color: red;" v-if="siretFoundAcd_gaz == false">
      le siret est n'est pas enregister dans l'acd
    </p>
    <!-- 
            rechercher si le numéro de siret se trouve sur l'acd 
        -->
    <div class="my-form">
      <label>Siret</label>
      <input
        type="number"
        min="0"
        maxLength="14"
        placeholder="Siret"
        step="1"
        onkeypress="return event.charCode >= 48 && event.charCode <= 57"
        oninput="javascript: if (this.value.length > this.maxLength) {this.value = this.value.slice(0, this.maxLength);}"
        @input="siretFoundAcd_gaz = null"
        v-model="siretProGaz"
        required
      />

      <button class="yellow-btn" type="button" @click="getCompteurListGaz">
        rechercher
      </button>
    </div>
    <hr />
    <!--
            formulaire qui compare et calcule le budget de vente pro_gaz "getDataProGaz"
            -->
    <div>
      <form @submit.prevent="getDataProGaz">
        <div class="my-form2">
          <table v-if="siretFoundAcd_gaz">
            <tr>
              <td><label>Numéro compteur</label></td>
              <td>
                <select
                  class="select"
                  v-model="compteurPro_gaz"
                  @change="getDonneeParCompteur"
                  required
                >
                  <option disabled>Compteur</option>
                  <option
                    :key="index"
                    :value="item"
                    v-for="(item, index) in les_compteur_gaz"
                  >
                    {{ item }}
                  </option>
                </select>
              </td>
            </tr>
          </table>

          <table v-if="siretFoundAcd_gaz">
            <tr>
              <td><label>Date début</label></td>
              <td>
                <input type="date" v-model="dateDebutPro_gaz" required />
              </td>
            </tr>
          </table>

          <table v-if="siretFoundAcd_gaz">
            <tr>
              <td><label>Date fin</label></td>
              <td>
                <input type="date" v-model="dateFinPro_gaz" required />
              </td>
            </tr>
          </table>

          <table v-if="siretFoundAcd_gaz">
            <tr>
              <td><label>Marge</label></td>
              <td>
                <input
                  type="number"
                  step="0.01"
                  max="10"
                  min="2"
                  v-model="marge_gaz"
                  placeholder="marge"
                  required
                />
              </td>
            </tr>
          </table>

          <table v-if="siretFoundAcd_gaz">
            <tr>
              <td><label>Type compteur</label></td>
              <td>
                <select class="select" v-model="type_compteur_gaz" required>
                  <option disabled>Type compteur</option>
                  <option value="T1"> T1 </option>
                  <option value="T2"> T2 </option>
                  <option value="T3"> T3 </option>
                  <option value="T4"> T4 </option>
                </select>
              </td>
            </tr>
          </table>

          <table v-if="siretFoundAcd_gaz">
            <tr>
              <td><label>Consomation</label></td>
              <td>
                <input
                  type="number"
                  step="0.01"
                  v-model="consomation_gaz"
                  placeholder="Consomation Gaz"
                  required
                />
              </td>
            </tr>
          </table>
        </div>
        <hr v-if="showfournisseurActuelle" />
        <div class="my-form2">
          <!-- ////////////////////////////// cout de fornisseur//////////////////////////////////////////////////////////////////// -->
          <table v-if="showfournisseurActuelle && siretFoundAcd_gaz">
            <tr>
              <td><label>Fournisseur actuelle:</label></td>
              <td>
                <select class="select" v-model="forActuelle">
                  <option disabled>Fournisseur actuelle</option>
                  <option
                    :key="index"
                    :value="item"
                    v-for="(item, index) in tousLesForActuelle"
                  >
                    {{ item }}
                  </option>
                </select>
              </td>
            </tr>
          </table>

          <table v-if="showfournisseurActuelle && siretFoundAcd_gaz">
            <tr>
              <td><label>Prix Molecule:</label></td>
              <td>
                <input
                  type="number"
                  step="0.001"
                  placeholder="Prix Molecule"
                  v-model="molecule"
                  required
                />
              </td>
            </tr>
          </table>

          <table v-if="showfournisseurActuelle && siretFoundAcd_gaz">
            <tr>
              <td><label>Abonnement:</label></td>
              <td>
                <input
                  type="number"
                  step="0.001"
                  placeholder="Abonnement"
                  v-model="aboForAct"
                  required
                />
              </td>
            </tr>
          </table>

          <table v-if="showfournisseurActuelle && siretFoundAcd_gaz">
            <tr>
              <td><label>CEE:</label></td>
              <td>
                <input
                  type="number"
                  step="0.001"
                  placeholder="CEE"
                  v-model="ceeForAct"
                  required
                />
              </td>
            </tr>
          </table>
        </div>
        <div style="float: center; margin: auto;" v-if="siretFoundAcd_gaz">
          <button class="yellow-btn" type="submit">Calculer</button>
        </div>
      </form>
    </div>
    <div style="float: right;" v-if="siretFoundAcd_gaz">
      <button
        width="200px"
        class="yellow-btn1"
        v-if="showfournisseurActuelle == false"
        @click="fournisseurActuelle"
        type="button"
      >
        + fournisseur actuelle
      </button>
      <button
        width="200px"
        class="yellow-btn1"
        v-if="showfournisseurActuelle == true"
        @click="disablefournisseurActuelle"
        type="button"
      >
        - fournisseur actuelle
      </button>
    </div>
    <div style="padding: 20px;">
      <!-- send the list of company that we will display them -->
      <proGazBody
        :companiesProGaz="companiesProGaz"
        :siretFoundAcdgaz="siretFoundAcd_gaz"
      />
    </div>
    <!-- The Modal that contain loader-->
    <div v-if="showModal" id="myModall" class="modall">
      <!-- Modal content -->
      <div class="modall-content">
        <p>Patientez  ...</p>
        <div class="loader"></div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import proGazBody from './proGazBody'
export default {
  name: 'FormProgaz',
  components: {
    proGazBody,
  },
  data() {
    return {
      //data pour pro gaz
      siretProGaz: null,
      les_compteur_gaz: [],
      siretFoundAcd_gaz: null,
      compteurPro_gaz: null,
      dateDebutPro_gaz: null,
      dateFinPro_gaz: null,
      marge_gaz: null,
      type_compteur_gaz: null,
      consomation_gaz: null,
      companiesProGaz: [],
      showModal: false,
      //testAdd forniseur acctuelle afficher ou non
      showfournisseurActuelle: false,
      //data actuelle
      tousLesForActuelle: [
        'ALPIQ',
        'ALTERNA',
        'ANTARGAZ',
        'BARRY',
        'BUTAGAZ',
        'CDISCOUNT',
        'DYNEFF',
        'EDF',
        'EKWATEUR',
        'ELECOCITE',
        'ENALP',
        'ENERCOOP',
        'ENERGEM',
        'ENERGIE DICI',
        'ENERGIE SANTERRE',
        'ENGIE',
        'ENI',
        'GAZ DE BORDEAUX',
        'GEG',
        'GREEN YELLOW',
        'HAPPE',
        'IBERDROLA',
        'ILEK',
        'JPME',
        'la_bellenergie',
        'LECLERCQ',
        'LUCIA',
        'MEGA',
        'MET-France',
        'MINT ENERGIE',
        'OHM',
        'OVO',
        'ELMY',
        'PLUM',
        'Primeo',
        'PRO VENCE',
        'PRO XELIA',
        'SELIA',
        'SOWEE',
        'TDE',
        'URBAN SOLAR',
        'VATTENFALL',
        'Volterres',
        'WEKIWI',
        'yeli xs',
      ],

      forActuelle: null,
      aboForAct: null,
      molecule: null,
      ceeForAct: null,
    }
  },

  methods: {
    fournisseurActuelle() {
      this.showfournisseurActuelle = true
    },
    disablefournisseurActuelle() {
      this.showfournisseurActuelle = false
      this.forActuelle = null
      this.aboForAct = null
      this.molecule = null
      this.ceeForAct = null
    },
    getImageLogo(logo) {
      return process.env.VUE_APP_URL_API_CLIENT + 'logoParticulier/' + logo
    },
    getImgUrl(logo) {
      return require('../../assets/' + logo.split('.')[0] + '.png')
    },
    getCompteurListGaz() {
      let self = this
      var formData = new FormData()
      formData.append('siret', self.siretProGaz)
      axios({
        url: process.env.VUE_APP_URL_API_CLIENT + 'getCompteurListGaz.php',
        method: 'POST',
        data: formData,
        
      })
        .then((res) => {
          var data = JSON.parse(JSON.stringify(res.data))
          if (data[0].result === 'notLogin') {
            localStorage.setItem('vueAfficher', 'particulier')
            localStorage.removeItem('vendeurName')
            localStorage.removeItem('typeUser')
            localStorage.removeItem('token')
            this.$router.push('/login')
          } else if (data[0].result === 'accessDenied') {
            localStorage.setItem('vueAfficher', 'particulier')
            localStorage.removeItem('vendeurName')
            localStorage.removeItem('typeUser')
            localStorage.removeItem('token')
            this.$router.push('/login')
          } else {
            if (res.data[0].res == 'true') {
              self.les_compteur_gaz = res.data[0].compteur
              self.siretFoundAcd_gaz = true
            } else {
              self.siretFoundAcd_gaz = false
              self.companiesProGaz = []
            }
          }
        })
        .catch((err) => {
          console.log(err)
          self.showModal = false
        })
    },
    getDataProGaz() {
      let self = this
      self.showModal = true
      var formData = new FormData()
      formData.append('siretProGaz', self.siretProGaz)
      formData.append('compteurPro_gaz', self.compteurPro_gaz)
      formData.append('dateDebutPro_gaz', self.dateDebutPro_gaz)
      formData.append('dateFinPro_gaz', self.dateFinPro_gaz)
      formData.append('marge_gaz', self.marge_gaz)
      formData.append('type_compteur_gaz', self.type_compteur_gaz)
      formData.append('consomation_gaz', self.consomation_gaz)

      formData.append('showfournisseurActuelle', self.showfournisseurActuelle)
      formData.append('forActuelle', self.forActuelle)
      formData.append('aboForAct', self.aboForAct)
      formData.append('molecule', self.molecule)
      formData.append('ceeForAct', self.ceeForAct)
      //upload pdf file to php server
      axios({
        url: process.env.VUE_APP_URL_API_CLIENT + 'calcule_cout_pro_gaz.php',
        method: 'POST',
        data: formData,
        
      })
        .then((res) => {
          var data = JSON.parse(JSON.stringify(res.data))
          if (data[0].result === 'notLogin') {
            localStorage.setItem('vueAfficher', 'particulier')
            localStorage.removeItem('vendeurName')
            localStorage.removeItem('typeUser')
            localStorage.removeItem('token')
            this.$router.push('/login')
          } else if (data[0].result === 'accessDenied') {
            localStorage.setItem('vueAfficher', 'particulier')
            localStorage.removeItem('vendeurName')
            localStorage.removeItem('typeUser')
            localStorage.removeItem('token')
            this.$router.push('/login')
          } else {
            if (data[0].result != '0') {
            } else if (data[0].result === '0') {
              // save le donnée du fornisseur dans une liste companiesPro gaz
              self.companiesProGaz = data
            }
          }
          self.showModal = false
        })
        .catch((err) => {
          console.log(err)
        })
    },
    getDonneeParCompteur() {
      let self = this
      self.showModal = true
      var formData = new FormData()
      formData.append('numCompteur', self.compteurPro_gaz)
      axios({
        url: process.env.VUE_APP_URL_API_CLIENT + 'getDonneeParCompteurGaz.php',
        method: 'POST',
        data: formData,
        
      })
        .then((res) => {
          var data = JSON.parse(JSON.stringify(res.data))
          if (data[0].res === '1') {
            self.dateDebutPro_gaz = data[0].dateDebut
            self.dateFinPro_gaz = data[0].dateFin
            self.marge_gaz = data[0].marge
            self.type_compteur_gaz = data[0].type
            self.consomation_gaz = data[0].consomation
          }
          if (data[1].res === '1') {
            self.forActuelle = data[1].fournisseur
            self.aboForAct = data[1].abonnement
            self.molecule = data[1].molecule
            self.ceeForAct = data[1].cee
          }
          self.showModal = false
        })
        .catch((err) => {
          console.log(err)
          self.showModal = false
        })
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h2,
h3,
p {
  margin: 0px;
  text-align: top;
}
.pt-2 {
  padding-top: 7px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: button;
}

.h2 {
  color: rgb(226, 224, 79);
}
.makeVente {
  margin-right: 50px;
  float: right;
}
.company {
  max-width: 100%;
  min-width: 100%;
  margin: auto;
  .my-form2 {
    display: grid;
    grid-template-columns: 33% 33% 33%;
    justify-items: center;
    align-items: center;
    table {
      display: flex;
    }
    label {
      max-width: 120px;
      min-width: 120px;
    }
    input[type='number'],
    input[type='text'],
    input[type='date'],
    select {
      display: inline-block;
      width: 100%;
      max-width: 200px;
      min-width: 200px;
      border: 2px solid #aaa;
      border-radius: 55px;
      outline: none;
      padding: 10px;
      color: #333;
      box-sizing: border-box;
      transition: 0.3s;
      // &focus {
      //   border-color: none;
      //   box-shadow: 0 0 8px 0 dodgerBlue;
      // }
    }

    @media screen and (max-width: 1150px) {
      label {
        max-width: 100px;
        min-width: 100px;
      }
      input[type='number'],
      input[type='text'],
      input[type='date'],
      select {
        display: inline-block;
        width: 100%;
        max-width: 150px;
        min-width: 150px;
        border: 2px solid #aaa;
        border-radius: 55px;
        outline: none;
        padding: 10px;
        color: #333;
        box-sizing: border-box;
        transition: 0.3s;
        // &focus {
        //   border-color: none;
        //   box-shadow: 0 0 8px 0 dodgerBlue;
        // }
      }
    }
    @media screen and (max-width: 1000px) {
      grid-template-columns: 50% 50%;
      input[type='number'],
      input[type='text'],
      input[type='date'],
      select {
        display: inline-block;
        width: 100%;
        max-width: 150px;
        min-width: 150px;
        border: 2px solid #aaa;
        border-radius: 55px;
        outline: none;
        padding: 10px;
        color: #333;
        box-sizing: border-box;
        transition: 0.3s;
        // &focus {
        //   border-color: none;
        //   box-shadow: 0 0 8px 0 dodgerBlue;
        // }
      }
      label {
        max-width: 100px;
        min-width: 100px;
      }
    }
    @media screen and (max-width: 600px) {
      grid-template-columns: 100%;
      input[type='number'],
      input[type='text'],
      input[type='date'],
      select {
        display: inline-block;
        width: 100%;
        min-width: 220px;
        border: 2px solid #aaa;
        border-radius: 55px;
        outline: none;
        padding: 10px;
        color: #333;
        box-sizing: border-box;
        transition: 0.3s;
        // &focus {
        //   border-color: none;
        //   box-shadow: 0 0 8px 0 dodgerBlue;
        // }
      }
      label {
        max-width: 100px;
        min-width: 100px;
      }
    }
  }
  .my-form {
    align-items: center;
    align-items: center;
    button {
      width: 200px;
      border-radius: 55px;
      margin: 10px;
    }
    table,
    button {
      display: inline-block;
    }
    table {
      width: 33%;
    }
    table td {
      width: 50%;
    }

    input[type='radio'] {
      -ms-transform: scale(2.5); /* IE */
      -moz-transform: scale(2.5); /* FF */
      -webkit-transform: scale(2.5); /* Safari and Chrome */
      -o-transform: scale(2.5); /* Opera */
      transform: scale(2.5);
      padding: 30px;
    }
    .check1 {
      display: inline-block;
      margin: 10px 10% 10px 20px;
    }
    .checkboxtext {
      font-size: 110%;
    }
    .compteur_form {
      display: inline-block;
    }
    label {
      min-width: 120px;
      max-width: 120px;
    }
    input[type='number'],
    input[type='text'],
    input[type='date'],
    select {
      display: inline-block;
      width: 100%;
      max-width: 200px;
      min-width: 200px;
      border: 2px solid #aaa;
      border-radius: 55px;
      outline: none;
      padding: 10px;
      color: #333;
      box-sizing: border-box;
      transition: 0.3s;
      // &focus {
      //   border-color: none;
      //   box-shadow: 0 0 8px 0 dodgerBlue;
      // }
    }
    #nom,
    #prenom {
      width: 100%;
      width: 200px;
      border: 2px solid #aaa;
      border-radius: 55px;
      margin: 10px;
      outline: none;
      padding: 10px;
      color: #333;
      box-sizing: border-box;
      transition: 0.3s;
      // &focus {
      //   border-color: none;
      //   box-shadow: 0 0 8px 0 dodgerBlue;
      // }
    }
    #raison {
      width: 100%;
      width: 500px;
      border: 2px solid #aaa;
      border-radius: 55px;
      margin: 10px;
      outline: none;
      padding: 10px;
      color: #333;
      box-sizing: border-box;
      transition: 0.3s;
      // &focus {
      //   border-color: none;
      //   box-shadow: 0 0 8px 0 dodgerBlue;
      // }
    }
    @media screen and (max-width: 600px) {
      table {
        width: 100%;
      }
      label {
        min-width: 120px;
        max-width: 120px;
      }
      input[type='number'],
      input[type='text'],
      input[type='date'],
      select {
        display: inline-block;
        width: 100%;
        max-width: 200px;
        min-width: 200px;
        border: 2px solid #aaa;
        border-radius: 55px;
        margin: 10px;
        outline: none;
        padding: 10px;
        color: #333;
        box-sizing: border-box;
        transition: 0.3s;
        // &focus {
        //   border-color: none;
        //   box-shadow: 0 0 8px 0 dodgerBlue;
        // }
      }
    }
  }
  .inputs {
    display: inline-block;
    width: 100%;
    max-width: 200px;
    min-width: 200px;
    border: 2px solid #aaa;
    border-radius: 55px;
    outline: none;
    padding: 10px;
    color: #333;
    box-sizing: border-box;
    transition: 0.3s;
    // &focus {
    //   border-color: none;
    //   box-shadow: 0 0 8px 0 dodgerBlue;
    // }
  }
  .company-list {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 30px 30px 0px 0px;
    margin-top: 20px;
    .list-header {
      border-radius: 55px 55px 0px 0px;
      text-align: left;
      background-color: #3394e7;
      font-weight: bold;
      color: #fff;
      font-size: 20px;
      padding: 15px;
      margin: 0px;
    }
    .list-data {
      display: grid;
      grid-template-columns: 20% 16% 16% 16% 16% 16%;
      justify-content: space-between;
      padding: 15px;
      align-items: center;
      .company-info {
        padding: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: 100%;
          height: auto;
        }
      }
      .details-btn {
        padding: 10px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
      }
      .yellow-bg {
        background-color: #fdedcd;
        border-radius: 10px;
      }
      .calculation {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        padding: 10px;
      }
      @media screen and (max-width: 650px) {
        grid-template-columns: 100%;
      }
    }
  }
}
.yellow-btn {
  background-color: #fab627;
  color: #fff;
  padding: 10px 0px;
  width: 120px;
  font-size: 16px;
  border-radius: 55px;
  border: none;
  cursor: pointer;
  &:focus {
    outline: none;
  }
  &:hover {
    background-color: #dea939;
  }
}
.yellow-btn1 {
  background-color: #fab627;
  color: #fff;
  font-size: 14px;
  border-radius: 55px;
  border: none;
  cursor: pointer;
  &:focus {
    outline: none;
  }
  &:hover {
    background-color: #dea939;
  }
}
.red-del {
  background-color: #ee4951;
  color: rgb(27, 25, 25);
  padding: 8px 8px;

  font-size: 14px;
  border-radius: 55px;
  border: none;
  cursor: pointer;
  &:focus {
    outline: none;
  }
  &:hover {
    background-color: #d44242;
  }
}
.green-add {
  background-color: #addda1;
  color: rgb(27, 25, 25);
  padding: 8px 8px;

  font-size: 14px;
  border-radius: 55px;
  border: none;
  cursor: pointer;
  &:focus {
    outline: none;
  }
  &:hover {
    background-color: #7ad351;
  }
}
.head1 {
  font-size: 36px;
}
.head2 {
  font-size: 1.15em;
}
.head3 {
  font-size: 18px;
}

.cls_002 {
  font-family: Arial, serif;
  font-size: 12.1px;
  color: rgb(0, 0, 0);
  font-weight: normal;
  font-style: normal;
  text-decoration: none;
}
.cls_003 {
  font-family: Arial, serif;
  font-size: 9.1px;
  color: rgb(0, 0, 0);
  font-weight: normal;
  font-style: normal;
  text-decoration: none;
}
.cls_004 {
  font-family: Arial, serif;
  font-size: 9.1px;
  color: rgb(0, 0, 0);
  font-weight: bold;
  font-style: normal;
  text-decoration: none;
}
.cls_005 {
  font-family: Arial, serif;
  font-size: 8.1px;
  color: rgb(0, 0, 0);
  font-weight: normal;
  font-style: normal;
  text-decoration: none;
}
.cls_006 {
  font-family: Arial, serif;
  font-size: 8.1px;
  color: rgb(17, 84, 204);
  font-weight: normal;
  font-style: normal;
  text-decoration: none;
}
.cls_007 {
  font-family: Arial, serif;
  font-size: 9.1px;
  color: rgb(0, 0, 0);
  font-weight: bold;
  font-style: normal;
}

/* The Modal (background) */
.modall {
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

/* Modal Content */
.modall-content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 50%;
}
.loader {
  margin: auto;
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
