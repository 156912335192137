<template>
    <div>
        <v-btn
        @click="createContrat"
        icon
        depressed class="ma-auto text-capitalize">
            <v-icon  :color="$colors[0]">mdi-text-box-edit</v-icon>
        </v-btn>
    </div>
</template>
<script>
    import { degrees, PDFDocument, rgb, StandardFonts,layoutMultilineText } from 'pdf-lib'
    import download from 'downloadjs'
    import jsPDF from 'jspdf'
    import 'jspdf-autotable'

    import dialogeModal from "../../components/ui/dialogeModal"
    import axios from 'axios'
    export default {
        name: 'engieProManyCFourOne',
        components:{dialogeModal:dialogeModal},
        props: {
            optionTarifaire: '',
            donner: null,
            numCompteur: null,
            societe: null,
            donnerEnedis: null,
        },
        data: () => ({
            /*lesCompteur:[
                "07584225718060",
                "21427496247874",
                "21192185126146",
                "21102894241430",
                "21194066529989",
                "22252821974303",
                "02339507895815",
                "07176121541621",
                "07179305333245",
                "07175397952689",
                "07179884204448",
                "07180607793427",
                "07176410977210",
                "19766714808644",
                "21406946318288",
                "21102894241430",
                "01118379041579",
                "01118523759312",
                "21311432658006",
                "23193921793788",
                "21425470218640",
                "21304052053218",
                "22431403696906",
                "07478726422292",
                "07478871140059",
                "07479594729009",
                "07487264822301",
                "07486251797702",
                "07486975386763",
                "19198118660299",
                "01103183700572",
                "21425470226740",
                "21425325508984",
                "21424891355509",
                "21424457202618",
                "21424312484855",
                "21424167766526",
                "21424023048701",
                "23278871182558",
                "23278002875720",
                "21416208372207",
                "23224167854062",
                "21491606358511",
                "23193198204710",
                "07110853823874",
                "07109985517025",
                "07109696081419",
                "07109840799262",
                "21302604840370",
                "21302749558109",
                "21302894275940",
                "21212156162928",
                "22135310998950",
                "22137047609501",
            ],*/
            lesCompteur:[
                "07584225718060",
            ]
        }),
        created() {
        },
        methods: {
            async createContrat(){
                for(var i =0; i<this.lesCompteur.length; i++){
                    var numCompteur = this.lesCompteur[i]
                    var dataToSend = {
                        numCompteur: numCompteur,
                    }
                    await axios({
                        url: process.env.VUE_APP_URL_API_CLIENT +'getInfoamtionParCompteurCfour/',
                        method: 'POST',
                        data: dataToSend,
                        headers:{'content-type': 'application/json'}
                        
                    })
                    .then(async (res) => {
                        if(res.data.result == true){
                            dataToSend = {
                                data: res.data.data,
                                autreDonner: res.data.autreDonner
                            }
                            await axios({
                                url: 'https://editioncontrat.volto.fr/engieCfour',
                                method: 'POST',
                                data: dataToSend,
                                headers:{'content-type': 'application/json'}
                                
                            }).then(async (res) => {
                                var doc = await PDFDocument.load(res.data)
                                doc = await doc.save()
                                download(doc, numCompteur+'_EngiePro_C4_.pdf', 'application/pdf')
                            }).catch((err)=>{
                                console.log(numCompteur)
                            })
                        }else{
                            console.log(numCompteur)
                        }
                    }).catch((err)=>{
                        console.log(numCompteur)
                    })
                }
                /*await axios({
                    url: 'https://editioncontrat.volto.fr/engieContrat',
                    method: 'POST',
                    data: dataToSend,
                    headers:{'content-type': 'application/json'}
                    
                })
                .then(async (res) => {
                    var doc = await PDFDocument.load(res.data)
                    doc = await doc.save()
                    download(doc, this.numCompteur+'_EngiePro_'+this.societe.Raison+'_.pdf', 'application/pdf')
                }).catch((err)=>{
                    console.log(err)
                })*/
            },
            async choisireQuelleOffre(){
                this.addData = false
                this.dialog= true
                this.error= false
                this.loading= true
                this.messageDialogeError= ""
                this.messageDialogeSucces= ""
                var dataToSend = {
                    donner: this.donner,
                    donnerEnedis: this.donnerEnedis,
                    optionTarifaire: this.optionTarifaire,
                    numCompteur: this.numCompteur,
                    societe: this.societe,
                }
                await this.getCodeNaf()
                /*await axios({
                    url: 'https://editioncontrat.volto.fr/engieContrat',
                    method: 'POST',
                    data: dataToSend,
                    headers:{'content-type': 'application/json'}
                    
                })
                .then(async (res) => {
                    var doc = await PDFDocument.load(res.data)
                    doc = await doc.save()
                    download(doc, 'ENGIE_'+this.data.raison+'_.pdf', 'application/pdf')
                }).catch((err)=>{
                    console.log(err)
                })*/
                if(this.data.typeCompteur=='BTSUPLU4' || this.data.typeCompteur=='BTSUPCU4'){
                    if(this.data.nbrYears=='2'){
                        await this.generatePdfCfourTwoYEARS()
                    }else if(this.data.nbrYears=='3'){
                        await this.generatePdf()
                    }
                }else if(this.data.typeCompteur=='BTINFLU' || this.data.typeCompteur=='BTINFCUST' || this.data.typeCompteur=='BTINFMUDT' ||
                     this.data.typeCompteur=='BTINFMU4' || this.data.typeCompteur=='BTINFCU4'){
                    if(this.donner.type != "Latitude"){
                        if(this.data.nbrYears=='1'){
                            await this.generatePdfCfiveOneYEARS()
                        }
                        if(this.data.nbrYears=='2'){
                            await this.generatePdfCfiveTwoYEARS()
                        }else if(this.data.nbrYears=='3'){
                            await this.generatePdfCfiveThreeYEARS()
                        }
                    }else{
                        await this.generatedPdfMarche()
                    }
                }
                this.dialog= false
                this.error= false
                this.loading= false
                this.messageDialogeError= ""
                this.messageDialogeSucces= ""
            },
            async seperateBySpaces(str){
                var result = str[0]
                for(var i =1; i<str.length; i++){
                    result = result + ' '+ str[i]
                }
                return result
            },
            async cu_lu_verification(typeCompteur){
                return typeCompteur.includes('LU')
                  
            },
            async getCodePostale(adresse){
                var myRe = new RegExp('\\d\\d\\d\\d\\d', '');
                var myArray = myRe.exec(adresse);
                var voie = ""
                var codePostale = ""
                var commune = ""
                if(myArray!=null){
                    codePostale =  myArray[0]
                    if(adresse.split(codePostale).length>=2){
                        voie = adresse.split(codePostale)[0]
                        commune = adresse.split(codePostale)[1]
                    }else{
                        commune = ""
                        voie = adresse
                    }
                }else{
                     voie = adresse
                }
                return [codePostale, voie, commune]
            },
            async generatedPdfMarche(){
                /////////////// page 1 ///////////////////////////////////////
                const url0 = '/pdf/engie/marche/engieMarche-1.pdf'
                const pageZero = await fetch(url0).then((res) =>
                    res.arrayBuffer()
                )
                // Load a PDFDocument from each of the existing PDFs
                const pageZeroDoc = await PDFDocument.load(pageZero)
                var getPages = pageZeroDoc.getPages()
                var firstPage = getPages[0]
                
                const timesRomanFont = await pageZeroDoc.embedFont(StandardFonts.HelveticaBold)
                //interloculteur
                firstPage.drawText(this.data.raison, {
                    x: 85,
                    y: 701,
                    size: 8,
                    color: rgb(0, 0, 0),
                })
                var siret = await this.seperateBySpaces(this.data.siret)
                firstPage.drawText(siret, {
                    x: 369,
                    y: 701,
                    size: 11.3,
                    color: rgb(0, 0, 0),
                })
                firstPage.drawText(this.data.codeNaf, {
                    x: 531,
                    y: 701,
                    size: 8,
                    color: rgb(0, 0, 0),
                })
                
                firstPage.drawText(this.data.adresseSociete, {
                    x: 80,
                    y: 691,
                    size: 8,
                    color: rgb(0, 0, 0),
                })
                
                var adresseSocieteSeparer = await this.getCodePostale(this.data.adresseSociete)
                if(!adresseSocieteSeparer[0]){
                    adresseSocieteSeparer[0] = "  "
                }
                if(!adresseSocieteSeparer[1]){
                    adresseSocieteSeparer[1] = "  "
                }
                if(!adresseSocieteSeparer[2]){
                    adresseSocieteSeparer[2] = "  "
                }
                firstPage.drawText(adresseSocieteSeparer[0], {
                    x: 85,
                    y: 682,
                    size: 8,
                    color: rgb(0, 0, 0),
                })
                
                firstPage.drawText(adresseSocieteSeparer[2], {
                    x: 196,
                    y: 682,
                    size: 8,
                    color: rgb(0, 0, 0),
                })
                
                firstPage.drawText(this.data.prenom+' '+this.data.nom, {
                    x: 295,
                    y: 661,
                    size: 9,
                    color: rgb(0, 0, 0),
                })
                firstPage.drawText('gérant', {
                    x: 300,
                    y: 648,
                    size: 9,
                    color: rgb(0, 0, 0),
                })
                firstPage.drawText(this.data.email, {
                    x: 260,
                    y: 628,
                    size: 9,
                    color: rgb(0, 0, 0),
                })
                /*firstPage.drawText(this.data.numTele, {
                    x: 275,
                    y: 607,
                    size: 9,
                    color: rgb(0, 0, 0),
                })*/
                firstPage.drawText(this.data.numTele, {
                    x: 275,
                    y: 596,
                    size: 9,
                    color: rgb(0, 0, 0),
                })
                var adresseSocieteSeparer = await this.getCodePostale(this.data.adresseCompteur)
                if(!adresseSocieteSeparer[0]){
                    adresseSocieteSeparer[0] = "  "
                }
                if(!adresseSocieteSeparer[1]){
                    adresseSocieteSeparer[1] = "  "
                }
                if(!adresseSocieteSeparer[2]){
                    adresseSocieteSeparer[2] = "  "
                }
                
                firstPage.drawText(adresseSocieteSeparer[0], {
                    x: 420,
                    y: 629,
                    size: 8,
                    color: rgb(0, 0, 0),
                })
                firstPage.drawText(adresseSocieteSeparer[2], {
                    x: 484,
                    y: 629,
                    size: 8,
                    color: rgb(0, 0, 0),
                })
                firstPage.drawText(adresseSocieteSeparer[1], {
                    x: 427,
                    y: 642,
                    size: 8,
                    color: rgb(0, 0, 0),
                })
                if(this.addAdrsFact == true){
                    var adresseSocieteSeparer = await this.getCodePostale(this.adresseFacturation)
                    if(!adresseSocieteSeparer[0]){
                        adresseSocieteSeparer[0] = "  "
                    }
                    if(!adresseSocieteSeparer[1]){
                        adresseSocieteSeparer[1] = "  "
                    }
                    if(!adresseSocieteSeparer[2]){
                        adresseSocieteSeparer[2] = "  "
                    }
                    firstPage.drawText(adresseSocieteSeparer[0], {
                        x: 420,
                        y: 578,
                        size: 8,
                        color: rgb(0, 0, 0),
                    })
                    firstPage.drawText(adresseSocieteSeparer[2], {
                        x: 480,
                        y: 578,
                        size: 8,
                        color: rgb(0, 0, 0),
                    })
                    firstPage.drawText(adresseSocieteSeparer[1], {
                        x: 420,
                        y: 600,
                        size: 8,
                        color: rgb(0, 0, 0),
                    }) 
                }
                firstPage.drawText('X', {
                    x: 51,
                    y: 533,
                    size: 10,
                    color: rgb(0, 0, 0),
                })
                var pdl = await this.seperateBySpaces(this.data.pdl)
                firstPage.drawText(pdl, {
                    x: 65,
                    y: 522,
                    size: 11.5,
                    color: rgb(0, 0, 0),
                })
                
                firstPage.drawText(this.data.kva, {
                    x: 122,
                    y: 485,
                    size: 9,
                    color: rgb(0, 0, 0),
                })
                 if(this.data.typeCompteur=='BTINFLU' || this.data.typeCompteur=='BTINFCUST' || this.data.typeCompteur=='BTINFCU4'){
                    /*firstPage.drawText(this.data.kvaBase, {
                        x: 105,
                        y: 470,
                        size: 9,
                        color: rgb(0, 0, 0),
                    })
                    
                    firstPage.drawText(this.data.kvaBase, {
                        x: 105,
                        y: 460,
                        size: 9,
                        color: rgb(0, 0, 0),
                    })*/
                    firstPage.drawText('X', {
                        x: 97,
                        y: 469,
                        size: 9,
                        color: rgb(0, 0, 0),
                    })
                }else{
                    firstPage.drawText('X', {
                        x: 128,
                        y: 469,
                        size: 9,
                        color: rgb(0, 0, 0),
                    })
                    firstPage.drawText(this.data.kvaHp, {
                        x: 275,
                        y: 469,
                        size: 9,
                        color: rgb(0, 0, 0),
                    })
                    
                    firstPage.drawText(this.data.kvaHc, {
                        x: 275,
                        y: 459,
                        size: 9,
                        color: rgb(0, 0, 0),
                    })
                }
                if(this.energieVert==true){
                    firstPage.drawText('X', {
                        x: 127,
                        y: 444,
                        size: 9,
                        color: rgb(0, 0, 0),
                    })
                }
                /*if(this.modePayement=="Prel"){
                    
                }else if(this.modePayement=="TIP"){
                    
                }
                if(this.typeFacture=='elec'){
                    
                }else if(this.typeFacture=='papier'){
                    
                    
                }
                */
                
                if(this.typePayement=='month'){
                    firstPage.drawText('X', {
                        x: 392,
                        y: 510,
                        size: 9,
                        color: rgb(0, 0, 0),
                    })
                }else{
                    firstPage.drawText('X', {
                        x: 392,
                        y: 365,
                        size: 9,
                        color: rgb(0, 0, 0),
                    })
                }
                if(this.typePayement=='month' && this.equipement=="oui"){
                    firstPage.drawText('X', {
                        x: 486,
                        y: 476,
                        size: 9,
                        color: rgb(0, 0, 0),
                    })
                }else if(this.typePayement=='month' && this.equipement=="non"){
                    firstPage.drawText('X', {
                        x: 389,
                        y: 476,
                        size: 9,
                        color: rgb(0, 0, 0),
                    })
                }
                if(this.typePayement=='month' && this.equipement=='non' && this.typeFacture=='papier'){
                    firstPage.drawText('X', {
                        x: 448,
                        y: 421,
                        size: 8,
                        color: rgb(0, 0, 0),
                    })

                }else if(this.typePayement=='month' && this.equipement=='non' && this.typeFacture=='elec'){
                    
                    firstPage.drawText('X', {
                        x: 393,
                        y: 421,
                        size: 8,
                        color: rgb(0, 0, 0),
                    })
                }
                
                if(this.typePayement=='autre' && this.modePayement=='Prel'){
                    firstPage.drawText('X', {
                        x: 457,
                        y: 335,
                        size: 9,
                        color: rgb(0, 0, 0),
                    })

                }else if(this.typePayement=='autre' && this.modePayement=='TIP'){
                    firstPage.drawText('X', {
                        x: 524,
                        y: 335,
                        size: 9,
                        color: rgb(0, 0, 0),
                    })
                }
                
                if(this.typePayement=='autre' && this.typeFacture=='elec'){
                    firstPage.drawText('X', {
                        x: 460,
                        y: 325,
                        size: 9,
                        color: rgb(0, 0, 0),
                    })

                }else if(this.typePayement=='autre' && this.typeFacture=='papier'){
                    
                    firstPage.drawText('X', {
                        x: 521,
                        y: 325,
                        size: 9,
                        color: rgb(0, 0, 0),
                    })
                }
                firstPage.drawText(this.data.dateDebut.split("-").reverse().join("/"), {
                    x: 238,
                    y: 285,
                    size: 13,
                    color: rgb(0, 0, 0),
                })

                /////////////// page 1 ///////////////////////////////////////
                var url1 = '/pdf/engie/marche/engieMarche-2.pdf'
                var pageOne = await fetch(url1).then((res) =>
                    res.arrayBuffer()
                )
                // Load a PDFDocument from each of the existing PDFs
                const pageOneDoc = await PDFDocument.load(pageOne)
                var getPages = pageOneDoc.getPages()
                var firstPage = getPages[0]

                /////////////// page 1 ///////////////////////////////////////
                var url1 = '/pdf/engie/marche/engieMarche-3.pdf'
                var pageOne = await fetch(url1).then((res) =>
                    res.arrayBuffer()
                )
                // Load a PDFDocument from each of the existing PDFs
                const pageTwoDoc = await PDFDocument.load(pageOne)
                var getPages = pageTwoDoc.getPages()
                var firstPage = getPages[0]

                /////////////// page 1 ///////////////////////////////////////
                var url1 = '/pdf/engie/marche/engieMarche-4.pdf'
                var pageOne = await fetch(url1).then((res) =>
                    res.arrayBuffer()
                )
                // Load a PDFDocument from each of the existing PDFs
                const pageThreeDoc = await PDFDocument.load(pageOne)
                var getPages = pageThreeDoc.getPages()
                var firstPage = getPages[0]

                /////////////// page 1 ///////////////////////////////////////
                var url1 = '/pdf/engie/marche/engieMarche-5.pdf'
                var pageOne = await fetch(url1).then((res) =>
                    res.arrayBuffer()
                )
                // Load a PDFDocument from each of the existing PDFs
                const pageFourDoc = await PDFDocument.load(pageOne)
                var getPages = pageFourDoc.getPages()
                var firstPage = getPages[0]
                
                 firstPage.drawText(this.data.raison, {
                    x: 90,
                    y: 725,
                    size: 8,
                    color: rgb(0, 0, 0),
                })
                firstPage.drawText(siret, {
                    x: 374,
                    y: 725,
                    size: 11.5,
                    color: rgb(0, 0, 0),
                })
                firstPage.drawText(this.data.codeNaf, {
                    x: 530,
                    y: 725,
                    size: 8,
                    color: rgb(0, 0, 0),
                })
                
                firstPage.drawText(this.data.adresseSociete, {
                    x: 80,
                    y: 709,
                    size: 8,
                    color: rgb(0, 0, 0),
                })
                var adresseSocieteSeparer = await this.getCodePostale(this.data.adresseSociete)
                if(!adresseSocieteSeparer[0]){
                    adresseSocieteSeparer[0] = "  "
                }
                if(!adresseSocieteSeparer[1]){
                    adresseSocieteSeparer[1] = "  "
                }
                if(!adresseSocieteSeparer[2]){
                    adresseSocieteSeparer[2] = "  "
                }
                firstPage.drawText(adresseSocieteSeparer[0], {
                    x: 80,
                    y: 692,
                    size: 8,
                    color: rgb(0, 0, 0),
                })
                
                firstPage.drawText(adresseSocieteSeparer[2], {
                    x: 177,
                    y: 692,
                    size: 8,
                    color: rgb(0, 0, 0),
                })
                if(this.energieVert == true){
                    firstPage.drawText('X', {
                        x: 379,
                        y: 656,
                        size: 9,
                        color: rgb(0, 0, 0),
                    })
                }else{
                    firstPage.drawText('X', {
                        x: 410,
                        y: 656,
                        size: 9,
                        color: rgb(0, 0, 0),
                    })
                }
                /////////////// page 1 ///////////////////////////////////////
                var url1 = '/pdf/engie/marche/engieMarche-6.pdf'
                var pageOne = await fetch(url1).then((res) =>
                    res.arrayBuffer()
                )
                // Load a PDFDocument from each of the existing PDFs
                const pageFiveDoc = await PDFDocument.load(pageOne)
                var getPages = pageFiveDoc.getPages()
                var firstPage = getPages[0]
                
                 firstPage.drawText(this.data.raison, {
                    x: 90,
                    y: 725,
                    size: 8,
                    color: rgb(0, 0, 0),
                })
                firstPage.drawText(siret, {
                    x: 374,
                    y: 725,
                    size: 11.5,
                    color: rgb(0, 0, 0),
                })
                firstPage.drawText(this.data.codeNaf, {
                    x: 530,
                    y: 725,
                    size: 8,
                    color: rgb(0, 0, 0),
                })
                
                firstPage.drawText(this.data.adresseSociete, {
                    x: 80,
                    y: 709,
                    size: 8,
                    color: rgb(0, 0, 0),
                })
                var adresseSocieteSeparer = await this.getCodePostale(this.data.adresseSociete)
                if(!adresseSocieteSeparer[0]){
                    adresseSocieteSeparer[0] = "  "
                }
                if(!adresseSocieteSeparer[1]){
                    adresseSocieteSeparer[1] = "  "
                }
                if(!adresseSocieteSeparer[2]){
                    adresseSocieteSeparer[2] = "  "
                }
                firstPage.drawText(adresseSocieteSeparer[0], {
                    x: 80,
                    y: 692,
                    size: 8,
                    color: rgb(0, 0, 0),
                })
                
                firstPage.drawText(adresseSocieteSeparer[2], {
                    x: 177,
                    y: 692,
                    size: 8,
                    color: rgb(0, 0, 0),
                })
                if(this.energieVert == true){
                    firstPage.drawText('X', {
                        x: 379,
                        y: 656,
                        size: 9,
                        color: rgb(0, 0, 0),
                    })
                }else{
                    firstPage.drawText('X', {
                        x: 410,
                        y: 656,
                        size: 9,
                        color: rgb(0, 0, 0),
                    })
                }

                /////////////// page 6 ///////////////////////////////////////
                
                var urlFinal = '/pdf/engie/marche/engieMarche-7.pdf'
                var pageFinal = await fetch(urlFinal).then((res) =>
                    res.arrayBuffer()
                )
                // Load a PDFDocument from each of the existing PDFs
                const pageFinalDoc = await PDFDocument.load(pageFinal)
                var getPages = pageFinalDoc.getPages()
                var firstPage = getPages[0]
                var timesRomanFont1 = await pageFinalDoc.embedFont(StandardFonts.TimesRoman)
                var para = "La société "+this.data.raison+", située au "+this.data.adresseSociete+", \nN° de SIRET "+this.data.siret+" et représentée par "+
                this.data.nom+" "+this.data.prenom +", agissant en qualité de gérant(e)"
                var multiText = layoutMultilineText(para, {
                    alignment: 'left', 
                    fontSize: 11.5,   
                    font: timesRomanFont1,
                    lineHeight: 12,
                    bounds: { width: 640 - 120, height: 1000  }
                })
                
                    var startingPositon=608
                for(let i = 0; i < multiText.lines.length; i++) {
                    
                    firstPage.drawText(`${multiText.lines[i].text}`, {
                        x: 60,
                        y: startingPositon,
                        size: 10,
                        maxWidth: 640 - 120,
                        color: rgb(0.20, 0.20, 0.14),
                    })
                    // move position down
                    startingPositon = startingPositon - (11)
                }
                 firstPage.drawText(this.data.pdl, {
                    x: 468,
                    y: 318,
                    size: 10.5,
                    color: rgb(0.20, 0.20, 0.14),
                })

                let mergedPdf = await PDFDocument.create()
                mergedPdf = await this.addPagesToPdf(mergedPdf, pageZeroDoc)
                mergedPdf = await this.addPagesToPdf(mergedPdf, pageOneDoc)
                mergedPdf = await this.addPagesToPdf(mergedPdf, pageTwoDoc)
                mergedPdf = await this.addPagesToPdf(mergedPdf, pageThreeDoc)
                mergedPdf = await this.addPagesToPdf(mergedPdf, pageFourDoc)
                mergedPdf = await this.addPagesToPdf(mergedPdf, pageFiveDoc)
                mergedPdf = await this.addPagesToPdf(mergedPdf, pageFinalDoc)
                //fin contact
                const pdfBytes = await mergedPdf.save()
                this.generatedPdf = pdfBytes
                download(pdfBytes, this.data.pdl+'_EngiePro_'+this.data.raison+'_.pdf', 'application/pdf')
            },
            async generatePdf(){
                /////////////// page 1 ///////////////////////////////////////
                const url0 = '/pdf/engie/c4_3ans/Engie Pro Activert C4 3ans merged-1.pdf'
                const pageZero = await fetch(url0).then((res) =>
                    res.arrayBuffer()
                )
                // Load a PDFDocument from each of the existing PDFs
                const pageZeroDoc = await PDFDocument.load(pageZero)
                var getPages = pageZeroDoc.getPages()
                var firstPage = getPages[0]
                
                const timesRomanFont = await pageZeroDoc.embedFont(StandardFonts.HelveticaBold)
                //interloculteur
                firstPage.drawText(this.data.raison, {
                    x: 85,
                    y: 701,
                    size: 8,
                    color: rgb(0, 0, 0),
                })
                var siret = await this.seperateBySpaces(this.data.siret)
                firstPage.drawText(siret, {
                    x: 369,
                    y: 701,
                    size: 11.3,
                    color: rgb(0, 0, 0),
                })
                firstPage.drawText(this.data.codeNaf, {
                    x: 531,
                    y: 701,
                    size: 8,
                    color: rgb(0, 0, 0),
                })
                
                firstPage.drawText(this.data.adresseSociete, {
                    x: 80,
                    y: 688,
                    size: 8,
                    color: rgb(0, 0, 0),
                })
                
                var adresseSocieteSeparer = await this.getCodePostale(this.data.adresseSociete)
                if(!adresseSocieteSeparer[0]){
                    adresseSocieteSeparer[0] = "  "
                }
                if(!adresseSocieteSeparer[1]){
                    adresseSocieteSeparer[1] = "  "
                }
                if(!adresseSocieteSeparer[2]){
                    adresseSocieteSeparer[2] = "  "
                }
                firstPage.drawText(adresseSocieteSeparer[0], {
                    x: 77,
                    y: 674,
                    size: 9,
                    color: rgb(0, 0, 0),
                })
                
                firstPage.drawText(adresseSocieteSeparer[2], {
                    x: 170,
                    y: 676,
                    size: 9,
                    color: rgb(0, 0, 0),
                })
                firstPage.drawText('X', {
                    x: 185,
                    y: 642,
                    size: 9,
                    color: rgb(0, 0, 0),
                })
                
                firstPage.drawText(this.data.prenom+' '+this.data.nom, {
                    x: 290,
                    y: 648,
                    size: 9,
                    color: rgb(0, 0, 0),
                })
                firstPage.drawText('gérant', {
                    x: 290,
                    y: 638,
                    size: 9,
                    color: rgb(0, 0, 0),
                })
                firstPage.drawText(this.data.email, {
                    x: 305,
                    y: 628,
                    size: 9,
                    color: rgb(0, 0, 0),
                })
               /* firstPage.drawText(this.data.numTele, {
                    x: 265,
                    y: 618,
                    size: 9,
                    color: rgb(0, 0, 0),
                })*/
                firstPage.drawText(this.data.numTele, {
                    x: 265,
                    y: 608,
                    size: 9,
                    color: rgb(0, 0, 0),
                })
                var adresseSocieteSeparer = await this.getCodePostale(this.data.adresseCompteur)
                if(!adresseSocieteSeparer[0]){
                    adresseSocieteSeparer[0] = "  "
                }
                if(!adresseSocieteSeparer[1]){
                    adresseSocieteSeparer[1] = "  "
                }
                if(!adresseSocieteSeparer[2]){
                    adresseSocieteSeparer[2] = "  "
                }
                
                firstPage.drawText(adresseSocieteSeparer[0], {
                    x: 420,
                    y: 642,
                    size: 8,
                    color: rgb(0, 0, 0),
                })
                firstPage.drawText(adresseSocieteSeparer[2], {
                    x: 480,
                    y: 642,
                    size: 8,
                    color: rgb(0, 0, 0),
                })
                firstPage.drawText(adresseSocieteSeparer[1], {
                    x: 420,
                    y: 652,
                    size: 8,
                    color: rgb(0, 0, 0),
                })
                if(this.addAdrsFact == true){
                    var adresseSocieteSeparer = await this.getCodePostale(this.adresseFacturation)
                    if(!adresseSocieteSeparer[0]){
                        adresseSocieteSeparer[0] = "  "
                    }
                    if(!adresseSocieteSeparer[1]){
                        adresseSocieteSeparer[1] = "  "
                    }
                    if(!adresseSocieteSeparer[2]){
                        adresseSocieteSeparer[2] = "  "
                    }
                    
                    firstPage.drawText(adresseSocieteSeparer[0], {
                        x: 420,
                        y: 585,
                        size: 8,
                        color: rgb(0, 0, 0),
                    })
                    firstPage.drawText(adresseSocieteSeparer[2], {
                        x: 480,
                        y: 585,
                        size: 8,
                        color: rgb(0, 0, 0),
                    })
                    firstPage.drawText(adresseSocieteSeparer[1], {
                        x: 420,
                        y: 595,
                        size: 8,
                        color: rgb(0, 0, 0),
                    }) 
                }
                var pdl = await this.seperateBySpaces(this.data.pdl)
                firstPage.drawText(pdl, {
                    x: 65,
                    y: 525,
                    size: 9,
                    color: rgb(0, 0, 0),
                })
                
                if(await this.cu_lu_verification(this.data.typeCompteur)==true){
                    firstPage.drawText('X', {
                        x: 295,
                        y: 522,
                        size: 9,
                        color: rgb(0, 0, 0),
                    })
                }else{
                    firstPage.drawText('X', {
                        x: 295,
                        y: 534,
                        size: 9,
                        color: rgb(0, 0, 0),
                    })
                }
                if(this.energieVert==true){
                    firstPage.drawText('X', {
                        x: 308,
                        y: 428,
                        size: 12,
                        color: rgb(0, 0, 0),
                    })
                }
                if(this.modePayement=="Prel"){
                    firstPage.drawText('X', {
                        x: 402,
                        y: 502,
                        size: 9,
                        color: rgb(0, 0, 0),
                    })
                }else if(this.modePayement=="TIP"){
                    firstPage.drawText('X', {
                        x: 498,
                        y: 502,
                        size: 9,
                        color: rgb(0, 0, 0),
                    })
                }
                
                if(this.typeFacture=='elec'){
                    firstPage.drawText('X', {
                        x: 498,
                        y: 460,
                        size: 9,
                        color: rgb(0, 0, 0),
                    })
                }else if(this.typeFacture=='papier'){
                    
                    firstPage.drawText('X', {
                        x: 524,
                        y: 460,
                        size: 9,
                        color: rgb(0, 0, 0),
                    })
                }
                firstPage.drawText(this.data.kva, {
                    x: 145,
                    y: 467,
                    size: 9,
                    color: rgb(0, 0, 0),
                })
                
                firstPage.drawText(this.data.kvaHph, {
                    x: 145,
                    y: 452,
                    size: 9,
                    color: rgb(0, 0, 0),
                })
                firstPage.drawText(this.data.kvaHch, {
                    x: 145,
                    y: 442,
                    size: 9,
                    color: rgb(0, 0, 0),
                })
                firstPage.drawText(this.data.kvaHpe, {
                    x: 145,
                    y: 432,
                    size: 9,
                    color: rgb(0, 0, 0),
                })
                firstPage.drawText(this.data.kvaHce, {
                    x: 145,
                    y: 422,
                    size: 9,
                    color: rgb(0, 0, 0),
                })

                firstPage.drawText(this.data.dateDebut.split("-").reverse().join("/"), {
                    x: 245,
                    y: 310,
                    size: 13,
                    color: rgb(0, 0, 0),
                })
                
                /////////////// page 2 ///////////////////////////////////////
                const url1 = '/pdf/engie/c4_3ans/Engie Pro Activert C4 3ans merged-2.pdf'
                const pageOne = await fetch(url1).then((res) =>
                    res.arrayBuffer()
                )
                // Load a PDFDocument from each of the existing PDFs
                const pageOneDoc = await PDFDocument.load(pageOne)
                var getPages = pageOneDoc.getPages()
                var firstPage = getPages[0]
                firstPage.drawText('X', {
                    x: 245,
                    y: 725,
                    size: 9,
                    color: rgb(0, 0, 0),
                })
                firstPage.drawText(this.data.dateAjourdhui, {
                    x: 210,
                    y: 480,
                    size: 13,
                    color: rgb(0, 0, 0),
                })
                
                 firstPage.drawText(this.data.prenom+' '+this.data.nom, {
                    x: 130,
                    y: 450,
                    size: 10,
                    color: rgb(0, 0, 0),
                })
                /////////////// page 3 ///////////////////////////////////////
                const url2 = '/pdf/engie/c4_3ans/Engie Pro Activert C4 3ans merged-3.pdf'
                const pageTwo = await fetch(url2).then((res) =>
                    res.arrayBuffer()
                )
                // Load a PDFDocument from each of the existing PDFs
                const pageTwoDoc = await PDFDocument.load(pageTwo)

                /////////////// page 4 ///////////////////////////////////////
                const url3 = '/pdf/engie/c4_3ans/Engie Pro Activert C4 3ans merged-4.pdf'
                const pageThree = await fetch(url3).then((res) =>
                    res.arrayBuffer()
                )
                // Load a PDFDocument from each of the existing PDFs
                const pageThreeDoc = await PDFDocument.load(pageThree)


                /////////////// page 5 ///////////////////////////////////////
                const url4 = '/pdf/engie/c4_3ans/Engie Pro Activert C4 3ans merged-5.pdf'
                const pageFour = await fetch(url4).then((res) =>
                    res.arrayBuffer()
                )
                // Load a PDFDocument from each of the existing PDFs
                const pageFourDoc = await PDFDocument.load(pageFour)
                var getPages = pageFourDoc.getPages()
                var firstPage = getPages[0]
                firstPage.drawText(this.data.raison, {
                    x: 90,
                    y: 725,
                    size: 8,
                    color: rgb(0, 0, 0),
                })
                firstPage.drawText(siret, {
                    x: 375,
                    y: 725,
                    size: 11.5,
                    color: rgb(0, 0, 0),
                })
                firstPage.drawText(this.data.codeNaf, {
                    x: 530,
                    y: 725,
                    size: 8,
                    color: rgb(0, 0, 0),
                })
                
                firstPage.drawText(this.data.adresseSociete, {
                    x: 80,
                    y: 709,
                    size: 8,
                    color: rgb(0, 0, 0),
                })
                var adresseSocieteSeparer = await this.getCodePostale(this.data.adresseSociete)
                if(!adresseSocieteSeparer[0]){
                    adresseSocieteSeparer[0] = "  "
                }
                if(!adresseSocieteSeparer[1]){
                    adresseSocieteSeparer[1] = "  "
                }
                if(!adresseSocieteSeparer[2]){
                    adresseSocieteSeparer[2] = "  "
                }
                firstPage.drawText(adresseSocieteSeparer[0], {
                    x: 80,
                    y: 692,
                    size: 8,
                    color: rgb(0, 0, 0),
                })
                
                firstPage.drawText(adresseSocieteSeparer[2], {
                    x: 177,
                    y: 692,
                    size: 8,
                    color: rgb(0, 0, 0),
                })
                firstPage.drawText('X', {
                    x: 292,
                    y: 667,
                    size: 9,
                    color: rgb(0, 0, 0),
                })
                if(this.energieVert==true){
                    firstPage.drawText('X', {
                        x: 385,
                        y: 656,
                        size: 9,
                        color: rgb(0, 0, 0),
                    })
                }
                if(this.percent=='25'){
                     firstPage.drawText('X', {
                        x: 393,
                        y: 557,
                        size: 8,
                        color: rgb(0, 0, 0),
                    })
                    firstPage.drawText('X', {
                        x: 491,
                        y: 557,
                        size: 8,
                        color: rgb(0, 0, 0),
                    })
                    firstPage.drawText('X', {
                        x: 293,
                        y: 557,
                        size: 8,
                        color: rgb(0, 0, 0),
                    })
                    firstPage.drawText('X', {
                        x: 197,
                        y: 557,
                        size: 8,
                        color: rgb(0, 0, 0),
                    })
                }else{
                    firstPage.drawText('X', {
                        x: 363,
                        y: 557,
                        size: 8,
                        color: rgb(0, 0, 0),
                    })
                    firstPage.drawText('X', {
                        x: 461,
                        y: 557,
                        size: 8,
                        color: rgb(0, 0, 0),
                    })
                    firstPage.drawText('X', {
                        x: 263,
                        y: 557,
                        size: 8,
                        color: rgb(0, 0, 0),
                    })
                    firstPage.drawText('X', {
                        x: 167,
                        y: 557,
                        size: 8,
                        color: rgb(0, 0, 0),
                    })
                }
               
                
                firstPage.drawText(this.data.dateAjourdhui, {
                    x: 225,
                    y: 65,
                    size: 11.5,
                    color: rgb(0, 0, 0),
                })
                /////////////// page 6 ///////////////////////////////////////
                const url5 = '/pdf/engie/c4_3ans/Engie Pro Activert C4 3ans merged-6.pdf'
                const pageFive = await fetch(url5).then((res) =>
                    res.arrayBuffer()
                )
                // Load a PDFDocument from each of the existing PDFs
                const pagefiveDoc = await PDFDocument.load(pageFive)
                var getPages = pagefiveDoc.getPages()
                var firstPage = getPages[0]
                var timesRomanFont1 = await pageZeroDoc.embedFont(StandardFonts.TimesRoman)
                var para = "La société "+this.data.raison+", située au "+this.data.adresseSociete+", \nN° de SIRET "+this.data.siret+" et représentée par "+
                this.data.nom+" "+this.data.prenom +", agissant en qualité de gérant(e)"
                var multiText = layoutMultilineText(para, {
                    alignment: 'left', 
                    fontSize: 11.5,   
                    font: timesRomanFont1,
                    lineHeight: 12,
                    bounds: { width: 640 - 120, height: 1000  }
                })
                
                    var startingPositon=608
                for(let i = 0; i < multiText.lines.length; i++) {
                    
                    firstPage.drawText(`${multiText.lines[i].text}`, {
                        x: 60,
                        y: startingPositon,
                        size: 10,
                        maxWidth: 640 - 120,
                        color: rgb(0.20, 0.20, 0.14),
                    })
                    // move position down
                    startingPositon = startingPositon - (11)
                }
                 firstPage.drawText(this.data.pdl, {
                    x: 468,
                    y: 318,
                    size: 10.5,
                    color: rgb(0.20, 0.20, 0.14),
                })
                
                // Create a new PDFDocument
                let mergedPdf = await PDFDocument.create()
                mergedPdf = await this.addPagesToPdf(mergedPdf, pageZeroDoc)
                mergedPdf = await this.addPagesToPdf(mergedPdf, pageOneDoc)
                mergedPdf = await this.addPagesToPdf(mergedPdf, pageTwoDoc)
                mergedPdf = await this.addPagesToPdf(mergedPdf, pageThreeDoc)
                mergedPdf = await this.addPagesToPdf(mergedPdf, pageFourDoc)
                mergedPdf = await this.addPagesToPdf(mergedPdf, pagefiveDoc)
                //fin contact
                const pdfBytes = await mergedPdf.save()
                this.generatedPdf = pdfBytes
                download(pdfBytes, this.data.pdl+'_EngiePro_'+this.data.raison+'_.pdf', 'application/pdf')
            },
            async generatePdfCfourTwoYEARS(){
                /////////////// page 1 ///////////////////////////////////////
                const url0 = '/pdf/engie/c4_2ans/Engie Pro Activert C4 2ans merged-1.pdf'
                const pageZero = await fetch(url0).then((res) =>
                    res.arrayBuffer()
                )
                // Load a PDFDocument from each of the existing PDFs
                const pageZeroDoc = await PDFDocument.load(pageZero)
                var getPages = pageZeroDoc.getPages()
                var firstPage = getPages[0]
                
                const timesRomanFont = await pageZeroDoc.embedFont(StandardFonts.HelveticaBold)
                //interloculteur
                firstPage.drawText(this.data.raison, {
                    x: 85,
                    y: 701,
                    size: 8,
                    color: rgb(0, 0, 0),
                })
                var siret = await this.seperateBySpaces(this.data.siret)
                firstPage.drawText(siret, {
                    x: 369,
                    y: 701,
                    size: 11.3,
                    color: rgb(0, 0, 0),
                })
                firstPage.drawText(this.data.codeNaf, {
                    x: 531,
                    y: 701,
                    size: 8,
                    color: rgb(0, 0, 0),
                })
                
                firstPage.drawText(this.data.adresseSociete, {
                    x: 80,
                    y: 688,
                    size: 8,
                    color: rgb(0, 0, 0),
                })
                
                var adresseSocieteSeparer = await this.getCodePostale(this.data.adresseSociete)
                if(!adresseSocieteSeparer[0]){
                    adresseSocieteSeparer[0] = "  "
                }
                if(!adresseSocieteSeparer[1]){
                    adresseSocieteSeparer[1] = "  "
                }
                if(!adresseSocieteSeparer[2]){
                    adresseSocieteSeparer[2] = "  "
                }
                firstPage.drawText(adresseSocieteSeparer[0], {
                    x: 77,
                    y: 674,
                    size: 8,
                    color: rgb(0, 0, 0),
                })
                
                firstPage.drawText(adresseSocieteSeparer[2], {
                    x: 170,
                    y: 676,
                    size: 8,
                    color: rgb(0, 0, 0),
                })
                firstPage.drawText('X', {
                    x: 156,
                    y: 641,
                    size: 9,
                    color: rgb(0, 0, 0),
                })
                
                firstPage.drawText(this.data.prenom+' '+this.data.nom, {
                    x: 290,
                    y: 648,
                    size: 9,
                    color: rgb(0, 0, 0),
                })
                firstPage.drawText('gérant', {
                    x: 290,
                    y: 638,
                    size: 9,
                    color: rgb(0, 0, 0),
                })
                firstPage.drawText(this.data.email, {
                    x: 305,
                    y: 628,
                    size: 9,
                    color: rgb(0, 0, 0),
                })
                /*firstPage.drawText(this.data.numTele, {
                    x: 265,
                    y: 618,
                    size: 9,
                    color: rgb(0, 0, 0),
                })*/
                firstPage.drawText(this.data.numTele, {
                    x: 265,
                    y: 608,
                    size: 9,
                    color: rgb(0, 0, 0),
                })
                var adresseSocieteSeparer = await this.getCodePostale(this.data.adresseCompteur)
                if(!adresseSocieteSeparer[0]){
                    adresseSocieteSeparer[0] = "  "
                }
                if(!adresseSocieteSeparer[1]){
                    adresseSocieteSeparer[1] = "  "
                }
                if(!adresseSocieteSeparer[2]){
                    adresseSocieteSeparer[2] = "  "
                }
                
                firstPage.drawText(adresseSocieteSeparer[0], {
                    x: 420,
                    y: 642,
                    size: 8,
                    color: rgb(0, 0, 0),
                })
                firstPage.drawText(adresseSocieteSeparer[2], {
                    x: 480,
                    y: 642,
                    size: 8,
                    color: rgb(0, 0, 0),
                })
                firstPage.drawText(adresseSocieteSeparer[1], {
                    x: 420,
                    y: 652,
                    size: 8,
                    color: rgb(0, 0, 0),
                })
                if(this.addAdrsFact == true){
                    var adresseSocieteSeparer = await this.getCodePostale(this.adresseFacturation)
                    if(!adresseSocieteSeparer[0]){
                        adresseSocieteSeparer[0] = "  "
                    }
                    if(!adresseSocieteSeparer[1]){
                        adresseSocieteSeparer[1] = "  "
                    }
                    if(!adresseSocieteSeparer[2]){
                        adresseSocieteSeparer[2] = "  "
                    }
                    
                    firstPage.drawText(adresseSocieteSeparer[0], {
                        x: 420,
                        y: 585,
                        size: 8,
                        color: rgb(0, 0, 0),
                    })
                    firstPage.drawText(adresseSocieteSeparer[2], {
                        x: 480,
                        y: 585,
                        size: 8,
                        color: rgb(0, 0, 0),
                    })
                    firstPage.drawText(adresseSocieteSeparer[1], {
                        x: 420,
                        y: 595,
                        size: 8,
                        color: rgb(0, 0, 0),
                    }) 
                }
                var pdl = await this.seperateBySpaces(this.data.pdl)
                firstPage.drawText(pdl, {
                    x: 65,
                    y: 525,
                    size: 9,
                    color: rgb(0, 0, 0),
                })
                
                if(await this.cu_lu_verification(this.data.typeCompteur)==true){
                    firstPage.drawText('X', {
                        x: 295,
                        y: 522,
                        size: 9,
                        color: rgb(0, 0, 0),
                    })
                }else{
                    firstPage.drawText('X', {
                        x: 295,
                        y: 534,
                        size: 9,
                        color: rgb(0, 0, 0),
                    })
                }
                if(this.energieVert==true){
                    firstPage.drawText('X', {
                        x: 308,
                        y: 428,
                        size: 12,
                        color: rgb(0, 0, 0),
                    })
                }
                if(this.modePayement=="Prel"){
                    firstPage.drawText('X', {
                        x: 402,
                        y: 502,
                        size: 9,
                        color: rgb(0, 0, 0),
                    })
                }else if(this.modePayement=="TIP"){
                    firstPage.drawText('X', {
                        x: 498,
                        y: 502,
                        size: 9,
                        color: rgb(0, 0, 0),
                    })
                }
                if(this.typeFacture=='elec'){
                    firstPage.drawText('X', {
                        x: 498,
                        y: 460,
                        size: 9,
                        color: rgb(0, 0, 0),
                    })
                }else if(this.typeFacture=='papier'){
                    
                    firstPage.drawText('X', {
                        x: 524,
                        y: 460,
                        size: 9,
                        color: rgb(0, 0, 0),
                    })
                }
                firstPage.drawText(this.data.kva, {
                    x: 145,
                    y: 467,
                    size: 9,
                    color: rgb(0, 0, 0),
                })
                
                firstPage.drawText(this.data.kvaHph, {
                    x: 145,
                    y: 452,
                    size: 9,
                    color: rgb(0, 0, 0),
                })
                firstPage.drawText(this.data.kvaHch, {
                    x: 145,
                    y: 442,
                    size: 9,
                    color: rgb(0, 0, 0),
                })
                firstPage.drawText(this.data.kvaHpe, {
                    x: 145,
                    y: 432,
                    size: 9,
                    color: rgb(0, 0, 0),
                })
                firstPage.drawText(this.data.kvaHce, {
                    x: 145,
                    y: 422,
                    size: 9,
                    color: rgb(0, 0, 0),
                })

                firstPage.drawText(this.data.dateDebut.split("-").reverse().join("/"), {
                    x: 245,
                    y: 310,
                    size: 13,
                    color: rgb(0, 0, 0),
                })
                
                /////////////// page 2 ///////////////////////////////////////
                const url1 = '/pdf/engie/c4_2ans/Engie Pro Activert C4 2ans merged-2.pdf'
                const pageOne = await fetch(url1).then((res) =>
                    res.arrayBuffer()
                )
                // Load a PDFDocument from each of the existing PDFs
                const pageOneDoc = await PDFDocument.load(pageOne)
                var getPages = pageOneDoc.getPages()
                var firstPage = getPages[0]
                firstPage.drawText('X', {
                    x: 245,
                    y: 725,
                    size: 9,
                    color: rgb(0, 0, 0),
                })
                firstPage.drawText(this.data.dateAjourdhui, {
                    x: 210,
                    y: 480,
                    size: 13,
                    color: rgb(0, 0, 0),
                })
                
                 firstPage.drawText(this.data.prenom+' '+this.data.nom, {
                    x: 130,
                    y: 450,
                    size: 10,
                    color: rgb(0, 0, 0),
                })
                /////////////// page 3 ///////////////////////////////////////
                const url2 = '/pdf/engie/c4_2ans/Engie Pro Activert C4 2ans merged-3.pdf'
                const pageTwo = await fetch(url2).then((res) =>
                    res.arrayBuffer()
                )
                // Load a PDFDocument from each of the existing PDFs
                const pageTwoDoc = await PDFDocument.load(pageTwo)

                /////////////// page 4 ///////////////////////////////////////
                const url3 = '/pdf/engie/c4_2ans/Engie Pro Activert C4 2ans merged-4.pdf'
                const pageThree = await fetch(url3).then((res) =>
                    res.arrayBuffer()
                )
                // Load a PDFDocument from each of the existing PDFs
                const pageThreeDoc = await PDFDocument.load(pageThree)


                /////////////// page 5 ///////////////////////////////////////
                const url4 = '/pdf/engie/c4_2ans/Engie Pro Activert C4 2ans merged-5.pdf'
                const pageFour = await fetch(url4).then((res) =>
                    res.arrayBuffer()
                )
                // Load a PDFDocument from each of the existing PDFs
                const pageFourDoc = await PDFDocument.load(pageFour)
                var getPages = pageFourDoc.getPages()
                var firstPage = getPages[0]
                firstPage.drawText(this.data.raison, {
                    x: 90,
                    y: 725,
                    size: 8,
                    color: rgb(0, 0, 0),
                })
                firstPage.drawText(siret, {
                    x: 375,
                    y: 725,
                    size: 11.5,
                    color: rgb(0, 0, 0),
                })
                firstPage.drawText(this.data.codeNaf, {
                    x: 530,
                    y: 725,
                    size: 8,
                    color: rgb(0, 0, 0),
                })
                
                firstPage.drawText(this.data.adresseSociete, {
                    x: 80,
                    y: 709,
                    size: 8,
                    color: rgb(0, 0, 0),
                })
                var adresseSocieteSeparer = await this.getCodePostale(this.data.adresseSociete)
                if(!adresseSocieteSeparer[0]){
                    adresseSocieteSeparer[0] = "  "
                }
                if(!adresseSocieteSeparer[1]){
                    adresseSocieteSeparer[1] = "  "
                }
                if(!adresseSocieteSeparer[2]){
                    adresseSocieteSeparer[2] = "  "
                }
                firstPage.drawText(adresseSocieteSeparer[0], {
                    x: 80,
                    y: 692,
                    size: 8,
                    color: rgb(0, 0, 0),
                })
                
                firstPage.drawText(adresseSocieteSeparer[2], {
                    x: 177,
                    y: 692,
                    size: 8,
                    color: rgb(0, 0, 0),
                })
                firstPage.drawText('X', {
                    x: 292,
                    y: 667,
                    size: 9,
                    color: rgb(0, 0, 0),
                })
                if(this.energieVert==true){
                    firstPage.drawText('X', {
                        x: 385,
                        y: 656,
                        size: 9,
                        color: rgb(0, 0, 0),
                    })
                }
                if(this.percent=='25'){
                     firstPage.drawText('X', {
                        x: 393,
                        y: 557,
                        size: 8,
                        color: rgb(0, 0, 0),
                    })
                    firstPage.drawText('X', {
                        x: 491,
                        y: 557,
                        size: 8,
                        color: rgb(0, 0, 0),
                    })
                    firstPage.drawText('X', {
                        x: 293,
                        y: 557,
                        size: 8,
                        color: rgb(0, 0, 0),
                    })
                    firstPage.drawText('X', {
                        x: 197,
                        y: 557,
                        size: 8,
                        color: rgb(0, 0, 0),
                    })
                }else{
                    firstPage.drawText('X', {
                        x: 363,
                        y: 557,
                        size: 8,
                        color: rgb(0, 0, 0),
                    })
                    firstPage.drawText('X', {
                        x: 461,
                        y: 557,
                        size: 8,
                        color: rgb(0, 0, 0),
                    })
                    firstPage.drawText('X', {
                        x: 263,
                        y: 557,
                        size: 8,
                        color: rgb(0, 0, 0),
                    })
                    firstPage.drawText('X', {
                        x: 167,
                        y: 557,
                        size: 8,
                        color: rgb(0, 0, 0),
                    })
                }
               
                
                firstPage.drawText(this.data.dateAjourdhui, {
                    x: 225,
                    y: 65,
                    size: 11.5,
                    color: rgb(0, 0, 0),
                })
                /////////////// page 6 ///////////////////////////////////////
                const url5 = '/pdf/engie/c4_2ans/Engie Pro Activert C4 2ans merged-6.pdf'
                const pageFive = await fetch(url5).then((res) =>
                    res.arrayBuffer()
                )
                // Load a PDFDocument from each of the existing PDFs
                const pagefiveDoc = await PDFDocument.load(pageFive)
                var getPages = pagefiveDoc.getPages()
                var firstPage = getPages[0]
                var timesRomanFont1 = await pageZeroDoc.embedFont(StandardFonts.TimesRoman)
                var para = "La société "+this.data.raison+", située au "+this.data.adresseSociete+", \nN° de SIRET "+this.data.siret+" et représentée par "+
                this.data.nom+" "+this.data.prenom +", agissant en qualité de gérant(e)"
                var multiText = layoutMultilineText(para, {
                    alignment: 'left', 
                    fontSize: 11.5,   
                    font: timesRomanFont1,
                    lineHeight: 12,
                    bounds: { width: 640 - 120, height: 1000  }
                })
                
                    var startingPositon=608
                for(let i = 0; i < multiText.lines.length; i++) {
                    
                    firstPage.drawText(`${multiText.lines[i].text}`, {
                        x: 60,
                        y: startingPositon,
                        size: 10,
                        maxWidth: 640 - 120,
                        color: rgb(0.20, 0.20, 0.14),
                    })
                    // move position down
                    startingPositon = startingPositon - (11)
                }
                 firstPage.drawText(this.data.pdl, {
                    x: 468,
                    y: 318,
                    size: 10.5,
                    color: rgb(0.20, 0.20, 0.14),
                })
                
                // Create a new PDFDocument
                let mergedPdf = await PDFDocument.create()
                mergedPdf = await this.addPagesToPdf(mergedPdf, pageZeroDoc)
                mergedPdf = await this.addPagesToPdf(mergedPdf, pageOneDoc)
                mergedPdf = await this.addPagesToPdf(mergedPdf, pageTwoDoc)
                mergedPdf = await this.addPagesToPdf(mergedPdf, pageThreeDoc)
                mergedPdf = await this.addPagesToPdf(mergedPdf, pageFourDoc)
                mergedPdf = await this.addPagesToPdf(mergedPdf, pagefiveDoc)
                
                //fin contact
                const pdfBytes = await mergedPdf.save()
                this.generatedPdf = pdfBytes
                download(pdfBytes, this.data.pdl+'_EngiePro_'+this.data.raison+'_.pdf', 'application/pdf')
            },
            async generatePdfCfiveThreeYEARS(){
                /////////////// page 1 ///////////////////////////////////////
                const url0 = '/pdf/engie/c5_3ans/Engie Pro Activert C5 3ans merged-1.pdf'
                const pageZero = await fetch(url0).then((res) =>
                    res.arrayBuffer()
                )
                // Load a PDFDocument from each of the existing PDFs
                const pageZeroDoc = await PDFDocument.load(pageZero)
                var getPages = pageZeroDoc.getPages()
                var firstPage = getPages[0]
                //interloculteur
                firstPage.drawText(this.data.raison, {
                    x: 85,
                    y: 703,
                    size: 8,
                    color: rgb(0, 0, 0),
                })
                var siret = await this.seperateBySpaces(this.data.siret)
                firstPage.drawText(siret, {
                    x: 369,
                    y: 701,
                    size: 11.3,
                    color: rgb(0, 0, 0),
                })
                firstPage.drawText(this.data.codeNaf, {
                    x: 531,
                    y: 701,
                    size: 8,
                    color: rgb(0, 0, 0),
                })
                
                firstPage.drawText(this.data.adresseSociete, {
                    x: 80,
                    y: 691,
                    size: 8,
                    color: rgb(0, 0, 0),
                })
                
                var adresseSocieteSeparer = await this.getCodePostale(this.data.adresseSociete)
                if(!adresseSocieteSeparer[0]){
                    adresseSocieteSeparer[0] = "  "
                }
                if(!adresseSocieteSeparer[1]){
                    adresseSocieteSeparer[1] = "  "
                }
                if(!adresseSocieteSeparer[2]){
                    adresseSocieteSeparer[2] = "  "
                }
                firstPage.drawText(adresseSocieteSeparer[0], {
                    x: 85,
                    y: 682,
                    size: 8,
                    color: rgb(0, 0, 0),
                })
                
                firstPage.drawText(adresseSocieteSeparer[2], {
                    x: 196,
                    y: 682,
                    size: 8,
                    color: rgb(0, 0, 0),
                })
                firstPage.drawText('X', {
                    x: 186,
                    y: 640,
                    size: 9,
                    color: rgb(0, 0, 0),
                })
                
                firstPage.drawText(this.data.prenom+' '+this.data.nom, {
                    x: 295,
                    y: 661,
                    size: 9,
                    color: rgb(0, 0, 0),
                })
                firstPage.drawText('gérant', {
                    x: 300,
                    y: 648,
                    size: 9,
                    color: rgb(0, 0, 0),
                })
                firstPage.drawText(this.data.email, {
                    x: 260,
                    y: 628,
                    size: 9,
                    color: rgb(0, 0, 0),
                })
                /*firstPage.drawText(this.data.numTele, {
                    x: 275,
                    y: 607,
                    size: 9,
                    color: rgb(0, 0, 0),
                })*/
                firstPage.drawText(this.data.numTele, {
                    x: 275,
                    y: 596,
                    size: 9,
                    color: rgb(0, 0, 0),
                })
                var adresseSocieteSeparer = await this.getCodePostale(this.data.adresseCompteur)
                if(!adresseSocieteSeparer[0]){
                    adresseSocieteSeparer[0] = "  "
                }
                if(!adresseSocieteSeparer[1]){
                    adresseSocieteSeparer[1] = "  "
                }
                if(!adresseSocieteSeparer[2]){
                    adresseSocieteSeparer[2] = "  "
                }
                
                firstPage.drawText(adresseSocieteSeparer[0], {
                    x: 420,
                    y: 629,
                    size: 8,
                    color: rgb(0, 0, 0),
                })
                firstPage.drawText(adresseSocieteSeparer[2], {
                    x: 484,
                    y: 629,
                    size: 8,
                    color: rgb(0, 0, 0),
                })
                firstPage.drawText(adresseSocieteSeparer[1], {
                    x: 427,
                    y: 642,
                    size: 8,
                    color: rgb(0, 0, 0),
                })
                if(this.addAdrsFact == true){
                    var adresseSocieteSeparer = await this.getCodePostale(this.adresseFacturation)
                    if(!adresseSocieteSeparer[0]){
                        adresseSocieteSeparer[0] = "  "
                    }
                    if(!adresseSocieteSeparer[1]){
                        adresseSocieteSeparer[1] = "  "
                    }
                    if(!adresseSocieteSeparer[2]){
                        adresseSocieteSeparer[2] = "  "
                    }
                    firstPage.drawText(adresseSocieteSeparer[0], {
                        x: 420,
                        y: 578,
                        size: 8,
                        color: rgb(0, 0, 0),
                    })
                    firstPage.drawText(adresseSocieteSeparer[2], {
                        x: 480,
                        y: 578,
                        size: 8,
                        color: rgb(0, 0, 0),
                    })
                    firstPage.drawText(adresseSocieteSeparer[1], {
                        x: 420,
                        y: 600,
                        size: 8,
                        color: rgb(0, 0, 0),
                    }) 
                }
                firstPage.drawText('X', {
                    x: 49,
                    y: 549,
                    size: 10,
                    color: rgb(0, 0, 0),
                })
                var pdl = await this.seperateBySpaces(this.data.pdl)
                firstPage.drawText(pdl, {
                    x: 65,
                    y: 536,
                    size: 11.5,
                    color: rgb(0, 0, 0),
                })
                
                firstPage.drawText(this.data.kva, {
                    x: 122,
                    y: 500,
                    size: 9,
                    color: rgb(0, 0, 0),
                })
                 if(this.data.typeCompteur=='BTINFLU' || this.data.typeCompteur=='BTINFCUST' || this.data.typeCompteur=='BTINFCU4'){
                    /*firstPage.drawText(this.data.kvaBase, {
                        x: 105,
                        y: 470,
                        size: 9,
                        color: rgb(0, 0, 0),
                    })
                    
                    firstPage.drawText(this.data.kvaBase, {
                        x: 105,
                        y: 460,
                        size: 9,
                        color: rgb(0, 0, 0),
                    })*/
                    firstPage.drawText('X', {
                        x: 95,
                        y: 488,
                        size: 9,
                        color: rgb(0, 0, 0),
                    })
                }else{
                    firstPage.drawText('X', {
                        x: 126,
                        y: 488,
                        size: 9,
                        color: rgb(0, 0, 0),
                    })
                    firstPage.drawText(this.data.kvaHp, {
                        x: 105,
                        y: 470,
                        size: 9,
                        color: rgb(0, 0, 0),
                    })
                    
                    firstPage.drawText(this.data.kvaHc, {
                        x: 105,
                        y: 460,
                        size: 9,
                        color: rgb(0, 0, 0),
                    })
                }
                if(this.energieVert==true){
                    firstPage.drawText('X', {
                        x: 112,
                        y: 446,
                        size: 9,
                        color: rgb(0, 0, 0),
                    })
                }
                if(this.typePayement=='month'){
                    firstPage.drawText('X', {
                        x: 389,
                        y: 514,
                        size: 9,
                        color: rgb(0, 0, 0),
                    })
                }else{
                    firstPage.drawText('X', {
                        x: 389,
                        y: 385,
                        size: 9,
                        color: rgb(0, 0, 0),
                    })
                }
                if(this.typePayement=='month' && this.equipement=="oui"){
                    firstPage.drawText('X', {
                        x: 488,
                        y: 492,
                        size: 9,
                        color: rgb(0, 0, 0),
                    })
                }else if(this.typePayement=='month' && this.equipement=="non"){
                    firstPage.drawText('X', {
                        x: 389,
                        y: 492,
                        size: 9,
                        color: rgb(0, 0, 0),
                    })
                }
                if(this.typePayement=='month' && this.equipement=='non' && this.typeFacture=='papier'){
                    firstPage.drawText('X', {
                        x: 446,
                        y: 429,
                        size: 8,
                        color: rgb(0, 0, 0),
                    })

                }else if(this.typePayement=='month' && this.equipement=='non' && this.typeFacture=='elec'){
                    
                    firstPage.drawText('X', {
                        x: 390,
                        y: 429,
                        size: 8,
                        color: rgb(0, 0, 0),
                    })
                }
                
                if(this.typePayement=='autre' && this.modePayement=='Prel'){
                    firstPage.drawText('X', {
                        x: 458,
                        y: 367,
                        size: 8,
                        color: rgb(0, 0, 0),
                    })

                }else if(this.typePayement=='autre' && this.modePayement=='TIP'){
                    
                    firstPage.drawText('X', {
                        x: 521,
                        y: 367,
                        size: 8,
                        color: rgb(0, 0, 0),
                    })
                }
                
                if(this.typePayement=='autre' && this.typeFacture=='elec'){
                    firstPage.drawText('X', {
                        x: 461,
                        y: 358,
                        size: 8,
                        color: rgb(0, 0, 0),
                    })

                }else if(this.typePayement=='autre' && this.typeFacture=='papier'){
                    
                    firstPage.drawText('X', {
                        x: 522,
                        y: 357,
                        size: 8,
                        color: rgb(0, 0, 0),
                    })
                }
                firstPage.drawText(this.data.dateDebut.split("-").reverse().join("/"), {
                    x: 225,
                    y: 335,
                    size: 13,
                    color: rgb(0, 0, 0),
                })
                //////////////////// page 1 /////////////////////////////////////////
                const url1 = '/pdf/engie/c5_3ans/Engie Pro Activert C5 3ans merged-2.pdf'
                const pageOne = await fetch(url1).then((res) =>
                    res.arrayBuffer()
                )
                const pageOneDoc = await PDFDocument.load(pageOne)
                var getPages = pageOneDoc.getPages()
                var firstPage = getPages[0]
                //////////////////// page 2 /////////////////////////////////////////
                const url2 = '/pdf/engie/c5_3ans/Engie Pro Activert C5 3ans merged-3.pdf'
                const pageTwo = await fetch(url2).then((res) =>
                    res.arrayBuffer()
                )
                const pageTwoDoc = await PDFDocument.load(pageTwo)
                var getPages = pageTwoDoc.getPages()
                var firstPage = getPages[0]
                //////////////////// page 3 /////////////////////////////////////////
                const url3 = '/pdf/engie/c5_3ans/Engie Pro Activert C5 3ans merged-4.pdf'
                const pageThree = await fetch(url3).then((res) =>
                    res.arrayBuffer()
                )
                const pageThreeDoc = await PDFDocument.load(pageThree)
                var getPages = pageThreeDoc.getPages()
                var firstPage = getPages[0]
                //////////////////// page 4 /////////////////////////////////////////
                const url4 = '/pdf/engie/c5_3ans/Engie Pro Activert C5 3ans merged-5.pdf'
                const pageFour = await fetch(url4).then((res) =>
                    res.arrayBuffer()
                )
                const pageFourDoc = await PDFDocument.load(pageFour)
                var getPages = pageFourDoc.getPages()
                var firstPage = getPages[0]
                //////////////////// page 5 /////////////////////////////////////////
                const url5 = '/pdf/engie/c5_3ans/Engie Pro Activert C5 3ans merged-6.pdf'
                const pageFive = await fetch(url5).then((res) =>
                    res.arrayBuffer()
                )
                const pageFiveDoc = await PDFDocument.load(pageFive)
                var getPages = pageFiveDoc.getPages()
                var firstPage = getPages[0]
                //////////////////// page 6 /////////////////////////////////////////
                const url6 = '/pdf/engie/c5_3ans/Engie Pro Activert C5 3ans merged-7.pdf'
                const pageSix = await fetch(url6).then((res) =>
                    res.arrayBuffer()
                )
                const pageSixDoc = await PDFDocument.load(pageSix)
                var getPages = pageSixDoc.getPages()
                var firstPage = getPages[0]
                //////////////////// page 7 /////////////////////////////////////////
                const url7 = '/pdf/engie/c5_3ans/Engie Pro Activert C5 3ans merged-8.pdf'
                const pageSeven = await fetch(url7).then((res) =>
                    res.arrayBuffer()
                )
                const pageSevenDoc = await PDFDocument.load(pageSeven)
                var getPages = pageSevenDoc.getPages()
                var firstPage = getPages[0]
                 firstPage.drawText(this.data.raison, {
                    x: 90,
                    y: 725,
                    size: 8,
                    color: rgb(0, 0, 0),
                })
                firstPage.drawText(siret, {
                    x: 374,
                    y: 725,
                    size: 11.5,
                    color: rgb(0, 0, 0),
                })
                firstPage.drawText(this.data.codeNaf, {
                    x: 530,
                    y: 725,
                    size: 8,
                    color: rgb(0, 0, 0),
                })
                
                firstPage.drawText(this.data.adresseSociete, {
                    x: 80,
                    y: 709,
                    size: 8,
                    color: rgb(0, 0, 0),
                })
                var adresseSocieteSeparer = await this.getCodePostale(this.data.adresseSociete)
                if(!adresseSocieteSeparer[0]){
                    adresseSocieteSeparer[0] = "  "
                }
                if(!adresseSocieteSeparer[1]){
                    adresseSocieteSeparer[1] = "  "
                }
                if(!adresseSocieteSeparer[2]){
                    adresseSocieteSeparer[2] = "  "
                }
                
                if(this.data.kva == '3'){
                     //3
                    firstPage.drawText('X', {
                        x: 16,
                        y: 501,
                        size: 9,
                        color: rgb(0, 0, 0),
                    })
                }else if(this.data.kva == '6'){
                    //6
                    firstPage.drawText('X', {
                        x: 16,
                        y: 487,
                        size: 9,
                        color: rgb(0, 0, 0),
                    })
                }else if(this.data.kva == '9'){
                    //9
                    firstPage.drawText('X', {
                        x: 16,
                        y: 473,
                        size: 9,
                        color: rgb(0, 0, 0),
                    })
                    
                }else if(this.data.kva == '12'){
                    //12
                    firstPage.drawText('X', {
                        x: 16,
                        y: 459,
                        size: 9,
                        color: rgb(0, 0, 0),
                    })
                    
                }else if(this.data.kva == '15'){
                    //15
                    firstPage.drawText('X', {
                        x: 16,
                        y: 446,
                        size: 9,
                        color: rgb(0, 0, 0),
                    })
                    
                }else if(this.data.kva == '18'){
                    //18
                    firstPage.drawText('X', {
                        x: 16,
                        y: 432,
                        size: 9,
                        color: rgb(0, 0, 0),
                    })
                    
                }else if(this.data.kva == '24'){
                    //24
                    firstPage.drawText('X', {
                        x: 16,
                        y: 418,
                        size: 9,
                        color: rgb(0, 0, 0),
                    })
                    
                }else if(this.data.kva == '30'){
                    //30
                    firstPage.drawText('X', {
                        x: 16,
                        y: 404,
                        size: 9,
                        color: rgb(0, 0, 0),
                    })
                    
                }else if(this.data.kva == '36'){
                    //36
                    firstPage.drawText('X', {
                        x: 16,
                        y: 389,
                        size: 9,
                        color: rgb(0, 0, 0),
                    })
                    
                }
                firstPage.drawText(adresseSocieteSeparer[0], {
                    x: 80,
                    y: 692,
                    size: 8,
                    color: rgb(0, 0, 0),
                })
                
                firstPage.drawText(adresseSocieteSeparer[2], {
                    x: 177,
                    y: 692,
                    size: 8,
                    color: rgb(0, 0, 0),
                })
                
                firstPage.drawText('X', {
                    x: 291,
                    y: 678,
                    size: 9,
                    color: rgb(0, 0, 0),
                })
                
                if(this.energieVert == true){
                    firstPage.drawText('X', {
                        x: 389,
                        y: 656,
                        size: 9,
                        color: rgb(0, 0, 0),
                    })
                }else{
                    firstPage.drawText('X', {
                        x: 419,
                        y: 656,
                        size: 9,
                        color: rgb(0, 0, 0),
                    })
                }
                if(this.data.typeCompteur=='BTINFLU'||this.data.typeCompteur=='BTINFCUST'||this.data.typeCompteur=='BTINFCU4'){
                    firstPage.drawText('X', {
                        x: 112,
                        y: 586,
                        size: 9,
                        color: rgb(0, 0, 0),
                    })
                    if(this.percent=='15'){
                        firstPage.drawText('X', {
                            x: 147,
                            y: 519,
                            size: 9,
                            color: rgb(0, 0, 0),
                        })
                    }else{
                        firstPage.drawText('X', {
                            x: 199,
                            y: 519,
                            size: 9,
                            color: rgb(0, 0, 0),
                        })
                    }
                }else{
                    firstPage.drawText('X', {
                        x: 335,
                        y: 586,
                        size: 9,
                        color: rgb(0, 0, 0),
                    })
                    if(this.percent=='25'){
                        
                        firstPage.drawText('X', {
                            x: 403,
                            y: 519,
                            size: 9,
                            color: rgb(0, 0, 0),
                        })
                        
                        firstPage.drawText('X', {
                            x: 524,
                            y: 519,
                            size: 9,
                            color: rgb(0, 0, 0),
                        })
                    }else{
                        firstPage.drawText('X', {
                            x: 359,
                            y: 518,
                            size: 9,
                            color: rgb(0, 0, 0),
                        })
                        firstPage.drawText('X', {
                            x: 481,
                            y: 518,
                            size: 9,
                            color: rgb(0, 0, 0),
                        })
                    }
                }
                //////////////////// page 8 /////////////////////////////////////////
                const url8 = '/pdf/engie/c5_3ans/Engie Pro Activert C5 3ans merged-9.pdf'
                const pageEight = await fetch(url8).then((res) =>
                    res.arrayBuffer()
                )
                const pageEightDoc = await PDFDocument.load(pageEight)
                var getPages = pageEightDoc.getPages()
                var firstPage = getPages[0]
                 firstPage.drawText(this.data.raison, {
                    x: 90,
                    y: 725,
                    size: 8,
                    color: rgb(0, 0, 0),
                })
                firstPage.drawText(siret, {
                    x: 374,
                    y: 725,
                    size: 11.5,
                    color: rgb(0, 0, 0),
                })
                firstPage.drawText(this.data.codeNaf, {
                    x: 530,
                    y: 725,
                    size: 8,
                    color: rgb(0, 0, 0),
                })
                
                firstPage.drawText(this.data.adresseSociete, {
                    x: 80,
                    y: 709,
                    size: 8,
                    color: rgb(0, 0, 0),
                })
                var adresseSocieteSeparer = await this.getCodePostale(this.data.adresseSociete)
                if(!adresseSocieteSeparer[0]){
                    adresseSocieteSeparer[0] = "  "
                }
                if(!adresseSocieteSeparer[1]){
                    adresseSocieteSeparer[1] = "  "
                }
                if(!adresseSocieteSeparer[2]){
                    adresseSocieteSeparer[2] = "  "
                }
                firstPage.drawText(adresseSocieteSeparer[0], {
                    x: 80,
                    y: 692,
                    size: 8,
                    color: rgb(0, 0, 0),
                })
                
                firstPage.drawText(adresseSocieteSeparer[2], {
                    x: 177,
                    y: 692,
                    size: 8,
                    color: rgb(0, 0, 0),
                })
                firstPage.drawText('X', {
                    x: 291,
                    y: 678,
                    size: 9,
                    color: rgb(0, 0, 0),
                })
                if(this.energieVert == true){
                    firstPage.drawText('X', {
                        x: 389,
                        y: 656,
                        size: 9,
                        color: rgb(0, 0, 0),
                    })
                }else{
                    firstPage.drawText('X', {
                        x: 419,
                        y: 656,
                        size: 9,
                        color: rgb(0, 0, 0),
                    })
                }
                
                /////////////// page Final ///////////////////////////////////////
                
                var urlFinal = '/pdf/engie/c5_3ans/Engie Pro Activert C5 3ans merged-10.pdf'
                var pageFinal = await fetch(urlFinal).then((res) =>
                    res.arrayBuffer()
                )
                // Load a PDFDocument from each of the existing PDFs
                const pageFinalDoc = await PDFDocument.load(pageFinal)
                var getPages = pageFinalDoc.getPages()
                var firstPage = getPages[0]
                var timesRomanFont1 = await pageFinalDoc.embedFont(StandardFonts.TimesRoman)
                var para = "La société "+this.data.raison+", située au "+this.data.adresseSociete+", \nN° de SIRET "+this.data.siret+" et représentée par "+
                this.data.nom+" "+this.data.prenom +", agissant en qualité de gérant(e)"
                var multiText = layoutMultilineText(para, {
                    alignment: 'left', 
                    fontSize: 11.5,   
                    font: timesRomanFont1,
                    lineHeight: 12,
                    bounds: { width: 640 - 120, height: 1000  }
                })
                
                    var startingPositon=608
                for(let i = 0; i < multiText.lines.length; i++) {
                    
                    firstPage.drawText(`${multiText.lines[i].text}`, {
                        x: 60,
                        y: startingPositon,
                        size: 10,
                        maxWidth: 640 - 120,
                        color: rgb(0.20, 0.20, 0.14),
                    })
                    // move position down
                    startingPositon = startingPositon - (11)
                }
                 firstPage.drawText(this.data.pdl, {
                    x: 468,
                    y: 318,
                    size: 10.5,
                    color: rgb(0.20, 0.20, 0.14),
                })
                // Create a new PDFDocument
                let mergedPdf = await PDFDocument.create()
                mergedPdf = await this.addPagesToPdf(mergedPdf, pageZeroDoc)
                mergedPdf = await this.addPagesToPdf(mergedPdf, pageOneDoc)
                mergedPdf = await this.addPagesToPdf(mergedPdf, pageTwoDoc)
                mergedPdf = await this.addPagesToPdf(mergedPdf, pageThreeDoc)
                mergedPdf = await this.addPagesToPdf(mergedPdf, pageFourDoc)
                mergedPdf = await this.addPagesToPdf(mergedPdf, pageFiveDoc)
                mergedPdf = await this.addPagesToPdf(mergedPdf, pageSixDoc)
                mergedPdf = await this.addPagesToPdf(mergedPdf, pageSevenDoc)
                mergedPdf = await this.addPagesToPdf(mergedPdf, pageEightDoc)
                mergedPdf = await this.addPagesToPdf(mergedPdf, pageFinalDoc)
                //fin contact
                const pdfBytes = await mergedPdf.save()
                this.generatedPdf = pdfBytes
                download(pdfBytes, this.data.pdl+'_EngiePro_'+this.data.raison+'_.pdf', 'application/pdf')
            },
            async generatePdfCfiveTwoYEARS(){
                /////////////// page 1 ///////////////////////////////////////
                const url0 = '/pdf/engie/c5_2ans/Engie Pro Activert C5 2ans merged-1.pdf'
                const pageZero = await fetch(url0).then((res) =>
                    res.arrayBuffer()
                )
                // Load a PDFDocument from each of the existing PDFs
                const pageZeroDoc = await PDFDocument.load(pageZero)
                var getPages = pageZeroDoc.getPages()
                var firstPage = getPages[0]
                //interloculteur
                firstPage.drawText(this.data.raison, {
                    x: 85,
                    y: 703,
                    size: 8,
                    color: rgb(0, 0, 0),
                })
                var siret = await this.seperateBySpaces(this.data.siret)
                firstPage.drawText(siret, {
                    x: 369,
                    y: 701,
                    size: 11.3,
                    color: rgb(0, 0, 0),
                })
                firstPage.drawText(this.data.codeNaf, {
                    x: 531,
                    y: 701,
                    size: 8,
                    color: rgb(0, 0, 0),
                })
                
                firstPage.drawText(this.data.adresseSociete, {
                    x: 80,
                    y: 691,
                    size: 8,
                    color: rgb(0, 0, 0),
                })
                
                var adresseSocieteSeparer = await this.getCodePostale(this.data.adresseSociete)
                if(!adresseSocieteSeparer[0]){
                    adresseSocieteSeparer[0] = "  "
                }
                if(!adresseSocieteSeparer[1]){
                    adresseSocieteSeparer[1] = "  "
                }
                if(!adresseSocieteSeparer[2]){
                    adresseSocieteSeparer[2] = "  "
                }
                firstPage.drawText(adresseSocieteSeparer[0], {
                    x: 85,
                    y: 682,
                    size: 8,
                    color: rgb(0, 0, 0),
                })
                
                firstPage.drawText(adresseSocieteSeparer[2], {
                    x: 196,
                    y: 682,
                    size: 8,
                    color: rgb(0, 0, 0),
                })
                firstPage.drawText('X', {
                    x: 158,
                    y: 639,
                    size: 8,
                    color: rgb(0, 0, 0),
                })
                
                firstPage.drawText(this.data.prenom+' '+this.data.nom, {
                    x: 295,
                    y: 661,
                    size: 9,
                    color: rgb(0, 0, 0),
                })
                firstPage.drawText('gérant', {
                    x: 300,
                    y: 648,
                    size: 9,
                    color: rgb(0, 0, 0),
                })
                firstPage.drawText(this.data.email, {
                    x: 260,
                    y: 628,
                    size: 9,
                    color: rgb(0, 0, 0),
                })
                /*firstPage.drawText(this.data.numTele, {
                    x: 275,
                    y: 607,
                    size: 9,
                    color: rgb(0, 0, 0),
                })*/
                firstPage.drawText(this.data.numTele, {
                    x: 275,
                    y: 596,
                    size: 9,
                    color: rgb(0, 0, 0),
                })
                var adresseSocieteSeparer = await this.getCodePostale(this.data.adresseCompteur)
                if(!adresseSocieteSeparer[0]){
                    adresseSocieteSeparer[0] = "  "
                }
                if(!adresseSocieteSeparer[1]){
                    adresseSocieteSeparer[1] = "  "
                }
                if(!adresseSocieteSeparer[2]){
                    adresseSocieteSeparer[2] = "  "
                }
                
                firstPage.drawText(adresseSocieteSeparer[0], {
                    x: 420,
                    y: 629,
                    size: 8,
                    color: rgb(0, 0, 0),
                })
                firstPage.drawText(adresseSocieteSeparer[2], {
                    x: 484,
                    y: 629,
                    size: 8,
                    color: rgb(0, 0, 0),
                })
                firstPage.drawText(adresseSocieteSeparer[1], {
                    x: 427,
                    y: 642,
                    size: 8,
                    color: rgb(0, 0, 0),
                })
                if(this.addAdrsFact == true){
                    var adresseSocieteSeparer = await this.getCodePostale(this.adresseFacturation)
                    if(!adresseSocieteSeparer[0]){
                        adresseSocieteSeparer[0] = "  "
                    }
                    if(!adresseSocieteSeparer[1]){
                        adresseSocieteSeparer[1] = "  "
                    }
                    if(!adresseSocieteSeparer[2]){
                        adresseSocieteSeparer[2] = "  "
                    }
                    
                    firstPage.drawText(adresseSocieteSeparer[0], {
                        x: 420,
                        y: 578,
                        size: 8,
                        color: rgb(0, 0, 0),
                    })
                    firstPage.drawText(adresseSocieteSeparer[2], {
                        x: 480,
                        y: 578,
                        size: 8,
                        color: rgb(0, 0, 0),
                    })
                    firstPage.drawText(adresseSocieteSeparer[1], {
                        x: 420,
                        y: 600,
                        size: 8,
                        color: rgb(0, 0, 0),
                    }) 
                }
                
                firstPage.drawText('X', {
                    x: 49,
                    y: 549,
                    size: 10,
                    color: rgb(0, 0, 0),
                })
                var pdl = await this.seperateBySpaces(this.data.pdl)
                firstPage.drawText(pdl, {
                    x: 65,
                    y: 536,
                    size: 11.5,
                    color: rgb(0, 0, 0),
                })
                
                firstPage.drawText(this.data.kva, {
                    x: 122,
                    y: 500,
                    size: 9,
                    color: rgb(0, 0, 0),
                })
                if(this.data.typeCompteur=='BTINFLU' || this.data.typeCompteur=='BTINFCUST' || this.data.typeCompteur=='BTINFCU4'){
                    /*firstPage.drawText(this.data.kvaBase, {
                        x: 105,
                        y: 470,
                        size: 9,
                        color: rgb(0, 0, 0),
                    })
                    
                    firstPage.drawText(this.data.kvaBase, {
                        x: 105,
                        y: 460,
                        size: 9,
                        color: rgb(0, 0, 0),
                    })*/

                    firstPage.drawText('X', {
                        x: 95,
                        y: 488,
                        size: 9,
                        color: rgb(0, 0, 0),
                    })
                }else{
                    firstPage.drawText('X', {
                        x: 126,
                        y: 488,
                        size: 9,
                        color: rgb(0, 0, 0),
                    })
                    firstPage.drawText(this.data.kvaHp, {
                        x: 105,
                        y: 470,
                        size: 9,
                        color: rgb(0, 0, 0),
                    })
                    
                    firstPage.drawText(this.data.kvaHc, {
                        x: 105,
                        y: 460,
                        size: 9,
                        color: rgb(0, 0, 0),
                    })
                }
                
                if(this.energieVert==true){
                    firstPage.drawText('X', {
                        x: 112,
                        y: 446,
                        size: 9,
                        color: rgb(0, 0, 0),
                    })
                }
                
                if(this.typePayement=='month'){
                    firstPage.drawText('X', {
                        x: 389,
                        y: 514,
                        size: 9,
                        color: rgb(0, 0, 0),
                    })
                }else{
                    firstPage.drawText('X', {
                        x: 389,
                        y: 385,
                        size: 9,
                        color: rgb(0, 0, 0),
                    })
                }
                if(this.typePayement=='month' && this.equipement=="oui"){
                    firstPage.drawText('X', {
                        x: 488,
                        y: 492,
                        size: 9,
                        color: rgb(0, 0, 0),
                    })
                }else if(this.typePayement=='month' && this.equipement=="non"){
                    firstPage.drawText('X', {
                        x: 389,
                        y: 492,
                        size: 9,
                        color: rgb(0, 0, 0),
                    })
                }
                if(this.typePayement=='month' && this.equipement=='non' && this.typeFacture=='papier'){
                    firstPage.drawText('X', {
                        x: 446,
                        y: 429,
                        size: 8,
                        color: rgb(0, 0, 0),
                    })

                }else if(this.typePayement=='month' && this.equipement=='non' && this.typeFacture=='elec'){
                    
                    firstPage.drawText('X', {
                        x: 390,
                        y: 429,
                        size: 8,
                        color: rgb(0, 0, 0),
                    })
                }
                
                if(this.typePayement=='autre' && this.modePayement=='Prel'){
                    firstPage.drawText('X', {
                        x: 458,
                        y: 367,
                        size: 8,
                        color: rgb(0, 0, 0),
                    })

                }else if(this.typePayement=='autre' && this.modePayement=='TIP'){
                    
                    firstPage.drawText('X', {
                        x: 521,
                        y: 367,
                        size: 8,
                        color: rgb(0, 0, 0),
                    })
                }
                
                if(this.typePayement=='autre' && this.typeFacture=='elec'){
                    firstPage.drawText('X', {
                        x: 461,
                        y: 358,
                        size: 8,
                        color: rgb(0, 0, 0),
                    })

                }else if(this.typePayement=='autre' && this.typeFacture=='papier'){
                    
                    firstPage.drawText('X', {
                        x: 522,
                        y: 357,
                        size: 8,
                        color: rgb(0, 0, 0),
                    })
                }
                firstPage.drawText(this.data.dateDebut.split("-").reverse().join("/"), {
                    x: 225,
                    y: 335,
                    size: 13,
                    color: rgb(0, 0, 0),
                })
                //////////////////// page 1 /////////////////////////////////////////
                const url1 = '/pdf/engie/c5_2ans/Engie Pro Activert C5 2ans merged-2.pdf'
                const pageOne = await fetch(url1).then((res) =>
                    res.arrayBuffer()
                )
                const pageOneDoc = await PDFDocument.load(pageOne)
                var getPages = pageOneDoc.getPages()
                var firstPage = getPages[0]
                //////////////////// page 2 /////////////////////////////////////////
                const url2 = '/pdf/engie/c5_2ans/Engie Pro Activert C5 2ans merged-3.pdf'
                const pageTwo = await fetch(url2).then((res) =>
                    res.arrayBuffer()
                )
                const pageTwoDoc = await PDFDocument.load(pageTwo)
                var getPages = pageTwoDoc.getPages()
                var firstPage = getPages[0]
                //////////////////// page 3 /////////////////////////////////////////
                const url3 = '/pdf/engie/c5_2ans/Engie Pro Activert C5 2ans merged-4.pdf'
                const pageThree = await fetch(url3).then((res) =>
                    res.arrayBuffer()
                )
                const pageThreeDoc = await PDFDocument.load(pageThree)
                var getPages = pageThreeDoc.getPages()
                var firstPage = getPages[0]
                //////////////////// page 4 /////////////////////////////////////////
                const url4 = '/pdf/engie/c5_2ans/Engie Pro Activert C5 2ans merged-5.pdf'
                const pageFour = await fetch(url4).then((res) =>
                    res.arrayBuffer()
                )
                const pageFourDoc = await PDFDocument.load(pageFour)
                var getPages = pageFourDoc.getPages()
                var firstPage = getPages[0]
                //////////////////// page 5 /////////////////////////////////////////
                const url5 = '/pdf/engie/c5_2ans/Engie Pro Activert C5 2ans merged-6.pdf'
                const pageFive = await fetch(url5).then((res) =>
                    res.arrayBuffer()
                )
                const pageFiveDoc = await PDFDocument.load(pageFive)
                var getPages = pageFiveDoc.getPages()
                var firstPage = getPages[0]
                //////////////////// page 6 /////////////////////////////////////////
                const url6 = '/pdf/engie/c5_2ans/Engie Pro Activert C5 2ans merged-7.pdf'
                const pageSix = await fetch(url6).then((res) =>
                    res.arrayBuffer()
                )
                const pageSixDoc = await PDFDocument.load(pageSix)
                var getPages = pageSixDoc.getPages()
                var firstPage = getPages[0]
                //////////////////// page 7 /////////////////////////////////////////
                const url7 = '/pdf/engie/c5_2ans/Engie Pro Activert C5 2ans merged-8.pdf'
                const pageSeven = await fetch(url7).then((res) =>
                    res.arrayBuffer()
                )
                const pageSevenDoc = await PDFDocument.load(pageSeven)
                var getPages = pageSevenDoc.getPages()
                var firstPage = getPages[0]
                 firstPage.drawText(this.data.raison, {
                    x: 90,
                    y: 725,
                    size: 8,
                    color: rgb(0, 0, 0),
                })
                firstPage.drawText(siret, {
                    x: 374,
                    y: 725,
                    size: 11.5,
                    color: rgb(0, 0, 0),
                })
                firstPage.drawText(this.data.codeNaf, {
                    x: 530,
                    y: 725,
                    size: 8,
                    color: rgb(0, 0, 0),
                })
                
                firstPage.drawText(this.data.adresseSociete, {
                    x: 80,
                    y: 709,
                    size: 8,
                    color: rgb(0, 0, 0),
                })
                var adresseSocieteSeparer = await this.getCodePostale(this.data.adresseSociete)
                if(!adresseSocieteSeparer[0]){
                    adresseSocieteSeparer[0] = "  "
                }
                if(!adresseSocieteSeparer[1]){
                    adresseSocieteSeparer[1] = "  "
                }
                if(!adresseSocieteSeparer[2]){
                    adresseSocieteSeparer[2] = "  "
                }
                firstPage.drawText(adresseSocieteSeparer[0], {
                    x: 80,
                    y: 692,
                    size: 8,
                    color: rgb(0, 0, 0),
                })
                
                firstPage.drawText(adresseSocieteSeparer[2], {
                    x: 177,
                    y: 692,
                    size: 8,
                    color: rgb(0, 0, 0),
                })
                if(this.data.kva == '3'){
                     //3
                    firstPage.drawText('X', {
                        x: 34,
                        y: 510,
                        size: 9,
                        color: rgb(0, 0, 0),
                    })
                }else if(this.data.kva == '6'){
                    //6
                    firstPage.drawText('X', {
                        x: 34,
                        y: 496,
                        size: 9,
                        color: rgb(0, 0, 0),
                    })
                }else if(this.data.kva == '9'){
                    //9
                    firstPage.drawText('X', {
                        x: 34,
                        y: 482,
                        size: 9,
                        color: rgb(0, 0, 0),
                    })
                    
                }else if(this.data.kva == '12'){
                    //12
                    firstPage.drawText('X', {
                        x: 34,
                        y: 468,
                        size: 9,
                        color: rgb(0, 0, 0),
                    })
                    
                }else if(this.data.kva == '15'){
                    //15
                    firstPage.drawText('X', {
                        x: 34,
                        y: 454,
                        size: 9,
                        color: rgb(0, 0, 0),
                    })
                    
                }else if(this.data.kva == '18'){
                    //18
                    firstPage.drawText('X', {
                        x: 34,
                        y: 440,
                        size: 9,
                        color: rgb(0, 0, 0),
                    })
                    
                }else if(this.data.kva == '24'){
                    //24
                    firstPage.drawText('X', {
                        x: 34,
                        y: 425,
                        size: 9,
                        color: rgb(0, 0, 0),
                    })
                    
                }else if(this.data.kva == '30'){
                    //30
                    firstPage.drawText('X', {
                        x: 34,
                        y: 412,
                        size: 9,
                        color: rgb(0, 0, 0),
                    })
                    
                }else if(this.data.kva == '36'){
                    //36
                    firstPage.drawText('X', {
                        x: 34,
                        y: 398,
                        size: 9,
                        color: rgb(0, 0, 0),
                    })
                    
                }
                firstPage.drawText('X', {
                    x: 291,
                    y: 678,
                    size: 9,
                    color: rgb(0, 0, 0),
                })
                if(this.energieVert == true){
                    firstPage.drawText('X', {
                        x: 389,
                        y: 656,
                        size: 9,
                        color: rgb(0, 0, 0),
                    })
                }else{
                    firstPage.drawText('X', {
                        x: 419,
                        y: 656,
                        size: 9,
                        color: rgb(0, 0, 0),
                    })
                }
                if(this.data.typeCompteur=='BTINFLU'||this.data.typeCompteur=='BTINFCUST'||this.data.typeCompteur=='BTINFCU4'){
                    firstPage.drawText('X', {
                        x: 100,
                        y: 572,
                        size: 9,
                        color: rgb(0, 0, 0),
                    })
                }else{
                    firstPage.drawText('X', {
                        x: 228,
                        y: 572,
                        size: 9,
                        color: rgb(0, 0, 0),
                    })
                }
                //////////////////// page 8 /////////////////////////////////////////
                const url8 = '/pdf/engie/c5_2ans/Engie Pro Activert C5 2ans merged-9.pdf'
                const pageEight = await fetch(url8).then((res) =>
                    res.arrayBuffer()
                )
                const pageEightDoc = await PDFDocument.load(pageEight)
                var getPages = pageEightDoc.getPages()
                var firstPage = getPages[0]
                 firstPage.drawText(this.data.raison, {
                    x: 90,
                    y: 725,
                    size: 8,
                    color: rgb(0, 0, 0),
                })
                firstPage.drawText(siret, {
                    x: 374,
                    y: 725,
                    size: 11.5,
                    color: rgb(0, 0, 0),
                })
                firstPage.drawText(this.data.codeNaf, {
                    x: 530,
                    y: 725,
                    size: 8,
                    color: rgb(0, 0, 0),
                })
                
                firstPage.drawText(this.data.adresseSociete, {
                    x: 80,
                    y: 709,
                    size: 8,
                    color: rgb(0, 0, 0),
                })
                var adresseSocieteSeparer = await this.getCodePostale(this.data.adresseSociete)
                if(!adresseSocieteSeparer[0]){
                    adresseSocieteSeparer[0] = "  "
                }
                if(!adresseSocieteSeparer[1]){
                    adresseSocieteSeparer[1] = "  "
                }
                if(!adresseSocieteSeparer[2]){
                    adresseSocieteSeparer[2] = "  "
                }
                firstPage.drawText(adresseSocieteSeparer[0], {
                    x: 80,
                    y: 692,
                    size: 8,
                    color: rgb(0, 0, 0),
                })
                
                firstPage.drawText(adresseSocieteSeparer[2], {
                    x: 177,
                    y: 692,
                    size: 8,
                    color: rgb(0, 0, 0),
                })
                firstPage.drawText('X', {
                    x: 291,
                    y: 678,
                    size: 9,
                    color: rgb(0, 0, 0),
                })
                if(this.energieVert == true){
                    firstPage.drawText('X', {
                        x: 389,
                        y: 656,
                        size: 9,
                        color: rgb(0, 0, 0),
                    })
                }else{
                    firstPage.drawText('X', {
                        x: 419,
                        y: 656,
                        size: 9,
                        color: rgb(0, 0, 0),
                    })
                }
                /////////////// page Final ///////////////////////////////////////
                
                var urlFinal = '/pdf/engie/c5_2ans/Engie Pro Activert C5 2ans merged-10.pdf'
                var pageFinal = await fetch(urlFinal).then((res) =>
                    res.arrayBuffer()
                )
                // Load a PDFDocument from each of the existing PDFs
                const pageFinalDoc = await PDFDocument.load(pageFinal)
                var getPages = pageFinalDoc.getPages()
                var firstPage = getPages[0]
                var timesRomanFont1 = await pageFinalDoc.embedFont(StandardFonts.TimesRoman)
                var para = "La société "+this.data.raison+", située au "+this.data.adresseSociete+", \nN° de SIRET "+this.data.siret+" et représentée par "+
                this.data.nom+" "+this.data.prenom +", agissant en qualité de gérant(e)"
                var multiText = layoutMultilineText(para, {
                    alignment: 'left', 
                    fontSize: 11.5,   
                    font: timesRomanFont1,
                    lineHeight: 12,
                    bounds: { width: 640 - 120, height: 1000  }
                })
                
                    var startingPositon=608
                for(let i = 0; i < multiText.lines.length; i++) {
                    
                    firstPage.drawText(`${multiText.lines[i].text}`, {
                        x: 60,
                        y: startingPositon,
                        size: 10,
                        maxWidth: 640 - 120,
                        color: rgb(0.20, 0.20, 0.14),
                    })
                    // move position down
                    startingPositon = startingPositon - (11)
                }
                 firstPage.drawText(this.data.pdl, {
                    x: 468,
                    y: 318,
                    size: 10.5,
                    color: rgb(0.20, 0.20, 0.14),
                })
                // Create a new PDFDocument
                let mergedPdf = await PDFDocument.create()
                mergedPdf = await this.addPagesToPdf(mergedPdf, pageZeroDoc)
                mergedPdf = await this.addPagesToPdf(mergedPdf, pageOneDoc)
                mergedPdf = await this.addPagesToPdf(mergedPdf, pageTwoDoc)
                mergedPdf = await this.addPagesToPdf(mergedPdf, pageThreeDoc)
                mergedPdf = await this.addPagesToPdf(mergedPdf, pageFourDoc)
                mergedPdf = await this.addPagesToPdf(mergedPdf, pageFiveDoc)
                mergedPdf = await this.addPagesToPdf(mergedPdf, pageSixDoc)
                mergedPdf = await this.addPagesToPdf(mergedPdf, pageSevenDoc)
                mergedPdf = await this.addPagesToPdf(mergedPdf, pageEightDoc)
                mergedPdf = await this.addPagesToPdf(mergedPdf, pageFinalDoc)
                //fin contact
                const pdfBytes = await mergedPdf.save()
                this.generatedPdf = pdfBytes
                download(pdfBytes, this.data.pdl+'_EngiePro_'+this.data.raison+'_.pdf', 'application/pdf')
            },
            async addPagesToPdf(mergedPdf, donorPdf) {
                const copiedPages = await mergedPdf.copyPages(
                    donorPdf,
                    donorPdf.getPageIndices()
                )
                copiedPages.forEach((page) => {
                    mergedPdf.addPage(page)
                })
                return mergedPdf
            },
        },
    }
</script>