<template>
    <div class="mt-8">
        
        <v-row>
          <v-col md="12" lg="12" xl="12" cols="12" v-if="dialog==true">
                <loadingComponent @closeDialog='dialog = $event' :dialogProps="dialog" :messageDialogeProps="messageDialogeError" :loadingProps="loading" :errorProps="error" />
          </v-col>
        </v-row>
        <v-row v-if="vraiSiret==true">
            <v-col cols="12" sm="12" md="12" lg="12" xl='12'>
                <v-subheader class="ma-0 pa-0">Score d'entreprise:</v-subheader>
                <v-divider class="ma-0 pa-0"></v-divider>
            </v-col>
            <v-col cols="6" sm="4" md="4" lg="4" xl='4'>
                <div>
                    <v-card-title class="justify-start">
                        <v-avatar class="mr-2" size="30" rounded>
                        <img
                            alt="credit safe score"
                            src="../../assets/images/logos/logo-credit-safe.png"
                        />
                        </v-avatar>
                        <p class="textLogo">creditsafe :</p>
                        <span class="ml-2 font-weight-black">
                            {{isNaN(this.scoreCreditSafe) ? "info manquante": this.scoreCreditSafe+"/100"}}
                        </span>
                    </v-card-title>
                </div>
            </v-col>
            <v-col cols="6" sm="4" md="4" lg="4" xl='4'>
                <div>
                    <v-card-title class="justify-start">
                        <v-avatar class="mr-2" height="30px" width="56px" rounded>
                        <img
                            alt="credit safe score"
                            src="../../assets/logoElliPro.png"
                        />
                        </v-avatar>
                        <p class="textLogo">Elli Pro :</p>
                        <span class="ml-2 font-weight-black">
                        {{isNaN(this.scoreEllipro) ? "info manquante": this.scoreEllipro+"/10"}}
                        </span>
                    </v-card-title>
                </div>
            </v-col>
        </v-row>
        <!-- form de siret -->
        <v-form ref="formeSiret" lazy-validation>
            <v-subheader>Recherche par SIRET ou Raison sociale :</v-subheader>
            <v-divider class="mb-12"></v-divider>
            <v-row>
                <v-col  cols="12" sm="11" md="6" lg="6" xl="6" >
                    <v-combobox
                        :search-input.sync="valueRechercheSiret"
                        @input="getPropositionSiretOrName"
                        v-model="valueRechercheSiret"
                        outlined
                        :rules="[required(`un siret ou une raison sociale valide`)]"
                        :items="listSociete"
                        :return-object="true"
                        label="Siret ou Raison sociale"
                    ></v-combobox>
                </v-col>
                <v-col cols="12" sm="8" md="6" lg="6" xl="6" class="d-flex">
                    <v-row  class="d-flex">
                        <v-col cols="12" sm="6" md="6" lg="6" xl="6"  class="d-flex justify-content-center">
                            <v-btn
                                depressed
                                elevation="1"
                                large
                                @click='getListeCompteurs'
                                :color="$colors[0]"
                                width="75%"
                                class="mt-1 text-white"
                                >
                                <span class="Poppins-Bold">Rechercher</span>
                            </v-btn>
                        </v-col>
                         <v-col cols="12" sm="4" md="4" lg="4" xl="4" class="d-flex justify-center mt-1" v-if="vraiSiret==true">
                            <v-btn
                                @click="openDialog"
                                elevation="1"
                                large
                                class="text-white"
                                :color="openModalFornisseurActuelle ? null : $colors[0]"
                                width="100%"                                
                            >
                                <v-icon left>{{ openModalFornisseurActuelle ? 'mdi-minus' : 'mdi-plus' }}</v-icon>
                                <span class="Poppins-Bold">Fournisseur</span>
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-form>
        
        <!--form details compteur -->
        <v-form ref="formGeneral" lazy-validation v-if="vraiSiret==true">
            <!-- marge et dates -->
            <v-row  v-if="vraiSiret==true" class="ma-0 pa-0">
                <v-col cols="12" sm="12" md="12" lg="12" xl='12' class="ma-0 pl-0">
                    <v-subheader class="ma-0 pa-0">Détails du Compteur :</v-subheader>
                    <v-divider class="ma-0 pa-0"></v-divider>
                </v-col>
                 <v-col class="pl-0" cols="12" sm="4" md="2" lg="2" xl='2'>
                    <v-select
                        class="ml-2"
                        required
                        outlined
                        @change="generateDateContrat"
                        v-model="donneDeCotation.numCompteur"
                        :items="listCompteur"
                        label="Numéro de compteur"
                        :rules="[required('un numéro de compteur valide')]"
                    ></v-select>
                </v-col>
                 <v-col class="pl-0" cols="12" sm="4" md="2" lg="2" xl='2'>
                    <v-select
                        required
                        outlined
                        @change="changeTarif"
                        v-model="donneDeCotation.typeCompteur"
                        :items="['T1', 'T2', 'T3', 'T4']"
                        label="Tarif"
                        :rules="[rules.required]"
                    ></v-select>
                </v-col>
                 <v-col class="pl-0" cols="12" sm="4" md="2" lg="2" xl='2'>
                    <v-select
                        v-if="donneDeCotation.typeCompteur=='T1'"
                        required
                        outlined
                        v-model="donneDeCotation.profilCompteur"
                        :items="listeProfileT1"
                        label="Profil du compteur"
                        :rules="[rules.required]"
                    ></v-select>
                    <v-select
                        v-if="donneDeCotation.typeCompteur=='T2'"
                        required
                        outlined
                        v-model="donneDeCotation.profilCompteur"
                        :items="listeProfileT2"
                        label="Profil du compteur"
                        :rules="[rules.required]"
                    ></v-select>
                    <v-select
                        v-if="donneDeCotation.typeCompteur=='T3' || donneDeCotation.typeCompteur=='T4'"
                        required
                        outlined
                        v-model="donneDeCotation.profilCompteur"
                        :items="listeProfileT3"
                        label="Profil du compteur"
                        :rules="[rules.required]"
                    ></v-select>
                </v-col>
            </v-row>
            <v-row class="ma-0 pa-0">
                <v-col cols="12" sm="12" md="12" lg="12" xl='12' class="ma-0 pa-0">
                    <v-subheader class="ma-0 pa-0">Détails de l'offre :</v-subheader>
                    <v-divider class="ma-0 pa-0"></v-divider>
                </v-col>
                <!-- marge -->
                <v-col class="pl-0" cols='12' sm="4" md="2" lg="2" xl='2'>
                    <v-text-field
                        type="number"
                        step="0.001"
                        required
                        outlined
                        min="0"
                        max="6"
                        v-model="donneDeCotation.CA"
                        label="Consommation en MWh"
                        :rules="[required('une valeur valide'), rules.consoMaxMin]"
                    ></v-text-field>
                </v-col>
                <!-- marge -->
                 <v-col class="pl-0" cols="12" sm="4" md="2" lg="2" xl='2'>
                    <v-text-field
                        type="number"
                        step="0.001"
                        max="25"
                        min="2"
                        required
                        outlined
                        v-model="donneDeCotation.marge"
                        label="Marge"
                        :rules="[required('une marge valide'), rules.margeMaxMin]"
                    ></v-text-field>
                </v-col>
                <!-- La date de début -->
                 <v-col class="pl-0" cols="12" sm="4" md="2" lg="2" xl='2'>
                    <v-text-field 
                        type="date" 
                        outlined
                        :rules="[required('une date de début de contrat valide'), 
                                isValidDate('DD/MM/YYYY', minDebutContrat, maxDateDebutContrat),isRangeDateValide('Début')]"
                        label="Début de Contrat"
                        v-model="donneDeCotation.dateDebut"
                        :min="minDebutContrat"
                        :max="maxDateDebutContrat"/>
                </v-col>

                <!-- La date de fin -->
                 <v-col class="pl-0" cols="12" sm="4" md="2" lg="2" xl='2'>
                    <v-text-field 
                        type="date" 
                        outlined
                        :rules="[required('une date de fin de contrat valide'), 
                                isValidDate('DD/MM/YYYY', minDebutFinContrat, maxDateFinContrat), isRangeDateValide('Fin')]"
                        label="Fin de Contrat"
                        v-model="donneDeCotation.dateFin"
                        :min="minDebutFinContrat"
                        :max="maxDateFinContrat"/>
                </v-col>
            </v-row>
            <div>
                <v-btn
                    @click="resetForm"
                    depressed
                    style="textTransform: none"
                    large
                    class="mx-1"
                    elevation="1"
                    width="20%"
                    >
                    <span class="Poppins-Bold">Réinitialiser</span>
                </v-btn>
                <v-btn
                    depressed
                    elevation="1"
                    large
                    @click="calculer"
                    :color="$colors[0]"
                    class="text-white mx-2"
                    width="20%">
                    <span class="Poppins-Bold">Calculer</span>
                </v-btn>
                 <v-btn
                depressed
                elevation="1"
                large
                :color="$colors[0]"
                class="white--text font-weight-bold"
                v-if="consoEndesa==false"
                @click="TryGetConsommationEndesa"
                width="27%">
                <span class="Poppins-Bold">Vérification de consommation</span>
            </v-btn>
            </div>
        </v-form>
        
        <!-- pop up de fornisseur actuelle -->
        <v-dialog
            v-model="openModalFornisseurActuelle"
            persistent
            max-width="80%"
            >
            <v-card>
                <v-toolbar
                    :color="$colors[0]"
                    class="text-white"
                    dark>
                    <span class="text-h6 pl-0">Informations Fournisseur</span>
                </v-toolbar>
                <v-form ref="formActuelle" class="pa-4">
                    <v-row class="ma-0 pa-0">
                        <v-col cols="11" sm="12" md="12" lg="12" xl='12' class="ma-0 pa-0"
                        v-if="!this.tousLesAutreFournisseur.some(item => item.typeFournisseur == 'Actuelle')">
                            <v-row>
                                <v-col cols='11' sm="5" md="5" lg="5" xl='5'>
                                    <v-radio-group
                                        v-model="autreFournisseur.typeFournisseur"
                                        row
                                        label="Type de Fournisseur:"
                                        @change="sameFournisseur == false"
                                        >
                                        <v-radio
                                            :color="$colors[2]"
                                            label="Fournisseur Actuel"
                                            value="Actuelle"
                                        ></v-radio>
                                        <v-radio
                                            :color="$colors[2]"
                                            label="Nouveau Fournisseur"
                                            value="Fournisseur"
                                        ></v-radio>
                                    </v-radio-group>
                                </v-col>
                                <v-col cols='11' sm="5" md="5" lg="5" xl='5'>
                                    <v-radio-group
                                        v-model="autreFournisseur.typeAbo"
                                        row
                                        label="Type Abo"
                                        >
                                        <v-radio
                                            :color="$colors[2]"
                                            label="Simple"
                                            value="simple"
                                        ></v-radio>
                                        <v-radio
                                            :color="$colors[2]"
                                            label="tout compris"
                                            value="tout compris"
                                        ></v-radio>
                                    </v-radio-group>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="11" sm="12" md="12" lg="12" xl='12' class="ma-0 pa-0">
                            <v-subheader class="ma-0">Informations de fournisseur :</v-subheader>
                        </v-col>
                        <v-col class="pl-0" cols='11' sm="4" md="3" lg="3" xl='3'>
                            <v-select
                                required
                                outlined
                                :rules="[required('un fournisseur valide')]"
                                v-model="autreFournisseur.fournisseur"
                                :items="optionsFournisseurs"
                                :label="(autreFournisseur.typeFournisseur == 'Actuelle') ? 'Fournisseur Actuel' : 'Nouveau Fournisseur'"
                            ></v-select>
                        </v-col>
                        <v-col class="pl-0" cols="11" sm="4" md="4" lg="4" xl='4'>
                            <v-row>
                                <v-col class="pl-0" cols="11" sm="11" md="11" lg="11" xl='11'v-if="autreFournisseur.typeOffre!='pré-écrêté'">
                                    <v-select
                                        required
                                        outlined
                                        :rules="[required(`un type d'offre valide`),rules.Length]"
                                        v-model="autreFournisseur.typeOffre"
                                        :items="['Arenh', 'Fixe']"
                                        label="type d'offre"
                                    ></v-select>
                                </v-col>
                                <v-col class="pl-0" cols="11" sm="4" md="4" lg="4" xl='4'v-if="autreFournisseur.typeOffre=='pré-écrêté'">
                                    <v-select
                                        required
                                        outlined
                                        :rules="[required(`un type d'offre valide`),rules.Length]"
                                        v-model="autreFournisseur.typeOffre"
                                        :items="['Arenh', 'Fixe']"
                                        label="type d'offre"
                                    ></v-select>
                                </v-col>
                                <v-col class="pl-0" cols="11" sm="4" md="4" lg="4" xl='4' v-if="autreFournisseur.typeOffre=='pré-écrêté'">
                                    <v-checkbox
                                        v-model="autreFournisseur.Classique"
                                        label="Classique"
                                        value="Classique"
                                    ></v-checkbox>
                                </v-col>
                                <v-col class="pl-0" cols="11" sm="4" md="4" lg="4" xl='4' v-if="autreFournisseur.typeOffre=='pré-écrêté'">
                                    <v-text-field
                                        v-if="autreFournisseur.Classique.indexOf('Classique')==-1"
                                        required
                                        outlined
                                        type="number"
                                        :rules="[required(`un valeur de pré-écrêté valide`),rules.Length, rules.pourcentageEcrete]"
                                        v-model="autreFournisseur.preEcrete"
                                        label="Valeur pré-écrêté"
                                        suffix="%"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col class="pl-0" cols='11' sm="4" md="3" lg="3" xl='3'>
                            <v-text-field
                                type="number"
                                step="1"
                                suffix="mois"
                                required
                                outlined
                                v-model="autreFournisseur.DUREE"
                                label="Durée de contrat"
                                :rules="[required(`une durée de contrat valide`),rules.isPositiveNumb]"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    
                    <!-- Prix -->
                    <v-row class="ma-0 pa-0">
                        <v-col cols="12" sm="12" md="12" lg="12" xl='12' class="ma-1 pa-0">
                            <v-subheader class="ma-0">Prix en €/MWh:</v-subheader>
                            <v-divider class="ma-0 pa-0"></v-divider>
                        </v-col>
                        <v-col class="pl-0" cols='11' sm="4" md="3" lg="3" xl='3'>
                            <v-text-field
                                type="number"
                                step="0.001"
                                required
                                outlined
                                suffix="€"
                                v-model="autreFournisseur.prixMol"
                                label="Prix de la molécule"
                                :rules="[required('un prix de la molécule valide')]"
                            ></v-text-field>
                        </v-col>
                        <v-col class="pl-0" cols='11' sm="4" md="3" lg="3" xl='3'>
                            <v-text-field
                                type="number"
                                step="0.001"
                                required
                                outlined
                                v-model="autreFournisseur.ABO"
                                suffix="€"
                                label="Abonnement mensuel"
                                :rules="[required('une valeur valide')]"
                            ></v-text-field>
                        </v-col>
                        <v-col class="pl-0" cols='11' sm="4" md="3" lg="3" xl='3'>
                            <v-text-field
                                type="number"
                                step="0.001"
                                required
                                outlined
                                suffix="€"
                                v-model="autreFournisseur.CEE"
                                label="CEE"
                                :rules="[required('un CEE valide')]"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </v-form>
                <v-card-actions>
                    <v-row class="ma-2" justify="space-between">
                    <div>
                            <v-btn
                                v-if='editFrn==true'
                                :color="$colors[0]"
                                class='marginBtn ml-5 text-white'
                                @click="AjouterFournisseur(editedIndex)"
                            >
                                Modifier
                            </v-btn>
                            <v-btn
                            v-if="this.sameFournisseur == false"
                                @click="resetFournisseurActuelle"
                                color="error"
                                text
                                >
                                <span class="Poppins-Bold">Réinitialiser et fermer</span>
                            </v-btn>
        
                    </div>
                    <div>
                            <v-btn
                                color="error"
                                class='marginBtn ml-5'
                                text
                                @click="closeModalHandleFournisseur(editFrn)"
                            >
                                <span class="Poppins-Bold">Fermer</span>
                            </v-btn>
                            <v-btn
                                v-if='editFrn==false'
                                :color="$colors[0]"
                                class='marginBtn ml-5 text-white'
                                @click="AjouterFournisseur()"
                            >
                                <span class="Poppins-Bold">Ajouter</span>
                            </v-btn>
                    </div>
                    </v-row>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- affichage des donner du fornisseur actuelle -->
            
        <v-subheader class="ma-0 mt-4" v-if='tousLesAutreFournisseur.length>0'>Informations des autres Fournisseurs :</v-subheader>
        <div style="width: 100%; overflow-x:auto;">
            <table class="table table-striped table-bordered" style="overflow-x:auto; width: 100%;" v-if='tousLesAutreFournisseur.length>0'>
                <thead>
                    <tr>
                        <th style="text-align: center; width:15vw;background-color:#103A5E;color:white;font-weight:bold">
                            Fournisseur
                        </th>
                        <th style="text-align: center;  width:15vw;background-color:#103A5E;color:white;font-weight:bold">
                            Type de fournisseur
                        </th>
                        <th style="text-align: center;  width:15vw;background-color:#103A5E;color:white;font-weight:bold">
                            Prix de la molécule
                        </th>
                        <th style="text-align: center;  width:15vw;background-color:#103A5E;color:white;font-weight:bold">
                            Abonnement
                        </th>
                        <th style="text-align: center;  width:15vw;background-color:#103A5E;color:white;font-weight:bold">
                            CEE
                        </th>
                        <th style="text-align: center;  width:15vw;background-color:#103A5E;color:white;font-weight:bold">
                            Durée de contrat
                        </th>
                        <th style="text-align: center;  width:15vw;background-color:#103A5E;color:white;font-weight:bold">
                            Actions
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for='(item, index)  in tousLesAutreFournisseur' >
                        <td style="text-align: center;">{{item.fournisseur}}</td>
                        <td style="text-align: center;">
                            <span v-if="item.typeFournisseur == 'Fournisseur'">Autre fournisseur</span>
                            <span v-else>Fournisseur actuel</span>
                        </td>
                        <td style="text-align: center;"><span>{{item.prixMol}} €</span></td>
                        <td style="text-align: center;">{{item.ABO}} €/Mois</td>
                        <td style="text-align: center;">{{item.CEE}} €</td>
                        <td style="text-align: center;">{{item.DUREE}} mois</td>
                        <td style="text-align: center;">
                            <v-col style="width:100%;">
                                <v-row justify="center">
                                    <v-btn
                                        icon 
                                        small
                                        class="mx-2"
                                        color="error"
                                        @click="supprimerFournisseur(index)">
                                        <v-icon center>mdi-delete
                                        </v-icon>
                                    </v-btn>
                                    <v-btn 
                                        icon
                                        small
                                        @click="editItem(index)">
                                        <v-icon center>mdi-application-edit-outline
                                        </v-icon>
                                    </v-btn>
                                </v-row>
                            </v-col>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>
<script>
    import "jquery/dist/jquery.min.js";
    import "bootstrap/dist/css/bootstrap.min.css";
    import "bootstrap/dist/css/bootstrap.css";
    import "bootstrap/dist/js/bootstrap.bundle";
    import "datatables.net-dt/js/dataTables.dataTables";
    import "datatables.net-dt/css/jquery.dataTables.min.css";
    import moment from 'moment'
    import axios from 'axios'

    import loadingComponent from '../ui/loading-component.vue'
    export default {
        name: 'FourmulaireDeCotationGaz',
        components:{loadingComponent},
        
        data() {
            return {
                messageEndesa: "",
                consoEndesa: false,
                sameFournisseur:false,
                errorDateDeFin: false,
                nbrMonth: 0,
                dialog: false,
                loading: false,
                error: false,
                messageDialogeError: '',
                messageDialogeSucces:'',
                openModalFornisseurActuelle: false,
                vraiSiret: false,
                siret:'',
                valueRechercheSiret: '',
                listSociete: [{data:'', disable: true}],
                listCompteur: [],
                parametreCompteur: '',
                scoreEllipro:'',
                scoreCreditSafe:'',
                listProposition: [],
                listeProfileT1: ['P011'],
                listeProfileT2: ['P012', 'P013', 'P014', 'P015', 'P016', 'P017', 'P018', 'P019'],
                listeProfileT3: ['P012', 'P013', 'P014', 'P015', 'P016', 'P017', 'P018', 'P019'],
                donneDeCotation:{
                    numCompteur: '',
                    typeCompteur: 'T1',
                    profilCompteur: 'P011',
                    dateDebut: '',
                    dateFin: '',
                    marge: '',
                    CA: '',
                },
                editFrn: false,
                editedIndex:-1,
                tousLesAutreFournisseur: [

                ],
                autreFournisseur:{
                    typeFournisseur: 'Fournisseur',
                    Classique: ['Classique'],
                    preEcrete: "0",
                    typeOffre: '',
                    fournisseur: null,
                    prixMol: null,
                    CEE: null,
                    ABO: null,
                    DUREE: null, 
                    typeAbo: "simple",             
                },
                defaultAutreFournisseur:{
                    typeFournisseur: 'Fournisseur',
                    Classique: ['Classique'],
                    preEcrete: "0",
                    typeOffre: '',
                    fournisseur: null,
                    prixMol: null,
                    CEE: null,
                    ABO: null,
                    DUREE: null,   
                    typeAbo: "simple",           
                },
                fournisseurActuelle: false,
                optionsFournisseurs: [
                    'Alpiq',
                    'Alterna',
                    'Antargaz',
                    'Axpo',
                    'BARRY',
                    'BULB',
                    'BUTAGAZ',
                    'CDISCOUNT',
                    'Dyneff',
                    'Edenkia',
                    'EDF',
                    'EDS',
                    'EDSB',
                    'Ekwateur',
                    'Elecocite',
                    'Electricite_de_provence',
                    'Electricite_de_savoie',
                    'Electricite_de_Strasbourg',
                    'ELMY',
                    'Enalp',
                    'Endesa',
                    'Enercoop',
                    'Energem',
                    'ENERGIE_DICI',
                    'Engie',
                    'ENI',
                    'Enovos',
                    'Gaz_de_Bordeaux',
                    'GazelEnergie',
                    'Gazprom',
                    'GEDIA',
                    'GEG',
                    'GREEN_YELLOW',
                    'HAPPE',
                    'IBERDROLA',
                    'ILEK',
                    'JPME',
                    'la_bellenergie',
                    'LECLERCQ',
                    'LLUM',
                    'LUCIA',
                    'MEGA',
                    'MET-France',
                    'mint-energie',
                    'NatGas',
                    'OHM',
                    'OVO',
                    'Picoty',
                    'PLUM',
                    'Primeo',
                    'Proxelia',
                    'Redeéo',
                    'SaveEnergies',
                    'Selia',
                    'SEFE',
                    'SOWEE',
                    'Synelva',
                    'TDE',
                    'Urban_Solar',
                    'Vattenfall',
                    'Volterres',
                    'Wekiwi',
                    'yeli-xs',
                ],

                rules: {
                    notSame: v => {
                            
                            for(let i = 0; i<this.tousLesAutreFournisseur.length;i++){
                                for(const[key,value] of Object.entries(this.tousLesAutreFournisseur[i])){
                                    if(key == 'fournisseur'){
                                        if(value == v && this.sameFournisseur == false ){
                                            return 'Fournisseur déjà séléctionné !'
                                        }else{
                                            return true
                                        }
                                    }
                                }
                            }
                        
                    },
                    isPositiveNumb:v=>(/^[1-9]\d*$/).test(v) || "La valeur doit etre un entier strictement supérieur à 0",        
                    required: value => !!value || value == 0 || 'Ce champ est obligatoire.',
                    isMonthCorrect: v => v.match(/^([1-9]|1[012])$/) || "Le mois doit etre compris entre 1 et 12 ",                    
                    siretLength: v => v.length == 14 || 'Le siret doit comporter 14 chiffres',
                    isNumber: v => v.match(/^[0-9]+$/) != null || "Ce champ n'accepte que les chiffres",
                    compteurElecLength: v => v.length == 14 || 'Le RAE / PDL est une suite de 14 chiffres',
                    isGazCompteur: v => (v.match(/^(GI)[0-9]{6}$/) || (v.length == 14 && v.match(/^[0-9]+$/) != null)) || 'Le numéro PCE/ PDL doit commencer par (GI) ou comporter 14 chiffres',
                    phoneLength: v => v.length == 10 || 'Le numéro de téléphone est constitué de 10 chiffres',
                    phoneisNumber: v => v.match(/^[0-9]+$/) != null || "Ce champ n'accepte que les chiffres",
                    margeMaxMin: v => (v<=25 && v>=2) || 'La marge doit être comprise entre 2 et 25',
                    pourcentageEcrete: v => (v<=100 && v>=1) || 'La valeur de pré-écrêté doit être comprise entre 1 et 100',
                    consoMaxMin: (v) => {
                        if(this.donneDeCotation.typeCompteur=='T1'){
                            if(v>=0 && v<6){
                                return true
                            }else{
                                return 'consomation pour un Tarif T1 doit être comprise entre 0 et 5.99'
                            }
                        }else if (this.donneDeCotation.typeCompteur=='T2'){
                            if(v>=3 && v<=350){
                                return true
                            }else{
                                return 'consomation pour un Tarif T2 doit être comprise entre 3 et 350'
                            }
                        }else if (this.donneDeCotation.typeCompteur=='T3'){
                            if(v>=100 && v<5000){
                                return true
                            }else{
                                //250 4999.99
                                return 'consomation pour un Tarif T3 doit être comprise entre 100 et 4999.99'
                            }
                        }else if(this.donneDeCotation.typeCompteur=='T4'){
                            if(v>=5000){
                                return true
                            }else{
                                return 'consomation pour un Tarif T4 doit être supérieur ou égal à 5000'
                            }
                        }
                    },
                     emailRules:v => !!v && /.+@.+\.+.+/.test(v) || 'E-mail invalide',
                },


                required(fieldName) {
                    return (value) => ((!!value && value >=0)||(!!value  && value != '')) || `Veuillez saisir ${fieldName}`
                },
                isMonthCorrect(){
                    return (value) => !!value && value.match(/^([1-9]|1[012])$/) || `Veuillez saisir un mois compris entre 1 et 12`
                },
                minDebutContrat: new Date().toISOString().substr(0, 10),
                minDebutFinContrat:new Date().toISOString().substr(0, 10),
                maxDateFinContrat: new Date(new Date().getFullYear() + 5+'-'+12+'-'+31).toISOString().substr(0, 10),
                maxDateDebutContrat: new Date(new Date().getFullYear() + 5+'-'+ 11+'-30').toISOString().substr(0, 10),
            }
        },
        created(){
            try {
                this.valueRechercheSiret = this.$route.query.siret
                this.siret = this.$route.query.siret
                this.listSociete= [this.siret]
            } catch (error) {
                console.log(error)
            }
        },
        methods: {
            openDialog(){
                this.openModalFornisseurActuelle = !this.openModalFornisseurActuelle
                this.fournisseurActuelle = false
            },
            close(){
                console.log("oui")
                this.$refs.formActuelle.resetValidation();
                this.openModalFornisseurActuelle = false,
                this.editFrn = false
                this.sameFournisseur = false;
                this.autreFournisseur = {
                    typeFournisseur: 'Fournisseur',
                    fournisseur: null,
                    prixMol: null,
                    CEE: null,
                    ABO: null,
                    DUREE: null,      
                    typeAbo: "simple",      
                }
            },
            generateDateContrat(){
                axios({
                    url:process.env.VUE_APP_URL_API_CLIENT + 'getLastCotationFormData/',
                    method: 'POST',
                    data: {'numcompteur': this.donneDeCotation.numCompteur,
                        'token': this.getToken('token'),
                    },
                }).then((res)=>{
                    this.renitialiserLaListeDeResultat()
                    if(res.data.result==true){
                        this.donneDeCotation.typeCompteur = res.data.tarif
                        this.donneDeCotation.dateDebut = res.data.dateDebut
                        this.donneDeCotation.dateFin = res.data.dateFin
                        this.donneDeCotation.marge = res.data.marge
                        this.donneDeCotation.CA = res.data.consommation
                        this.tousLesAutreFournisseur = res.data.fournisseurAuxiliaireGaz;
                        for(var i=0; i<this.tousLesAutreFournisseur.lenght; i++){
                            
                            if(this.tousLesAutreFournisseur[i]['typeOffre'].indexOf("pré-écrêté")!=-1){
                                if(this.tousLesAutreFournisseur[i]['typeOffre'].indexOf("%")!=-1){
                                    this.tousLesAutreFournisseur[i]['Classique']= ['']
                                    this.tousLesAutreFournisseur[i]['preEcrete']=this.tousLesAutreFournisseur[i]['typeOffre'].split('pré-écrêté')[1].split('%')[0].replace(' ', '')
                                }else{
                                    this.tousLesAutreFournisseur[i]['Classique']= ['Classique']
                                    this.tousLesAutreFournisseur[i]['preEcrete']="0"
                                }
                                this.tousLesAutreFournisseur[i]['typeOffre'] = "pré-écrêté"
                            }
                        }
                    }else if(res.data.result=='permission'){
                        localStorage.removeItem('vendeurName')
                        localStorage.removeItem('typeUser')
                        localStorage.removeItem('token')
                        this.$router.push('/login')
                    }else{
                        this.tousLesAutreFournisseur = []
                        this.listProposition = []
                    }
                    
                }).catch((err)=>{
                    console.log(err)
                })
            },

            changeTarif(){
                if(this.donneDeCotation.typeCompteur == 'T1'){
                    this.donneDeCotation.profilCompteur = this.listeProfileT1[0]
                }
                if(this.donneDeCotation.typeCompteur == 'T2'){
                    this.donneDeCotation.profilCompteur = this.listeProfileT2[0]
                }
                if(this.donneDeCotation.typeCompteur == 'T3' || this.donneDeCotation.typeCompteur == 'T4'){
                    this.donneDeCotation.profilCompteur = this.listeProfileT3[0]
                }
            },
            renitialiserLaListeDeResultat(){
                this.listProposition = []
                this.$emit('sendDataToParent',{
                    Propositions: [],
                    SocieteData: '',
                    numCompteur: '',
                    typeCompteur: '',
                    fournisseurActuelle: '',
                    selected: [],
                    listCompteur: [],
                    profilCompteur: '',
                    consommationGlobal: '', 
                    dateDebut: ''
                })
            },
            editItem(index){
                this.openModalFornisseurActuelle=true
                this.fournisseurActuelle = false
                this.editFrn = true
                this.editedIndex = index
                var fournisseurToUpdate = this.tousLesAutreFournisseur[index]
                if(fournisseurToUpdate['typeOffre'].indexOf("pré-écrêté")!=-1){
                    if(fournisseurToUpdate['typeOffre'].indexOf("%")!=-1){
                        fournisseurToUpdate['Classique']= ['']
                        fournisseurToUpdate['preEcrete']=fournisseurToUpdate['typeOffre'].split('pré-écrêté')[1].split('%')[0].replace(' ', '')
                    }else{
                        fournisseurToUpdate['Classique']= ['Classique']
                        fournisseurToUpdate['preEcrete']="0"
                    }
                    fournisseurToUpdate['typeOffre'] = "pré-écrêté"
                }
                this.defaultAutreFournisseur = {...fournisseurToUpdate}
                this.autreFournisseur = fournisseurToUpdate
                this.sameFournisseur = true
            },
            async TryGetConsommationEndesa(){
                if(new Date(this.donneDeCotation.dateDebut).getTime()>= new Date(this.donneDeCotation.dateFin).getTime()){
                    this.errorDateDeFin = true
                }else{
                    this.errorDateDeFin = false
                }
                if(!this.$refs.formGeneral.validate()){
                    return false
                }
                this.errorDateDeFin = false
                this.dialog = true
                this.loading = true
                this.messageDialogeError = ""
                this.messageDialogeSucces = ""
                this.error = false
                this.renitialiserLaListeDeResultat()
                this.$refs.formGeneral.validate()
                if(this.$refs.formGeneral.validate()){
                    await axios({
                        url: process.env.VUE_APP_URL_API_CLIENT + 'TryGetConsommationEndesa/',
                        method: 'POST',
                        data: {'donneDeCotation': this.donneDeCotation,
                                'token': this.getToken('token'),
                                'siret': this.siret
                            },
                    }).then((res) => {
                        console.log(res.data)
                        if (res.data.result === 'permission') {
                            localStorage.setItem('vueAfficher', 'particulier')
                            localStorage.removeItem('vendeurName')
                            localStorage.removeItem('typeUser')
                            localStorage.removeItem('token')
                            this.$router.push('/login')
                        }
                        if(res.data.result == true){
                            this.donneDeCotation.CA = res.data.car
                            this.messageDialogeError = "la consommation est mise à jour"
                        }else{
                            this.messageDialogeError = res.data.message
                            this.error = true
                        }
                        
                    }).catch((err)=>{
                        console.log(err)
                    })
                    this.loading = false
                }
            },
            async calculer(){
                if(new Date(this.donneDeCotation.dateDebut).getTime()>= new Date(this.donneDeCotation.dateFin).getTime()){
                    this.errorDateDeFin = true
                }else{
                    this.errorDateDeFin = false
                }
                if(!this.$refs.formGeneral.validate()){
                    return false
                }
                this.errorDateDeFin = false
                this.dialog = true
                this.loading = true
                this.messageDialogeError = ""
                this.messageDialogeSucces = ""
                this.error = false
                this.renitialiserLaListeDeResultat()
                this.$refs.formGeneral.validate()
                if(this.$refs.formGeneral.validate()){
                    await axios({
                        url: process.env.VUE_APP_URL_API_CLIENT + 'CalculeCoutProGaz/',
                        method: 'POST',
                        data: {'donneDeCotation': this.donneDeCotation,
                                'token': this.getToken('token'),
                                'siret': this.siret,
                                'autreFournisseur': this.tousLesAutreFournisseur,
                            },
                    }).then((res) => {
                        if (res.data.result === 'permission') {
                            localStorage.setItem('vueAfficher', 'particulier')
                            localStorage.removeItem('vendeurName')
                            localStorage.removeItem('typeUser')
                            localStorage.removeItem('token')
                            this.$router.push('/login')
                        }
                        if(this.errorEnedis == true){

                        }else{
                        }
                        this.listProposition = res.data.result
                        this.$emit('sendDataToParent',{
                            Propositions: res.data.result,
                            SocieteData: res.data.dataSociete,
                            numCompteur: res.data.numCompteur,
                            typeCompteur: res.data.typeCompteur,
                            fournisseurActuelle: res.data.actuelle,
                            selected: [],
                            listCompteur: this.listCompteur,
                            profilCompteur: this.donneDeCotation.profilCompteur,
                            consommationGlobal: this.donneDeCotation.CA,
                            dateDebut: this.donneDeCotation.dateDebut
                        })
                        
                    }).catch((err)=>{
                        console.log(err)
                    })
                    this.dialog = false
                        this.loading = false
                }
            },

            resetForm(){
                this.renitialiserLaListeDeResultat()
                this.editFrn = false
                this.fournisseurActuelle= false
                //donner generale
                this.openModalFornisseurActuelle = false
                this.vraiSiret = false
                this.siret = ''
                this.listSociete = []
                this.listCompteur = []
                this.scoreEllipro = ''
                this.scoreCreditSafe = ''
                //donner de cotation
                this.donneDeCotation.numCompteur = ''
                this.donneDeCotation.typeCompteur = 'T1'
                this.donneDeCotation.CA = ''
                this.donneDeCotation.dateDebut = ''
                this.donneDeCotation.dateFin = ''
                this.donneDeCotation.marge = ''
                
                //fornisseur actuelle
                
                this.openModalFornisseurActuelle = false
                this.autreFournisseur.typeFournisseur = 'Fournisseur'
                this.autreFournisseur.Classique = ['Classique']
                this.autreFournisseur.preEcrete = "0"
                this.autreFournisseur.fournisseur = ''
                this.autreFournisseur.typeOffre = ''
                this.autreFournisseur.prixMol = null
                this.autreFournisseur.CEE = null
                this.autreFournisseur.ABO = null
                this.autreFournisseur.typeAbo="simple"   
                this.autreFournisseur.DUREE = null
                
                this.tousLesAutreFournisseur = []
            },
            resetFournisseurActuelle(){
                this.$refs.formActuelle.resetValidation();
                this.suppr
                this.editFrn = false
                //fornisseur actuelle
                this.openModalFornisseurActuelle = false
                this.autreFournisseur.typeFournisseur = 'Fournisseur'
                this.autreFournisseur.Classique = ['Classique']
                this.autreFournisseur.preEcrete = "0"
                this.autreFournisseur.fournisseur = ''
                this.autreFournisseur.typeOffre = ''
                this.autreFournisseur.prixMol = null
                this.autreFournisseur.CEE = null
                this.autreFournisseur.ABO = null
                this.autreFournisseur.typeAbo="simple"  
                this.autreFournisseur.DUREE = null
            },
            dateFormatSlach(date){
                return date.split('-').reverse().join('/')
            },
            dateFormatMinus(date){
                return date.split('/').reverse().join('-')
            },
            isRangeDateValide(date){
               if(this.donneDeCotation.dateFin != "" && this.donneDeCotation.dateDebut!=""&& this.donneDeCotation.dateFin<this.donneDeCotation.dateDebut){
                switch(date){
                    case "Début":
                        return "la date de début doit etre inférieure à la date de fin";
                        break;
                    case "Fin":
                        return "la date de fin doit etre supérieur à la date de début";
                        break;

                    default:
                        break;
                }
               }else{
                return true
               }
            },
            isValidDate(formatDate, minDate, maxDate) {
                return (date) => {
                    var tmpdate = this.dateFormatMinus(date)
                    if (!date) {
                        return 'Veuillez saisir une date.'
                    }
                    if (moment(tmpdate).isBefore(minDate)) {
                        return `La date doit être supérieure  à ${this.dateFormatSlach(minDate)}.`
                    }
                    if (moment(tmpdate).isAfter(maxDate)) {
                        return `La date limite de fin de contrat est: ${this.dateFormatSlach(maxDate)}.`
                    }
                    return true
                }
            },
            getListeCompteurs() {
                this.$refs.formeSiret.validate();
                if(this.$refs.formeSiret.validate()){
                    
                
                this.dialog = true
                this.loading = true
                this.error = false
                this.messageDialogeError = ''
                this.messageDialogeSucces = ''
                this.siret = this.valueRechercheSiret
                this.renitialiserLaListeDeResultat()
                var formData = new FormData()
                formData.append('value', this.siret)
                formData.append('token', this.getToken('token'))
                axios({
                    url: process.env.VUE_APP_URL_API_CLIENT + 'getListCompteurGazParSiret/',
                    method: 'POST',
                    data: formData,
                })
                .then((res) => {
                    const responseData = res.data
                    if (responseData.result === 'permission') {
                        localStorage.setItem('vueAfficher', 'particulier')
                        localStorage.removeItem('vendeurName')
                        localStorage.removeItem('typeUser')
                        localStorage.removeItem('token')
                        this.$router.push('/login')
                    }else if (responseData.result == true) {
                        if(responseData.compteur.length<=0){
                            this.loading = false
                            this.messageDialogeError = "Vous n'avez aucun compteur associé à ce siret/raison sociale"
                            this.error = true
                        }
                        this.vraiSiret = true
                        this.scoreEllipro = responseData.scoreEllipro
                        this.scoreCreditSafe = responseData.scoreCreditSafe
                        this.listCompteur = responseData.compteur
                        this.siret = responseData.siret
                        
                        this.dialog = false
                        this.loading = false
                    }else if(responseData.result == false){
                        this.vraiSiret = false
                        this.loading = false
                        this.messageDialogeError = "Vérifiez que votre siret/raison social soit valide et que vous disposez d'une demande de cotation déjà acceptée"
                        this.error = true
                    }
                    
                })
                .catch((error) => {
                    this.loading = false
                    this.messageDialogeError = "Il semble qu'une erreur soit survenue ! Veuillez réessayer plus tard "
                    this.error = true
                    console.log(error)
                })
                }
            },
            supprimerFournisseur(index){
                
                this.renitialiserLaListeDeResultat()
                this.tousLesAutreFournisseur.splice(index, 1)
                const foundActuelle = this.tousLesAutreFournisseur.some(item => item.typeFournisseur == "Actuelle");
                if (foundActuelle){
                    this.fournisseurActuelle = true
                } else {
                    this.fournisseurActuelle = false
                }

            },
            AjouterFournisseur(index=-1){
                
                this.renitialiserLaListeDeResultat()
                this.$refs.formActuelle.validate()
                if (this.$refs.formActuelle.validate()) {
                    const foundActuelle = this.tousLesAutreFournisseur.some(item => item.typeFournisseur == "Actuelle");
                    const isCurrentActuelle = this.autreFournisseur.typeFournisseur == "Actuelle";
                    if (foundActuelle || isCurrentActuelle){
                        this.fournisseurActuelle = true
                    } else {
                        this.fournisseurActuelle = false
                    }
                    if(this.editFrn == true){

                        var d = {
                            typeFournisseur: this.autreFournisseur.typeFournisseur,
                            fournisseur: this.autreFournisseur.fournisseur,
                            typeOffre: this.autreFournisseur.typeOffre,
                            prixMol: this.autreFournisseur.prixMol,
                            CEE: this.autreFournisseur.CEE,
                            ABO: this.autreFournisseur.ABO,
                            typeAbo: this.autreFournisseur.typeAbo,
                            DUREE: this.autreFournisseur.DUREE,
                        }
                        if(this.autreFournisseur.typeOffre == "pré-écrêté"){
                            if(this.autreFournisseur.Classique.indexOf('Classique')!=-1){
                                d["typeOffre"] = "pré-écrêté Classique"
                            }else{
                                d["typeOffre"] = this.autreFournisseur.typeOffre + " "+this.autreFournisseur.preEcrete+"%"
                            }
                        }else{
                            d["typeOffre"] = this.autreFournisseur.typeOffre
                        }
                        this.tousLesAutreFournisseur[index] = d
                    }else{
                        var d ={
                            typeFournisseur: this.autreFournisseur.typeFournisseur,
                            fournisseur: this.autreFournisseur.fournisseur,
                            typeOffre: this.autreFournisseur.typeOffre,
                            prixMol: this.autreFournisseur.prixMol,
                            CEE: this.autreFournisseur.CEE,
                            ABO: this.autreFournisseur.ABO,
                            typeAbo: this.autreFournisseur.typeAbo,
                            DUREE: this.autreFournisseur.DUREE,
                        }
                        if(this.autreFournisseur.typeOffre == "pré-écrêté"){
                            if(this.autreFournisseur.Classique.indexOf('Classique')!=-1){
                                d["typeOffre"] = "pré-écrêté Classique"
                            }else{
                                d["typeOffre"] = this.autreFournisseur.typeOffre + " "+this.autreFournisseur.preEcrete+"%"
                            }
                        }else{
                            d["typeOffre"] = this.autreFournisseur.typeOffre
                        }
                        this.tousLesAutreFournisseur.push(d)
                    }
                    this.editFrn = false
                    this.resetFournisseurActuelle()
                    this.openModalFornisseurActuelle = false
                }
            },
            closeModalHandleFournisseur(editFrn) {
                if (editFrn) {
                    this.close()
                    this.tousLesAutreFournisseur[this.editedIndex] = this.defaultAutreFournisseur;
                    this.defaultAutreFournisseur = {
                        typeFournisseur: 'Fournisseur',
                        fournisseur: null,
                        prixMol: null,
                        CEE: null,
                        ABO: null,
                        DUREE: null,            
                    }
                } else {
                    this.resetFournisseurActuelle()
                }
            }
        },
        computed: {
            
            isDisabledMesures() {
                if (this.nbrMonth>=8) {
                    return true
                } else {
                    return false
                }
            },

            getPropositionSiretOrName(){
                this.Propositions=[]
                if(this.Propositions.length==0){
                    this.renitialiserLaListeDeResultat()
                }
                this.listCompteur = []
                this.donneDeCotation.numCompteur=''
                this.enedisRecherche =false
                this.vraiSiret = false
                if(this.valueRechercheSiret != ''){
                    var formData = new FormData()
                    formData.append('siret', this.valueRechercheSiret)
                    formData.append('token', this.getToken('token'))
                    this.isCompteursLoading = true
                    axios({
                        url: process.env.VUE_APP_URL_API_CLIENT + 'getPropositionSocieteGaz/',
                        method: 'POST',
                        data: formData,
                    }).then((res) => {
                        this.listSociete = res.data.result
                    })
                }else{
                    this.listSociete = []
                }
                return true
            }
        }
    }

</script>
<style scoped>
.textLogo{
    margin:0;
    padding:0
}
.v-subheader{
    font-size:large;
    font-weight:300;
    color:black;
    padding:0px;
}

::v-deep .v-label{
   margin:0px; 
}

@media only screen and (max-width:600px){
    .v-subheader{
        font-size:medium;
        margin-top:10px
    }
}

@media only screen and (max-width:1260px){
    .responsive-margin{
        margin-bottom:20px;
    }
}

@media only screen and (max-width:500px){
    .responsiveClass{

            display:flex;
            flex-direction:column;
            align-items:center;
    }
    .textLogo{
        font-size:.8em;
    }
    .reponsiveBtn{
        margin:0px;
    }

    .v-dialog > .v-card > .v-card__title,.v-subheader {
            font-size:small
        }

    .responsiveGroupButton{
        display:flex;
        flex-direction:column;
    }

    ::v-deep .marginBtn{
    margin-bottom:10px !important;
    margin-left:0px !important;
    }

    .marginBtnContainer{
        padding-left:0px !important;
    }
        
}

@media  screen and (max-width:300px){
    .v-dialog > .v-card > .v-card__title{
            font-size:.72em;
            padding-left:0px;
            padding-right:0px;
        }
    }
</style>
