<style>
    .td-valeur{
        align-items:center;
        flex-direction:column
    }

    .td-statut{
        align-items:center;
        flex-direction:row;
    }
    .td-table, .td-valeur, .td-statut{
        display:flex;
        justify-content:center;
    }
    .d1{
        display: flex;
        align-items:center;
        width:100%;
        justify-content:flex-end
    }
</style>
<template>
    <div>
        <datatable class="ma-8" :columns="columns" :sortKey="sortKey" :sortOrders="sortOrders" @sort="sortBy"  @search="rechercheData">
            <template v-slot:body>
                <tbody>
                    <tr  v-for="( project, index) in projects" :key="project.id">
                        <td><div class="td-table">{{project.commercialeNom}} </div></td>
                        <td><div class="td-table">{{project.responsableNom}} </div></td>
                        <td>
                            <div style="max-width:200px">
                                <small>{{project.Raison}}</small>
                                <v-icon large
                                    data-toggle="collapse" :data-target="'#multiCollapseSociete'+index" aria-expanded="false" :aria-controls="'multiCollapseSociete'+index"
                                    right >
                                    mdi-menu-down
                                </v-icon>
                                <div class="collapse multi-collapse" :id="'multiCollapseSociete'+index">
                                    <div >
                                        <div class="td-table"><small>Siret: {{project.siret}}</small></div>    
                                        <div class="td-table"><small>code Naf: {{project.codeApe}}</small></div>
                                        <div class="td-table"><small>F.JU: {{project.FormJu}}</small></div>
                                        <div class="td-table"><small>TVA: {{project.TVA}}</small></div>
                                        <div class="td-table"><small>RCS: {{project.RCS}}</small></div>
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td>
                            <div style="max-width:200px">
                                <small>Elli Pro: {{project.scoreElliPro}}</small>
                                <v-icon large
                                    data-toggle="collapse" :data-target="'#multiCollapseScoring'+index" aria-expanded="false" :aria-controls="'multiCollapseScoring'+index"
                                    right >
                                    mdi-menu-down
                                </v-icon>
                                <div class="collapse multi-collapse" :id="'multiCollapseScoring'+index">
                                    <div >
                                        <div class="td-table"><small>Credit Safe: {{project.scoreCreditSafe}}</small></div>    
                                        <div class="td-table"><small>Info Legale: {{project.scoreInfoLegal}}</small></div>
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td>
                            <div style="max-width:200px">
                                <small>{{project.civil}} {{project.nom}} {{project.prenom}}</small>
                                <v-icon large
                                    data-toggle="collapse" :data-target="'#multiCollapseClient'+index" aria-expanded="false" :aria-controls="'multiCollapseClient'+index"
                                    right >
                                    mdi-menu-down
                                </v-icon>
                                <div class="collapse multi-collapse" :id="'multiCollapseClient'+index">
                                    <div >
                                        <div class="td-table"><small>Email: {{project.email}}</small></div>    
                                        <div class="td-table"><small>N° télé: {{project.numTele}}</small></div>
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td><div class="td-table">{{project.modeReglement}} </div></td>
                        <td>
                            <div style="max-width:200px">
                                <small>{{project.typeOffre}}</small>
                                <v-icon large
                                    data-toggle="collapse" :data-target="'#multiCollapseDates'+index" aria-expanded="false" :aria-controls="'multiCollapseDates'+index"
                                    right >
                                    mdi-menu-down
                                </v-icon>
                                <div class="collapse multi-collapse" :id="'multiCollapseDates'+index">
                                    <div >
                                        <div class="td-table"><small>{{project.dateDebut}}</small></div>    
                                        <div class="td-table"><small>{{project.dateFin}}</small></div>
                                        <div class="td-table"><small>{{project.duree}}</small></div>
                                        <div class="td-table"><small>{{project.transmisLe}}</small></div>
                                        <div class="td-table"><small>{{project.dateValiditer}}</small></div>
                                        <div class="td-table"><small>{{project.dateSignature}}</small></div>
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td>
                            <div style="max-width:200px">
                                <small>{{project.adresseDesite}}</small>
                                <v-icon large
                                    data-toggle="collapse" :data-target="'#multiCollapseAdrSite'+index" aria-expanded="false" :aria-controls="'multiCollapseAdrSite'+index"
                                    right >
                                    mdi-menu-down
                                </v-icon>
                                <div class="collapse multi-collapse" :id="'multiCollapseAdrSite'+index">
                                    <div >
                                        <div class="td-table"><small>{{project.codePostaleDesite}}</small></div>    
                                        <div class="td-table"><small>{{project.villeDesite}}</small></div>
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td>
                            <div style="max-width:200px">
                                <small>{{project.adresseDeFacturation}}</small>
                                <v-icon large
                                    data-toggle="collapse" :data-target="'#multiCollapseAdrFact'+index" aria-expanded="false" :aria-controls="'multiCollapseAdrFact'+index"
                                    right >
                                    mdi-menu-down
                                </v-icon>
                                <div class="collapse multi-collapse" :id="'multiCollapseAdrFact'+index">
                                    <div >
                                        <div class="td-table"><small>{{project.codePostaleDeFacturation}}</small></div>    
                                        <div class="td-table"><small>{{project.villeDeFacturation}}</small></div>
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td><div class="td-table">{{project.GRD}} </div></td>
                        <td>
                            <div style="max-width:200px">
                                <small>{{project.numCompteur}}</small>
                                <v-icon large
                                    data-toggle="collapse" :data-target="'#multiCollapseCompteur'+index" aria-expanded="false" :aria-controls="'multiCollapseCompteur'+index"
                                    right >
                                    mdi-menu-down
                                </v-icon>
                                <div class="collapse multi-collapse" :id="'multiCollapseCompteur'+index">
                                    <div >
                                        <div class="td-table"><small>OPT: {{project.typeCompteur}}</small></div>    
                                        <div class="td-table"><small>segment: {{project.segment}}</small></div>
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td>
                            <div style="max-width:200px">
                                <small>Puissance: {{project.PS}}</small>
                                <v-icon large
                                    data-toggle="collapse" :data-target="'#multiCollapsePuissance'+index" aria-expanded="false" :aria-controls="'multiCollapsePuissance'+index"
                                    right >
                                    mdi-menu-down
                                </v-icon>
                                <div class="collapse multi-collapse" :id="'multiCollapsePuissance'+index">
                                    <div >
                                        <div class="td-table"><small>Pointe: {{project.PSPOINTE}}</small></div>    
                                        <div class="td-table"><small>HPH: {{project.PSHPH}}</small></div>    
                                        <div class="td-table"><small>HCH: {{project.PSHCH}}</small></div>    
                                        <div class="td-table"><small>HPE: {{project.PSHPE}}</small></div>    
                                        <div class="td-table"><small>HCE: {{project.PSHCE}}</small></div>    
                                        <div class="td-table"><small>HP: {{project.PSHP}}</small></div> 
                                        <div class="td-table"><small>HC: {{project.PSHC}}</small></div> 
                                        <div class="td-table"><small>BASE: {{project.PSBASE}}</small></div> 
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td>
                            <div style="max-width:200px">
                                <small>CAR: {{project.CAR}}</small>
                                <v-icon large
                                    data-toggle="collapse" :data-target="'#multiCollapseCAR'+index" aria-expanded="false" :aria-controls="'multiCollapseCAR'+index"
                                    right >
                                    mdi-menu-down
                                </v-icon>
                                <div class="collapse multi-collapse" :id="'multiCollapseCAR'+index">
                                    <div >
                                        <div class="td-table"><small>Pointe: {{project.CARPOINTE}}</small></div>    
                                        <div class="td-table"><small>HPH: {{project.CARHPH}}</small></div>    
                                        <div class="td-table"><small>HCH: {{project.CARHCH}}</small></div>    
                                        <div class="td-table"><small>HPE: {{project.CARHPE}}</small></div>    
                                        <div class="td-table"><small>HCE: {{project.CARHCE}}</small></div>    
                                        <div class="td-table"><small>HP: {{project.CARHP}}</small></div> 
                                        <div class="td-table"><small>HC: {{project.CARHC}}</small></div> 
                                        <div class="td-table"><small>BASE: {{project.CARBASE}}</small></div> 
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td><div class="td-table">{{project.Fournisseur}} </div></td>
                        <td><div class="td-table">{{project.Etat}} </div></td>
                    </tr>
                </tbody>
            </template>
        </datatable>
        <v-row >
            <v-col >
                <pagination :pagination="pagination"
                    @selectNumberOfRecords="rechercheData"
                    @first="getData(pagination.firstPage)"
                    @last="getData(pagination.lastPage)"
                    @prev="getData(pagination.prevPageUrl)"
                    @current="getData(pagination.currentPage)"
                    @next="getData(pagination.nextPageUrl)">
                </pagination>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import "jquery/dist/jquery.min.js";
    import "bootstrap/dist/css/bootstrap.min.css";
    import "bootstrap/dist/css/bootstrap.css";
    import "bootstrap/dist/js/bootstrap.bundle";
    import "datatables.net-dt/js/dataTables.dataTables";
    import "datatables.net-dt/css/jquery.dataTables.min.css";

    import $ from 'jquery'
    import jQuery from 'jquery'

    import Datatable from '../../components/dataTable/Datatable.vue'
    import Pagination from '../../components/dataTable/Pagination.vue'
    import axios from 'axios'
    export default{
        name: 'suiviCommerciale',
        components: {datatable:Datatable, pagination:Pagination},
        data(){
            let sortOrders={};
            let columns = [
                {width:'10vw', label:'Commerciale', name:'commercialeNom', sortable:true},
                {width:'10vw', label:'Responsable', name:'responsableNom', sortable:true},
                {width:'10vw', label:'Entreprise', name:'Raison', sortable:true},
                {width:'10vw', label:'Scoring', name:'siret', sortable:true},
                {width:'10vw', label:'Client', name:'codeApe', sortable:true},
                {width:'10vw', label:'Mode Reglement', name:'scoreInfoLegal', sortable:true},
                {width:'10vw', label:"Date et type d'offre", name:'civil', sortable:false},
                {width:'10vw', label:'Adresse de site', name:'scoreElliPro', sortable:false},
                {width:'10vw', label:'Adresse de facturtion', name:'scoreCreditSafe', sortable:false},
                {width:'10vw', label:'GRD', name:'modeReglement', sortable:false},
                {width:'10vw', label:'Compteur', name:'numCompteur', sortable:true},
                {width:'10vw', label:'Puissance', name:'dateSignature', sortable:true},
                {width:'10vw', label:'Consommation', name:'transmisLe', sortable:true},
                {width:'10vw', label:'Fournisseur', name:'transmisLe', sortable:true},
                {width:'10vw', label:'Etat', name:'transmisLe', sortable:true},
            ];
            columns.forEach((column)=>{
                if(column.sortable==true){
                    sortOrders[column.name] = -1;
                }
            });
            return{
                dialog: false,
                loading: false,
                error: false,
                commentRefuser:'',
                messageDialoge: '',
                ouvrireDialogeRefuser: false,
                ouvrireDialogeSupprimer: false,
                projects: [],
                columns: columns,
                sortKey: 'date',
                sortOrders: sortOrders,
                perPage: ['15', '15', '30'],
                tableData: {
                    draw: 0,
                    length: 15,
                    search: '',
                    column: 0,
                    dir: 'desc',
                },
                pagination: {
                    perPage: ['10', '15', '30'],
                    firstPage: 0,
                    lastPage: '',
                    currentPage: '',
                    total: '',
                    lastPageUrl: '',
                    nextPageUrl: '',
                    prevPageUrl: '',
                    from: '',
                    to: ''
                },

                 rules: {
                    required(finPhrase) {
                    return (value) => !!value || `Veuillez saisir ${finPhrase}`
                    },
                },
            }
        },
        async created() {
            var whoIam = ""
            await this.whoIAm(this.getToken('token')).then((data)=>{whoIam=data});
            if(whoIam == "noToken"){
                localStorage.removeItem('vendeurName')
                localStorage.removeItem('typeUser') 
                localStorage.removeItem('token')
                this.$router.push('/login')
            }else{
                localStorage.setItem('typeUser', whoIam)
            }
            this.getData();
        },
        methods: {
            
            /*tryUpdateValue(id){
                $("#buttonTryEdit"+id).css("display", "none");
                $("#buttonAnnulerEdit"+id).css("display", "inline-block");
                $("#buttonValideEdit"+id).css("display", "inline-block");
                
                $("#Value"+id).css("display", "none");
                $("#inputValue"+id).css("display", "inline-block");
            },
            annulerUpdateValue(id){
                $("#buttonTryEdit"+id).css("display", "inline-block");
                $("#buttonAnnulerEdit"+id).css("display", "none");
                $("#buttonValideEdit"+id).css("display", "none");
                $("#Value"+id).css("display", "inline-block");
                $("#inputValue"+id).css("display", "none");
            },
            modifierValue(id){
                var val = $('#inputValue'+id).val();
                var formData = new FormData()
                formData.append('token', this.getToken('token'))
                formData.append('val', val)
                formData.append('idVente', id)
                axios({
                    url:
                    process.env.VUE_APP_URL_API_CLIENT + 'updateValeurVentePro/',
                    method: 'POST',
                    data: formData,
                }).then((res) => {
                    if(res.data.result == true){
                        alert('la valeur de cette vente a été mise à jour')
                        this.getData(this.pagination.currentPage)
                        this.annulerUpdateValue(id)
                    }else if(res.data.result == false){
                        alert('vérifiez que votre valeur soit valide')
                        this.getData(this.pagination.currentPage)
                    }else if(res.data.result == "permission"){
                        alert('permission refusée')
                        localStorage.removeItem('vendeurName')
                        localStorage.removeItem('typeUser')
                        localStorage.removeItem('token')
                        this.$router.push('/login')
                    }
                }).catch((err)=>{
                        alert('permission refusée')
                })
            },*/
            rechercheData({type, key}) {
                if(type=='search'){
                    var pageNumber = 0
                    this.tableData.search = key
                }else if(type=="nbrRecords")
                {
                    this.tableData.length = key
                }
                var pageNumber = 0
                this.tableData.search = key
                this.tableData.draw++;
                axios({url:process.env.VUE_APP_URL_API_CLIENT + 'suiviCommerciale/',
                    method: 'POST',
                    data: {'dataTable': this.tableData,
                        "pageNumber": pageNumber,
                        'token': this.getToken('token')},
                })
                    .then(response => {
                       if(response.data.result == true){
                        let data = response.data.data;
                        if (this.tableData.draw == data.draw) {
                            this.projects = data.data.data;
                            this.configPagination(data.data);
                        }
                    }else{
                        alert('permission refusée')
                        localStorage.removeItem('vendeurName')
                        localStorage.removeItem('typeUser')
                        localStorage.removeItem('token')
                        this.$router.push('/login')
                    }
                })
                .catch(errors => {
                    console.log(errors);
                });
            },
            ouvrireDialogeRefuserVente(item){
                this.VenteRefuser = item
                this.ouvrireDialogeRefuser = true
            },
            fermerDialogeRefuserVente(){
                this.VenteRefuser = ''
                this.ouvrireDialogeRefuser = false
            },
            ouvrireDialogeSupprimerVente(item){
                this.VenteSupprimer = item
                this.ouvrireDialogeSupprimer = true
            },
            fermerDialogeSupprimerVente(){
                this.VenteSupprimer = ''
                this.ouvrireDialogeSupprimer = false
            },
            getData(pageNumber = 0) {
                this.tableData.draw++;
                axios({url:process.env.VUE_APP_URL_API_CLIENT + 'suiviCommerciale/',
                    method: 'POST',
                    data: {'dataTable': this.tableData,
                        "pageNumber": pageNumber,
                        'token': this.getToken('token')},
                })
                .then(response => {
                    if(response.data.result == true){
                    let data = response.data.data;
                    if (this.tableData.draw == data.draw) {
                        this.projects = data.data.data;
                        this.configPagination(data.data);
                    }
                }else{
                    alert('permission refusée')
                    localStorage.removeItem('vendeurName')
                    localStorage.removeItem('typeUser')
                    localStorage.removeItem('token')
                    this.$router.push('/login')
                }
            })
            .catch(errors => {
                console.log(errors);
            });
            },
            configPagination(data) {
                this.pagination.lastPage = data.last_page;
                this.pagination.currentPage = data.current_page;
                this.pagination.total = data.total;
                this.pagination.lastPageUrl = data.last_page_url;
                this.pagination.nextPageUrl = data.next_page_url;
                this.pagination.prevPageUrl = data.prev_page_url;
                this.pagination.from = data.from;
                this.pagination.to = data.to;
            },
            sortBy(key) {
                this.sortKey = key;
                this.sortOrders[key] = this.sortOrders[key] * -1;
                this.tableData.column = this.getIndex(this.columns, 'name', key);
                this.tableData.dir = this.sortOrders[key] === 1 ? 'asc' : 'desc';
                this.getData();
            },
            getIndex(array, key, value) {
                return array.findIndex(i => i[key] == value)
            },

            
        }
    }
</script>
