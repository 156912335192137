export const gazDataTherm = [
  {
    id: 12,
    gas: 'Day-Ahead',
    '26_apr': 148.0,
    '27_apr': 121.0,
    day_on_day: -0.92,
    day_on_day_perc: -18.24,
    week_on_day: -27.54,
  },
  {
    id: 13,
    gas: 'Day-Ahead',
    '26_apr': 148.0,
    '27_apr': 121.0,
    day_on_day: -0.92,
    day_on_day_perc: -18.24,
    week_on_day: -27.54,
  },
  {
    id: 14,
    gas: 'Day-Ahead',
    '26_apr': 148.0,
    '27_apr': 121.0,
    day_on_day: -0.92,
    day_on_day_perc: -18.24,
    week_on_day: -27.54,
  },
  {
    id: 15,
    gas: 'Day-Ahead',
    '26_apr': 148.0,
    '27_apr': 121.0,
    day_on_day: -0.92,
    day_on_day_perc: -18.24,
    week_on_day: -27.54,
  },
  {
    id: 16,
    gas: 'Day-Ahead',
    '26_apr': 148.0,
    '27_apr': 121.0,
    day_on_day: -0.92,
    day_on_day_perc: 8.24,
    week_on_day: -27.54,
  },
  {
    id: 17,
    gas: 'Day-Ahead',
    '26_apr': 148.0,
    '27_apr': 121.0,
    day_on_day: -0.92,
    day_on_day_perc: -18.24,
    week_on_day: -27.54,
  },
  {
    id: 18,
    gas: 'Day-Ahead',
    '26_apr': 148.0,
    '27_apr': 121.0,
    day_on_day: -0.92,
    day_on_day_perc: 5.24,
    week_on_day: -27.54,
  },
]
