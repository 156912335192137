export const series2 = [
  [1167609600000, 0.5537],
  [1167696000000, 0.5537],
  [1167782400000, 0.5559],
  [1167868800000, 0.5631],
  [1167955200000, 0.5644],
  [1168214400000, 0.569],
  [1168300800000, 0.5683],
  [1168387200000, 0.57],
  [1168473600000, 0.5703],
  [1168560000000, 0.5757],
  [1168819200000, 0.5728],
  [1168905600000, 0.5721],
  [1168992000000, 0.5748],
  [1169078400000, 0.574],
  [1169164800000, 0.5718],
  [1169424000000, 0.5731],
  [1169510400000, 0.567],
  [1169596800000, 0.569],
  [1169683200000, 0.5706],
  [1169769600000, 0.5752],
  [1170028800000, 0.574],
  [1170115200000, 0.571],
  [1170201600000, 0.5721],
  [1170288000000, 0.5681],
  [1170374400000, 0.5681],
  [1170633600000, 0.5738],
  [1170720000000, 0.572],
  [1170806400000, 0.5701],
  [1170892800000, 0.5699],
  [1170979200000, 0.5689],
  [1171238400000, 0.5719],
  [1171324800000, 0.568],
  [1171411200000, 0.5645],
  [1171497600000, 0.5613],
  [1171584000000, 0.5624],
  [1171843200000, 0.5616],
  [1171929600000, 0.5608],
  [1172016000000, 0.5608],
  [1172102400000, 0.5631],
  [1172188800000, 0.5615],
  [1172448000000, 0.56],
  [1172534400000, 0.556],
  [1172620800000, 0.557],
  [1172707200000, 0.5562],
  [1172793600000, 0.5598],
  [1173052800000, 0.5645],
  [1173139200000, 0.5635],
  [1173225600000, 0.5614],
  [1173312000000, 0.5604],
  [1173398400000, 0.5603],
  [1173657600000, 0.5602],
  [1173744000000, 0.5566],
  [1173830400000, 0.5587],
  [1173916800000, 0.5562],
  [1174003200000, 0.5506],
  [1174262400000, 0.5518],
  [1174348800000, 0.5522],
  [1174435200000, 0.5524],
  [1174521600000, 0.5491],
  [1174608000000, 0.5505],
  [1174867200000, 0.554],
  [1174953600000, 0.5493],
  [1175040000000, 0.5493],
  [1175126400000, 0.5491],
  [1175212800000, 0.551],
  [1175472000000, 0.5483],
  [1175558400000, 0.5487],
  [1175644800000, 0.5491],
  [1175731200000, 0.5479],
  [1175817600000, 0.5479],
  [1176076800000, 0.5479],
  [1176163200000, 0.5449],
  [1176249600000, 0.5454],
  [1176336000000, 0.5427],
  [1176422400000, 0.5391],
  [1176681600000, 0.5381],
  [1176768000000, 0.5382],
  [1176854400000, 0.5366],
  [1176940800000, 0.5353],
  [1177027200000, 0.5351],
  [1177286400000, 0.5377],
  [1177372800000, 0.5364],
  [1177459200000, 0.5328],
  [1177545600000, 0.5356],
  [1177632000000, 0.5331],
  [1177891200000, 0.5351],
  [1177977600000, 0.5351],
  [1178064000000, 0.536],
  [1178150400000, 0.5347],
  [1178236800000, 0.5375],
  [1178496000000, 0.5346],
  [1178582400000, 0.5377],
  [1178668800000, 0.5389],
  [1178755200000, 0.5394],
  [1178841600000, 0.5416],
  [1179100800000, 0.5382],
  [1179187200000, 0.5388],
  [1179273600000, 0.5368],
  [1179360000000, 0.54],
  [1179446400000, 0.5421],
  [1179705600000, 0.5439],
  [1179792000000, 0.5434],
  [1179878400000, 0.5414],
  [1179964800000, 0.5437],
  [1180051200000, 0.5441],
  [1180310400000, 0.5434],
  [1180396800000, 0.5403],
  [1180483200000, 0.5453],
  [1180569600000, 0.5434],
  [1180656000000, 0.5444],
  [1180915200000, 0.5418],
  [1181001600000, 0.5391],
  [1181088000000, 0.5401],
  [1181174400000, 0.5425],
  [1181260800000, 0.5492],
  [1181520000000, 0.5489],
  [1181606400000, 0.5494],
  [1181692800000, 0.5527],
  [1181779200000, 0.5518],
  [1181865600000, 0.5512],
  [1182124800000, 0.5461],
  [1182211200000, 0.5462],
  [1182297600000, 0.5449],
  [1182384000000, 0.5465],
  [1182470400000, 0.5441],
  [1182729600000, 0.543],
  [1182816000000, 0.543],
  [1182902400000, 0.5443],
  [1182988800000, 0.5427],
  [1183075200000, 0.5406],
  [1183334400000, 0.536],
  [1183420800000, 0.5353],
  [1183507200000, 0.5344],
  [1183593600000, 0.5332],
  [1183680000000, 0.5356],
  [1183939200000, 0.5343],
  [1184025600000, 0.5318],
  [1184112000000, 0.5272],
  [1184198400000, 0.5254],
  [1184284800000, 0.5257],
  [1184544000000, 0.5257],
  [1184630400000, 0.5263],
  [1184716800000, 0.5258],
  [1184803200000, 0.5237],
  [1184889600000, 0.5246],
  [1185148800000, 0.5236],
  [1185235200000, 0.523],
  [1185321600000, 0.5277],
  [1185408000000, 0.5289],
  [1185494400000, 0.5326],
  [1185753600000, 0.5322],
  [1185840000000, 0.5297],
  [1185926400000, 0.532],
  [1186012800000, 0.532],
  [1186099200000, 0.5303],
  [1186358400000, 0.5238],
  [1186444800000, 0.5251],
  [1186531200000, 0.5251],
  [1186617600000, 0.5285],
  [1186704000000, 0.5327],
  [1186963200000, 0.5326],
  [1187049600000, 0.5359],
  [1187136000000, 0.5422],
  [1187222400000, 0.5461],
  [1187308800000, 0.5434],
  [1187568000000, 0.5422],
  [1187654400000, 0.5404],
  [1187740800000, 0.5412],
  [1187827200000, 0.5368],
  [1187913600000, 0.5346],
  [1188172800000, 0.5323],
  [1188259200000, 0.532],
  [1188345600000, 0.5337],
  [1188432000000, 0.5349],
  [1188518400000, 0.5298],
  [1188777600000, 0.5337],
  [1188864000000, 0.5365],
  [1188950400000, 0.536],
  [1189036800000, 0.5317],
  [1189123200000, 0.5302],
  [1189382400000, 0.525],
  [1189468800000, 0.5235],
  [1189555200000, 0.5203],
  [1189641600000, 0.5197],
  [1189728000000, 0.5216],
  [1189987200000, 0.5207],
  [1190073600000, 0.5212],
  [1190160000000, 0.5157],
  [1190246400000, 0.5129],
  [1190332800000, 0.5119],
  [1190592000000, 0.5087],
  [1190678400000, 0.509],
  [1190764800000, 0.508],
  [1190851200000, 0.5053],
  [1190937600000, 0.5054],
  [1191196800000, 0.5027],
  [1191283200000, 0.5061],
  [1191369600000, 0.5046],
  [1191456000000, 0.5089],
  [1191542400000, 0.5075],
  [1191801600000, 0.5099],
  [1191888000000, 0.5125],
  [1191974400000, 0.507],
  [1192060800000, 0.5044],
  [1192147200000, 0.5057],
  [1192406400000, 0.503],
  [1192492800000, 0.5068],
  [1192579200000, 0.5043],
  [1192665600000, 0.4994],
  [1192752000000, 0.5],
  [1193011200000, 0.506],
  [1193097600000, 0.5017],
  [1193184000000, 0.5028],
  [1193270400000, 0.499],
  [1193356800000, 0.4953],
  [1193616000000, 0.495],
  [1193702400000, 0.4942],
  [1193788800000, 0.4923],
  [1193875200000, 0.4934],
  [1193961600000, 0.4908],
  [1194220800000, 0.4903],
  [1194307200000, 0.4875],
  [1194393600000, 0.4794],
  [1194480000000, 0.4819],
  [1194566400000, 0.4812],
  [1194825600000, 0.486],
  [1194912000000, 0.4847],
  [1194998400000, 0.4804],
  [1195084800000, 0.4832],
  [1195171200000, 0.4826],
  [1195430400000, 0.4825],
  [1195516800000, 0.4765],
  [1195603200000, 0.4751],
  [1195689600000, 0.4745],
  [1195776000000, 0.4754],
  [1196035200000, 0.4737],
  [1196121600000, 0.4724],
  [1196208000000, 0.4782],
  [1196294400000, 0.4786],
  [1196380800000, 0.4776],
  [1196640000000, 0.4819],
  [1196726400000, 0.4785],
  [1196812800000, 0.4794],
  [1196899200000, 0.4872],
  [1196985600000, 0.4827],
  [1197244800000, 0.4795],
  [1197331200000, 0.4817],
  [1197417600000, 0.4815],
  [1197504000000, 0.4812],
  [1197590400000, 0.4893],
  [1197849600000, 0.4949],
  [1197936000000, 0.4938],
  [1198022400000, 0.4953],
  [1198108800000, 0.497],
  [1198195200000, 0.4955],
  [1198454400000, 0.4946],
  [1198540800000, 0.4946],
  [1198627200000, 0.4946],
  [1198713600000, 0.489],
  [1198800000000, 0.4807],
  [1199059200000, 0.4794],
  [1199145600000, 0.4794],
  [1199232000000, 0.4809],
  [1199318400000, 0.4779],
  [1199404800000, 0.4791],
  [1199664000000, 0.4793],
  [1199750400000, 0.4801],
  [1199836800000, 0.4813],
  [1199923200000, 0.4821],
  [1200009600000, 0.4761],
  [1200268800000, 0.4715],
  [1200355200000, 0.4719],
  [1200441600000, 0.4761],
  [1200528000000, 0.4808],
  [1200614400000, 0.4816],
  [1200873600000, 0.4906],
  [1200960000000, 0.49],
  [1201046400000, 0.4863],
  [1201132800000, 0.4821],
  [1201219200000, 0.4801],
  [1201478400000, 0.4778],
  [1201564800000, 0.477],
  [1201651200000, 0.4753],
  [1201737600000, 0.4726],
  [1201824000000, 0.4717],
  [1202083200000, 0.4745],
  [1202169600000, 0.4809],
  [1202256000000, 0.484],
  [1202342400000, 0.4865],
  [1202428800000, 0.4891],
  [1202688000000, 0.4878],
  [1202774400000, 0.488],
  [1202860800000, 0.4857],
  [1202947200000, 0.4838],
  [1203033600000, 0.4816],
  [1203292800000, 0.4833],
  [1203379200000, 0.4784],
  [1203465600000, 0.4824],
  [1203552000000, 0.4787],
  [1203638400000, 0.4736],
  [1203897600000, 0.475],
  [1203984000000, 0.4724],
  [1204070400000, 0.4648],
  [1204156800000, 0.4614],
  [1204243200000, 0.4594],
  [1204502400000, 0.4579],
  [1204588800000, 0.4577],
  [1204675200000, 0.4582],
  [1204761600000, 0.4529],
  [1204848000000, 0.4487],
  [1205107200000, 0.452],
  [1205193600000, 0.4503],
  [1205280000000, 0.4462],
  [1205366400000, 0.4421],
  [1205452800000, 0.4427],
  [1205712000000, 0.4342],
  [1205798400000, 0.4342],
  [1205884800000, 0.4374],
  [1205971200000, 0.4485],
  [1206057600000, 0.4485],
  [1206316800000, 0.4485],
  [1206403200000, 0.4424],
  [1206489600000, 0.4366],
  [1206576000000, 0.4336],
  [1206662400000, 0.4332],
  [1206921600000, 0.4325],
  [1207008000000, 0.4387],
  [1207094400000, 0.4398],
  [1207180800000, 0.4442],
  [1207267200000, 0.4362],
  [1207526400000, 0.4373],
  [1207612800000, 0.4373],
  [1207699200000, 0.436],
  [1207785600000, 0.43],
  [1207872000000, 0.4317],
  [1208131200000, 0.4303],
  [1208217600000, 0.4319],
  [1208304000000, 0.4279],
  [1208390400000, 0.4301],
  [1208476800000, 0.4338],
  [1208736000000, 0.4291],
  [1208822400000, 0.4278],
  [1208908800000, 0.4275],
  [1208995200000, 0.4343],
  [1209081600000, 0.4413],
  [1209340800000, 0.44],
  [1209427200000, 0.4423],
  [1209513600000, 0.4436],
  [1209600000000, 0.4436],
  [1209686400000, 0.447],
  [1209945600000, 0.4469],
  [1210032000000, 0.4441],
  [1210118400000, 0.4482],
  [1210204800000, 0.4517],
  [1210291200000, 0.447],
  [1210550400000, 0.4482],
  [1210636800000, 0.4464],
  [1210723200000, 0.4478],
  [1210809600000, 0.4463],
  [1210896000000, 0.4453],
  [1211155200000, 0.4421],
  [1211241600000, 0.4395],
  [1211328000000, 0.4349],
  [1211414400000, 0.4348],
  [1211500800000, 0.4353],
  [1211760000000, 0.4346],
  [1211846400000, 0.4346],
  [1211932800000, 0.4388],
  [1212019200000, 0.4431],
  [1212105600000, 0.4449],
  [1212364800000, 0.4444],
  [1212451200000, 0.4414],
  [1212537600000, 0.4467],
  [1212624000000, 0.4494],
  [1212710400000, 0.4412],
  [1212969600000, 0.4337],
  [1213056000000, 0.4442],
  [1213142400000, 0.4446],
  [1213228800000, 0.4487],
  [1213315200000, 0.4522],
  [1213574400000, 0.447],
  [1213660800000, 0.4462],
  [1213747200000, 0.4456],
  [1213833600000, 0.4461],
  [1213920000000, 0.4407],
  [1214179200000, 0.4444],
  [1214265600000, 0.4424],
  [1214352000000, 0.4412],
  [1214438400000, 0.4358],
  [1214524800000, 0.4351],
  [1214784000000, 0.4345],
  [1214870400000, 0.434],
  [1214956800000, 0.4328],
  [1215043200000, 0.4296],
  [1215129600000, 0.4382],
  [1215388800000, 0.439],
  [1215475200000, 0.4376],
  [1215561600000, 0.4364],
  [1215648000000, 0.4367],
  [1215734400000, 0.4316],
  [1215993600000, 0.4311],
  [1216080000000, 0.4255],
  [1216166400000, 0.4295],
  [1216252800000, 0.4311],
  [1216339200000, 0.4324],
  [1216598400000, 0.4307],
  [1216684800000, 0.4283],
  [1216771200000, 0.4354],
  [1216857600000, 0.438],
  [1216944000000, 0.4357],
  [1217203200000, 0.4352],
  [1217289600000, 0.4368],
  [1217376000000, 0.4416],
  [1217462400000, 0.4407],
  [1217548800000, 0.4422],
  [1217808000000, 0.4425],
  [1217894400000, 0.4458],
  [1217980800000, 0.4462],
  [1218067200000, 0.4465],
  [1218153600000, 0.4635],
  [1218412800000, 0.4662],
  [1218499200000, 0.4709],
  [1218585600000, 0.4711],
  [1218672000000, 0.4709],
  [1218758400000, 0.479],
  [1219017600000, 0.4802],
  [1219104000000, 0.4814],
  [1219190400000, 0.4788],
  [1219276800000, 0.4751],
  [1219363200000, 0.4754],
  [1219622400000, 0.4773],
  [1219708800000, 0.4851],
  [1219795200000, 0.4773],
  [1219881600000, 0.4771],
  [1219968000000, 0.4788],
  [1220227200000, 0.484],
  [1220313600000, 0.489],
  [1220400000000, 0.4926],
  [1220486400000, 0.4903],
  [1220572800000, 0.502],
  [1220832000000, 0.5036],
  [1220918400000, 0.5071],
  [1221004800000, 0.5096],
  [1221091200000, 0.5178],
  [1221177600000, 0.511],
  [1221436800000, 0.5068],
  [1221523200000, 0.501],
  [1221609600000, 0.5031],
  [1221696000000, 0.4897],
  [1221782400000, 0.5025],
  [1222041600000, 0.4864],
  [1222128000000, 0.4789],
  [1222214400000, 0.4808],
  [1222300800000, 0.4804],
  [1222387200000, 0.4832],
  [1222646400000, 0.497],
  [1222732800000, 0.4993],
  [1222819200000, 0.5103],
  [1222905600000, 0.5194],
  [1222992000000, 0.523],
  [1223251200000, 0.5336],
  [1223337600000, 0.5337],
  [1223424000000, 0.5284],
  [1223510400000, 0.531],
  [1223596800000, 0.5365],
  [1223856000000, 0.5333],
  [1223942400000, 0.5273],
  [1224028800000, 0.534],
  [1224115200000, 0.5405],
  [1224201600000, 0.5461],
  [1224460800000, 0.545],
  [1224547200000, 0.5586],
  [1224633600000, 0.5787],
  [1224720000000, 0.5807],
  [1224806400000, 0.594],
  [1225065600000, 0.6027],
  [1225152000000, 0.5984],
  [1225238400000, 0.5832],
  [1225324800000, 0.5673],
  [1225411200000, 0.584],
  [1225670400000, 0.58],
  [1225756800000, 0.5801],
  [1225843200000, 0.5771],
  [1225929600000, 0.5832],
  [1226016000000, 0.584],
  [1226275200000, 0.5758],
  [1226361600000, 0.5846],
  [1226448000000, 0.5982],
  [1226534400000, 0.5985],
  [1226620800000, 0.5891],
  [1226880000000, 0.59],
  [1226966400000, 0.5904],
  [1227052800000, 0.5916],
  [1227139200000, 0.5974],
  [1227225600000, 0.5936],
  [1227484800000, 0.583],
  [1227571200000, 0.5807],
  [1227657600000, 0.5732],
  [1227744000000, 0.5753],
  [1227830400000, 0.5858],
  [1228089600000, 0.5932],
  [1228176000000, 0.5877],
  [1228262400000, 0.5923],
  [1228348800000, 0.5925],
  [1228435200000, 0.5897],
  [1228694400000, 0.5781],
  [1228780800000, 0.579],
  [1228867200000, 0.5738],
  [1228953600000, 0.5568],
  [1229040000000, 0.5497],
  [1229299200000, 0.5403],
  [1229385600000, 0.5306],
  [1229472000000, 0.5114],
  [1229558400000, 0.4843],
  [1229644800000, 0.5175],
  [1229904000000, 0.5159],
  [1229990400000, 0.5155],
  [1230076800000, 0.5141],
  [1230163200000, 0.5141],
  [1230249600000, 0.5141],
  [1230508800000, 0.5009],
  [1230595200000, 0.5094],
  [1230681600000, 0.5186],
  [1230768000000, 0.5186],
  [1230854400000, 0.5213],
  [1231113600000, 0.5364],
  [1231200000000, 0.5502],
  [1231286400000, 0.5357],
  [1231372800000, 0.5345],
  [1231459200000, 0.5309],
  [1231718400000, 0.5467],
  [1231804800000, 0.5541],
  [1231891200000, 0.5592],
  [1231977600000, 0.5643],
  [1232064000000, 0.5537],
  [1232323200000, 0.5587],
  [1232409600000, 0.5735],
  [1232496000000, 0.5747],
  [1232582400000, 0.5703],
  [1232668800000, 0.5817],
  [1232928000000, 0.5699],
  [1233014400000, 0.5592],
  [1233100800000, 0.5542],
  [1233187200000, 0.5628],
  [1233273600000, 0.5804],
  [1233532800000, 0.5838],
  [1233619200000, 0.5784],
  [1233705600000, 0.5803],
  [1233792000000, 0.5796],
  [1233878400000, 0.5816],
  [1234137600000, 0.5689],
  [1234224000000, 0.5713],
  [1234310400000, 0.573],
  [1234396800000, 0.5793],
  [1234483200000, 0.5799],
  [1234742400000, 0.5835],
  [1234828800000, 0.5916],
  [1234915200000, 0.594],
  [1235001600000, 0.5872],
  [1235088000000, 0.5943],
  [1235347200000, 0.5815],
  [1235433600000, 0.5836],
  [1235520000000, 0.5817],
  [1235606400000, 0.5825],
  [1235692800000, 0.591],
  [1235952000000, 0.594],
  [1236038400000, 0.5928],
  [1236124800000, 0.5966],
  [1236211200000, 0.5966],
  [1236297600000, 0.5901],
  [1236556800000, 0.596],
  [1236643200000, 0.5824],
  [1236729600000, 0.5822],
  [1236816000000, 0.5825],
  [1236902400000, 0.575],
  [1237161600000, 0.5669],
  [1237248000000, 0.5728],
  [1237334400000, 0.5617],
  [1237420800000, 0.5316],
  [1237507200000, 0.5382],
  [1237766400000, 0.5377],
  [1237852800000, 0.5405],
  [1237939200000, 0.5412],
  [1238025600000, 0.535],
  [1238112000000, 0.5523],
  [1238371200000, 0.5581],
  [1238457600000, 0.5515],
  [1238544000000, 0.555],
  [1238630400000, 0.5468],
  [1238716800000, 0.545],
  [1238976000000, 0.5411],
  [1239062400000, 0.5545],
  [1239148800000, 0.5559],
  [1239235200000, 0.5535],
  [1239321600000, 0.5535],
  [1239580800000, 0.5535],
  [1239667200000, 0.5533],
  [1239753600000, 0.5592],
  [1239840000000, 0.5579],
  [1239926400000, 0.5659],
  [1240185600000, 0.5713],
  [1240272000000, 0.5734],
  [1240358400000, 0.5725],
  [1240444800000, 0.5664],
  [1240531200000, 0.5558],
  [1240790400000, 0.562],
  [1240876800000, 0.5698],
  [1240963200000, 0.5539],
  [1241049600000, 0.5534],
  [1241136000000, 0.5534],
  [1241395200000, 0.5564],
  [1241481600000, 0.5462],
  [1241568000000, 0.5507],
  [1241654400000, 0.5484],
  [1241740800000, 0.545],
  [1242000000000, 0.5368],
  [1242086400000, 0.5309],
  [1242172800000, 0.5342],
  [1242259200000, 0.5374],
  [1242345600000, 0.5399],
  [1242604800000, 0.5412],
  [1242691200000, 0.5347],
  [1242777600000, 0.5306],
  [1242864000000, 0.5263],
  [1242950400000, 0.5158],
  [1243209600000, 0.5139],
  [1243296000000, 0.5191],
  [1243382400000, 0.5195],
  [1243468800000, 0.5218],
  [1243555200000, 0.5094],
  [1243814400000, 0.5033],
  [1243900800000, 0.5024],
  [1243987200000, 0.504],
  [1244073600000, 0.5096],
  [1244160000000, 0.5055],
  [1244419200000, 0.5213],
  [1244505600000, 0.5165],
  [1244592000000, 0.5092],
  [1244678400000, 0.516],
  [1244764800000, 0.5142],
  [1245024000000, 0.5221],
  [1245110400000, 0.52],
  [1245196800000, 0.5226],
  [1245283200000, 0.5185],
  [1245369600000, 0.5179],
  [1245628800000, 0.5217],
  [1245715200000, 0.5155],
  [1245801600000, 0.5129],
  [1245888000000, 0.5175],
  [1245974400000, 0.5095],
  [1246233600000, 0.5114],
  [1246320000000, 0.5076],
  [1246406400000, 0.5095],
  [1246492800000, 0.5119],
  [1246579200000, 0.5139],
  [1246838400000, 0.5197],
  [1246924800000, 0.5134],
  [1247011200000, 0.5195],
  [1247097600000, 0.5149],
  [1247184000000, 0.5195],
  [1247443200000, 0.5157],
  [1247529600000, 0.5148],
  [1247616000000, 0.5099],
  [1247702400000, 0.5078],
  [1247788800000, 0.5098],
  [1248048000000, 0.5035],
  [1248134400000, 0.5032],
  [1248220800000, 0.5048],
  [1248307200000, 0.5029],
  [1248393600000, 0.503],
  [1248652800000, 0.5009],
  [1248739200000, 0.5029],
  [1248825600000, 0.5091],
  [1248912000000, 0.5117],
  [1248998400000, 0.5074],
  [1249257600000, 0.4993],
  [1249344000000, 0.4953],
  [1249430400000, 0.4941],
  [1249516800000, 0.496],
  [1249603200000, 0.4966],
  [1249862400000, 0.5042],
  [1249948800000, 0.506],
  [1250035200000, 0.5058],
  [1250121600000, 0.4997],
  [1250208000000, 0.4997],
  [1250467200000, 0.5107],
  [1250553600000, 0.5093],
  [1250640000000, 0.5087],
  [1250726400000, 0.5022],
  [1250812800000, 0.4979],
  [1251072000000, 0.4983],
  [1251158400000, 0.4982],
  [1251244800000, 0.5009],
  [1251331200000, 0.501],
  [1251417600000, 0.4963],
  [1251676800000, 0.5008],
  [1251763200000, 0.4987],
  [1251849600000, 0.5033],
  [1251936000000, 0.4977],
  [1252022400000, 0.5013],
  [1252281600000, 0.4979],
  [1252368000000, 0.491],
  [1252454400000, 0.4887],
  [1252540800000, 0.4876],
  [1252627200000, 0.4853],
  [1252886400000, 0.4869],
  [1252972800000, 0.4845],
  [1253059200000, 0.4817],
  [1253145600000, 0.4798],
  [1253232000000, 0.4801],
  [1253491200000, 0.4823],
  [1253577600000, 0.4767],
  [1253664000000, 0.4766],
  [1253750400000, 0.4772],
  [1253836800000, 0.4818],
  [1254096000000, 0.4827],
  [1254182400000, 0.4874],
  [1254268800000, 0.483],
  [1254355200000, 0.4879],
  [1254441600000, 0.488],
  [1254700800000, 0.4843],
  [1254787200000, 0.4794],
  [1254873600000, 0.4806],
  [1254960000000, 0.4775],
  [1255046400000, 0.4781],
  [1255305600000, 0.4774],
  [1255392000000, 0.4729],
  [1255478400000, 0.4721],
  [1255564800000, 0.4729],
  [1255651200000, 0.4726],
  [1255910400000, 0.4704],
  [1255996800000, 0.4681],
  [1256083200000, 0.4703],
  [1256169600000, 0.4668],
  [1256256000000, 0.4659],
  [1256515200000, 0.4659],
  [1256601600000, 0.4724],
  [1256688000000, 0.4765],
  [1256774400000, 0.4763],
  [1256860800000, 0.4758],
  [1257120000000, 0.4771],
  [1257206400000, 0.4823],
  [1257292800000, 0.4776],
  [1257379200000, 0.4727],
  [1257465600000, 0.473],
  [1257724800000, 0.4675],
  [1257811200000, 0.4683],
  [1257897600000, 0.4651],
  [1257984000000, 0.4703],
  [1258070400000, 0.4727],
  [1258329600000, 0.4683],
  [1258416000000, 0.4724],
  [1258502400000, 0.4687],
  [1258588800000, 0.4729],
  [1258675200000, 0.4751],
  [1258934400000, 0.4682],
  [1259020800000, 0.4681],
  [1259107200000, 0.4631],
  [1259193600000, 0.4636],
  [1259280000000, 0.4704],
  [1259539200000, 0.4657],
  [1259625600000, 0.4635],
  [1259712000000, 0.4628],
  [1259798400000, 0.4615],
  [1259884800000, 0.4638],
  [1260144000000, 0.4764],
  [1260230400000, 0.477],
  [1260316800000, 0.4772],
  [1260403200000, 0.479],
  [1260489600000, 0.4777],
  [1260748800000, 0.4828],
  [1260835200000, 0.4878],
  [1260921600000, 0.4869],
  [1261008000000, 0.4973],
  [1261094400000, 0.4976],
  [1261353600000, 0.4961],
  [1261440000000, 0.5004],
  [1261526400000, 0.5006],
  [1261612800000, 0.4946],
  [1261699200000, 0.4946],
  [1261958400000, 0.4943],
  [1262044800000, 0.493],
  [1262131200000, 0.4975],
  [1262217600000, 0.4943],
  [1262304000000, 0.4943],
  [1262563200000, 0.4951],
  [1262649600000, 0.4925],
  [1262736000000, 0.497],
  [1262822400000, 0.4992],
  [1262908800000, 0.5007],
  [1263168000000, 0.4884],
  [1263254400000, 0.4907],
  [1263340800000, 0.4868],
  [1263427200000, 0.4904],
  [1263513600000, 0.4958],
  [1263772800000, 0.496],
  [1263859200000, 0.5004],
  [1263945600000, 0.5077],
  [1264032000000, 0.5111],
  [1264118400000, 0.5076],
  [1264377600000, 0.5068],
  [1264464000000, 0.5101],
  [1264550400000, 0.5107],
  [1264636800000, 0.5144],
  [1264723200000, 0.5161],
  [1264982400000, 0.5189],
  [1265068800000, 0.5176],
  [1265155200000, 0.5152],
  [1265241600000, 0.5223],
  [1265328000000, 0.5305],
  [1265587200000, 0.5314],
  [1265673600000, 0.5268],
  [1265760000000, 0.5279],
  [1265846400000, 0.5291],
  [1265932800000, 0.5369],
  [1266192000000, 0.535],
  [1266278400000, 0.5328],
  [1266364800000, 0.5286],
  [1266451200000, 0.5372],
  [1266537600000, 0.5398],
  [1266796800000, 0.534],
  [1266883200000, 0.5366],
  [1266969600000, 0.5383],
  [1267056000000, 0.5414],
  [1267142400000, 0.537],
  [1267401600000, 0.5395],
  [1267488000000, 0.5382],
  [1267574400000, 0.5332],
  [1267660800000, 0.5317],
  [1267747200000, 0.5364],
  [1268006400000, 0.5321],
  [1268092800000, 0.5377],
  [1268179200000, 0.5349],
  [1268265600000, 0.5323],
  [1268352000000, 0.5266],
  [1268611200000, 0.5298],
  [1268697600000, 0.5288],
  [1268784000000, 0.5271],
  [1268870400000, 0.5322],
  [1268956800000, 0.5382],
  [1269216000000, 0.5424],
  [1269302400000, 0.5398],
  [1269388800000, 0.5498],
  [1269475200000, 0.5488],
  [1269561600000, 0.549],
  [1269820800000, 0.5424],
  [1269907200000, 0.5418],
  [1269993600000, 0.542],
  [1270080000000, 0.5426],
  [1270166400000, 0.5426],
  [1270425600000, 0.5426],
  [1270512000000, 0.5466],
  [1270598400000, 0.5497],
  [1270684800000, 0.5522],
  [1270771200000, 0.5473],
  [1271030400000, 0.5362],
  [1271116800000, 0.5363],
  [1271203200000, 0.5346],
  [1271289600000, 0.5384],
  [1271376000000, 0.5389],
  [1271635200000, 0.5446],
  [1271721600000, 0.5416],
  [1271808000000, 0.5479],
  [1271894400000, 0.5498],
  [1271980800000, 0.5514],
  [1272240000000, 0.5508],
  [1272326400000, 0.5525],
  [1272412800000, 0.5551],
  [1272499200000, 0.5545],
  [1272585600000, 0.5511],
  [1272844800000, 0.5555],
  [1272931200000, 0.5641],
  [1273017600000, 0.5739],
  [1273104000000, 0.5858],
  [1273190400000, 0.5847],
  [1273449600000, 0.5712],
  [1273536000000, 0.5876],
  [1273622400000, 0.5884],
  [1273708800000, 0.5946],
  [1273795200000, 0.6006],
  [1274054400000, 0.6099],
  [1274140800000, 0.6047],
  [1274227200000, 0.6151],
  [1274313600000, 0.6109],
  [1274400000000, 0.6003],
  [1274659200000, 0.6092],
  [1274745600000, 0.6182],
  [1274832000000, 0.6125],
  [1274918400000, 0.6161],
  [1275004800000, 0.6076],
  [1275264000000, 0.6126],
  [1275350400000, 0.6228],
  [1275436800000, 0.6186],
  [1275523200000, 0.6152],
  [1275609600000, 0.6293],
  [1275868800000, 0.6363],
  [1275955200000, 0.6375],
  [1276041600000, 0.6327],
  [1276128000000, 0.6303],
  [1276214400000, 0.6247],
  [1276473600000, 0.6165],
  [1276560000000, 0.6159],
  [1276646400000, 0.6146],
  [1276732800000, 0.609],
  [1276819200000, 0.6084],
  [1277078400000, 0.6071],
  [1277164800000, 0.6159],
  [1277251200000, 0.615],
  [1277337600000, 0.6156],
  [1277424000000, 0.6135],
  [1277683200000, 0.6105],
  [1277769600000, 0.6199],
  [1277856000000, 0.615],
  [1277942400000, 0.6113],
  [1278028800000, 0.597],
  [1278288000000, 0.5981],
  [1278374400000, 0.5951],
  [1278460800000, 0.5958],
  [1278547200000, 0.59],
  [1278633600000, 0.5914],
  [1278892800000, 0.5955],
  [1278979200000, 0.5957],
  [1279065600000, 0.5873],
  [1279152000000, 0.5796],
  [1279238400000, 0.5693],
  [1279497600000, 0.5719],
  [1279584000000, 0.5787],
  [1279670400000, 0.5803],
  [1279756800000, 0.5783],
  [1279843200000, 0.5755],
  [1280102400000, 0.5734],
  [1280188800000, 0.5674],
  [1280275200000, 0.5698],
  [1280361600000, 0.5653],
  [1280448000000, 0.5677],
  [1280707200000, 0.565],
  [1280793600000, 0.5565],
  [1280880000000, 0.5573],
  [1280966400000, 0.5586],
  [1281052800000, 0.5591],
  [1281312000000, 0.5546],
  [1281398400000, 0.5615],
  [1281484800000, 0.5684],
  [1281571200000, 0.582],
  [1281657600000, 0.5814],
  [1281916800000, 0.5801],
  [1282003200000, 0.5777],
  [1282089600000, 0.5765],
  [1282176000000, 0.5792],
  [1282262400000, 0.5868],
  [1282521600000, 0.5873],
  [1282608000000, 0.5931],
  [1282694400000, 0.5929],
  [1282780800000, 0.5879],
  [1282867200000, 0.5867],
  [1283126400000, 0.5875],
  [1283212800000, 0.5887],
  [1283299200000, 0.5814],
  [1283385600000, 0.5803],
  [1283472000000, 0.5793],
  [1283731200000, 0.5769],
  [1283817600000, 0.5848],
  [1283904000000, 0.5877],
  [1283990400000, 0.5866],
  [1284076800000, 0.586],
  [1284336000000, 0.5813],
  [1284422400000, 0.5783],
  [1284508800000, 0.57],
  [1284595200000, 0.5647],
  [1284681600000, 0.5658],
  [1284940800000, 0.565],
  [1285027200000, 0.5623],
  [1285113600000, 0.5484],
  [1285200000000, 0.5507],
  [1285286400000, 0.5457],
  [1285545600000, 0.5421],
  [1285632000000, 0.543],
  [1285718400000, 0.5348],
  [1285804800000, 0.5328],
  [1285891200000, 0.5286],
  [1286150400000, 0.5298],
  [1286236800000, 0.5258],
  [1286323200000, 0.5218],
  [1286409600000, 0.5159],
  [1286496000000, 0.5209],
  [1286755200000, 0.5177],
  [1286841600000, 0.523],
  [1286928000000, 0.5165],
  [1287014400000, 0.5093],
  [1287100800000, 0.5099],
  [1287360000000, 0.5197],
  [1287446400000, 0.5217],
  [1287532800000, 0.5215],
  [1287619200000, 0.5136],
  [1287705600000, 0.5178],
  [1287964800000, 0.5128],
  [1288051200000, 0.5189],
  [1288137600000, 0.5246],
  [1288224000000, 0.5218],
  [1288310400000, 0.5218],
  [1288569600000, 0.5182],
  [1288656000000, 0.5135],
  [1288742400000, 0.5137],
  [1288828800000, 0.5021],
  [1288915200000, 0.5101],
  [1289174400000, 0.5186],
  [1289260800000, 0.5172],
  [1289347200000, 0.5263],
  [1289433600000, 0.53],
  [1289520000000, 0.5294],
  [1289779200000, 0.534],
  [1289865600000, 0.5347],
  [1289952000000, 0.5419],
  [1290038400000, 0.5329],
  [1290124800000, 0.5314],
  [1290384000000, 0.5329],
  [1290470400000, 0.5411],
  [1290556800000, 0.5498],
  [1290643200000, 0.5508],
  [1290729600000, 0.5562],
  [1290988800000, 0.5608],
  [1291075200000, 0.5694],
  [1291161600000, 0.5626],
  [1291248000000, 0.5603],
  [1291334400000, 0.555],
  [1291593600000, 0.5531],
  [1291680000000, 0.5484],
  [1291766400000, 0.5577],
  [1291852800000, 0.5569],
  [1291939200000, 0.5552],
  [1292198400000, 0.5538],
  [1292284800000, 0.5444],
  [1292371200000, 0.5486],
  [1292457600000, 0.5555],
  [1292544000000, 0.5542],
  [1292803200000, 0.5607],
  [1292889600000, 0.5603],
  [1292976000000, 0.5628],
  [1293062400000, 0.5656],
  [1293148800000, 0.5635],
  [1293408000000, 0.5614],
  [1293494400000, 0.558],
  [1293580800000, 0.5614],
  [1293667200000, 0.5531],
  [1293753600000, 0.5485],
  [1294012800000, 0.5493],
  [1294099200000, 0.5452],
  [1294185600000, 0.5569],
  [1294272000000, 0.564],
  [1294358400000, 0.5716],
  [1294617600000, 0.5751],
  [1294704000000, 0.5724],
  [1294790400000, 0.5709],
  [1294876800000, 0.5577],
  [1294963200000, 0.5492],
  [1295222400000, 0.5514],
  [1295308800000, 0.548],
  [1295395200000, 0.5405],
  [1295481600000, 0.5424],
  [1295568000000, 0.5397],
  [1295827200000, 0.537],
  [1295913600000, 0.5356],
  [1296000000000, 0.531],
  [1296086400000, 0.5292],
  [1296172800000, 0.5295],
  [1296432000000, 0.5305],
  [1296518400000, 0.5271],
  [1296604800000, 0.5246],
  [1296691200000, 0.5276],
  [1296777600000, 0.5337],
  [1297036800000, 0.5379],
  [1297123200000, 0.5335],
  [1297209600000, 0.5329],
  [1297296000000, 0.5352],
  [1297382400000, 0.5395],
  [1297641600000, 0.5441],
  [1297728000000, 0.5403],
  [1297814400000, 0.5403],
  [1297900800000, 0.5376],
  [1297987200000, 0.5339],
  [1298246400000, 0.5317],
  [1298332800000, 0.5318],
  [1298419200000, 0.5284],
  [1298505600000, 0.5262],
  [1298592000000, 0.5267],
  [1298851200000, 0.523],
  [1298937600000, 0.5234],
  [1299024000000, 0.5243],
  [1299110400000, 0.5221],
  [1299196800000, 0.5166],
  [1299456000000, 0.513],
  [1299542400000, 0.5196],
  [1299628800000, 0.5181],
  [1299715200000, 0.5238],
  [1299801600000, 0.5262],
  [1300060800000, 0.517],
  [1300147200000, 0.5204],
  [1300233600000, 0.5169],
  [1300320000000, 0.5142],
  [1300406400000, 0.5078],
  [1300665600000, 0.5046],
  [1300752000000, 0.5038],
  [1300838400000, 0.5075],
  [1300924800000, 0.5079],
  [1301011200000, 0.5086],
  [1301270400000, 0.5128],
  [1301356800000, 0.511],
  [1301443200000, 0.5098],
  [1301529600000, 0.504],
  [1301616000000, 0.5073],
  [1301875200000, 0.5023],
  [1301961600000, 0.506],
  [1302048000000, 0.4994],
  [1302134400000, 0.5002],
  [1302220800000, 0.4945],
  [1302480000000, 0.4929],
  [1302566400000, 0.4912],
  [1302652800000, 0.4901],
  [1302739200000, 0.4945],
  [1302825600000, 0.4921],
  [1303084800000, 0.5006],
  [1303171200000, 0.4993],
  [1303257600000, 0.489],
  [1303344000000, 0.4858],
  [1303430400000, 0.4858],
  [1303689600000, 0.4858],
  [1303776000000, 0.4842],
  [1303862400000, 0.4819],
  [1303948800000, 0.476],
  [1304035200000, 0.473],
  [1304294400000, 0.4741],
  [1304380800000, 0.4767],
  [1304467200000, 0.4721],
  [1304553600000, 0.4751],
  [1304640000000, 0.4897],
  [1304899200000, 0.4947],
  [1304985600000, 0.4966],
  [1305072000000, 0.4966],
  [1305158400000, 0.5067],
  [1305244800000, 0.5004],
  [1305504000000, 0.5072],
  [1305590400000, 0.5058],
  [1305676800000, 0.503],
  [1305763200000, 0.5011],
  [1305849600000, 0.5025],
  [1306108800000, 0.5134],
  [1306195200000, 0.5099],
  [1306281600000, 0.5109],
  [1306368000000, 0.5059],
  [1306454400000, 0.5011],
  [1306713600000, 0.5008],
  [1306800000000, 0.4953],
  [1306886400000, 0.4942],
  [1306972800000, 0.4917],
  [1307059200000, 0.4903],
  [1307318400000, 0.4852],
  [1307404800000, 0.4826],
  [1307491200000, 0.4847],
  [1307577600000, 0.4844],
  [1307664000000, 0.4904],
  [1307923200000, 0.4968],
  [1308009600000, 0.4922],
  [1308096000000, 0.4998],
  [1308182400000, 0.5099],
  [1308268800000, 0.5009],
  [1308528000000, 0.5026],
  [1308614400000, 0.4958],
  [1308700800000, 0.4947],
  [1308787200000, 0.5037],
  [1308873600000, 0.5033],
  [1309132800000, 0.5041],
  [1309219200000, 0.5013],
  [1309305600000, 0.4933],
  [1309392000000, 0.492],
  [1309478400000, 0.4903],
  [1309737600000, 0.4898],
  [1309824000000, 0.4916],
  [1309910400000, 0.4985],
  [1309996800000, 0.502],
  [1310083200000, 0.5022],
  [1310342400000, 0.5115],
  [1310428800000, 0.5157],
  [1310515200000, 0.5107],
  [1310601600000, 0.5042],
  [1310688000000, 0.507],
  [1310947200000, 0.5121],
  [1311033600000, 0.5063],
  [1311120000000, 0.504],
  [1311206400000, 0.5032],
  [1311292800000, 0.495],
  [1311552000000, 0.4955],
  [1311638400000, 0.4911],
  [1311724800000, 0.4923],
  [1311811200000, 0.5014],
  [1311897600000, 0.5014],
  [1312156800000, 0.4938],
  [1312243200000, 0.5058],
  [1312329600000, 0.4994],
  [1312416000000, 0.5029],
  [1312502400000, 0.5066],
  [1312761600000, 0.5031],
  [1312848000000, 0.501],
  [1312934400000, 0.4961],
  [1313020800000, 0.5072],
  [1313107200000, 0.5019],
  [1313366400000, 0.499],
  [1313452800000, 0.4965],
  [1313539200000, 0.4909],
  [1313625600000, 0.496],
  [1313712000000, 0.4953],
  [1313971200000, 0.4939],
  [1314057600000, 0.4916],
  [1314144000000, 0.493],
  [1314230400000, 0.4934],
  [1314316800000, 0.4944],
  [1314576000000, 0.4904],
  [1314662400000, 0.4944],
  [1314748800000, 0.4921],
  [1314835200000, 0.5001],
  [1314921600000, 0.5016],
  [1315180800000, 0.508],
  [1315267200000, 0.5094],
  [1315353600000, 0.5126],
  [1315440000000, 0.5121],
  [1315526400000, 0.5238],
  [1315785600000, 0.5324],
  [1315872000000, 0.533],
  [1315958400000, 0.5285],
  [1316044800000, 0.525],
  [1316131200000, 0.5268],
  [1316390400000, 0.5332],
  [1316476800000, 0.5295],
  [1316563200000, 0.5335],
  [1316649600000, 0.5437],
  [1316736000000, 0.5447],
  [1316995200000, 0.5408],
  [1317081600000, 0.5365],
  [1317168000000, 0.5337],
  [1317254400000, 0.5346],
  [1317340800000, 0.5407],
  [1317600000000, 0.5505],
  [1317686400000, 0.5588],
  [1317772800000, 0.5499],
  [1317859200000, 0.5537],
  [1317945600000, 0.5445],
  [1318204800000, 0.5358],
  [1318291200000, 0.535],
  [1318377600000, 0.5265],
  [1318464000000, 0.5286],
  [1318550400000, 0.5244],
  [1318809600000, 0.526],
  [1318896000000, 0.5313],
  [1318982400000, 0.5233],
  [1319068800000, 0.5244],
  [1319155200000, 0.5248],
  [1319414400000, 0.5218],
  [1319500800000, 0.5186],
  [1319587200000, 0.5181],
  [1319673600000, 0.5125],
  [1319760000000, 0.5063],
  [1320019200000, 0.5143],
  [1320105600000, 0.5339],
  [1320192000000, 0.5243],
  [1320278400000, 0.5262],
  [1320364800000, 0.5262],
  [1320624000000, 0.5278],
  [1320710400000, 0.5254],
  [1320796800000, 0.5336],
  [1320883200000, 0.5345],
  [1320969600000, 0.5327],
  [1321228800000, 0.5322],
  [1321315200000, 0.5391],
  [1321401600000, 0.5417],
  [1321488000000, 0.5419],
  [1321574400000, 0.5367],
  [1321833600000, 0.5432],
  [1321920000000, 0.5389],
  [1322006400000, 0.5471],
  [1322092800000, 0.5479],
  [1322179200000, 0.556],
  [1322438400000, 0.5493],
  [1322524800000, 0.55],
  [1322611200000, 0.5454],
  [1322697600000, 0.5413],
  [1322784000000, 0.5402],
  [1323043200000, 0.544],
  [1323129600000, 0.5467],
  [1323216000000, 0.5477],
  [1323302400000, 0.5458],
  [1323388800000, 0.5473],
  [1323648000000, 0.5548],
  [1323734400000, 0.5588],
  [1323820800000, 0.5697],
  [1323907200000, 0.5682],
  [1323993600000, 0.5656],
  [1324252800000, 0.567],
  [1324339200000, 0.565],
  [1324425600000, 0.5661],
  [1324512000000, 0.5666],
  [1324598400000, 0.566],
  [1324857600000, 0.566],
  [1324944000000, 0.5653],
  [1325030400000, 0.565],
  [1325116800000, 0.576],
  [1325203200000, 0.573],
  [1325462400000, 0.5732],
  [1325548800000, 0.5685],
  [1325635200000, 0.5724],
  [1325721600000, 0.5794],
  [1325808000000, 0.5828],
  [1326067200000, 0.5858],
  [1326153600000, 0.5809],
  [1326240000000, 0.5864],
  [1326326400000, 0.5853],
  [1326412800000, 0.5831],
  [1326672000000, 0.5894],
  [1326758400000, 0.582],
  [1326844800000, 0.5795],
  [1326931200000, 0.5746],
  [1327017600000, 0.5752],
  [1327276800000, 0.5683],
  [1327363200000, 0.5692],
  [1327449600000, 0.5728],
  [1327536000000, 0.5608],
  [1327622400000, 0.5608],
  [1327881600000, 0.5629],
  [1327968000000, 0.5591],
  [1328054400000, 0.5591],
  [1328140800000, 0.5638],
  [1328227200000, 0.56],
  [1328486400000, 0.5669],
  [1328572800000, 0.5627],
  [1328659200000, 0.5535],
  [1328745600000, 0.5527],
  [1328832000000, 0.5583],
  [1329091200000, 0.5546],
  [1329177600000, 0.5595],
  [1329264000000, 0.5639],
  [1329350400000, 0.5704],
  [1329436800000, 0.56],
  [1329696000000, 0.5539],
  [1329782400000, 0.5564],
  [1329868800000, 0.556],
  [1329955200000, 0.552],
  [1330041600000, 0.5457],
  [1330300800000, 0.547],
  [1330387200000, 0.5434],
  [1330473600000, 0.544],
  [1330560000000, 0.5513],
  [1330646400000, 0.5567],
  [1330905600000, 0.5565],
  [1330992000000, 0.5604],
  [1331078400000, 0.5623],
  [1331164800000, 0.5553],
  [1331251200000, 0.5582],
  [1331510400000, 0.5624],
  [1331596800000, 0.566],
  [1331683200000, 0.5657],
  [1331769600000, 0.566],
  [1331856000000, 0.5625],
  [1332115200000, 0.5606],
  [1332201600000, 0.5578],
  [1332288000000, 0.5562],
  [1332374400000, 0.5596],
  [1332460800000, 0.5553],
  [1332720000000, 0.5533],
  [1332806400000, 0.5501],
  [1332892800000, 0.5499],
  [1332979200000, 0.5536],
  [1333065600000, 0.5488],
  [1333324800000, 0.5509],
  [1333411200000, 0.5511],
  [1333497600000, 0.561],
  [1333584000000, 0.5653],
  [1333670400000, 0.5653],
  [1333929600000, 0.5653],
  [1334016000000, 0.5626],
  [1334102400000, 0.5617],
  [1334188800000, 0.5604],
  [1334275200000, 0.5607],
  [1334534400000, 0.5679],
  [1334620800000, 0.5616],
  [1334707200000, 0.5639],
  [1334793600000, 0.5643],
  [1334880000000, 0.5581],
  [1335139200000, 0.5617],
  [1335225600000, 0.5599],
  [1335312000000, 0.5573],
  [1335398400000, 0.5568],
  [1335484800000, 0.556],
  [1335744000000, 0.5569],
  [1335830400000, 0.5569],
  [1335916800000, 0.5617],
  [1336003200000, 0.5621],
  [1336089600000, 0.5616],
  [1336348800000, 0.5674],
  [1336435200000, 0.5679],
  [1336521600000, 0.5723],
  [1336608000000, 0.5716],
  [1336694400000, 0.5727],
  [1336953600000, 0.5775],
  [1337040000000, 0.5787],
  [1337126400000, 0.5852],
  [1337212800000, 0.5886],
  [1337299200000, 0.5862],
  [1337558400000, 0.5844],
  [1337644800000, 0.5833],
  [1337731200000, 0.5901],
  [1337817600000, 0.5965],
  [1337904000000, 0.5972],
  [1338163200000, 0.5959],
  [1338249600000, 0.5986],
  [1338336000000, 0.6041],
  [1338422400000, 0.6064],
  [1338508800000, 0.6117],
  [1338768000000, 0.6042],
  [1338854400000, 0.6047],
  [1338940800000, 0.6011],
  [1339027200000, 0.5941],
  [1339113600000, 0.6022],
  [1339372800000, 0.5973],
  [1339459200000, 0.6006],
  [1339545600000, 0.5979],
  [1339632000000, 0.5968],
  [1339718400000, 0.594],
  [1339977600000, 0.5926],
  [1340064000000, 0.5926],
  [1340150400000, 0.5873],
  [1340236800000, 0.5894],
  [1340323200000, 0.5976],
  [1340582400000, 0.6009],
  [1340668800000, 0.6017],
  [1340755200000, 0.6015],
  [1340841600000, 0.6054],
  [1340928000000, 0.5944],
  [1341187200000, 0.5942],
  [1341273600000, 0.5953],
  [1341360000000, 0.5963],
  [1341446400000, 0.6049],
  [1341532800000, 0.6081],
  [1341792000000, 0.6136],
  [1341878400000, 0.6141],
  [1341964800000, 0.6158],
  [1342051200000, 0.6213],
  [1342137600000, 0.6208],
  [1342396800000, 0.6213],
  [1342483200000, 0.6144],
  [1342569600000, 0.6175],
  [1342656000000, 0.614],
  [1342742400000, 0.6198],
  [1343001600000, 0.6262],
  [1343088000000, 0.6273],
  [1343174400000, 0.6242],
  [1343260800000, 0.6158],
  [1343347200000, 0.612],
  [1343606400000, 0.6167],
  [1343692800000, 0.6142],
  [1343779200000, 0.6132],
  [1343865600000, 0.6101],
  [1343952000000, 0.6168],
  [1344211200000, 0.6079],
  [1344297600000, 0.6042],
  [1344384000000, 0.6107],
  [1344470400000, 0.613],
  [1344556800000, 0.6156],
  [1344816000000, 0.6105],
  [1344902400000, 0.6097],
  [1344988800000, 0.6147],
  [1345075200000, 0.6145],
  [1345161600000, 0.6107],
  [1345420800000, 0.6131],
  [1345507200000, 0.6047],
  [1345593600000, 0.6034],
  [1345680000000, 0.5968],
  [1345766400000, 0.5997],
  [1346025600000, 0.5982],
  [1346112000000, 0.597],
  [1346198400000, 0.5972],
  [1346284800000, 0.5973],
  [1346371200000, 0.5931],
  [1346630400000, 0.5958],
  [1346716800000, 0.5951],
  [1346803200000, 0.5951],
  [1346889600000, 0.5914],
  [1346976000000, 0.5871],
  [1347235200000, 0.5828],
  [1347321600000, 0.5821],
  [1347408000000, 0.5755],
  [1347494400000, 0.5747],
  [1347580800000, 0.5638],
  [1347840000000, 0.5643],
  [1347926400000, 0.5661],
  [1348012800000, 0.5692],
  [1348099200000, 0.5721],
  [1348185600000, 0.57],
  [1348444800000, 0.5743],
  [1348531200000, 0.5734],
  [1348617600000, 0.5786],
  [1348704000000, 0.5769],
  [1348790400000, 0.5735],
  [1349049600000, 0.5767],
  [1349136000000, 0.5735],
  [1349222400000, 0.5751],
  [1349308800000, 0.5722],
  [1349395200000, 0.5692],
  [1349654400000, 0.5718],
  [1349740800000, 0.5721],
  [1349827200000, 0.576],
  [1349913600000, 0.5742],
  [1350000000000, 0.5711],
  [1350259200000, 0.5711],
  [1350345600000, 0.5666],
  [1350432000000, 0.5623],
  [1350518400000, 0.5624],
  [1350604800000, 0.5673],
  [1350864000000, 0.5656],
  [1350950400000, 0.569],
  [1351036800000, 0.5728],
  [1351123200000, 0.5697],
  [1351209600000, 0.5748],
  [1351468800000, 0.5754],
  [1351555200000, 0.5716],
  [1351641600000, 0.5697],
  [1351728000000, 0.5708],
  [1351814400000, 0.5783],
  [1352073600000, 0.5828],
  [1352160000000, 0.5814],
  [1352246400000, 0.5847],
  [1352332800000, 0.5853],
  [1352419200000, 0.5879],
  [1352678400000, 0.5853],
  [1352764800000, 0.5877],
  [1352851200000, 0.5859],
  [1352937600000, 0.584],
  [1353024000000, 0.5847],
  [1353283200000, 0.5837],
  [1353369600000, 0.5808],
  [1353456000000, 0.581],
  [1353542400000, 0.5757],
  [1353628800000, 0.5748],
  [1353888000000, 0.5715],
  [1353974400000, 0.5716],
  [1354060800000, 0.5758],
  [1354147200000, 0.5697],
  [1354233600000, 0.5702],
  [1354492800000, 0.566],
  [1354579200000, 0.5639],
  [1354665600000, 0.5655],
  [1354752000000, 0.5651],
  [1354838400000, 0.575],
  [1355097600000, 0.5735],
  [1355184000000, 0.5697],
  [1355270400000, 0.567],
  [1355356800000, 0.5648],
  [1355443200000, 0.5646],
  [1355702400000, 0.56],
  [1355788800000, 0.5589],
  [1355875200000, 0.5519],
  [1355961600000, 0.555],
  [1356048000000, 0.5572],
  [1356307200000, 0.5566],
  [1356566400000, 0.5539],
  [1356652800000, 0.5587],
  [1356912000000, 0.558],
  [1357084800000, 0.5541],
  [1357171200000, 0.5633],
  [1357257600000, 0.5686],
  [1357516800000, 0.567],
  [1357603200000, 0.5643],
  [1357689600000, 0.566],
  [1357776000000, 0.5627],
  [1357862400000, 0.5535],
  [1358121600000, 0.5497],
  [1358208000000, 0.5505],
  [1358294400000, 0.5533],
  [1358380800000, 0.5482],
  [1358467200000, 0.5506],
  [1358726400000, 0.5507],
  [1358812800000, 0.551],
  [1358899200000, 0.5503],
  [1358985600000, 0.5492],
  [1359072000000, 0.5425],
  [1359331200000, 0.5439],
  [1359417600000, 0.5445],
  [1359504000000, 0.5386],
  [1359590400000, 0.5381],
  [1359676800000, 0.533],
  [1359936000000, 0.538],
  [1360022400000, 0.5388],
  [1360108800000, 0.5399],
  [1360195200000, 0.5383],
  [1360281600000, 0.5478],
  [1360540800000, 0.5469],
  [1360627200000, 0.5443],
  [1360713600000, 0.5419],
  [1360800000000, 0.5505],
  [1360886400000, 0.5506],
  [1361145600000, 0.5491],
  [1361232000000, 0.5492],
  [1361318400000, 0.548],
  [1361404800000, 0.5585],
  [1361491200000, 0.5585],
  [1361750400000, 0.5518],
  [1361836800000, 0.5648],
  [1361923200000, 0.5636],
  [1362009600000, 0.5618],
  [1362096000000, 0.5693],
  [1362355200000, 0.5689],
  [1362441600000, 0.5673],
  [1362528000000, 0.5673],
  [1362614400000, 0.5687],
  [1362700800000, 0.564],
  [1362960000000, 0.5697],
  [1363046400000, 0.5662],
  [1363132800000, 0.5705],
  [1363219200000, 0.5731],
  [1363305600000, 0.5643],
  [1363564800000, 0.5736],
  [1363651200000, 0.5727],
  [1363737600000, 0.5726],
  [1363824000000, 0.5747],
  [1363910400000, 0.5724],
  [1364169600000, 0.5732],
  [1364256000000, 0.5776],
  [1364342400000, 0.5833],
  [1364428800000, 0.581],
  [1364860800000, 0.5789],
  [1364947200000, 0.5796],
  [1365033600000, 0.5803],
  [1365120000000, 0.5727],
  [1365379200000, 0.568],
  [1365465600000, 0.567],
  [1365552000000, 0.5643],
  [1365638400000, 0.5624],
  [1365724800000, 0.5663],
  [1365984000000, 0.5646],
  [1366070400000, 0.5618],
  [1366156800000, 0.5618],
  [1366243200000, 0.5667],
  [1366329600000, 0.5626],
  [1366588800000, 0.5671],
  [1366675200000, 0.5699],
  [1366761600000, 0.569],
  [1366848000000, 0.5646],
  [1366934400000, 0.5694],
  [1367193600000, 0.5627],
  [1367280000000, 0.5651],
  [1367452800000, 0.5582],
  [1367539200000, 0.5626],
  [1367798400000, 0.5631],
  [1367884800000, 0.5631],
  [1367971200000, 0.5614],
  [1368057600000, 0.561],
  [1368144000000, 0.57],
  [1368403200000, 0.5709],
  [1368489600000, 0.5707],
  [1368576000000, 0.5775],
  [1368662400000, 0.5759],
  [1368748800000, 0.5772],
  [1369008000000, 0.5781],
  [1369094400000, 0.5773],
  [1369180800000, 0.5739],
  [1369267200000, 0.576],
  [1369353600000, 0.573],
  [1369612800000, 0.573],
  [1369699200000, 0.573],
  [1369785600000, 0.5722],
  [1369872000000, 0.5727],
  [1369958400000, 0.569],
  [1370217600000, 0.5689],
  [1370304000000, 0.5639],
  [1370390400000, 0.5654],
  [1370476800000, 0.5624],
  [1370563200000, 0.5542],
  [1370822400000, 0.5572],
  [1370908800000, 0.5535],
  [1370995200000, 0.5533],
  [1371081600000, 0.5511],
  [1371168000000, 0.5518],
  [1371427200000, 0.5499],
  [1371513600000, 0.5478],
  [1371600000000, 0.546],
  [1371686400000, 0.5577],
  [1371772800000, 0.5588],
  [1372032000000, 0.5643],
  [1372118400000, 0.5615],
  [1372204800000, 0.5679],
  [1372291200000, 0.5674],
  [1372377600000, 0.5646],
  [1372636800000, 0.5671],
  [1372723200000, 0.5683],
  [1372809600000, 0.5718],
  [1372896000000, 0.5703],
  [1372982400000, 0.5763],
  [1373241600000, 0.5783],
  [1373328000000, 0.5779],
  [1373414400000, 0.5806],
  [1373500800000, 0.5667],
  [1373587200000, 0.5673],
  [1373846400000, 0.5686],
  [1373932800000, 0.5624],
  [1374019200000, 0.5614],
  [1374105600000, 0.5639],
  [1374192000000, 0.5621],
  [1374451200000, 0.5596],
  [1374537600000, 0.5588],
  [1374624000000, 0.555],
  [1374710400000, 0.5576],
  [1374796800000, 0.5542],
  [1375056000000, 0.5537],
  [1375142400000, 0.5529],
  [1375228800000, 0.5534],
  [1375315200000, 0.5556],
  [1375401600000, 0.5575],
  [1375660800000, 0.5544],
  [1375747200000, 0.5531],
  [1375833600000, 0.5517],
  [1375920000000, 0.5486],
  [1376006400000, 0.5479],
  [1376265600000, 0.5531],
  [1376352000000, 0.5525],
  [1376438400000, 0.5552],
  [1376524800000, 0.5521],
  [1376611200000, 0.5497],
  [1376870400000, 0.5495],
  [1376956800000, 0.5468],
  [1377043200000, 0.5473],
  [1377129600000, 0.5507],
  [1377216000000, 0.5489],
  [1377475200000, 0.5485],
  [1377561600000, 0.5498],
  [1377648000000, 0.5493],
  [1377734400000, 0.5539],
  [1377820800000, 0.5557],
  [1378080000000, 0.5573],
  [1378166400000, 0.5593],
  [1378252800000, 0.5593],
  [1378339200000, 0.5576],
  [1378425600000, 0.5625],
  [1378684800000, 0.558],
  [1378771200000, 0.5554],
  [1378857600000, 0.5538],
  [1378944000000, 0.5525],
  [1379030400000, 0.5523],
  [1379289600000, 0.5488],
  [1379376000000, 0.5488],
  [1379462400000, 0.5491],
  [1379548800000, 0.5384],
  [1379635200000, 0.5401],
  [1379894400000, 0.5404],
  [1379980800000, 0.5423],
  [1380067200000, 0.5406],
  [1380153600000, 0.5409],
  [1380240000000, 0.5388],
  [1380499200000, 0.5406],
  [1380585600000, 0.5379],
  [1380672000000, 0.54],
  [1380758400000, 0.5357],
  [1380844800000, 0.5358],
  [1381104000000, 0.5369],
  [1381190400000, 0.5367],
  [1381276800000, 0.54],
  [1381363200000, 0.5391],
  [1381449600000, 0.5372],
  [1381708800000, 0.5373],
  [1381795200000, 0.5412],
  [1381881600000, 0.5375],
  [1381968000000, 0.5321],
  [1382054400000, 0.5309],
  [1382313600000, 0.5318],
  [1382400000000, 0.5314],
  [1382486400000, 0.5273],
  [1382572800000, 0.5245],
  [1382659200000, 0.5259],
  [1382918400000, 0.5256],
  [1383004800000, 0.5264],
  [1383091200000, 0.5271],
  [1383177600000, 0.5332],
  [1383264000000, 0.5406],
  [1383523200000, 0.5405],
  [1383609600000, 0.5412],
  [1383696000000, 0.5399],
  [1383782400000, 0.5483],
  [1383868800000, 0.5446],
  [1384128000000, 0.5467],
  [1384214400000, 0.5446],
  [1384300800000, 0.5455],
  [1384387200000, 0.5444],
  [1384473600000, 0.543],
  [1384732800000, 0.5399],
  [1384819200000, 0.5407],
  [1384905600000, 0.5394],
  [1384992000000, 0.5424],
  [1385078400000, 0.5399],
  [1385337600000, 0.5401],
  [1385424000000, 0.5383],
  [1385510400000, 0.5356],
  [1385596800000, 0.5358],
  [1385683200000, 0.5348],
  [1385942400000, 0.5389],
  [1386028800000, 0.5366],
  [1386115200000, 0.5358],
  [1386201600000, 0.5357],
  [1386288000000, 0.5321],
  [1386547200000, 0.5289],
  [1386633600000, 0.5274],
  [1386720000000, 0.5265],
  [1386806400000, 0.5261],
  [1386892800000, 0.5286],
  [1387152000000, 0.526],
  [1387238400000, 0.5274],
  [1387324800000, 0.5274],
  [1387411200000, 0.5318],
  [1387497600000, 0.5324],
  [1387756800000, 0.5299],
  [1387843200000, 0.5309],
  [1388102400000, 0.524],
  [1388361600000, 0.5256],
  [1388448000000, 0.5252],
  [1388620800000, 0.5323],
  [1388707200000, 0.5336],
  [1388966400000, 0.5353],
  [1389052800000, 0.5332],
  [1389139200000, 0.5357],
  [1389225600000, 0.5347],
  [1389312000000, 0.5361],
  [1389571200000, 0.5325],
  [1389657600000, 0.5318],
  [1389744000000, 0.5351],
  [1389830400000, 0.5356],
  [1389916800000, 0.5363],
  [1390176000000, 0.5372],
  [1390262400000, 0.5394],
  [1390348800000, 0.5372],
  [1390435200000, 0.5333],
  [1390521600000, 0.5307],
  [1390780800000, 0.5323],
  [1390867200000, 0.5328],
  [1390953600000, 0.535],
  [1391040000000, 0.5368],
  [1391126400000, 0.54],
  [1391385600000, 0.541],
  [1391472000000, 0.5398],
  [1391558400000, 0.5385],
  [1391644800000, 0.5411],
  [1391731200000, 0.5368],
  [1391990400000, 0.5333],
  [1392076800000, 0.5313],
  [1392163200000, 0.5369],
  [1392249600000, 0.5314],
  [1392336000000, 0.5297],
  [1392595200000, 0.5301],
  [1392681600000, 0.5284],
  [1392768000000, 0.5276],
  [1392854400000, 0.5297],
  [1392940800000, 0.5297],
  [1393200000000, 0.5282],
  [1393286400000, 0.5272],
  [1393372800000, 0.5286],
  [1393459200000, 0.5324],
  [1393545600000, 0.5241],
  [1393804800000, 0.5264],
  [1393891200000, 0.5264],
  [1393977600000, 0.5283],
  [1394064000000, 0.5276],
  [1394150400000, 0.5198],
  [1394409600000, 0.5205],
  [1394496000000, 0.5221],
  [1394582400000, 0.5202],
  [1394668800000, 0.5174],
  [1394755200000, 0.5204],
  [1395014400000, 0.5192],
  [1395100800000, 0.5194],
  [1395187200000, 0.5189],
  [1395273600000, 0.5267],
  [1395360000000, 0.5258],
  [1395619200000, 0.5261],
  [1395705600000, 0.5253],
  [1395792000000, 0.5252],
  [1395878400000, 0.5269],
  [1395964800000, 0.5269],
  [1396224000000, 0.5254],
  [1396310400000, 0.5253],
  [1396396800000, 0.525],
  [1396483200000, 0.5263],
  [1396569600000, 0.53],
  [1396828800000, 0.5288],
  [1396915200000, 0.5261],
  [1397001600000, 0.5251],
  [1397088000000, 0.5212],
  [1397174400000, 0.521],
  [1397433600000, 0.5233],
  [1397520000000, 0.5246],
  [1397606400000, 0.5226],
  [1397692800000, 0.5219],
  [1398124800000, 0.5238],
  [1398211200000, 0.523],
  [1398297600000, 0.5237],
  [1398384000000, 0.5231],
  [1398643200000, 0.5215],
  [1398729600000, 0.5234],
  [1398816000000, 0.5221],
  [1398988800000, 0.5215],
  [1399248000000, 0.5209],
  [1399334400000, 0.5172],
  [1399420800000, 0.5181],
  [1399507200000, 0.5168],
  [1399593600000, 0.5257],
  [1399852800000, 0.5266],
  [1399939200000, 0.5299],
  [1400025600000, 0.529],
  [1400112000000, 0.5322],
  [1400198400000, 0.5302],
  [1400457600000, 0.5292],
  [1400544000000, 0.5299],
  [1400630400000, 0.5313],
  [1400716800000, 0.5317],
  [1400803200000, 0.5338],
  [1401062400000, 0.5335],
  [1401148800000, 0.5333],
  [1401235200000, 0.535],
  [1401321600000, 0.5347],
  [1401408000000, 0.535],
  [1401667200000, 0.5348],
  [1401753600000, 0.533],
  [1401840000000, 0.5339],
  [1401926400000, 0.5372],
  [1402012800000, 0.5331],
  [1402272000000, 0.535],
  [1402358400000, 0.5383],
  [1402444800000, 0.5383],
  [1402531200000, 0.5393],
  [1402617600000, 0.539],
  [1402876800000, 0.5391],
  [1402963200000, 0.5371],
  [1403049600000, 0.5374],
  [1403136000000, 0.5343],
  [1403222400000, 0.536],
  [1403481600000, 0.5356],
  [1403568000000, 0.5344],
  [1403654400000, 0.5346],
  [1403740800000, 0.5351],
  [1403827200000, 0.5343],
  [1404086400000, 0.5323],
  [1404172800000, 0.5307],
  [1404259200000, 0.5324],
  [1404345600000, 0.5329],
  [1404432000000, 0.536],
  [1404691200000, 0.5358],
  [1404777600000, 0.536],
  [1404864000000, 0.5352],
  [1404950400000, 0.5352],
  [1405036800000, 0.5357],
  [1405296000000, 0.5339],
  [1405382400000, 0.5347],
  [1405468800000, 0.5391],
  [1405555200000, 0.5395],
  [1405641600000, 0.5395],
  [1405900800000, 0.5399],
  [1405987200000, 0.5419],
  [1406073600000, 0.5428],
  [1406160000000, 0.5424],
  [1406246400000, 0.5441],
  [1406505600000, 0.5445],
  [1406592000000, 0.5448],
  [1406678400000, 0.5463],
  [1406764800000, 0.5475],
  [1406851200000, 0.5466],
  [1407110400000, 0.5451],
  [1407196800000, 0.5474],
  [1407283200000, 0.5494],
  [1407369600000, 0.5482],
  [1407456000000, 0.547],
  [1407715200000, 0.5471],
  [1407801600000, 0.5494],
  [1407888000000, 0.5486],
  [1407974400000, 0.5479],
  [1408060800000, 0.547],
  [1408320000000, 0.5473],
  [1408406400000, 0.5489],
  [1408492800000, 0.5529],
  [1408579200000, 0.5541],
  [1408665600000, 0.5538],
  [1408924800000, 0.5577],
  [1409011200000, 0.5581],
  [1409097600000, 0.559],
  [1409184000000, 0.5589],
  [1409270400000, 0.5584],
  [1409529600000, 0.5615],
  [1409616000000, 0.5626],
  [1409702400000, 0.5605],
  [1409788800000, 0.5684],
  [1409875200000, 0.5724],
  [1410134400000, 0.5725],
  [1410220800000, 0.5752],
  [1410307200000, 0.5736],
  [1410393600000, 0.5736],
  [1410480000000, 0.5734],
  [1410739200000, 0.5746],
  [1410825600000, 0.5724],
  [1410912000000, 0.5719],
  [1410998400000, 0.577],
  [1411084800000, 0.5782],
  [1411344000000, 0.5786],
  [1411430400000, 0.5758],
  [1411516800000, 0.5798],
  [1411603200000, 0.5868],
  [1411689600000, 0.5855],
  [1411948800000, 0.5874],
  [1412035200000, 0.5948],
  [1412121600000, 0.5936],
  [1412208000000, 0.5918],
  [1412294400000, 0.5927],
  [1412553600000, 0.596],
  [1412640000000, 0.5933],
  [1412726400000, 0.5909],
  [1412812800000, 0.5836],
  [1412899200000, 0.5914],
  [1413158400000, 0.5888],
  [1413244800000, 0.5909],
  [1413331200000, 0.5896],
  [1413417600000, 0.5845],
  [1413504000000, 0.5799],
  [1413763200000, 0.583],
  [1413849600000, 0.5837],
  [1413936000000, 0.5879],
  [1414022400000, 0.5894],
  [1414108800000, 0.5901],
  [1414368000000, 0.5888],
  [1414454400000, 0.5845],
  [1414540800000, 0.5852],
  [1414627200000, 0.5939],
  [1414713600000, 0.5986],
  [1414972800000, 0.6005],
  [1415059200000, 0.5992],
  [1415145600000, 0.6014],
  [1415232000000, 0.599],
  [1415318400000, 0.607],
  [1415577600000, 0.601],
  [1415664000000, 0.605],
  [1415750400000, 0.6022],
  [1415836800000, 0.6029],
  [1415923200000, 0.6042],
  [1416182400000, 0.6004],
  [1416268800000, 0.5992],
  [1416355200000, 0.5979],
  [1416441600000, 0.5976],
  [1416528000000, 0.6051],
  [1416787200000, 0.6059],
  [1416873600000, 0.605],
  [1416960000000, 0.6017],
  [1417046400000, 0.6014],
  [1417132800000, 0.6012],
  [1417392000000, 0.6021],
  [1417478400000, 0.605],
  [1417564800000, 0.6111],
  [1417651200000, 0.6124],
  [1417737600000, 0.609],
  [1417996800000, 0.6159],
  [1418083200000, 0.6086],
  [1418169600000, 0.6071],
  [1418256000000, 0.6047],
  [1418342400000, 0.6033],
  [1418601600000, 0.6049],
  [1418688000000, 0.5977],
  [1418774400000, 0.6034],
  [1418860800000, 0.6141],
  [1418947200000, 0.6145],
  [1419206400000, 0.6158],
  [1419292800000, 0.6189],
  [1419379200000, 0.6185],
  [1419811200000, 0.62],
  [1419897600000, 0.6225],
  [1419984000000, 0.6238],
  [1420156800000, 0.6305],
  [1420416000000, 0.6394],
  [1420502400000, 0.6394],
  [1420588800000, 0.6453],
  [1420675200000, 0.6499],
  [1420761600000, 0.6466],
  [1421020800000, 0.6473],
  [1421107200000, 0.6489],
  [1421193600000, 0.6494],
  [1421280000000, 0.6542],
  [1421366400000, 0.6631],
  [1421625600000, 0.6618],
  [1421712000000, 0.6637],
  [1421798400000, 0.6627],
  [1421884800000, 0.6608],
  [1421971200000, 0.6931],
  [1422230400000, 0.6895],
  [1422316800000, 0.6846],
  [1422403200000, 0.6816],
  [1422489600000, 0.6839],
  [1422576000000, 0.6847],
  [1422835200000, 0.6843],
  [1422921600000, 0.6791],
  [1423008000000, 0.6738],
  [1423094400000, 0.6765],
  [1423180800000, 0.6737],
  [1423440000000, 0.687],
  [1423526400000, 0.6853],
  [1423612800000, 0.684],
  [1423699200000, 0.6829],
  [1423785600000, 0.6788],
  [1424044800000, 0.6767],
  [1424131200000, 0.6761],
  [1424217600000, 0.6795],
  [1424304000000, 0.6783],
  [1424390400000, 0.6852],
  [1424649600000, 0.6852],
  [1424736000000, 0.6829],
  [1424822400000, 0.6815],
  [1424908800000, 0.6837],
  [1424995200000, 0.6898],
  [1425254400000, 0.6908],
  [1425340800000, 0.6955],
  [1425427200000, 0.6991],
  [1425513600000, 0.7035],
  [1425600000000, 0.7123],
  [1425859200000, 0.7209],
  [1425945600000, 0.7314],
  [1426032000000, 0.7455],
  [1426118400000, 0.7423],
  [1426204800000, 0.746],
  [1426464000000, 0.7473],
  [1426550400000, 0.7404],
  [1426636800000, 0.7442],
  [1426723200000, 0.7367],
  [1426809600000, 0.7281],
  [1427068800000, 0.7165],
  [1427155200000, 0.7133],
  [1427241600000, 0.7104],
  [1427328000000, 0.7114],
  [1427414400000, 0.7212],
  [1427673600000, 0.7222],
  [1427760000000, 0.7296],
  [1427846400000, 0.7299],
  [1427932800000, 0.7235],
  [1428364800000, 0.722],
  [1428451200000, 0.7207],
  [1428537600000, 0.7283],
  [1428624000000, 0.7462],
  [1428883200000, 0.7478],
  [1428969600000, 0.7467],
  [1429056000000, 0.7454],
  [1429142400000, 0.7337],
  [1429228800000, 0.7248],
  [1429488000000, 0.7327],
  [1429574400000, 0.7347],
  [1429660800000, 0.7309],
  [1429747200000, 0.7284],
  [1429833600000, 0.724],
  [1430092800000, 0.7241],
  [1430179200000, 0.7153],
  [1430265600000, 0.709],
  [1430352000000, 0.6918],
  [1430697600000, 0.6968],
  [1430784000000, 0.6996],
  [1430870400000, 0.6906],
  [1430956800000, 0.6847],
  [1431043200000, 0.6913],
  [1431302400000, 0.6976],
  [1431388800000, 0.6899],
  [1431475200000, 0.6913],
  [1431561600000, 0.6758],
  [1431648000000, 0.6829],
  [1431907200000, 0.6781],
  [1431993600000, 0.6946],
  [1432080000000, 0.6995],
  [1432166400000, 0.6983],
  [1432252800000, 0.6958],
  [1432512000000, 0.711],
  [1432598400000, 0.7153],
  [1432684800000, 0.7207],
  [1432771200000, 0.7179],
  [1432857600000, 0.7117],
  [1433116800000, 0.7138],
  [1433203200000, 0.7068],
  [1433289600000, 0.6982],
  [1433376000000, 0.6837],
  [1433462400000, 0.6915],
  [1433721600000, 0.696],
  [1433808000000, 0.6891],
  [1433894400000, 0.6867],
  [1433980800000, 0.6904],
  [1434067200000, 0.6914],
  [1434326400000, 0.6915],
  [1434412800000, 0.6918],
  [1434499200000, 0.6867],
  [1434585600000, 0.677],
  [1434672000000, 0.6851],
  [1434931200000, 0.6815],
  [1435017600000, 0.6926],
  [1435104000000, 0.6919],
  [1435190400000, 0.6925],
  [1435276800000, 0.6928],
  [1435536000000, 0.6983],
  [1435622400000, 0.6938],
  [1435708800000, 0.701],
  [1435795200000, 0.7038],
  [1435881600000, 0.7013],
  [1436140800000, 0.7085],
  [1436227200000, 0.7149],
  [1436313600000, 0.7072],
  [1436400000000, 0.7047],
  [1436486400000, 0.6942],
  [1436745600000, 0.7052],
  [1436832000000, 0.7066],
  [1436918400000, 0.7084],
  [1437004800000, 0.7203],
  [1437091200000, 0.7185],
  [1437350400000, 0.7216],
  [1437436800000, 0.7203],
  [1437523200000, 0.7174],
  [1437609600000, 0.7093],
  [1437696000000, 0.7143],
  [1437955200000, 0.7044],
  [1438041600000, 0.7071],
  [1438128000000, 0.7067],
  [1438214400000, 0.7129],
  [1438300800000, 0.7119],
  [1438560000000, 0.7133],
  [1438646400000, 0.7114],
  [1438732800000, 0.719],
  [1438819200000, 0.7188],
  [1438905600000, 0.7141],
  [1439164800000, 0.7125],
  [1439251200000, 0.7047],
  [1439337600000, 0.6966],
  [1439424000000, 0.7003],
  [1439510400000, 0.6953],
  [1439769600000, 0.701],
  [1439856000000, 0.7043],
  [1439942400000, 0.7058],
  [1440028800000, 0.6943],
  [1440115200000, 0.6865],
  [1440374400000, 0.6699],
  [1440460800000, 0.6692],
  [1440547200000, 0.6771],
  [1440633600000, 0.6863],
  [1440720000000, 0.6876],
  [1440979200000, 0.6918],
  [1441065600000, 0.6901],
  [1441152000000, 0.6886],
  [1441238400000, 0.6907],
  [1441324800000, 0.6979],
  [1441584000000, 0.6973],
  [1441670400000, 0.696],
  [1441756800000, 0.6978],
  [1441843200000, 0.6942],
  [1441929600000, 0.6876],
  [1442188800000, 0.6847],
  [1442275200000, 0.6835],
  [1442361600000, 0.6907],
  [1442448000000, 0.6841],
  [1442534400000, 0.6758],
  [1442793600000, 0.689],
  [1442880000000, 0.6966],
  [1442966400000, 0.697],
  [1443052800000, 0.6897],
  [1443139200000, 0.6969],
  [1443398400000, 0.6954],
  [1443484800000, 0.6926],
  [1443571200000, 0.6927],
  [1443657600000, 0.6967],
  [1443744000000, 0.6962],
  [1444003200000, 0.6901],
  [1444089600000, 0.691],
  [1444176000000, 0.6877],
  [1444262400000, 0.6887],
  [1444348800000, 0.6802],
  [1444608000000, 0.6794],
  [1444694400000, 0.6793],
  [1444780800000, 0.6765],
  [1444867200000, 0.6743],
  [1444953600000, 0.6804],
  [1445212800000, 0.6825],
  [1445299200000, 0.6794],
  [1445385600000, 0.6808],
  [1445472000000, 0.684],
  [1445558400000, 0.7023],
  [1445817600000, 0.7083],
  [1445904000000, 0.7042],
  [1445990400000, 0.7022],
  [1446076800000, 0.715],
  [1446163200000, 0.7078],
  [1446422400000, 0.7066],
  [1446508800000, 0.7112],
  [1446595200000, 0.7146],
  [1446681600000, 0.719],
  [1446768000000, 0.7206],
  [1447027200000, 0.7281],
  [1447113600000, 0.7337],
  [1447200000000, 0.7333],
  [1447286400000, 0.7324],
  [1447372800000, 0.7291],
  [1447632000000, 0.7327],
  [1447718400000, 0.7373],
  [1447804800000, 0.7377],
  [1447891200000, 0.7358],
  [1447977600000, 0.7357],
  [1448236800000, 0.7407],
  [1448323200000, 0.739],
  [1448409600000, 0.7447],
  [1448496000000, 0.7424],
  [1448582400000, 0.7453],
  [1448841600000, 0.7454],
  [1448928000000, 0.7435],
  [1449014400000, 0.7424],
  [1449100800000, 0.7372],
  [1449187200000, 0.7174],
  [1449446400000, 0.7253],
  [1449532800000, 0.7196],
  [1449619200000, 0.7141],
  [1449705600000, 0.7139],
  [1449792000000, 0.7133],
  [1450051200000, 0.7106],
  [1450137600000, 0.71],
  [1450224000000, 0.7148],
  [1450310400000, 0.7225],
  [1450396800000, 0.7229],
  [1450656000000, 0.7201],
  [1450742400000, 0.7132],
  [1450828800000, 0.7162],
  [1450915200000, 0.7136],
  [1451260800000, 0.7123],
  [1451347200000, 0.7132],
  [1451433600000, 0.7153],
  [1451520000000, 0.7186],
  [1451865600000, 0.7177],
  [1451952000000, 0.7307],
  [1452038400000, 0.731],
  [1452124800000, 0.7202],
  [1452211200000, 0.7208],
  [1452470400000, 0.7185],
  [1452556800000, 0.7229],
  [1452643200000, 0.7247],
  [1452729600000, 0.7181],
  [1452816000000, 0.7164],
  [1453075200000, 0.7182],
  [1453161600000, 0.7202],
  [1453248000000, 0.7169],
  [1453334400000, 0.7181],
  [1453420800000, 0.7253],
  [1453680000000, 0.7247],
  [1453766400000, 0.7229],
  [1453852800000, 0.7185],
  [1453939200000, 0.7173],
  [1454025600000, 0.7159],
  [1454284800000, 0.7189],
  [1454371200000, 0.7159],
  [1454457600000, 0.7148],
  [1454544000000, 0.6925],
  [1454630400000, 0.6928],
  [1454889600000, 0.7009],
  [1454976000000, 0.6901],
  [1455062400000, 0.6884],
  [1455148800000, 0.6814],
  [1455235200000, 0.687],
  [1455494400000, 0.6946],
  [1455580800000, 0.6957],
  [1455667200000, 0.6981],
  [1455753600000, 0.7023],
  [1455840000000, 0.7013],
  [1456099200000, 0.707],
  [1456185600000, 0.709],
  [1456272000000, 0.7108],
  [1456358400000, 0.707],
  [1456444800000, 0.7087],
  [1456704000000, 0.7185],
  [1456790400000, 0.7199],
  [1456876800000, 0.7212],
  [1456963200000, 0.7174],
  [1457049600000, 0.7117],
  [1457308800000, 0.7131],
  [1457395200000, 0.7069],
  [1457481600000, 0.7114],
  [1457568000000, 0.7212],
  [1457654400000, 0.7018],
  [1457913600000, 0.6995],
  [1458000000000, 0.7003],
  [1458086400000, 0.7039],
  [1458172800000, 0.6842],
  [1458259200000, 0.6867],
  [1458518400000, 0.6873],
  [1458604800000, 0.692],
  [1458691200000, 0.6953],
  [1458777600000, 0.6966],
  [1459209600000, 0.6934],
  [1459296000000, 0.6832],
  [1459382400000, 0.6784],
  [1459468800000, 0.6748],
  [1459728000000, 0.6788],
  [1459814400000, 0.6798],
  [1459900800000, 0.6822],
  [1459987200000, 0.6801],
  [1460073600000, 0.6801],
  [1460332800000, 0.6781],
  [1460419200000, 0.6776],
  [1460505600000, 0.6852],
  [1460592000000, 0.6888],
  [1460678400000, 0.6863],
  [1460937600000, 0.6846],
  [1461024000000, 0.6817],
  [1461110400000, 0.6789],
  [1461196800000, 0.6808],
  [1461283200000, 0.688],
  [1461542400000, 0.6879],
  [1461628800000, 0.6861],
  [1461715200000, 0.6848],
  [1461801600000, 0.6805],
  [1461888000000, 0.6771],
  [1462147200000, 0.6702],
  [1462233600000, 0.6645],
  [1462320000000, 0.6693],
  [1462406400000, 0.6743],
  [1462492800000, 0.6752],
  [1462752000000, 0.6777],
  [1462838400000, 0.6792],
  [1462924800000, 0.6766],
  [1463011200000, 0.6781],
  [1463097600000, 0.6813],
  [1463356800000, 0.6832],
  [1463443200000, 0.6836],
  [1463529600000, 0.6867],
  [1463616000000, 0.6932],
  [1463702400000, 0.6914],
  [1463961600000, 0.6918],
  [1464048000000, 0.6955],
  [1464134400000, 0.6973],
  [1464220800000, 0.6955],
  [1464307200000, 0.6955],
  [1464566400000, 0.6978],
  [1464652800000, 0.6966],
  [1464739200000, 0.695],
  [1464825600000, 0.6939],
  [1464912000000, 0.6966],
  [1465171200000, 0.6812],
  [1465257600000, 0.6813],
  [1465344000000, 0.679],
  [1465430400000, 0.6817],
  [1465516800000, 0.6847],
  [1465776000000, 0.6876],
  [1465862400000, 0.691],
  [1465948800000, 0.6906],
  [1466035200000, 0.695],
  [1466121600000, 0.6887],
  [1466380800000, 0.6826],
  [1466467200000, 0.684],
  [1466553600000, 0.6864],
  [1466640000000, 0.6781],
  [1466726400000, 0.7038],
  [1466985600000, 0.7094],
  [1467072000000, 0.7032],
  [1467158400000, 0.7018],
  [1467244800000, 0.7008],
  [1467331200000, 0.6982],
  [1467590400000, 0.6979],
  [1467676800000, 0.6973],
  [1467763200000, 0.7035],
  [1467849600000, 0.7026],
  [1467936000000, 0.7034],
  [1468195200000, 0.7052],
  [1468281600000, 0.7017],
  [1468368000000, 0.7033],
  [1468454400000, 0.6964],
  [1468540800000, 0.6987],
  [1468800000000, 0.7048],
  [1468886400000, 0.7063],
  [1468972800000, 0.7081],
  [1469059200000, 0.708],
  [1469145600000, 0.708],
  [1469404800000, 0.7107],
  [1469491200000, 0.7094],
  [1469577600000, 0.7099],
  [1469664000000, 0.7018],
  [1469750400000, 0.6999],
  [1470009600000, 0.6958],
  [1470096000000, 0.6935],
  [1470182400000, 0.693],
  [1470268800000, 0.6981],
  [1470355200000, 0.6965],
  [1470614400000, 0.7021],
  [1470700800000, 0.7028],
  [1470787200000, 0.6942],
  [1470873600000, 0.6967],
  [1470960000000, 0.6963],
  [1471219200000, 0.6946],
  [1471305600000, 0.6854],
  [1471392000000, 0.6869],
  [1471478400000, 0.6834],
  [1471564800000, 0.683],
  [1471824000000, 0.6846],
  [1471910400000, 0.682],
  [1471996800000, 0.6876],
  [1472083200000, 0.6858],
  [1472169600000, 0.6858],
  [1472428800000, 0.6954],
  [1472515200000, 0.6955],
  [1472601600000, 0.6984],
  [1472688000000, 0.6973],
  [1472774400000, 0.6935],
  [1473033600000, 0.6965],
  [1473120000000, 0.6962],
  [1473206400000, 0.69],
  [1473292800000, 0.6854],
  [1473379200000, 0.6876],
  [1473638400000, 0.6909],
  [1473724800000, 0.6892],
  [1473811200000, 0.6915],
  [1473897600000, 0.6887],
  [1473984000000, 0.6909],
  [1474243200000, 0.6958],
  [1474329600000, 0.6942],
  [1474416000000, 0.697],
  [1474502400000, 0.6899],
  [1474588800000, 0.6918],
  [1474848000000, 0.688],
  [1474934400000, 0.6914],
  [1475020800000, 0.691],
  [1475107200000, 0.6913],
  [1475193600000, 0.6961],
  [1475452800000, 0.6901],
  [1475539200000, 0.6961],
  [1475625600000, 0.6921],
  [1475712000000, 0.6942],
  [1475798400000, 0.6978],
  [1476057600000, 0.6962],
  [1476144000000, 0.7027],
  [1476230400000, 0.7075],
  [1476316800000, 0.7061],
  [1476403200000, 0.709],
  [1476662400000, 0.7097],
  [1476748800000, 0.7098],
  [1476835200000, 0.7109],
  [1476921600000, 0.7108],
  [1477008000000, 0.7187],
  [1477267200000, 0.7183],
  [1477353600000, 0.7199],
  [1477440000000, 0.7154],
  [1477526400000, 0.7153],
  [1477612800000, 0.7157],
  [1477872000000, 0.7137],
  [1477958400000, 0.7071],
  [1478044800000, 0.7014],
  [1478131200000, 0.7039],
  [1478217600000, 0.7016],
  [1478476800000, 0.7041],
  [1478563200000, 0.7061],
  [1478649600000, 0.7074],
  [1478736000000, 0.718],
  [1478822400000, 0.7172],
  [1479081600000, 0.728],
  [1479168000000, 0.729],
  [1479254400000, 0.7345],
  [1479340800000, 0.7332],
  [1479427200000, 0.7409],
  [1479686400000, 0.7407],
  [1479772800000, 0.742],
  [1479859200000, 0.7433],
  [1479945600000, 0.7481],
  [1480032000000, 0.7442],
  [1480291200000, 0.7446],
  [1480377600000, 0.7456],
  [1480464000000, 0.7404],
  [1480550400000, 0.7411],
  [1480636800000, 0.7398],
  [1480896000000, 0.7345],
  [1480982400000, 0.7317],
  [1481068800000, 0.7321],
  [1481155200000, 0.7293],
  [1481241600000, 0.7472],
  [1481500800000, 0.7439],
  [1481587200000, 0.7426],
  [1481673600000, 0.7396],
  [1481760000000, 0.7599],
  [1481846400000, 0.758],
  [1482105600000, 0.7596],
  [1482192000000, 0.765],
  [1482278400000, 0.7597],
  [1482364800000, 0.7576],
  [1482451200000, 0.7574],
  [1482796800000, 0.7575],
  [1482883200000, 0.7615],
  [1482969600000, 0.7568],
  [1483056000000, 0.7488],
  [1483315200000, 0.7557],
  [1483401600000, 0.763],
  [1483488000000, 0.7582],
  [1483574400000, 0.7524],
  [1483660800000, 0.7445],
  [1483920000000, 0.751],
  [1484006400000, 0.7464],
  [1484092800000, 0.7522],
  [1484179200000, 0.7365],
  [1484265600000, 0.7381],
  [1484524800000, 0.744],
  [1484611200000, 0.7361],
  [1484697600000, 0.7378],
  [1484784000000, 0.7375],
  [1484870400000, 0.7407],
  [1485129600000, 0.7334],
  [1485216000000, 0.7305],
  [1485302400000, 0.7309],
  [1485388800000, 0.7347],
  [1485475200000, 0.7363],
  [1485734400000, 0.7408],
  [1485820800000, 0.7299],
  [1485907200000, 0.7269],
  [1485993600000, 0.7253],
  [1486080000000, 0.7311],
  [1486339200000, 0.7336],
  [1486425600000, 0.7369],
  [1486512000000, 0.7377],
  [1486598400000, 0.7354],
  [1486684800000, 0.7409],
  [1486944000000, 0.7409],
  [1487030400000, 0.7415],
  [1487116800000, 0.7475],
  [1487203200000, 0.7389],
  [1487289600000, 0.7391],
  [1487548800000, 0.7421],
  [1487635200000, 0.7491],
  [1487721600000, 0.7513],
  [1487808000000, 0.7459],
  [1487894400000, 0.7427],
  [1488153600000, 0.7447],
  [1488240000000, 0.7438],
  [1488326400000, 0.7495],
  [1488412800000, 0.7512],
  [1488499200000, 0.7466],
  [1488758400000, 0.7442],
  [1488844800000, 0.7456],
  [1488931200000, 0.7474],
  [1489017600000, 0.7479],
  [1489104000000, 0.743],
  [1489363200000, 0.7379],
  [1489449600000, 0.7407],
  [1489536000000, 0.7415],
  [1489622400000, 0.7324],
  [1489708800000, 0.7315],
  [1489968000000, 0.7302],
  [1490054400000, 0.7259],
  [1490140800000, 0.7254],
  [1490227200000, 0.7272],
  [1490313600000, 0.7256],
  [1490572800000, 0.7185],
  [1490659200000, 0.721],
  [1490745600000, 0.7305],
  [1490832000000, 0.7315],
  [1490918400000, 0.7355],
  [1491177600000, 0.7381],
  [1491264000000, 0.739],
  [1491350400000, 0.7366],
  [1491436800000, 0.7377],
  [1491523200000, 0.7408],
  [1491782400000, 0.7455],
  [1491868800000, 0.7421],
  [1491955200000, 0.7431],
  [1492041600000, 0.7408],
  [1492473600000, 0.7363],
  [1492560000000, 0.7325],
  [1492646400000, 0.7308],
  [1492732800000, 0.7349],
  [1492992000000, 0.7219],
  [1493078400000, 0.7183],
  [1493164800000, 0.7181],
  [1493251200000, 0.7191],
  [1493337600000, 0.715],
  [1493683200000, 0.7163],
  [1493769600000, 0.7159],
  [1493856000000, 0.7153],
  [1493942400000, 0.7124],
  [1494201600000, 0.7143],
  [1494288000000, 0.7185],
  [1494374400000, 0.719],
  [1494460800000, 0.7209],
  [1494547200000, 0.7196],
  [1494806400000, 0.7115],
  [1494892800000, 0.7043],
  [1494979200000, 0.6996],
  [1495065600000, 0.6987],
  [1495152000000, 0.6946],
  [1495411200000, 0.6895],
  [1495497600000, 0.6918],
  [1495584000000, 0.6935],
  [1495670400000, 0.6918],
  [1495756800000, 0.6933],
  [1496016000000, 0.6939],
  [1496102400000, 0.6951],
  [1496188800000, 0.6913],
  [1496275200000, 0.6914],
  [1496361600000, 0.6916],
  [1496620800000, 0.6891],
  [1496707200000, 0.6884],
  [1496793600000, 0.6916],
  [1496880000000, 0.6907],
  [1496966400000, 0.6949],
  [1497225600000, 0.6913],
  [1497312000000, 0.6916],
  [1497398400000, 0.6927],
  [1497484800000, 0.6957],
  [1497571200000, 0.6956],
  [1497830400000, 0.693],
  [1497916800000, 0.6965],
  [1498003200000, 0.6972],
  [1498089600000, 0.6954],
  [1498176000000, 0.6951],
  [1498435200000, 0.694],
  [1498521600000, 0.6868],
  [1498608000000, 0.6792],
  [1498694400000, 0.6763],
  [1498780800000, 0.6764],
  [1499040000000, 0.6797],
  [1499126400000, 0.6809],
  [1499212800000, 0.6828],
  [1499299200000, 0.6784],
  [1499385600000, 0.6764],
  [1499644800000, 0.6783],
  [1499731200000, 0.6769],
  [1499817600000, 0.6735],
  [1499904000000, 0.676],
  [1499990400000, 0.6761],
  [1500249600000, 0.6725],
  [1500336000000, 0.6655],
  [1500422400000, 0.6672],
  [1500508800000, 0.6708],
  [1500595200000, 0.6591],
  [1500854400000, 0.6586],
  [1500940800000, 0.6552],
  [1501027200000, 0.6589],
  [1501113600000, 0.6552],
  [1501200000000, 0.6527],
  [1501459200000, 0.6528],
  [1501545600000, 0.6467],
  [1501632000000, 0.6455],
  [1501718400000, 0.6433],
  [1501804800000, 0.6427],
  [1502064000000, 0.6478],
  [1502150400000, 0.6466],
  [1502236800000, 0.6525],
  [1502323200000, 0.6525],
  [1502409600000, 0.6501],
  [1502668800000, 0.6478],
  [1502755200000, 0.6516],
  [1502841600000, 0.6541],
  [1502928000000, 0.655],
  [1503014400000, 0.6519],
  [1503273600000, 0.6504],
  [1503360000000, 0.6496],
  [1503446400000, 0.6476],
  [1503532800000, 0.6471],
  [1503619200000, 0.647],
  [1503878400000, 0.6387],
  [1503964800000, 0.6301],
  [1504051200000, 0.6393],
  [1504137600000, 0.6458],
  [1504224000000, 0.639],
  [1504483200000, 0.6401],
  [1504569600000, 0.6411],
  [1504656000000, 0.6383],
  [1504742400000, 0.6355],
  [1504828800000, 0.6293],
  [1505088000000, 0.6336],
  [1505174400000, 0.6381],
  [1505260800000, 0.6349],
  [1505347200000, 0.6415],
  [1505433600000, 0.636],
  [1505692800000, 0.6371],
  [1505779200000, 0.6354],
  [1505865600000, 0.6329],
  [1505952000000, 0.6401],
  [1506038400000, 0.6362],
  [1506297600000, 0.6428],
  [1506384000000, 0.6485],
  [1506470400000, 0.6518],
  [1506556800000, 0.6491],
  [1506643200000, 0.6471],
  [1506902400000, 0.6516],
  [1506988800000, 0.6509],
  [1507075200000, 0.6485],
  [1507161600000, 0.6517],
  [1507248000000, 0.6543],
  [1507507200000, 0.6515],
  [1507593600000, 0.6478],
  [1507680000000, 0.6454],
  [1507766400000, 0.6436],
  [1507852800000, 0.6468],
  [1508112000000, 0.6473],
  [1508198400000, 0.6505],
  [1508284800000, 0.6512],
  [1508371200000, 0.6451],
  [1508457600000, 0.6463],
  [1508716800000, 0.6519],
  [1508803200000, 0.6504],
  [1508889600000, 0.6486],
  [1508976000000, 0.6509],
  [1509062400000, 0.6618],
  [1509321600000, 0.6613],
  [1509408000000, 0.6594],
  [1509494400000, 0.6613],
  [1509580800000, 0.6588],
  [1509667200000, 0.658],
  [1509926400000, 0.6629],
  [1510012800000, 0.665],
  [1510099200000, 0.6629],
  [1510185600000, 0.6599],
  [1510272000000, 0.6582],
  [1510531200000, 0.658],
  [1510617600000, 0.6515],
  [1510704000000, 0.6447],
  [1510790400000, 0.6496],
  [1510876800000, 0.6479],
  [1511136000000, 0.6489],
  [1511222400000, 0.6535],
  [1511308800000, 0.6512],
  [1511395200000, 0.6441],
  [1511481600000, 0.6421],
  [1511740800000, 0.6368],
  [1511827200000, 0.6413],
  [1511913600000, 0.6456],
  [1512000000000, 0.6441],
  [1512086400000, 0.6415],
  [1512345600000, 0.6429],
  [1512432000000, 0.6442],
  [1512518400000, 0.6463],
  [1512604800000, 0.6486],
  [1512691200000, 0.6517],
  [1512950400000, 0.6478],
  [1513036800000, 0.65],
  [1513123200000, 0.6522],
  [1513209600000, 0.6443],
  [1513296000000, 0.6471],
  [1513555200000, 0.6479],
  [1513641600000, 0.6459],
  [1513728000000, 0.6443],
  [1513814400000, 0.6433],
  [1513900800000, 0.6438],
  [1514332800000, 0.6408],
  [1514419200000, 0.638],
  [1514505600000, 0.6339],
]
