<template>
    <div class="ma-3">
        <v-card elevation="10">
            <v-toolbar
                class="mb-6"
                :color="$colors[0]"
                dark>
                <span class="Poppins-Bold pl-0">Electricité professionnel</span>
            </v-toolbar>
            <FormulaireDeCotation class="pa-4" @sendDataToParent="replireDataFromCotation"/>
            <ListeDesPropositions class="pa-4" v-if="Propositions.length>0" :optionTarifaire="optionTarifaire" :parametreCompteur="parametreCompteur" :Propositions="Propositions" :SocieteData="SocieteData" :enedisData="enedisData" :numCompteur="numCompteur"
             :turpeData="turpeData" :taxeData="taxeData" :typeCompteur="typeCompteur" :fournisseurActuelle="fournisseurActuelle"
             :selected="selected" :listCompteur="listCompteur" :donnerCompteur="donnerCompteur" :donneDeCotation="donneDeCotation" />
        </v-card>
    </div>
</template>
<script>
    import FormulaireDeCotation from '../../components/proposition-professionnels/FormulaireDeCotation.vue'
    import ListeDesPropositions from '../../components/proposition-professionnels/ListeDesPropositions.vue'
    import './css/cotationPro.css'

    import axios from 'axios'
    import dialogeModal from '../../components/ui/dialogeModal.vue'
    export default {
        name: 'demandeDeCotationElecPro',
        components: {
            FormulaireDeCotation,
            ListeDesPropositions,
            dialogeModal,
        },
        data() {
            return {
                parametreCompteur: null,
                Propositions: [],
                SocieteData:[],
                optionTarifaire: '',
                enedisData: [],
                donneDeCotation: [],
                numCompteur: null,
                turpeData: [],
                taxeData: [],
                typeCompteur: null,
                panel: [],
                fournisseurActuelle: null,
                selected: [],
                listCompteur: [],
                donnerCompteur: null,
            }
        },async created() {
            var whoIam = ""
            await this.whoIAm(this.getToken('token')).then((data)=>{whoIam=data});
            if(whoIam == "noToken"){
                localStorage.removeItem('vendeurName')
                localStorage.removeItem('typeUser') 
                localStorage.removeItem('token')
                this.$router.push('/login')
            }else{
            localStorage.setItem('typeUser', whoIam)
            }
        },
        methods:{
            replireDataFromCotation({donneDeCotation, optionTarifaire, parametreCompteur, Propositions, SocieteData, enedisData, numCompteur, turpeData, taxeData,
             typeCompteur, fournisseurActuelle, selected, listCompteur, donnerCompteur}){
                this.donneDeCotation = donneDeCotation
                this.parametreCompteur = parametreCompteur
                this.Propositions =Propositions
                this.SocieteData =SocieteData
                this.enedisData = enedisData
                this.numCompteur = numCompteur
                this.turpeData = turpeData
                this.taxeData = taxeData
                this.optionTarifaire = optionTarifaire
                this.typeCompteur = typeCompteur
                this.fournisseurActuelle = fournisseurActuelle
                this.listCompteur = listCompteur
                this.donnerCompteur = donnerCompteur
            }
        }
    }
</script>
<style>
  @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@700&display=swap');
</style>
