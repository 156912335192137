<template>

    <section style="background-color: #eee;">
        <div class="container-fluid pt-5" style="height: 100%;">
            <div class="row">
                <div class="col-lg-12">
                    <div class="card mb-4">
                    <ListePropositionsGaz  v-if="cotation!=null" :Propositions="cotation.cotation.result" :SocieteData="cotation.cotation.dataSociete"
                        :numCompteur="cotation.numCompteur"
                        :typeCompteur="cotation.cotation.typeCompteur" 
                        :panel= "[]"
                        :fournisseurActuelle="cotation.cotation.result.actuelle"
                        :selected="[]"
                        :listCompteur="[]"/>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>


<script>
    import "jquery/dist/jquery.min.js";
    import "bootstrap/dist/css/bootstrap.min.css";
    import "bootstrap/dist/css/bootstrap.css";
    import "bootstrap/dist/js/bootstrap.bundle";
    import "datatables.net-dt/js/dataTables.dataTables";
    import "datatables.net-dt/css/jquery.dataTables.min.css";
    import $ from 'jquery'
    import jQuery from 'jquery'
    import axios from 'axios'
    import ListePropositionsGaz from '../../components/proposition-professionnels-gaz/listePropositionGaz.vue'
    export default{
        components: {
            ListePropositionsGaz,
        },
        name: 'historiqueCotationGaz',
        data() {
            return {
                cotation: null,
            }
        },
        async created() {
            var whoIam = ""
            await this.whoIAm(this.getToken('token')).then((data)=>{whoIam=data});
            if(whoIam == "noToken"){
                localStorage.removeItem('vendeurName')
                localStorage.removeItem('typeUser') 
                localStorage.removeItem('token')
                this.$router.push('/login')
            }else{
                localStorage.setItem('typeUser', whoIam)
            }
            await this.getCotation();
            console.log(this.cotation)
        },
        methods: {
            async getCotation(){
                var formData = new FormData()
                formData.append('token', this.getToken('token'))
                formData.append('idCotation', this.$route.query.id)
                await axios({
                    url:
                    process.env.VUE_APP_URL_API_CLIENT +
                    'getAncienCotationGaz/',
                    method: 'POST',
                    data: formData,
                })
                .then((res) => {
                    if(res.data.result==true){
                        this.cotation = res.data.cotation
                    }else if(res.data.result==false){
                        alert("Il semble que la société n'existe pas")
                    }else if(res.data.result=="permission"){
                        alert('permission')
                        localStorage.removeItem('vendeurName')
                        localStorage.removeItem('typeUser') 
                        localStorage.removeItem('token')
                        this.$router.push('/login')
                    }
                }).catch((err)=>{
                    alert('permission refusée')
                    localStorage.removeItem('vendeurName')
                    localStorage.removeItem('typeUser') 
                    localStorage.removeItem('token')
                    this.$router.push('/login')
                })
            }
        }
    }

</script>