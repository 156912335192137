<template>
    <v-card class="mt-6 pa-4 mr-2 ml-3" elevation="10">
        <v-form>
            <v-file-input
            truncate-length="15"
            v-model="fil"
            ></v-file-input>
            <v-btn @click="test">click</v-btn>
            <v-file-input
            truncate-length="15"
            v-model="fil2"
            ></v-file-input>
            <iframe :src="fil2" width="150px" height="150px" style="overflow: none; "></iframe>
            <canvas id = 'canvas'></canvas>
        </v-form>
    </v-card>
</template>
<script src="https://cdnjs.cloudflare.com/ajax/libs/FileSaver.js/2.0.5/FileSaver.min.js"></script>
<script>
    import { degrees, PDFDocument, rgb, StandardFonts } from 'pdf-lib'
    import download from 'downloadjs'
    import jsPDF from 'jspdf'
    import 'jspdf-autotable'
    import moment from 'moment'
    import axios from 'axios'
    export default {
        name: "testPdf",
        data() {
            return {
                fil: null,
                fil2: null,
            }
        },
        async created() {
        },
        methods: {
            async test(){
                var pdfDoc = null
                var begin = 2
                var length = 8
                const reader = new FileReader();
                reader.readAsArrayBuffer(this.fil);
                reader.onload = async () => {
                    pdfDoc = await PDFDocument.load(reader.result);
                    let mergedPdf = await PDFDocument.create()
                    for (let i = begin; i < length; i += 1) {
                        var [page] = await mergedPdf.copyPages(pdfDoc, [i])
                        mergedPdf.addPage(page)
                    }
                    const newPdf = await mergedPdf.save()
                    var bb = new Blob([newPdf ], { type: 'application/pdf' });
                    this.fil2 = window.URL.createObjectURL(bb)
                };
            },
        }
    }
</script>