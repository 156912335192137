import Vue from 'vue'
import VueRouter from 'vue-router'

import Login from '../views/Login'

import ElecParticuliers from '../components/proposition-particuliers/ElecParticuliers'
import ElecProfessionnels from '../components/proposition-professionnels/ElecProfessionnels'
import GazProfessionnels from '../components/proposition-professionnels-gaz/GazProfessionnels'
import AutorisatonDeCollectionDeDonnees from '../views/AutorisatonDeCollectionDeDonnees.vue'
import creationDeACD from '../views/creationDeACD.vue'

import Vendeurs from '../views/admin/Vendeurs'
import VendeursAvd from '../views/admin/vendeursAvd'
import VendeursChef from '../views/admin/vendeursChef'

import addRapideMessage from '../views/admin/addRapideMessage.vue'

import ControleParticuliers from '../views/admin/ControleParticuliers.vue'
import VenteParticuliers from '../views/ventePart/VenteParticuliers.vue'
import toutVentesParticuliers from '../views/ventePart/listeDesVentesPart.vue'
import mesVenteParticulier from '../views/ventePart/listeDesMesVentesPart.vue'

import VentePro from '../views/admin/VentePro.vue'
import DemandeDeCotation from '../views/demandeDeCotation/DemandeDeCotation.vue'

import TestView from '../views/TestView'
import mesDemandeAcd from '../views/mesDemandeAcd'
import NouvelleOportunite from '../views/NouvelleOpportunite'
import DashBoard from '../views/dashboard/DashBoard'
import newDashBoard from '../views/dashboard/newDashBoard'
import OldDashBoard from '../views/dashboard/OldDashBoard'
import AjouterVentePro from '../views/ventePro/AjouterVentePro'
import ajouterVenteProMulti from '../views/ventePro/ajouterVenteProMulti'

import controleVenteProMulti from '../views/ventePro/controleVenteProMulti'
import mesVenteProMulti from '../views/ventePro/mesVenteProMulti'

import mesVentesPro from '../views/ventePro/mesVentesPro'
import mesVentesPart from '../views/ventePart/mesVentesPart'
import listeDesVentePro from '../views/ventePro/listeDesVentePro'

import monProfileSociete from '../views/societe/monProfileSociete'
import profileSociete from '../views/societe/societeProfil'

import listeDeMesVentePro from '../views/ventePro/listeDeMesVentePro'
import toutLesSociete from '../views/societe/toutLesSociete'
import mesSocietes from '../views/societe/mesSocietes'

import dataTable from '../views/ventePro/dataTableFromScratch'
import listeMesVentePro from '../views/ventePro/listeMesVentePro'
import RapportDeVente from '../views/ventePro/RapportDeVente'

import controleSlackBot from '../views/admin/controleSlackBot'
import suiviCommerciale from '../views/admin/suiviCommerciale.vue'
import contratEmbauche from '../views/leFormContrat/formContratDembouche.vue'
import getAllEmployeur from "../views/leFormContrat/AllDemandeEmployeur.vue"


import demandeDeCotationGazPro from '../views/demande-cotation-pro/demandeDeCotationGazPro'


import FourmulaireDeCotation from "../components/proposition-professionnels/FormulaireDeCotation.vue"
import demandeDeCotationElecPro from '../views/demande-cotation-pro/demandeCotationElecPro'

import NewdemandeDeCotationElecPro from '../views/demande-cotation-pro/newDemandeDeCotationElec'
import NewDemandeDeCotationGazPro from "../views/demande-cotation-pro/newDemandeDeCotationGazPro"

import Homepage from '../views/Homepage.vue'
import toutDemandeDeCotation from '../views/demandeDeCotation/listeDeDemandeDeCotationSS.vue'
import mesDemandeDeCotation from '../views/demandeDeCotation/listeMesDemandeDeCotation.vue'
import historiqueCotation from '../views/societe/historiqueCotation.vue'
import newHistoriqueCotation from '../views/societe/new-historiqueCotation.vue'
import gazProfessionnels from '../components/proposition-professionnels-gaz/GazProfessionnels.vue'

import EkwateurContrat from '../views/edition-contrat/Ekwateur.vue'
import EkwateurExcel from '../views/edition-contrat/EkwateurExcel.vue'

import Charts from '../views/charts/ChartsPage.vue'
import mesOffre from '../views/lesNouvelleOffre/toutMesOffre.vue'
import ajouterNouvelleOffre from '../views/lesNouvelleOffre/ajouterNouvelleOffre.vue'
import toutLesOffreAdmin from '../views/lesNouvelleOffre/toutLesOffreAdmin.vue'

// les equipe

import ajouterEquipe from '../views/equipe/ajouterEquipe.vue'
import ajouterEquipeAdv from '../views/equipe/ajouterEquipeAdv.vue'
import demandeCreationEquipeAdmin from '../views/equipe/listeDemandeDeCreationequipeAdmin.vue'
import listeEquipeAdmin from '../views/equipe/listeEquipeAdmin.vue'
import listeEquipeAdv from '../views/equipe/listeEquipeAdv.vue'

import testPdf from '../views/equipe/testPdf.vue'
import ajouterUneVenteDansRapport from '../views/rapportDeVente/ajouterVente.vue'

import presentation from '../views/presentation/presentations.vue'
import engieProManyCFiveOne from '../views/edition-contrat/engieManyCompteur.vue'
import engieProManyCFourOne from '../views/edition-contrat/engieManyCompteurC4.vue'

import historiqueCotationGaz from '../views/societe/historiqueCotationGaz.vue'
import newHistoriqueCotationGaz from '../views/societe/new-historiqueCotationGaz.vue'


import gestionVentes from "../views/gestionVentes.vue"
Vue.use(VueRouter)
const routes = [ 
  {
    path:"/gestion-ventes",
    name:"gestionVentes",
    component:gestionVentes,
    meta:{
      requiresAuth:true,
      roles:['admin', 'superadmin', 'comptable', 'ADV']
    }
  },
  {
    path: '/historiqueCotationGaz',
    name: 'historiqueCotationGaz',
    component: historiqueCotationGaz,
    meta:{
      requiresAuth:true,
      roles:['admin', 'superadmin', 'pro','pro-part','Chef équipe', 'ADV','Vendeur']
    }    
  },
  {
    path: '/new-HistoriqueCotationGaz',
    name: 'newHistoriqueCotationGaz',
    component: newHistoriqueCotationGaz,
    meta:{
      requiresAuth:true,
      roles:['admin', 'superadmin', 'pro','pro-part','Chef équipe', 'ADV','Vendeur']
    }    
  },
  {
    path: '/engieProManyCFourOne',
    name: 'engieProManyCFourOne',
    component: engieProManyCFourOne,
    meta:{
      requiresAuth:true
    }
    
  },
  {
    path: '/engieProManyCFiveOne',
    name: 'engieProManyCFiveOne',
    component: engieProManyCFiveOne,
    meta:{
      requiresAuth:true
    }
    
  },
  {
    path: '/controleVenteProMulti',
    name: 'controleVenteProMulti',
    component: controleVenteProMulti,
    meta:{
      requiresAuth:true,
      roles:['admin', 'superadmin' ,'ADV', 'Chef équipe','comptable']
    }
    
  },
  {
    path: '/mesVenteProMulti',
    name: 'mesVenteProMulti',
    component: mesVenteProMulti,
    meta:{
      requiresAuth:true,
      roles:['admin', 'superadmin', 'part', 'pro-part', 'ADV', 'Prospecteur', 'Vendeur', 'Chef équipe']
    }
    
  },
  {
    path: '/rapport-vente',
    name: 'RapportDeVente',
    component: RapportDeVente,
    meta:{
      requiresAuth:true,
      roles:['admin', 'superadmin', 'comptable', 'ADV']
    }
  },
  {
    path: '/presentation',
    name: 'presentation',
    component: presentation,
    meta:{
      requiresAuth:true
    }
  },
  {
    path: '/ajouterUneVenteDansRapport',
    name: 'ajouterUneVenteDansRapport',
    component: ajouterUneVenteDansRapport,
    meta:{
      requiresAuth:true,
      roles:[
        "admin",
        "superadmin",
      ]
    }
  },
  {
    path: '/controle-alerte',
    name: 'addRapideMessage',
    component: addRapideMessage,
    meta:{
      requiresAuth:true,
      roles:["admin", "superadmin"]
    }
  },
  {
    path: '/suiviCommerciale',
    name: 'suiviCommerciale',
    component: suiviCommerciale,
    meta:{
      requiresAuth:true,
      roles:['admin','superadmin']
    }
  },
  {
    path: '/gestion-equipes',
    name: 'listeEquipeAdmin',
    component: listeEquipeAdmin,
    meta:{
      requiresAuth:true,
      roles:["admin", "superadmin"]
    }
  },
  {
    path: '/listeEquipeAdv',
    name: 'listeEquipeAdv',
    component: listeEquipeAdv,
    meta:{
      requiresAuth:true,
      roles:["ADV"]
    }
  },
  {
    path: '/demandeCreationEquipeAdmin',
    name: 'demandeCreationEquipeAdmin',
    component: demandeCreationEquipeAdmin,
    meta:{
      requiresAuth:true,
      roles:['admin', 'superadmin', 'ADV']
    }
  },
  {
    path: '/testPdf',
    name: 'testPdf',
    component: testPdf,
    meta:{
      requiresAuth:true
    }
  },
  {
    path: '/ajouterEquipe',
    name: 'ajouterEquipe',
    component: ajouterEquipe,
    meta:{
      requiresAuth:true,
      roles:['admin','superadmin','ADV']
    }
  },
  {
    path: '/ajouterEquipeAdv',
    name: 'ajouterEquipeAdv',
    component: ajouterEquipeAdv,
    meta:{
      requiresAuth:true,
      roles:['admin','superadmin','ADV']
    }
  },
  {
    path: '/EkwateurExcel',
    name: 'EkwateurExcel',
    component: EkwateurExcel,
    meta:{
      requiresAuth:true
    }
  },
  {
    path: '/mesOffre',
    name: 'mesOffre',
    component: mesOffre,
    meta:{
      requiresAuth:true,
      roles:["fournisseur"]
    }
  },
  {
    path: '/offres-externes-admin',
    name: 'toutLesOffreAdmin',
    component: toutLesOffreAdmin,
    meta:{
      requiresAuth:true,
      roles:["admin", "superadmin"]
    }
  },
  {
    path: '/ajouterNouvelleOffre',
    name: 'ajouterNouvelleOffre',
    component: ajouterNouvelleOffre,
    meta:{
      requiresAuth:true,
      roles:['admin','superadmin','fournisseur']
    }
  },
  {
    path: '/EkwateurContrat',
    name: 'EkwateurContrat',
    component: EkwateurContrat,
    meta:{
      requiresAuth:true
    }
  },
  {
    path: '/mes-societes',
    name: 'mesSocietes',
    component: mesSocietes,
    meta:{
      requiresAuth:true,
      roles:[
        "pro",
        "pro-part",
        "admin",
        "superadmin",
        "ADV",
        "Chef équipe",
        "Vendeur",
        "Prospecteur",
      ]
    }
  },
  {
    path: '/mes-profils-societes',
    name: 'monProfileSociete',
    component: monProfileSociete,
    meta:{
      requiresAuth:true,
      roles:['admin','superadmin','pro','pro-part','ADV','Chef équipe','Vendeur','Prospecteur']
    }
  },
  {
    path: '/old-demande-cotation-gaz-pro',
    name: 'demandeDeCotationGazPro',
    component: demandeDeCotationGazPro,
    meta:{
      requiresAuth:true,
      roles:[
        "pro",
        "pro-part",
        "admin",
        "superadmin",
        "ADV",
        "Chef équipe",
        "Vendeur",
      ]
    }
  },
  {
    path: '/demande-cotation-gaz-pro',
    name: 'NewDemandeDeCotationGazPro',
    component: NewDemandeDeCotationGazPro,
    meta:{
      requiresAuth:true,
      roles:[
        "pro",
        "pro-part",
        "admin",
        "superadmin",
        "ADV",
        "Chef équipe",
        "Vendeur",
      ]
    }
  },
  {
    path: '/gazProfessionnels',
    name: 'gazProfessionnels',
    component: gazProfessionnels,
    meta:{
      requiresAuth:true
    }
  },
  {
    path: '/graphiques',
    name: 'Charts',
    component: Charts,
    meta:{
      requiresAuth:true,
      roles:[
        "part",
        "pro",
        "pro-part",
        "admin",
        "superadmin",
        "ADV",
        "Chef équipe",
        "Vendeur",
        "Prospecteur",
      ]
    }
  },
  {
    path: '/controle-societes',
    name: 'toutLesSociete',
    component: toutLesSociete,
    meta:{
      requiresAuth:true,
      roles:["admin", "superadmin", "ADV", "Chef équipe"]
    }
  },
  
  {
    path: '/mes-ventes-part',
    name: 'mesVenteParticulier',
    component: mesVenteParticulier,
    meta:{
      requiresAuth:true,
      roles:[
        "part",
        "pro-part",
        "admin",
        "superadmin",
        "ADV",
        "Chef équipe",
        "Vendeur",
        "Prospecteur",
      ]
    }
  },
  {
    path: '/controle-ventes-part',
    name: 'toutVentesParticuliers',
    component: toutVentesParticuliers,
    meta:{
      requiresAuth:true,
      roles:["admin", "superadmin", "ADV", "Chef équipe"]
    }
  },
  {
    path: '/validation-cotation',
    name: 'toutDemandeDeCotation',
    component: toutDemandeDeCotation,
    meta:{
      requiresAuth:true,
      roles:["admin", "superadmin", "ADV", "Chef équipe"]
    }
  },
  {
    path: '/mes-demandes-cotation',
    name: 'mesDemandeDeCotation',
    component: mesDemandeDeCotation,
    meta:{
      requiresAuth:true,
      roles:[
        "pro",
        "pro-part",
        "admin",
        "superadmin",
        "ADV",
        "Chef équipe",
        "Vendeur",
        "Prospecteur",
      ]
    }
  },
  {
    path: '/historiqueCotation',
    name: 'historiqueCotation',
    component: historiqueCotation,
    meta:{
      requiresAuth:true,
      roles:['admin', 'superadmin', 'pro','pro-part','Chef équipe', 'ADV','Vendeur']
    }
  },
  {
    path: '/new-historiqueCotation',
    name: 'newHistoriqueCotation',
    component: newHistoriqueCotation,
    meta:{
      requiresAuth:true,
      roles:['admin', 'superadmin', 'pro','pro-part','Chef équipe', 'ADV','Vendeur']
    }
  },
  {
    path: '/controle-ventes-pro',
    name: 'dataTable',
    component: dataTable,
    meta:{
      requiresAuth:true,
      roles:['admin','superadmin']
    }
  },
  {
    path: '/mes-ventes-pro',
    name: 'listeMesVentePro',
    component: listeMesVentePro,
    meta:{
      requiresAuth:true,
      roles:['admin','superadmin','pro','pro-part', 'ADV' ,'Chef équipe','Prospecteur' ,'Vendeur']
    }
  },
  
  {
    path: '/listeDeMesVentePro',
    name: 'listeDeMesVentePro',
    component: listeDeMesVentePro,
    meta:{
      requiresAuth:true,
      roles:['admin','superadmin','pro-part','pro']
    }
  },
  {
    path: '/les-profils-societes',
    name: 'profileSociete',
    component: profileSociete,
    meta:{
      requiresAuth:true,
      roles:['admin','superadmin','pro','pro-part','ADV','Chef équipe','Vendeur','Prospecteur','comptable']
    }
  },
  {
    path: '/listeDesVentePro',
    name: 'listeDesVentePro',
    component: listeDesVentePro,
    meta:{
      requiresAuth:true,
      roles:['admin', 'superadmin']
    }
  },
  
  {
    path: '/homepage',
    name: 'homepage',
    component: Homepage,
    meta:{
      requiresAuth:true
    }
  },
  {
    path: '/FourmulaireDeCotation',
    name: 'FourmulaireDeCotation',
    component: FourmulaireDeCotation,
    meta:{
      requiresAuth:true
    }
  },
  {
    path: '/new-demande-cotation-elec-pro',
    name: 'demandeDeCotationElecPro',
    component: demandeDeCotationElecPro,
    meta:{
      requiresAuth:true,
      roles:[
        "pro",
        "pro-part",
        "admin",
        "superadmin",
        "ADV",
        "Chef équipe",
        "Vendeur",
      ]
    }
  },
  {
    path: '/demande-cotation-elec-pro',
    name: 'NewdemandeDeCotationElecPro',
    component: NewdemandeDeCotationElecPro,
    meta:{
      requiresAuth:true,
      roles:[
        "pro",
        "pro-part",
        "admin",
        "superadmin",
        "ADV",
        "Chef équipe",
        "Vendeur",
      ]
    }
  },
  
  {
    path: '/contrat-embauche',
    name: 'contratEmbauche',
    component: contratEmbauche,
    meta:{
      requiresAuth:false,
      roles:["admin"]
    }
  },
  {
    path: '/demandes-embauche',
    name: 'getAllEmployeur',
    component: getAllEmployeur,
    meta:{
      requiresAuth:true,
      roles:["admin"]
    }
  },
  {
    path: '/controleSlackBot', 
    name: 'controleSlackBot',
    component: controleSlackBot,
    meta:{
      requiresAuth:true,
      roles:['admin']
    }
  },
  {
    path: '/mesVentesPart',
    name: 'mesVentesPart',
    component: mesVentesPart,
    meta:{
      requiresAuth:true,
      roles:['admin', 'superadmin', 'part', 'pro-part', 'ADV', 'Prospecteur', 'Vendeur', 'Chef équipe']
    }
  },
  {
    path: '/MesVentesPro',
    name: 'MesVentesPro',
    component: mesVentesPro,
    meta:{
      requiresAuth:true,
      roles:['admin','superadmin','pro-part','pro']
    }
  },
  
  {
    path: '/ajouter-vente-pro',
    name: 'AjouterVentePro',
    component: AjouterVentePro,
    meta:{
      requiresAuth:true
    }
  },
  {
    path: '/ajouterVenteProMulti',
    name: 'ajouterVenteProMulti',
    component: ajouterVenteProMulti,
    meta:{
      requiresAuth:true,
      roles:[
        "pro",
        "pro-part",
        "admin",
        "superadmin",
        "ADV",
        "Chef équipe",
        "Vendeur",
        "Prospecteur",
      ]
    }
  },
  {
    path: '/mesDemandeAcd',
    name: 'mesDemandeAcd',
    component: mesDemandeAcd,
    meta:{
      requiresAuth:true,
      roles:['admin','superadmin','pro','pro-part']
    }
  },
  {
    path: '/newDashBoard',
    name: 'DashBoard V1',
    component: DashBoard,
    meta:{
      requiresAuth:true,
      roles:['part', 'pro', 'pro-part', 'admin', 'superadmin', 'ADV', 'Chef équipe', 'Vendeur', 'Prospecteur']
    }
  },
  {
    path: '/dashboard',
    name: 'DashBoard',
    component: newDashBoard,
    meta:{
      requiresAuth:true,
      roles:['part', 'pro', 'pro-part', 'admin', 'superadmin', 'ADV', 'Chef équipe', 'Vendeur', 'Prospecteur']
    }
  },
  {
    path: '/OldDashBoard',
    name: 'OldDashBoard',
    component: OldDashBoard,
    meta:{
      requiresAuth:true,
      roles:[
        "part",
        "pro",
        "pro-part",
        "admin",
        "superadmin",
        "ADV",
        "Chef équipe",
        "Vendeur",
        "Prospecteur",
        'comptable'
      ]
    }
  },
  {
    path: '/nouvelle-opportunite',
    name: 'NouvelleOportunite',
    component: NouvelleOportunite,
    meta:{
      requiresAuth:true,
      roles:[
        "part",
        "pro",
        "pro-part",
        "admin",
        "superadmin",
        "ADV",
        "Chef équipe",
        "Vendeur",
        "Prospecteur",
      ]
    }
  },
  {
    path: '/nouvelle-opportunite/elec-particuliers',
    name: 'ElecParticuliers',
    component: ElecParticuliers,
    meta:{
      requiresAuth:true,
      roles:[
        "part",
        "pro",
        "pro-part",
        "admin",
        "superadmin",
        "ADV",
        "Chef équipe",
        "Vendeur",
        "Prospecteur",
      ]
    }
  },

  {
    path: '/nouvelle-opportunite/autorisation-collecte-donnees',
    name: 'AutorisatonDeCollectionDeDonnees',
    component: AutorisatonDeCollectionDeDonnees,
    meta:{
      requiresAuth:true,
      roles:[
        "part",
        "pro",
        "pro-part",
        "admin",
        "superadmin",
        "ADV",
        "Chef équipe",
        "Vendeur",
        "Prospecteur",
      ]
    }
  },
  {
    path: '/nouvelle-opportunite/autorisation-collecte-de-donnees',
    name: 'autorisation-collecte-donnees',
    component: creationDeACD,
    meta:{
      requiresAuth:true,
      roles:[
        "part",
        "pro",
        "pro-part",
        "admin",
        "superadmin",
        "ADV",
        "Chef équipe",
        "Vendeur",
        "Prospecteur",
      ]
    }
  },

  {
    path: '/nouvelle-opportunite/demande-cotation',
    name: 'DemandeDeCotation',
    component: DemandeDeCotation,
    meta:{
      requiresAuth:true,
      roles:['admin','superadmin','pro','pro-part','ADV','Chef équipe','Vendeur','Prospecteur']
    }
  },
  {
    path: '/test-view', 
    name: 'test',
    component: TestView,
    meta:{
      requiresAuth:true
    }
  },
  {
    path: '/vendeurs',
    name: 'Vendeurs',
    component: Vendeurs,
    meta:{
      requiresAuth:true,
      roles:["admin", "superadmin"]
    }
  },
  {
    path: '/VendeursAvd',
    name: 'VendeursAvd',
    component: VendeursAvd,
    meta:{
      requiresAuth:true,
      roles:["ADV"]
    }
  },
  {
    path: '/VendeursChef',
    name: 'VendeursChef',
    component: VendeursChef,
    meta:{
      requiresAuth:true,
      roles:["Chef équipe"]
    }
  },
  {
    path: '/VentePro',
    name: 'VentePro',
    component: VentePro,
    meta:{
      requiresAuth:true,
      roles:['admin', 'superadmin']
    }
  },
  {
    path: '/controle-particuliers',
    name: 'ControleParticuliers',
    component: ControleParticuliers,
    meta:{
      requiresAuth:true,
      roles:['admin','superadmin','ADV','Chef équipe']
    }
  },
  {
    path: '/ajouter-vente-part',
    name: 'ControleParticuliers',
    component: VenteParticuliers,
    meta:{
      requiresAuth:true,
      roles:[
        "part",
        "pro-part",
        "admin",
        "superadmin",
        "ADV",
        "Chef équipe",
        "Vendeur",
        "Prospecteur",
      ]
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    beforeEnter: (to, from, next) => {
      //router.app.testing.test()    
      //router.app.test1() 
      localStorage.setItem("lastLink", from.fullPath)
      next()
    }
  },
  {
    path: '/elec-professionnels',
    name: 'ElecProfessionnels',
    component: ElecProfessionnels,
  },
  {
    path: '/gaz-professionnels',
    name: 'GazProfessionnels',
    component: GazProfessionnels,
  },
  {
    path: "*",
    name: 'Login',
    component: Login,
    beforeEnter: (to, from, next) => {
      localStorage.setItem("lastLink", "notFound")
      next()
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const userRole = localStorage.getItem('typeUser')
  console.log(requiresAuth,userRole)
  if (requiresAuth) {
    if (localStorage.getItem('token') == null) {
      // L'utilisateur n'est pas authentifié. Redirection vers la page de login.
      next('/login');
    } else if (userRole && localStorage.getItem('token')) {
      const requiredRoles = to.meta.roles;
      console.log(to.meta)
      console.log(userRole)
      /*if(requiredRoles == undefined){
        next();
      }else{*/
        if (requiredRoles.includes(userRole)) {
          //L'utilisateur est authentifié et a le rôle approprié. Accès autorisé.
          next();
        } else {
          if(userRole == "comptable"){
            //L'utilisateur est authentifié mais n'a pas le rôle approprié. Redirection vers la page d'accueil.
            next('/rapport-vente');
          }else{
            //L'utilisateur est authentifié mais n'a pas le rôle approprié. Redirection vers la page d'accueil.
            next('/');
          }
        //}
      }
      
    }
  } else {
    // Pour les routes non protégées, laissez simplement passer
    next();
  }
});

export default router
