<template>
  <div>
    <v-card class="mt-8 pa-3" elevation="10">
     
      <!--<v-toolbar dark :color="$colors[0]" fixed>
        <v-toolbar-title class="white--text">Vente Particulier</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-text-field v-model="search" append-icon="search" label="Search" single-line hide-details></v-text-field>
      </v-toolbar>-->
      <v-dialog v-model="dialog" max-width="500px">
        <v-card>
          <v-card-title>
            <span class="text-h5">{{ formTitle }}</span>
            
          </v-card-title>

          <v-card-text>
            <v-container class="ml-0">
              <v-form
                ref="form"
                lazy-validation
              >
                <v-row>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="editedItem.Nom_du_client"
                      :rules="[rules.required]"
                      label="Nom du client"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                        v-model="editedItem.date"
                        type="date"
                        class="mt-4"
                        label="Date de signature"
                        :min="minDatePossible"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="editedItem.Email"
                      :rules="rules.emailRules"
                      label="Email"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="editedItem.Telephone"
                      :rules="[rules.required, rules.phoneisNumber, rules.phoneLength]"
                      label="Téléphone"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-select
                      :items="fornisseurOption"
                      :rules="[rules.required]"
                      v-model="editedItem.Fournisseur"
                      label="Fournisseur"
                      v-on:change="onSelectFornisseur(editedItem.Fournisseur)"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-select
                      :items="energierOption"
                      :rules="[rules.required]"
                      v-model="editedItem.Energie"
                      label="Énergie(s)"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-select
                      :items="offreOption"
                      :rules="[rules.required]"
                      v-model="editedItem.Offre"
                      label="Offre"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-select
                      :items="statusOption"
                      :rules="[rules.required]"
                      v-model="editedItem.Status"
                      label="Statut"
                    ></v-select>
                  </v-col>
                  
                  <v-col v-if="editedItem.Fournisseur=='ELECOCITE'" cols="12" sm="6" md="6">
                    <v-select
                      :items="kvaOption"
                      :rules="[rules.required]"
                      v-model="editedItem.kva"
                      label="KVA"
                    ></v-select>
                  </v-col>
                </v-row>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="close">
              Cancel
            </v-btn>
            <v-btn color="blue darken-1" text @click="save">
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogDelete" max-width="500px">
        <v-card>
          <v-card-title class="text-h5">Êtes-vous sûr de bien vouloir supprimer cette vente ?</v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeDelete"
              >Annuler</v-btn
            >
            <v-btn color="blue darken-1" text @click="deleteItemConfirm"
              >Confirmer</v-btn
            >
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-data-iterator
        :items="Les_vente_particulier.ventes"
        :items-per-page.sync="itemsPerPage"
        :page.sync="page"
        :search="search"
        :sort-by="sortBy"
        :sort-desc="sortDesc"
        hide-default-footer
       >
        <template v-slot:header>
          <v-toolbar
            dark
            color="blue darken-3"
            class="mb-1"
          >
          <v-toolbar-title class="white--text">Vente Particulier</v-toolbar-title>
          <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              clearable
              flat
              solo-inverted
              hide-details
              prepend-inner-icon="mdi-magnify"
              label="Search"
            ></v-text-field>
            <template v-if="$vuetify.breakpoint.mdAndUp">
              <v-spacer></v-spacer>
              <v-select
                v-model="sortBy"
                flat
                solo-inverted
                hide-details
                :items="filteredKeysSorted"
                prepend-inner-icon="mdi-magnify"
                label="Sort by"
              ></v-select>
              <v-spacer></v-spacer>
              <v-btn-toggle
                v-model="sortDesc"
                mandatory
              >
                <v-btn
                  large
                  depressed
                  color="blue"
                  :value="false"
                >
                  <v-icon>mdi-arrow-up</v-icon>
                </v-btn>
                <v-btn
                  large
                  depressed
                  color="blue"
                  :value="true"
                >
                  <v-icon>mdi-arrow-down</v-icon>
                </v-btn>
              </v-btn-toggle>
            </template>
          </v-toolbar>
        </template>
  
        <template v-slot:default="props">
          <v-row>
            <v-col
              v-for="item in props.items"
              :key="item.id"
              cols="12"
              sm="12"
              md="12"
              lg="12"
            >
              <v-card>
                <v-row>
                  <v-col cols='12' sm='2' md='1' lg='1' xl='1'
                    v-for="(key, index) in filteredKeys"
                    :key="index"
                    v-if='key!="Action" && key!="Email" && key!="date"&& key!="Vendeur"'
                    :class="{ 'blue--text': sortBy === key }">
                      <span class="text-body-1 grey--text  mb-2">{{key}}:</span>
                       {{ item[key] }}
                  </v-col>
                  <v-col cols='12' sm='2' md='1' lg='1' xl='1'
                    v-else-if='key=="date"'
                    :class="{ 'blue--text': sortBy === key }">
                      {{ dateFormatSlach(item[key]) }}
                  </v-col>
                  <v-col cols='12' sm='3' md='2' lg='2' xl='2'
                    v-else-if='key=="Email"'
                    :class="{ 'blue--text': sortBy === key }">
                     <p> {{ item[key] }}</p>
                      <p>{{ item['Nom_du_client']}}</p>
                  </v-col>
                  
                  <v-col cols='12' sm='3' md='2' lg='2' xl='2'
                    v-else-if='key=="Vendeur"'
                    :class="{ 'blue--text': sortBy === key }">
                      <span class="text-body-1 grey--text  mb-2">{{key}}:</span>
                      {{ item[key] }}
                  </v-col>
                  <v-col cols='12' sm='2' md='1' lg='1' xl='1'
                    v-else-if='key=="Action"'>
                      <v-row>
                        <v-col xs="6" md="6" >
                          <v-icon medium class="mr-2" @click="editItem(item)">
                            mdi-pencil
                          </v-icon>
                        </v-col>
                        <v-col xs="6" md="6" >
                          <v-icon class="mr-4" medium @click="deleteItem(item)">
                            mdi-delete
                          </v-icon>
                        </v-col>
                      </v-row>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </template>
        <template v-slot:footer>
          <v-row
            class="mt-2"
            align="center"
            justify="center"
          >
            <span class="grey--text">Élements affichés </span>
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  dark
                  text
                  :color="$colors[0]"
                  class="ml-2 text-white"
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ itemsPerPage }}
                  <v-icon>mdi-chevron-down</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  v-for="(number, index) in itemsPerPageArray"
                  :key="index"
                  @click="updateItemsPerPage(number)"
                >
                  <v-list-item-title>{{ number }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
  
            <v-spacer></v-spacer>
  
            <span
              class="mr-4
              grey--text"
            >
              Page {{ page }} sur {{ numberOfPages }}
            </span>
            <v-btn
              fab
              dark
              color="blue darken-3"
              class="mr-1"
              @click="formerPage"
            >
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
            <v-btn
              fab
              dark
              color="blue darken-3"
              class="ml-1"
              @click="nextPage"
            >
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </v-row>
        </template>
      </v-data-iterator>
    </v-card>
  </div>
</template>
<script>
import axios from 'axios'

export default {
  name: 'VenteParticulier',

  data: () => ({
    isMobile: false,
    minDatePossible: null,
    editedItemId: -1,
    dialog: false,
    dialogDelete: false,
    editedItem: '',
    search: '',
    loading: true,
    rules: {
        required: value => !!value || 'Ce champ est obligatoire.',
        minPasswordLength: v => v.length >= 8 || "Le mot de passe doit avoir au minimum 8 caractères pour être valide",
        phoneLength: v => v.length == 10 || 'Le numéro de téléphone doit être constitué de 10 chiffres',
        phoneisNumber: v => v.match(/^[0-9]+$/) != null || 'Ce champ doit être constitué uniquement de chiffres',
        emailMatch: () => (`l'adresse mail et le mot de passe sont invalides`),
        
        emailRules: [
          v => !!v || 'E-mail obligatoire',
          v => /.+@.+/.test(v) || "l'adresse mail est invalide",
        ],
    },

      itemsPerPageArray: [10, 15, 20, 30],
      search: '',
      filter: {},
      sortDesc: true,
      page: 1,
      itemsPerPage: 10,
      sortBy: 'date',
      keys: [
        'date',
        'Telephone',
        'Email',
        'Fournisseur',
        'Energie',
        'Offre',
        'Status',
        'remuneration',
        'Vendeur',
        'Action',
      ],

    Les_vente_particulier: [],
    
    editedItem: {
      id: null,
      Date: null,
      Nom_du_client: null,
      Email: null,
      Telephone: null,
      Fournisseur: null,
      Energie: null,
      Offre: null,
      Status: null,
      kva: 3,
    },
    offreOption: [],
    kvaOption: [3, 6, 9, 12, 15, 18, 24, 30, 36],
    fornisseurOption: ['WEKIWI', 'OHM'],
    energierOption: ['Gaz', 'Elec', 'Dual'],
    statusOption: ['En attente délais de rétractation', 'Validé', 'Retracté']
  }),
  watch: {
    dialog(val) {
      val || this.close()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    },
  },
  computed: {
    formTitle() {
        if(this.editedItemId >= 0)
            return 'Editer Vente Particulier'
        else
            return ''
    },
    numberOfPages () {
      return Math.ceil(this.Les_vente_particulier.ventes.length / this.itemsPerPage)
    },
    filteredKeys () {
      return this.keys
    },
    filteredKeysSorted () {
      return this.keys.filter(key => key !== 'Action')
    },
  },
  created() {
    this.minDatePossible = this.currentDateTime();
    this.typeUser = localStorage.getItem('typeUser')
    //this.verifyApplicationDataSecurity()
    this.getAllVente()
    
    
  },
  methods: { 
    
    dateFormatSlach(date){
      return date.split('-').reverse().join('/')
    },
    dateFormatMinus(date){
      return date.split('/').reverse().join('-')
    },
    nextPage () {
      if (this.page + 1 <= this.numberOfPages) this.page += 1
    },
    formerPage () {
      if (this.page - 1 >= 1) this.page -= 1
    },
    updateItemsPerPage (number) {
      this.itemsPerPage = number
    },
    onResize() {
        if (window.innerWidth < 769)
          this.isMobile = true;
        else
          this.isMobile = false;
    },
    editItem(item) {
      this.editedItemId = item.id
      this.editedItem = Object.assign({}, item) //copie le contenu d'item dans editedItem
      this.onSelectFornisseur(this.editedItem.Fournisseur);
      this.dialog = true
    },

    deleteItem(item) {
      this.editedItem = item
      this.dialogDelete = true
    },

    deleteItemConfirm() {
      this.supprimerVente(this.editedItem)
      this.closeDelete()
    },
    close() {
        this.compteExist = false;
        this.dialog = false
        this.$nextTick(() => {
            this.editedItem = Object.assign({}, this.defaultItem)
            this.editedItemId = -1
        })
    },
    onSelectFornisseur(fornisseur){
        if(fornisseur=='WEKIWI'){
            this.offreOption=['Prix Indexe', 'Fixe'];
        }else if(fornisseur=='OHM'){
            this.offreOption=['Classique', 'Petite conso'];
        }else if(fornisseur=='ELMY'){
            this.offreOption=['Indexe TRV'];
        }else if(fornisseur=='ELECOCITE'){
            this.offreOption=['Indexe TRV'];
        }
    },
    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedItemId = -1
      })
    },
    save() {
      this.$refs.form.validate()
      if(this.$refs.form.validate()){
        if (this.editedItemId > -1) {
          this.modifierVente(this.editedItem)
        }
      }
    },
    verifyApplicationDataSecurity() {
      if (this.$cookies.get('token')) {
                var formData = new FormData()
                formData.append('type', localStorage.getItem('typeUser'))
                formData.append('vueAfficher', 'part')
                formData.append('token', this.$cookies.get('token'))
                axios({
                    url:
                    process.env.VUE_APP_URL_API_CLIENT +
                    'verifyApplicationDataSecurity.php',
                    method: 'POST',
                    data: formData,
                    
                })
                .then((res) => {
                var data = JSON.parse(JSON.stringify(res.data))
                if (data[0].result === 'notLogin') {
                    localStorage.setItem('vueAfficher', 'particulier')
                    localStorage.removeItem('vendeurName')
                    localStorage.removeItem('typeUser')
                    this.$cookies.remove('token')
                    this.$router.push('/login')
                } else if (data[0].result === 'accessDenied') {
                    localStorage.setItem('vueAfficher', 'particulier')
                    localStorage.removeItem('vendeurName')
                    localStorage.removeItem('typeUser')
                    this.$cookies.remove('token')
                    this.$router.push('/login')
                }
                })
                .catch((err) => {
                console.log(err)
                })
            }else{
                this.$router.push('/login')
            }
    },
    supprimerVente(item) {
      let self = this
      var formData = new FormData()
      formData.append('id', item.id)
      formData.append('token', this.getToken('token'))
      axios({
        url:
          process.env.VUE_APP_URL_API_CLIENT +
          'supprimerVenteParticulier/',
        method: 'POST',
        data: formData,
      })
        .then((res) => {
          if (res.data.result === true) {
           var mess = "-------------------------------------\n"+
              "l'administrateur "+localStorage.getItem('email')+" a supprimé la vente particulier:"+
              "\n email client:"+item.Email+
              "\n N° téléphone:"+item.Telephone+
              "\n date: "+item.Date+
              "\n----------------------------------------"
            //this.envoyerNotification(mess)
            this.getAllVente()
          } else if (res.data.result === false) {
            alert('vente déjà supprimée')
            this.getAllVente()
          } else if (res.data.result === 'permission') {
            localStorage.removeItem('vendeurName')
            localStorage.removeItem('typeUser')
            localStorage.removeItem('token')
            this.$router.push('/login')
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    getAllVente() {
      let self = this
      var formData = new FormData()
        formData.append('token', this.getToken('token'))
      axios({
        url:
          process.env.VUE_APP_URL_API_CLIENT +
          'getAllVenteParticulier/',
        method: 'POST',
        data: formData,
        
      })
        .then((res) => {
          if (res.data.result == true) {
            self.Les_vente_particulier = res.data
          } else if (res.data.result === 'permission') {
            localStorage.removeItem('vendeurName')
            localStorage.removeItem('typeUser')
            localStorage.removeItem('token')
            this.$router.push('/login')
          }
          self.loading = false;
        })
        .catch((err) => {
          console.log(err)
          self.showModalLoader = false
        })
    },
    modifierVente(vente) {
      //let self = this
      var formData = new FormData()
      formData.append('id', vente.id)
      formData.append('date', vente.date)
      formData.append('Nom_du_client', vente.Nom_du_client)
      formData.append('Email', vente.Email)
      formData.append('Telephone', vente.Telephone)
      formData.append('Fournisseur', vente.Fournisseur)
      formData.append('Energie', vente.Energie)
      formData.append('Offre', vente.Offre)
      formData.append('Status', vente.Status)
      formData.append('kva', vente.kva)
      formData.append('token', this.getToken('token'))
      axios({
        url:
          process.env.VUE_APP_URL_API_CLIENT +
          'modifierVenteParticulier/',
        method: 'POST',
        data: formData,
      })
        .then((res) => {
          if (res.data.result == true) {
            this.getAllVente()
            this.close()
          }else if (res.data.result == false) {
            alert('vente déjà supprimée')
            this.close()
          } else if (res.data.result == 'permission')  {
            localStorage.removeItem('vendeurName')
            localStorage.removeItem('typeUser')
            localStorage.removeItem('token')
            this.$router.push('/login')
          }
          this.showModalLoader = false
        })
        .catch((err) => {
          this.showModalLoader = false
          console.log(err)
        })
    },
    currentDateTime() {
      var current = new Date()
      current.setDate(current.getDate() - 7)
      var day = 0
      if (current.getDate() < 10) {
        day = '0' + current.getDate()
      } else {
        day = current.getDate()
      }
      var month = 0
      if (current.getMonth() + 1 < 10) {
        month = '0' + (current.getMonth() + 1)
      } else {
        month = current.getMonth() + 1
      }
      const date = current.getFullYear() + '-' + month + '-' + day
      return date
    },
    async envoyerNotification(message){
        var formData = new FormData()
        formData.append('msg', message)
        axios({
            url:'https://app.volto.fr/slackBotSendMessage/',
            method: 'POST',
            data: formData,
        }).then((res) => {
        })
    },
  },
}
</script>