<template>
  <div>
    <!-- si la liste companiesProGaz est vide  -->
    <div
      class="company-list"
      v-if="
        !companiesProGaz.length ||
          siretFoundAcdgaz == false || siretFoundAcdgaz == null
      "
    >
      <h3 class="list-header">
        veuillez renseigner vos informations techniques
      </h3>
      <h3 class="llist-data" style="padding: 20px">
        veuillez renseigner vos informations techinques
      </h3>
    </div>
    <!-- si la liste companiesProGaz n'est pas vide   -->
    <div
      v-else
      class="company-list"
      v-for="company in companiesProGaz"
      v-bind:key="company.forniseur"
    >
      <h3 class="list-header">{{ company.forniseur }}</h3>
      <div class="list-data">
        <div class="company-info">
          <img :src="getImageLogo(company.logo)" alt="" />
        </div>
        <div class="calculation yellow-bg">
          <p><b>Budget Annuel</b></p>
          <p>
            <b>{{ company.budget.toFixed(2) }}€</b>
          </p>
        </div>
        <div class="calculation">
          <p><b>Molécule/ARTD</b></p>
          <h3 class="head2">Molécule: {{ company.molecule }}€/Mwh</h3>
          <h3 class="head2">ARTD: {{ company.artd }}€/Mwh</h3>
        </div>
        <div class="calculation">
          <p><b>TICGN/CEE</b></p>
          <h3 class="head2">TICGN: {{ company.ticgn }}€/Mwh</h3>
          <h3 class="head2">CEE: {{ company.cee }}€/Mwh</h3>
        </div>
        <div class="calculation">
          <p><b>Abonnement/CTA</b></p>
          <h3 class="head2">Abonnement: {{ company.abonnement }}€/mois</h3>
          <h3 class="head2">CTA: {{ company.cta }}€/ans</h3>
        </div>
        <div class="calculation">
          <button class="yellow-btn1" @click="openLink(company.link)">
            Éditer contrat
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'proGazBody',
  props: {
    companiesProGaz: [],
    siretFoundAcdgaz: null,
  },
  methods: {
    getImageLogo(logo) {
      return process.env.VUE_APP_URL_API_CLIENT + 'logoParticulier/' + logo
    },
    getImgUrl(logo) {
      return require('../../assets/' + logo.split('.')[0] + '.png')
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h2,
h3,
p {
  margin: 0px;
  text-align: top;
}
.pt-2 {
  padding-top: 7px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: button;
}

.h2 {
  color: rgb(226, 224, 79);
}
.makeVente {
  margin-right: 50px;
  float: right;
}
.company {
  max-width: 100%;
  min-width: 100%;
  margin: 0px auto;
  .my-form {
    justify-content: center;
    align-items: center;
    button {
      width: 200px;
      border-radius: 55px;
      margin: 10px;
    }
    table,
    button {
      display: inline-block;
    }
    table {
      width: 350px;
    }
    table td {
      width: 175px;
    }
    input[type='radio'] {
      -ms-transform: scale(2.5); /* IE */
      -moz-transform: scale(2.5); /* FF */
      -webkit-transform: scale(2.5); /* Safari and Chrome */
      -o-transform: scale(2.5); /* Opera */
      transform: scale(2.5);
      padding: 30px;
    }
    .check1 {
      display: inline-block;
      margin: 10px 10% 10px 20px;
    }
    .checkboxtext {
      font-size: 110%;
    }
    .compteur_form {
      display: inline-block;
    }
    input[type='number'],
    input[type='text'],
    input[type='date'],
    select {
      display: inline-block;
      width: 100%;
      width: 200px;
      border: 2px solid #aaa;
      border-radius: 55px;
      margin: 10px;
      outline: none;
      padding: 10px;
      color: #333;
      box-sizing: border-box;
      transition: 0.3s;
      // &focus {
      //   border-color: none;
      //   box-shadow: 0 0 8px 0 dodgerBlue;
      // }
    }
    #nom,
    #prenom {
      width: 100%;
      width: 200px;
      border: 2px solid #aaa;
      border-radius: 55px;
      margin: 10px;
      outline: none;
      padding: 10px;
      color: #333;
      box-sizing: border-box;
      transition: 0.3s;
      // &focus {
      //   border-color: none;
      //   box-shadow: 0 0 8px 0 dodgerBlue;
      // }
    }
    #raison {
      width: 100%;
      width: 500px;
      border: 2px solid #aaa;
      border-radius: 55px;
      margin: 10px;
      outline: none;
      padding: 10px;
      color: #333;
      box-sizing: border-box;
      transition: 0.3s;
      // &focus {
      //   border-color: none;
      //   box-shadow: 0 0 8px 0 dodgerBlue;
      // }
    }
    @media screen and (max-width: 600px) {
      display: grid;
      grid-template-columns: 100%;
      button,
      select,
      input[type='number'] {
        width: 100%;
        margin: 10px 0px;
      }
    }
  }
  .company-list {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 30px 30px 0px 0px;
    margin-top: 20px;
    .list-header {
      border-radius: 30px 30px 0px 0px;
      text-align: left;
      background-color: #3394e7;
      font-weight: bold;
      color: #fff;
      font-size: 20px;
      padding: 15px;
      margin: 0px;
    }

    .list-data {
      display: grid;
      grid-template-columns: 20% 14% 16% 16% 18% 16%;
      justify-content: space-between;
      padding: 15px;
      align-items: center;
      .company-info {
        padding: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: 100%;
          height: auto;
        }
      }
      .details-btn {
        padding: 10px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
      }
      .yellow-bg {
        background-color: #fdedcd;
        border-radius: 10px;
      }
      .calculation {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        padding: 10px;
      }

      @media screen and (max-width: 1050px) {
        grid-template-columns: 33% 33% 33%;
      }
      @media screen and (max-width: 550px) {
        grid-template-columns: 100%;
      }
    }
  }
}
.yellow-btn {
  background-color: #fab627;
  color: #fff;
  padding: 10px 0px;
  width: 120px;
  font-size: 16px;
  border-radius: 55px;
  border: none;
  cursor: pointer;
  &:focus {
    outline: none;
  }
  &:hover {
    background-color: #dea939;
  }
}
.yellow-btn1 {
  background-color: #fab627;
  color: #fff;
  padding: 10px 0px;
  width: 80px;
  font-size: 14px;
  border-radius: 55px;
  border: none;
  cursor: pointer;
  &:focus {
    outline: none;
  }
  &:hover {
    background-color: #dea939;
  }
}
.red-del {
  background-color: #ee4951;
  color: rgb(27, 25, 25);
  padding: 8px 8px;

  font-size: 14px;
  border-radius: 55px;
  border: none;
  cursor: pointer;
  &:focus {
    outline: none;
  }
  &:hover {
    background-color: #d44242;
  }
}
.green-add {
  background-color: #addda1;
  color: rgb(27, 25, 25);
  padding: 8px 8px;

  font-size: 14px;
  border-radius: 55px;
  border: none;
  cursor: pointer;
  &:focus {
    outline: none;
  }
  &:hover {
    background-color: #7ad351;
  }
}
.head1 {
  font-size: 36px;
}
.head2 {
  font-size: 1.15em;
}
.head3 {
  font-size: 18px;
}

.cls_002 {
  font-family: Arial, serif;
  font-size: 12.1px;
  color: rgb(0, 0, 0);
  font-weight: normal;
  font-style: normal;
  text-decoration: none;
}
.cls_003 {
  font-family: Arial, serif;
  font-size: 9.1px;
  color: rgb(0, 0, 0);
  font-weight: normal;
  font-style: normal;
  text-decoration: none;
}
.cls_004 {
  font-family: Arial, serif;
  font-size: 9.1px;
  color: rgb(0, 0, 0);
  font-weight: bold;
  font-style: normal;
  text-decoration: none;
}
.cls_005 {
  font-family: Arial, serif;
  font-size: 8.1px;
  color: rgb(0, 0, 0);
  font-weight: normal;
  font-style: normal;
  text-decoration: none;
}
.cls_006 {
  font-family: Arial, serif;
  font-size: 8.1px;
  color: rgb(17, 84, 204);
  font-weight: normal;
  font-style: normal;
  text-decoration: none;
}
.cls_007 {
  font-family: Arial, serif;
  font-size: 9.1px;
  color: rgb(0, 0, 0);
  font-weight: bold;
  font-style: normal;
}

/* The Modal (background) */
.modall {
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

/* Modal Content */
.modall-content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 50%;
}
.loader {
  margin: auto;
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
