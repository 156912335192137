var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(
      !_vm.companiesProGaz.length ||
        _vm.siretFoundAcdgaz == false || _vm.siretFoundAcdgaz == null
    )?_c('div',{staticClass:"company-list"},[_c('h3',{staticClass:"list-header"},[_vm._v(" veuillez renseigner vos informations techniques ")]),_c('h3',{staticClass:"llist-data",staticStyle:{"padding":"20px"}},[_vm._v(" veuillez renseigner vos informations techinques ")])]):_vm._l((_vm.companiesProGaz),function(company){return _c('div',{key:company.forniseur,staticClass:"company-list"},[_c('h3',{staticClass:"list-header"},[_vm._v(_vm._s(company.forniseur))]),_c('div',{staticClass:"list-data"},[_c('div',{staticClass:"company-info"},[_c('img',{attrs:{"src":_vm.getImageLogo(company.logo),"alt":""}})]),_c('div',{staticClass:"calculation yellow-bg"},[_vm._m(0,true),_c('p',[_c('b',[_vm._v(_vm._s(company.budget.toFixed(2))+"€")])])]),_c('div',{staticClass:"calculation"},[_vm._m(1,true),_c('h3',{staticClass:"head2"},[_vm._v("Molécule: "+_vm._s(company.molecule)+"€/Mwh")]),_c('h3',{staticClass:"head2"},[_vm._v("ARTD: "+_vm._s(company.artd)+"€/Mwh")])]),_c('div',{staticClass:"calculation"},[_vm._m(2,true),_c('h3',{staticClass:"head2"},[_vm._v("TICGN: "+_vm._s(company.ticgn)+"€/Mwh")]),_c('h3',{staticClass:"head2"},[_vm._v("CEE: "+_vm._s(company.cee)+"€/Mwh")])]),_c('div',{staticClass:"calculation"},[_vm._m(3,true),_c('h3',{staticClass:"head2"},[_vm._v("Abonnement: "+_vm._s(company.abonnement)+"€/mois")]),_c('h3',{staticClass:"head2"},[_vm._v("CTA: "+_vm._s(company.cta)+"€/ans")])]),_c('div',{staticClass:"calculation"},[_c('button',{staticClass:"yellow-btn1",on:{"click":function($event){return _vm.openLink(company.link)}}},[_vm._v(" Éditer contrat ")])])])])})],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('b',[_vm._v("Budget Annuel")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('b',[_vm._v("Molécule/ARTD")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('b',[_vm._v("TICGN/CEE")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('b',[_vm._v("Abonnement/CTA")])])
}]

export { render, staticRenderFns }