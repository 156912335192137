<template>
    <div  class='ml-3 mr-2'>
      <v-card>
            <v-toolbar dark :color="$colors[0]" fixed>
                <v-toolbar-title class="white--text">Mes Ventes Pro</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-text-field v-model="search" append-icon="search" label="Search" single-line hide-details></v-text-field>
            </v-toolbar>
            
            <v-layout column style="padding-top:0px">

                <v-data-iterator
                    :search="search"
                    :items="mesVentePro.ventes"
                    item-key="id"
                    :items-per-page="4"
                    :single-expand="singleExpand"
                    
                    >
                    <template v-slot:default="{ items, isExpanded, expand }">
                    
                    <v-row>
                        <v-col
                        v-for="item in items"
                        :key="item.id"
                        cols="12"
                        sm="12"
                        md="12"
                        lg="12"
                        >
                        <v-card>
                            <!--<v-switch
                            :input-value="isExpanded(item)"
                            :label="isExpanded(item) ? 'ferme' : 'ouvre'"
                            class="pl-4 mt-0"
                            @change="(v) => expand(item, v)"
                            ></v-switch>
                            <v-card-title>
                            </v-card-title>
                            -->
                            <v-divider></v-divider>
                            <v-list
                            dense
                            >
                            
                            <v-list-item>
                                <v-list-item-content>
                                <v-row>
                                    <v-col cols="12" sm="2" md="1" lg="1" xl='1'>
                                        <div style="max-height:80px; overflow: auto;">
                                            <p>{{dateFormatSlach(item.date)}}</p>
                                        </div>
                                    </v-col>
                                    <v-col cols="12" sm="2" md="1" lg="1" xl='1'>
                                        <div style="max-height:80px; overflow: auto;">
                                            <p>{{item.type}}</p>
                                        </div>
                                    </v-col>
                                    <v-col cols="12" sm="4" md="4" lg="3" xl='3'>
                                        <div style="max-height:130px; overflow: auto;">
                                            <p><span class="text-body-1 grey--text  mb-2">Raison:</span>{{mesVentePro.societes[item.Societe].Raison}}</p>
                                            <p><span class="text-body-1 grey--text  mb-2">Vendeur:</span>{{mesVentePro.comptes[item.Compte].nom+' '+mesVentePro.comptes[item.Compte].prenom }}</p>
                                            <p><span class="text-body-1 grey--text  mb-2">N° compteur: </span>{{item.numCompteur}}</p>
                                        </div>
                                    </v-col>
                                    <v-col cols="12" sm="2" md="2" lg="1" xl='1'>
                                            <span class="text-body-1 grey--text  mb-2">Fournisseur: </span>
                                            <p>{{item.fournisseur}}</p>
                                    </v-col>
                                    <v-col cols="12" sm="2" md="1" lg="1" xl='1'>
                                        <span class="text-body-1 grey--text  mb-2">Valeur: </span>
                                        <p>{{item.valeur+" €"}}</p>
                                    </v-col>
                                    <v-col cols="12" sm="3" md="2" lg="2" xl='2'>
                                        <span class="text-body-1 grey--text  mb-2">Commentaire:</span>
                                        <p>{{item.commentaire}}</p>
                                        <hr/>
                                        <div v-if="item.status=='Refuser' && item.commentaireRefus!= ''" style="max-height:120px; overflow: auto;" class="mt-2">
                                            <span class="text-body-3 orange--text  mb-2">Commentaire refus</span>
                                            <p>{{item.commentaire_refus}}</p>
                                        </div>
                                    </v-col>
                                    <v-col cols="12" sm="2" md="1" lg="1" xl='1'>
                                        <span class="text-body-1 grey--text  mb-2">Status: </span>
                                        <div>
                                            <p v-if="item.status == 'Refuser'" style="color: red;"><b>refusée</b></p>
                                            <p v-if="item.status == 'Accepter'" style="color: green;"><b>acceptée</b></p>
                                            <p v-if="item.status == 'en Attend'" style="color: gray;"><b>en attente</b></p>
                                            <p v-if="item.status == 'en attente de validation'" style="color: blue;"><b>Phase de validation</b></p>
                                        </div>
                                    </v-col>
                                    <v-col cols="12" sm="2" md="2" lg="1" xl='1'>
                                        <div style="max-height:120px; overflow: auto;">
                                            <div class="mb-2">
                                                <div v-if="item.Contrat != null">
                                                    <a style="text-decoration:none" :href="item.Contrat" target="_blank">
                                                        <span class="text-body-2 blue--text mb-2">Contrat</span>
                                                    </a>
                                                </div>
                                            </div>

                                            <div class="mb-2">
                                                <div v-if="item.RIB != null">
                                                    <a style="text-decoration:none" :href="item.RIB" target="_blank">
                                                        <span class="text-body-2 blue--text mb-2">RIB</span>
                                                    </a>
                                                </div>
                                            </div>
                                            <div class="mb-2">
                                                <div v-if="item.Factures != null">
                                                    <a style="text-decoration:none" :href="item.Factures" target="_blank">
                                                        <span class="text-body-2 blue--text mb-2">Factures</span>
                                                    </a>
                                                </div>
                                            </div>
                                            
                                            <div class="mb-2">
                                                <div v-if="item.SEPA != null">
                                                    <a style="text-decoration:none" :href="item.SEPA" target="_blank">
                                                        <span class="text-body-2 blue--text mb-2">SEPA</span>
                                                    </a>
                                                </div>
                                            </div>
                                            
                                            <div class="mb-2">
                                                <div v-if="item.ACD != null">
                                                    <a style="text-decoration:none" :href="item.ACD" target="_blank">
                                                        <span class="text-body-2 blue--text mb-2">ACD</span>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </v-col>
                                </v-row>
                                </v-list-item-content>
                            </v-list-item>
                            </v-list>
                        </v-card>
                        </v-col>
                    </v-row>
                    </template>
                </v-data-iterator>
            </v-layout>
      </v-card>
    </div>
</template>

<script>
    import axios from 'axios'
    export default {
        name: 'mesVentePro',
        components: {
        },
        data: () => ({
                status : "",
                search: '',
                mesVentePro: [],

        }),
        async created() {
            var whoIam = ""
            await this.whoIAm(this.getToken('token')).then((data)=>{whoIam=data});
            if(whoIam == "noToken"){
                localStorage.removeItem('vendeurName')
                localStorage.removeItem('typeUser') 
                localStorage.removeItem('token')
                this.$router.push('/login')
            }else{
                localStorage.setItem('typeUser', whoIam)
            }
            this.getAllVentePro()
        },
        methods: {
            dateFormatSlach(date){
                return date.split('-').reverse().join('/')
            },
            dateFormatMinus(date){
                return date.split('/').reverse().join('-')
            },
            getFileName(fileUrl){
                return fileUrl.split('/')[fileUrl.split('/').length-1].split('_')[0]
            },
            splitedList(str){
                
                if(str !== '' && str != null){
                    return str.split(',')
                }else{
                    return null
                }
            },
            verifyApplicationDataSecurity() {
               if (this.$cookies.get('token')) {
                    var formData = new FormData()
                    formData.append('type', localStorage.getItem('typeUser'))
                    formData.append('vueAfficher', 'pro')
                    formData.append('token', this.$cookies.get('token'))
                    axios({
                        url:
                        process.env.VUE_APP_URL_API_CLIENT +
                        'verifyApplicationDataSecurity.php',
                        method: 'POST',
                        data: formData,
                        
                    })
                    .then((res) => {
                    var data = JSON.parse(JSON.stringify(res.data))
                    if (data[0].result === 'notLogin') {
                        localStorage.setItem('vueAfficher', 'particulier')
                        localStorage.removeItem('vendeurName')
                        localStorage.removeItem('typeUser')
                        this.$cookies.remove('token')
                        this.$router.push('/login')
                    } else if (data[0].result === 'accessDenied') {
                        localStorage.setItem('vueAfficher', 'particulier')
                        localStorage.removeItem('vendeurName')
                        localStorage.removeItem('typeUser')
                        this.$cookies.remove('token')
                        this.$router.push('/login')
                    }
                    })
                    .catch((err) => {
                    console.log(err)
                    })
                }else{
                    this.$router.push('/login')
                }
            },
            getAllVentePro() {
                let self = this
                
                var formData = new FormData()
                formData.append('token', this.getToken('token'))
                axios({
                    url:
                    process.env.VUE_APP_URL_API_CLIENT +
                    'getMesVentePro/',
                    method: 'POST',
                    data: formData,
                }).then((res) => {
                    if (res.data.result == true) {
                        self.mesVentePro = res.data
                    } if (res.data.result == false) {
                        alert('no data')
                    }else if (res.data.result == 'permission'){
                        localStorage.removeItem('vendeurName')
                        localStorage.removeItem('typeUser')
                        localStorage.removeItem('token')
                        this.$router.push('/login')
                    }
                })
            },
        
        }
    }
</script>