<template>
    <div>
        <v-btn
        @click="editExel"
        icon
        depressed>
            <v-icon  :color="$colors[0]">mdi-text-box-edit</v-icon>
        </v-btn>
    </div>
</template>
<script>
    import { readFile, writeFileXLSX, utils} from "xlsx";
    export default {
        name: "EkwateurExcel",
        data() {
            return {

            }
        },
        methods: {
            editExel(){
                var file = readFile('C:/Users/slime/Desktop/gitLabFrontNewVersion/front-architecture/front-gaz-elec-moinscher/src/views/editionContrat/Annexe.xlsx');
                var workSheet = {}
                for(var sheetName of file.SheetNames){
                    console.log(sheetName)
                }
            }
        }
    }

</script>